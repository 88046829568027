import React, { FC } from 'react'
import { Box, Stack } from '@mui/material'
import {
  GridToolbarQuickFilter,
  GridToolbarQuickFilterProps,
} from '@mui/x-data-grid-premium'
import { observer } from 'mobx-react'

import Documents from '../../../Model/Documents'

import BulkActions from './BulkActions'

const DocumentListTableToolbar: FC<
  GridToolbarQuickFilterProps & {
    disableBulkActions: boolean
    documents: Documents
  }
> = ({ disableBulkActions, documents, ...rest }): JSX.Element => (
  <Stack direction='row' justifyContent='space-between'>
    {!disableBulkActions && (
      <Box sx={{ mt: 1 }}>
        <BulkActions documents={documents} />
      </Box>
    )}
    <Box p={1}>
      <GridToolbarQuickFilter {...rest} sx={{ mt: 1 }} />
    </Box>
  </Stack>
)

export default observer(DocumentListTableToolbar)
