import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'

const MainPanelCard = styled(Card)({
  display: 'flex',
  height: '100%',
  width: '100%',
})

export default MainPanelCard
