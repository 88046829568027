import {
  observable,
  override,
  toJS,
  makeObservable,
  IObservableArray,
} from 'mobx'
import moment from 'moment'

import File from './File'
import ModelBase from './ModelBase'

import RootStore from '../stores/RootStore'

import { generateDocumentSlug } from '../lib/slug'
import { sortArrayAlpha } from '../utils/arrays'

export default class Folder extends ModelBase {
  folderProperties = [
    'path',
    'contents',
    'name',
    'groups',
    'modified',
    'tags',
    'users',
    'isPublic',
    'creator',
  ]

  constructor(
    rootStore: RootStore,
    org_id: string | undefined,
    folder: {
      isNew: boolean | undefined
      groups: string[] | undefined
      users: string[] | undefined
      contents: Array<File | typeof this>
      inheritedGroups: string[] | undefined
      inheritedUsers: string[] | undefined
      viewedBy: string[] | undefined
      isGlobalFolder: boolean | undefined
      memberCanUpload: boolean | undefined
      isReadOnly: boolean | undefined
      path: string | undefined
      name: string | undefined
      creator: string | undefined
      modified: number | undefined
      isInherited: boolean | undefined
      isHostFolder: boolean | undefined
    } = {
      isNew: undefined,
      groups: undefined,
      users: undefined,
      contents: [],
      inheritedGroups: undefined,
      inheritedUsers: undefined,
      viewedBy: undefined,
      isGlobalFolder: undefined,
      memberCanUpload: undefined,
      isReadOnly: undefined,
      path: undefined,
      name: undefined,
      creator: undefined,
      modified: undefined,
      isInherited: undefined,
      isHostFolder: undefined,
    },
    userId: string | undefined = undefined
  ) {
    super(rootStore)

    makeObservable(this, {
      type: observable,
      name: observable,
      path: observable,
      groups: observable,
      users: observable,
      modified: observable,
      contents: observable,
      isNew: observable,
      tags: observable,
      isGlobalFolder: observable,
      isInherited: observable,
      isReadOnly: observable,
      memberCanUpload: observable,
      creator: observable,
      org_id: observable,
      icon: override,
      iconColor: override,
      inheritedGroupsArray: observable,
      inheritedGroups: override,
      inheritedUsersArray: observable,
      inheritedUsers: override,
      data: override,
      category: override,
      permalink: override,
      posted: override,
    })

    this.org_id = org_id
    this.isReadOnly = folder.isReadOnly || false
    this.isGlobalFolder = folder.isGlobalFolder || false
    this.isHostFolder = Boolean(folder.isHostFolder || false)
    this.isInherited = Boolean(folder.isInherited || false)
    this.permissionTags = observable.array(folder?.permissionTags || [])
    this.memberCanUpload = this.isHostFolder
      ? false
      : typeof folder.memberCanUpload !== 'undefined'
      ? folder.memberCanUpload
      : true
    this.folderProperties.forEach(prop => {
      if (prop === 'contents') {
        this.contents = observable.array(
          folder.contents
            .map(obj => {
              obj.inheritedGroups =
                folder.groups && folder.groups.length > 0
                  ? folder.groups
                  : folder.inheritedGroups || []
              obj.inheritedUsers =
                folder.users && folder.users.length > 0
                  ? folder.users
                  : folder.inheritedUsers || []
              if (obj.contents) {
                return new Folder(
                  this.rootStore,
                  org_id,
                  {
                    isReadOnly: this.isReadOnly,
                    memberCanUpload: this.memberCanUpload,
                    ...obj,
                    path: `${this.path}/${obj.name}`,
                    isInherited: this.isInherited,
                    isHostFolder: this.isHostFolder,
                  },
                  userId
                )
              }
              return new File(
                this.rootStore,
                org_id,
                {
                  isReadOnly: this.isReadOnly,
                  path: this.path,
                  ...obj,
                  isInherited: this.isInherited,
                },
                userId
              )
            })
            .sort((a, b) => {
              if (a.type === 'folder') {
                if (b.type === 'file') {
                  return -1
                }
              }

              if (a.type === 'file' && b.type === 'folder') {
                return 1
              }

              return sortArrayAlpha(a, b, 'name')
            })
        )
      } else if (prop === 'path') {
        this.path = folder.path || `/${folder.name}`
      } else {
        this[prop] = folder[prop]
      }
    })
    this.isNew = folder.isNew
    // Prevents rerender on traversal from showing new folder indicator again
    setTimeout(() => {
      this.isNew = false
    }, 7000)
    this.groups = observable.array(
      Array.isArray(folder.groups) ? folder.groups : []
    )
    this.users = observable.array(
      Array.isArray(folder.users) ? folder.users : []
    )
    this.inheritedUsersArray = observable.array(
      Array.isArray(folder.inheritedUsers) ? folder.inheritedUsers : []
    )
    this.inheritedGroupsArray = observable.array(
      Array.isArray(folder.inheritedGroups) ? folder.inheritedGroups : []
    )
    this.path =
      typeof this.path === 'string' ? this.path.replace(/\/\//g, '/') : ''
  }

  modelCollection = 'documents'

  type = 'folder'

  name = ''

  path = ''

  groups: IObservableArray<string> = observable.array([])

  users: IObservableArray<string> = observable.array([])

  inheritedGroupsArray: IObservableArray<string> = observable.array([])

  inheritedUsersArray: IObservableArray<string> = observable.array([])

  modified = Date.now()

  contents: IObservableArray<File | typeof this> = observable.array([])

  isNew = false

  tags: IObservableArray<string> = observable.array([])

  isGlobalFolder = false

  isHostFolder = false

  isInherited = false

  toggleGlobalFolderContent = async () => {
    const { data } = await this.client.documents.toggleGlobalFolderContent(
      this.org_id,
      this.data
    )

    this.rootStore.documentStore.loadDocuments(data)
  }

  isReadOnly = false

  memberCanUpload = true

  creator: string | undefined = undefined

  org_id: string | undefined = undefined

  getNotifyId = (): string => this.path

  getPathString = (): string => this.path

  get icon(): string {
    if (this.isHostFolder) {
      return 'hostFolder'
    }

    if (this.isInherited) {
      return 'globalContentFolder'
    }

    return 'folderOpened'
  }

  get iconColor(): string {
    if (this.isHostFolder || this.isInherited) {
      return ''
    }

    return 'sandybrown'
  }

  get inheritedGroups(): string[] {
    return this.inheritedGroupsArray.toJSON()
  }

  get inheritedUsers(): string[] {
    return this.inheritedUsersArray.toJSON()
  }

  get data() {
    if (this.isHostFolder) {
      return undefined
    }

    return this.folderProperties.reduce(
      (acc, prop) => {
        if (prop === 'contents') {
          acc.contents = this.contents.map(p => p.data)
        } else {
          acc[prop] = toJS(this[prop])
        }
        return acc
      },
      {
        contents: undefined,
        isInherited: this.isInherited || undefined,
        permissionTags: this.isInherited ? toJS(this.permissionTags) : [],
      }
    )
  }

  get permalink(): string {
    return `/documents/${generateDocumentSlug(
      this.rootStore.orgStore.currentOrg._id,
      {
        path: this.path,
      }
    )}`
  }

  get posted(): string {
    return moment(this.modified).fromNow()
  }

  get category(): string {
    return 'folder'
  }

  get displayName(): string {
    return 'Folder'
  }
}
