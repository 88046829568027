import {
  action,
  IObservableArray,
  computed,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx'

import DiscussionMessage from './DiscussionMessage'
import Link from './Link'
import ModelBase from './ModelBase'

import RootStore from '../stores/RootStore'

import { useLog } from '../lib/log'

import { DiscussionProps } from '../types/discussion'

const log = useLog()

const discussionProps = [
  '_id',
  'org_id',
  'topic',
  'summary',
  'parent',
  'parentCollection',
  'parentTitle',
  'parentSlug',
  'creator',
  'messages',
  'tags',
  'groups',
  'users',
  'readBy',
]

export default class Discussion extends ModelBase {
  constructor(rootStore: RootStore, discussion: DiscussionProps) {
    super(rootStore)
    makeObservable(this, {
      createdAt: observable,
      updatedAt: observable,
      author: observable,
      _id: observable,
      org_id: observable,
      parent: observable,
      parentCollection: observable,
      parentTitle: observable,
      parentSlug: observable,
      creator: observable,
      topic: observable,
      summary: observable,
      messages: observable,
      tags: observable,
      groups: observable,
      users: observable,
      readBy: observable,
      data: override,
      isLinked: computed,
      addMessage: action,
      deleteMessage: action,
      discussionSaving: observable,
      discussionSaveSuccess: observable,
      discussionSaveError: observable,
      refresh: action,
      save: action,
      toggleArchived: action,
      category: override,
      permalink: override,
      getMessageModel: action,
      getMessageReadByList: action,
    })

    this.loadData(discussion)

    this.createdAt = discussion.createdAt || Date.now()
    this.updatedAt = discussion.updatedAt || Date.now()
    this.author = discussion.author
    this.readBy = observable.array(discussion.readBy || [])
  }

  loadData = (data: DiscussionProps) => {
    discussionProps.forEach(prop => {
      if (prop !== 'messages') {
        this[prop] = data[prop]
      } else {
        this.messages = observable.array(
          Array.isArray(data.messages)
            ? data.messages.map(
                m =>
                  new DiscussionMessage(
                    this.rootStore,
                    m,
                    data.org_id,
                    data._id
                  )
              )
            : []
        )
      }
    })

    this.archivedAt = data.archivedAt || 0
    this.createdAt = data.createdAt || Date.now()
    this.updatedAt = data.updatedAt || Date.now()
    this.author = data.author
    this.readBy = observable.array(data.readBy || [])
  }

  modelCollection = 'discussions'

  createdAt: number | undefined = undefined

  updatedAt: number | undefined = undefined

  author: string | undefined = undefined

  _id: string | undefined = undefined

  org_id: string | undefined = undefined

  parent: string | undefined = undefined

  parentCollection: string | undefined = undefined

  parentTitle: string | undefined = undefined

  parentSlug: string | undefined = undefined

  creator: string | undefined = undefined

  topic = ''

  summary = false

  messages: IObservableArray<DiscussionMessage> = observable.array([])

  tags: string[] = []

  groups: string[] = []

  users: string[] = []

  readBy: IObservableArray<string> = observable.array([])

  get data(): DiscussionProps {
    return discussionProps.reduce((acc, prop) => {
      acc[prop] =
        prop !== 'messages' ? toJS(this[prop]) : this.messages.map(m => m.data)
      return acc
    }, {})
  }

  get isLinked(): boolean {
    return Boolean(this.parent)
  }

  get permalink(): string {
    return `/messages/${this.slug}`
  }

  get category(): string {
    return 'messages'
  }

  addMessage = async (
    message: DiscussionMessage,
    userId: string,
    notify = false
  ) => {
    const currentMessages = this.messages

    try {
      if (!message.text || message.text.length < 6) {
        message.messageSaveError = 'Message cannot be blank'
        return false
      }

      message.org_id = this.org_id
      message.discussion_id = this._id
      message.timestamp = Date.now()
      message.readBy.push(userId)

      this.messages.push(message)

      await this.save(notify)

      log.code('dsc002', { message: message.data })
      return true
    } catch (e) {
      log.code('dsc304', { error: e })
      this.messages = currentMessages
      throw e
    }
  }

  deleteMessage = async (id: string) => {
    const message = this.messages.find(m => m.id === id)
    if (message) {
      try {
        await message.delete()
        log.code('dsc003', { id })
        this.messages.replace(this.messages.filter(m => m.id !== id))
      } catch (e) {
        log.code('303', { error: e })
      }
    }
  }

  discussionSaving = false

  discussionSaveSuccess: string | undefined = undefined

  discussionSaveError: string | undefined = undefined

  save = async (notify: boolean) => {
    this.discussionSaving = true
    this.discussionSaveSuccess = undefined
    this.discussionSaveError = undefined

    try {
      const { data: resData } = await this.client.discussions.saveDiscussion(
        this.data,
        notify
      )
      this.loadData(resData)
      this.discussionSaving = false
      this.discussionSaveSuccess = 'Discussion saved successfully'
      log.code('dsc001', { discussion: resData })
      return this.rootStore.discussionStore.handleSavedDiscussion(this)
    } catch (e) {
      log.code(e.statusCode === 400 ? 'dsc201' : 'dsc301', { error: e })
      this.discussionSaveError = 'Could not save discussion'
      this.discussionSaving = false
      throw e
    }
  }

  refresh = async () => {
    const { data } = await this.client.discussions.getDiscussion(
      this.org_id,
      this._id
    )

    this.loadData(data)
  }

  toggleArchived = async () => {
    const { data } = await this.client.discussions.toggleArchived(
      this.org_id,
      this._id
    )

    this.loadData(data)

    return data
  }

  delete = async () => {
    try {
      this.client.discussions.deleteDiscussion({
        org_id: this.org_id,
        _id: this._id,
      })
      log.code('dsc002', { id: this._id })
    } catch (e) {
      log.code('dsc302', { error: e })
    }
  }

  getMessageModel(data: {
    id?: string
    links?: Link[]
    readBy?: string[]
    text?: string
    timestamp?: number
    user_id?: string | undefined
  }): DiscussionMessage {
    if (data?.id) {
      const message = this.messages.find(d => d.id === data.id)

      if (message) {
        return message
      }
    }

    return new DiscussionMessage(this.rootStore, data, this.org_id, this._id)
  }

  getMessageReadByList(messageId: string) {
    const {
      orgStore,
      userStore: { _id: currentUserId },
    } = this.rootStore
    const { isHostUser, isImpersonating, impersonatingId, members } =
      orgStore.currentOrg
    const message = this.messages.find(d => d.id === messageId)
    const userId = impersonatingId || currentUserId
    const sortedMessages = this.messages
      .toJSON()
      .sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))
    const thisMessageIndex = sortedMessages.findIndex(m => m.id === message.id)
    const showHostAdmin = isImpersonating
      ? false
      : isHostUser ||
        (!this.parent &&
          ((this.users && this.users.length > 0) ||
            (this.groups && this.groups.length > 0)))
    const readBy = message.readBy
      ? message.readBy
          .toJSON()
          .reduce((acc, read) => {
            if (
              read !== userId &&
              read !== message.user_id &&
              !acc.some(m => m && m._id === read)
            ) {
              const lastReadMessageIndex = sortedMessages.findIndex(
                (m, idx) =>
                  m.readBy && m.readBy.includes(read) && idx > thisMessageIndex
              )

              if (
                lastReadMessageIndex < thisMessageIndex ||
                (thisMessageIndex === 0 && this.messages.length === 1)
              ) {
                const mem = members.find(
                  m => m._id === read && (showHostAdmin ? true : !m.isHostAdmin)
                )
                if (mem) {
                  acc.push(mem)
                }
              }
            }
            return acc
          }, [])
          .filter(sB => sB)
      : []

    return readBy
  }

  get displayName(): string {
    return this.parentCollection ? 'Comment' : 'Message'
  }

  get permissionsName(): string {
    return 'Recipient'
  }

  get hasPermissions(): boolean {
    return this.groups.length > 0 || this.users.length > 0
  }
}
