import React, { FC, memo } from 'react'
import {
  Button,
  ButtonProps,
  CircularProgress,
  SxProps,
  useTheme,
} from '@mui/material'

import { useRootStore } from '../../services/RootStoreContext'

const SaveButton: FC<
  ButtonProps & {
    disabled?: boolean
    isSaving?: boolean
    variant?: string
    sx?: SxProps
    text?: string
  }
> = ({ disabled, isSaving, sx, text, ...rest }): JSX.Element => {
  const theme = useTheme()
  const {
    orgStore: {
      currentOrg: { isImpersonating },
    },
  } = useRootStore()

  return (
    <Button
      {...rest}
      disabled={isImpersonating || disabled || isSaving}
      sx={{ pr: isSaving ? '40px' : theme.spacing(2), ...sx }}
      endIcon={
        isSaving ? (
          <CircularProgress color='inherit' size={16} />
        ) : (
          rest.endIcon || undefined
        )
      }
    >
      {isImpersonating ? 'Disabled While Impersonating' : text}
    </Button>
  )
}

SaveButton.defaultProps = {
  disabled: false,
  isSaving: false,
  variant: 'contained',
  sx: {},
  text: 'Save',
}

export default memo(SaveButton)
