import { makeObservable, observable, override, toJS } from 'mobx'

import FeeBase from './FeeBase'

import { TierFeeType } from '../../types'

export default class TierFee extends FeeBase {
  maximumBalance = 0

  minimumBalance = 0

  id = ''

  constructor(fee: TierFeeType) {
    super(fee)
    makeObservable(this, {
      data: override,
      maximumBalance: observable,
      minimumBalance: observable,
      id: observable,
    })

    this.maximumBalance = +fee.maximumBalance || 0
    this.minimumBalance = +fee.minimumBalance || 0
    this.id = fee.id || ''
  }

  get data(): TierFeeType {
    return {
      amount: +toJS(this.amount) || 0,
      createdAt: toJS(this.createdAt),
      feeType: toJS(this.feeType),
      maximumBalance: +toJS(this.maximumBalance) || 0,
      minimumBalance: +toJS(this.minimumBalance) || 0,
    }
  }
}
