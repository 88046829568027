import React, { FC, ReactElement } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { observer } from 'mobx-react'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import UserAvatar from '../../UserAvatar'

const UserListItemType = {
  sx: PropTypes.shape({}),
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
  }).isRequired,
}

const UserListItem: FC<InferProps<typeof UserListItemType>> = ({
  user,
  sx,
}): ReactElement => {
  if (!user || typeof user === 'string') {
    return null
  }

  function getName() {
    const { name, fname, lname } = user
    if (name) {
      return name
    }

    if (fname && lname) {
      return `${fname} ${lname}`
    }
    return ''
  }

  return (
    <ListItemButton sx={sx}>
      <ListItemIcon>
        <UserAvatar user={user} />
      </ListItemIcon>
      <ListItemText primary={getName()} />
    </ListItemButton>
  )
}

UserListItem.defaultProps = {
  sx: {},
}

UserListItem.propTypes = UserListItemType

export default observer(UserListItem)
