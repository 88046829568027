/* eslint-disable func-names */
import { observable, action, makeObservable } from 'mobx'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

import { getDeviceHash, getDeviceInfo } from '../lib/deviceInfo'

import RootStore from './RootStore'
import StoreResourceBase from './StoreResourceBase'

import VisitTimer from '../Model/VisitTimer'

import { clearCache } from '../lib/utils'
import { useLog } from '../lib/log'

import { UserToken } from '../types'

const { MODE } = import.meta.env

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '1m',
    ss: '1m',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
})

const log = useLog()
export default class CommonStore extends StoreResourceBase {
  jwt = window.localStorage.getItem('jwt')

  intercomAppID = ''

  search: { autologintoken?: string; deviceHash?: string } | undefined =
    undefined

  deviceInfo: { components: object } | undefined = undefined

  deviceHash: string | undefined = undefined

  // make sure the token is stored on the role
  token: UserToken = undefined

  managingDirectory = false

  // For switching between list and detail views - primarily mobile concern
  viewMode = 'list'

  navigationExpanded = false

  visitTimer: VisitTimer | undefined = undefined

  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      token: observable,
      checkVersion: action,
      loadIntercom: action,
      setAnaliticsPage: action,
      search: observable.ref,
      setSearch: action,
      managingDirectory: observable,
      toggleManagingDirectory: action,
      intercomAppID: observable,
      visitTimer: observable,
      setVisitTimer: action,
      setDevice: action,
      deviceInfo: observable,
      deviceHash: observable,
      jwt: observable,
      parseJWT: action,
    })

    this.intercomAppID =
      ['production', 'demo'].includes(MODE) ||
      location.hostname === 'demo.myecio.com'
        ? 'm8mztkso'
        : 'kfspjnn2'

    this.loadIntercom()

    this.managingDirectory = localStorage.getItem('manageDirectory') === 'set'

    this.setDevice()
  }

  setDevice = async () => {
    this.deviceHash = await getDeviceHash()
    this.deviceInfo = await getDeviceInfo()
  }

  setVisitTimer(orgId: string, userId: string) {
    this.visitTimer = new VisitTimer(this.rootStore, orgId, userId)
  }

  setSearch = (query: { autologintoken?: string; deviceHash?: string }) => {
    this.search = query
  }

  setDeviceInfo = async () => {
    const deviceInfo = await getDeviceInfo()
    this.deviceInfo = deviceInfo.components
  }

  setDeviceHash = async () => {
    this.deviceHash = await getDeviceHash()
  }

  get isMobile() {
    return Boolean(
      navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i) ||
        false
    )
  }

  // orgStore and userStore are undefined on class instantiaton
  callSegment = (fromGlobal = false) => {
    if (
      window.analytics &&
      (!fromGlobal || !this.rootStore.orgStore.globalsSet)
    ) {
      const { fname, lname, role, phone, emails } = this.rootStore.userStore
      const { legal_business_name } = this.rootStore.orgStore.currentOrg || {}

      window.analytics.identify(this.rootStore.userStore._id, {
        id: this.rootStore.userStore._id,
        firstName: fname,
        lastName: lname,
        phone,
        role,
        organization: this.rootStore.orgStore.currentOrg
          ? {
              id: this.rootStore.orgStore.currentOrg._id,
              legal_business_name,
            }
          : {},
        emails,
      })
    }
  }

  toggleManagingDirectory = () => {
    if (this.managingDirectory) {
      localStorage.setItem('manageDirectory', null)
      this.managingDirectory = false
    } else {
      localStorage.setItem('manageDirectory', 'set')
      this.managingDirectory = true
    }
  }

  parseJWT = async (jwt: string) => {
    try {
      await localStorage.setItem('jwt', jwt)
      this.jwt = jwt

      const parsedToken = await jwtDecode<UserToken>(jwt)
      this.token = parsedToken
      return parsedToken
    } catch (e) {
      log.error({ error: e })
      return undefined
    }
  }

  loadIntercom() {
    const w = window
    const ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      const d = document
      const i = function () {
        // eslint-disable-next-line prefer-rest-params
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      w.intercomSettings = {
        app_id: this.intercomAppID,
        hide_default_launcher: true,
        custom_launcher_selector: '#support-launcher',
      }

      const l = function () {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = `https://widget.intercom.io/widget/${this.intercomAppID}`
        const x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  }

  // for public page
  setAnaliticsPage = async (pageType: 'secure' | 'public', title: string) => {
    if (title && window.analytics) {
      window.analytics.page(pageType, title, {
        url: location.href,
        path: location.hash,
        search: location.search,
      })
    }
  }

  checkVersion = async () => {
    const currentVersionLS = localStorage.getItem('currentVersion')
    const { data: currentVersion } = await axios.get(
      `${window.location.origin}/version.txt`
    )

    if (!currentVersionLS || currentVersionLS !== currentVersion) {
      await clearCache()
    }

    localStorage.setItem('currentVersion', currentVersion)
  }
}
