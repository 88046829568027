import React, { FC } from 'react'
import { IconButton, Stack, Tooltip, useTheme } from '@mui/material'
import { ViewModel } from 'mobx-utils'

import ManageNodePermissions from '../FileManager/components/ManageNodePermissions/ManageNodePermissions'

import PermissionsIconPopupContent from '../PermissionsIconPopupContent/PermissionsIconPopupContent'
import ShareItem from '../ShareItem/ShareItem'
import { GetWrappedIcon } from '../Icons'

import { useRootStore } from '../../services/RootStoreContext'

import ModelBase from '../../Model/ModelBase'

const ActivityIndicators: FC<{
  hideStar?: boolean
  item: ModelBase | ViewModel<ModelBase>
  isDocumentList?: boolean
  disablePermissionsEdit?: boolean
}> = ({
  disablePermissionsEdit,
  hideStar,
  item,
  isDocumentList,
}): JSX.Element => {
  const theme = useTheme()
  const { discussionStore, starStore, orgStore, documentStore } = useRootStore()
  const { documents } = documentStore
  const { currentOrg } = orgStore
  const { discussions } = discussionStore
  const { items } = starStore
  const {
    host,
    hostGroups,
    hostName,
    isOrgContributor,
    isOrgAdmin,
    groups,
    members,
  } = currentOrg

  if (!item) {
    return null
  }

  const indicators = []

  // Event upcoming?
  if (item.category === 'events' && item.start._i > Date.now()) {
    indicators.push({
      icon: 'clock',
      title: `Event ${item.start.fromNow()}`,
    })
  }

  // Poll set to expire?
  if (item.category === 'polls' && item.hasEnding) {
    indicators.push({
      icon: 'clock',
      title: `Poll voting ${
        item.isPassed ? 'is closed' : `expires in ${item.expiresIn}`
      }`,
    })
  }

  const comments =
    discussions &&
    discussions.find(discussion => discussion.parent === item.getNotifyId())

  if (
    comments &&
    comments.messages &&
    comments.messages.length > 0 &&
    !item.disable_comments
  ) {
    indicators.push({
      icon: 'comment',
      title: `${comments.messages.length} comment${
        comments.messages.length === 1 ? '' : 's'
      }`,
    })
  }

  if (item && item.isPublic) {
    indicators.push({
      icon: 'globe',
      title: `This ${item.displayName.toLowerCase()} is public`,
    })
  }

  const itemIsStarred = () => {
    let isStarred = false
    const findNested = (arr, uid) =>
      arr.map(i => {
        if (i.uid && i.uid === uid) {
          isStarred = true
        }
        if (i.children && Array.isArray(i.children)) {
          findNested(i.children, uid)
        }
        return i
      })
    if (items && items.length > 0) {
      findNested(
        items,
        item.getNotifyId
          ? item.getNotifyId()
          : item._id || item.key || item.path
      )
    }

    return isStarred
  }

  if (!hideStar && itemIsStarred()) {
    indicators.push({
      icon: 'stars',
      title: `This ${item.displayName.toLowerCase()} is starred`,
    })
  }

  if (item.isInherited) {
    const hasGroups = item.groups?.length > 0
    const hasTags = item.permissionTags?.length > 0

    indicators.push({
      color:
        hasGroups || hasTags
          ? theme.palette.warning.dark
          : theme.palette.success.dark,
      icon: 'globalContentFolder',
      title: `This item is visible${
        hasGroups
          ? ` to members of the selected Global Groups in ${
              hasTags ? 'Clients with the selected Tags' : 'all Clients'
            }`
          : hasTags
          ? ' to Clients with the selected Tags'
          : ' to all members of all clients'
      }`,
    })
  } else if (item.org_id === host) {
    if (item.type === 'folder' && item.path.split('/').length === 2) {
      indicators.push({
        icon: 'globalContentFolder',
        title: `Shared from ${hostName}`,
      })
    }
  } else if (isOrgAdmin || isOrgContributor) {
    const groupPermissions = item
      ? item.groups && item.groups.length > 0
        ? item.groups
        : item.inheritedGroups && item.inheritedGroups.length > 0
        ? item.inheritedGroups
        : []
      : []
    const userPermissions = item
      ? item.users && item.users.length > 0
        ? item.users
        : item.inheritedUsers && item.inheritedUsers.length > 0
        ? item.inheritedUsers
        : []
      : []
    const hasUserOrGroupPermissions =
      (groupPermissions.length > 0 &&
        groupPermissions.every(groupId =>
          [...groups, ...hostGroups].some(
            group => group.id === groupId && !group.isDeleted
          )
        )) ||
      (userPermissions.length > 0 &&
        userPermissions.every(userId =>
          members.some(
            member => member._id === userId && member.orgRole !== 'disabled'
          )
        ))

    indicators.push({
      icon: hasUserOrGroupPermissions ? 'lock' : 'unlock',
      title: <PermissionsIconPopupContent item={item} />,
    })
  }

  const getTitle = (indicator: { title: string | JSX.Element }) => {
    const { title } = indicator

    if (typeof title === 'string') {
      return title
    }

    return title
  }

  const getTrigger = (indicator: { icon: string }) => {
    if (isDocumentList) {
      if (['lock', 'unlock'].includes(indicator.icon)) {
        return (
          <ManageNodePermissions
            node={item}
            documents={documents}
            disablePermissionsEdit={disablePermissionsEdit}
            hideText
          />
        )
      }

      if (indicator.icon === 'globe') {
        return <ShareItem item={item} type='activity' />
      }
    }

    return (
      <IconButton>
        <GetWrappedIcon
          height='16px'
          width='16px'
          color={indicator.color}
          name={indicator.icon}
        />
      </IconButton>
    )
  }
  if (indicators.length === 0) {
    return null
  }

  return (
    <Stack direction='row' justifyContent='space-evenly'>
      {indicators.map(indicator => (
        <Tooltip
          disableHoverListener={
            isDocumentList &&
            ['lock', 'unlock', 'globe'].includes(indicator.icon)
          }
          key={`${indicator.icon}-${item.getNotifyId()}`}
          placement='top'
          title={getTitle(indicator)}
        >
          {getTrigger(indicator)}
        </Tooltip>
      ))}
    </Stack>
  )
}

ActivityIndicators.defaultProps = {
  disablePermissionsEdit: false,
  hideStar: false,
  isDocumentList: false,
}

export default ActivityIndicators
