import React, { FC, Fragment, useState } from 'react'
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  Stack,
  useTheme,
} from '@mui/material'
import { observer } from 'mobx-react'
import { useMatches, useParams } from 'react-router-dom'

import { GetWrappedIcon } from '../../components/Icons'
import Scrollbar from '../../components/Scrollbar'
import Animation from '../../components/Animation/Animation'
import SearchInput from '../../components/SearchInput/SearchInput'

import CallsToAction from '../../pages/Assistance/CallsToAction'

import LeftPanelArchivedList from './LeftPanelArchivedList'
import LeftPanelListItem from './LeftPanelListItem'

import { useTwoPanelSideNavContext } from '../../services/TwoPanelSideNavContext'

import useResponsive from '../../hooks/useResponsive'

import ModelBase from '../../Model/ModelBase'

const LeftPanelList: FC<{
  items?: (ModelBase & {
    created?: number
    createdAt?: number
    modified?: number
    updated?: number
    updatedAt?: number
  })[]
}> = ({ items }): JSX.Element => {
  const { openSidebar, setOpenSidebar } = useTwoPanelSideNavContext()
  const isDesktop = useResponsive('up', 'lg')
  const { id } = useParams()
  const theme = useTheme()
  const [searchTerm, setSearchTerm] = useState('')
  const matches = useMatches()
  const isDiscussion = matches.some(element => element.pathname === '/messages')
  const actualList = items
    .filter(item => !item.isArchived)
    .filter(item => {
      if (searchTerm) {
        const lowered = searchTerm.toLowerCase()
        return item.listTitle.toLowerCase().includes(lowered)
      }

      return true
    })
    .sort((a, b) => {
      const aTime = +(
        a.modified ||
        a.updatedAt ||
        a.updated ||
        a.created ||
        a.createdAt
      )
      const bTime = +(
        b.modified ||
        b.updatedAt ||
        b.updated ||
        b.created ||
        b.createdAt
      )

      return aTime > bTime ? -1 : bTime > aTime ? 1 : 0
    })

  let showSearchAndDetail = openSidebar

  if (!isDesktop && !id) {
    showSearchAndDetail = true
  }

  const getListData = () => {
    if (items.length > 0) {
      return (
        <Scrollbar>
          <List disablePadding>
            {actualList.map(item => (
              <LeftPanelListItem key={item.getNotifyId()} item={item} />
            ))}
          </List>
          <LeftPanelArchivedList items={items} searchTerm={searchTerm} />
        </Scrollbar>
      )
    }

    return searchTerm && items.length > 0 ? (
      <Box component='div' sx={{ mt: 6 }}>
        <Animation
          width='2rem'
          height='2rem'
          name='warning'
          subtext='No results'
        />
      </Box>
    ) : (
      <Box component='div' sx={{ mt: 6 }}>
        <Animation
          width='2rem'
          height='2rem'
          name='warning'
          subtext='Nothing Here Yet'
        />
      </Box>
    )
  }

  return (
    <Stack>
      {showSearchAndDetail && (
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: theme.spacing(10),
            pl: 2,
            pr: 2,
            pb: 0.6,
            pt: 0.6,
            position: 'sticky',
            top: 0,
            zIndex: 2,
          }}
        >
          <SearchInput
            sx={{ width: isDesktop ? '284px' : '320px' }}
            onChange={({ target: { value } }) => setSearchTerm(value)}
          />
          {isDesktop && (
            <Box sx={{ ml: 1, mr: 1 }}>
              <IconButton onClick={() => setOpenSidebar(false)}>
                <GetWrappedIcon name='chevronLeft' />
              </IconButton>
            </Box>
          )}
        </Stack>
      )}
      {!searchTerm && !isDesktop && isDiscussion && !id && (
        <Fragment>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ padding: 2 }}
          >
            <CallsToAction />
          </Grid>
          <Divider />
        </Fragment>
      )}
      {getListData()}
    </Stack>
  )
}

LeftPanelList.defaultProps = {
  items: [],
}

export default observer(LeftPanelList)
