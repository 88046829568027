import APIResponseType from './APIResponseType'
import { ContactManagementProps } from '../../../types'

export const save = (data: ContactManagementProps): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/contactManagement`,
  method: 'PATCH',
  data,
})

export const get = (org_id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/contactManagement`,
  method: 'GET',
})
