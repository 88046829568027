import { observable, computed, toJS, makeObservable } from 'mobx'

import { generateID } from '../lib/utils'

export default class MemberRecordsRecordProperty {
  constructor(property: {
    id?: string | undefined
    label: string | undefined
    visible: boolean | undefined
    memberCanEdit: boolean | undefined
  }) {
    makeObservable(this, {
      id: observable,
      label: observable,
      visible: observable,
      memberCanEdit: observable,
      data: computed,
    })

    this.id = property.id || generateID()
    this.label = property.label || ''
    this.visible = Boolean(property.visible)
    this.memberCanEdit = Boolean(property.memberCanEdit)
  }

  id = ''

  label = ''

  visible = false

  memberCanEdit = false

  get data() {
    return toJS({
      id: this.id,
      label: this.label,
      visible: this.visible,
      memberCanEdit: this.memberCanEdit,
    })
  }
}
