import React, { FC } from 'react'
import { observer } from 'mobx-react'

import useMobxForm from './useMobxForm'
import { FormProvider } from './FormContext'
import { MobXFormProps } from './types'

const MobxForm: FC<MobXFormProps> = ({
  children,
  handleSubmit,
  model,
}): JSX.Element => {
  const formProps = useMobxForm(model, handleSubmit)

  return <FormProvider value={formProps}>{children(formProps)}</FormProvider>
}

export default observer(MobxForm)
