import React from 'react'
import { Icon } from '@iconify/react'
import { Chip, Grid } from '@mui/material'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import { GetWrappedIcon } from '../Icons'

import { getIcon } from '../../lib/fileIcons'

const TextFieldLinkChips = observer(({ link, item, idx }) => {
  const getLinkIcon = () => {
    if (link.type) {
      if (link.type === 'document') {
        const iconData = getIcon(link.id)
        return (
          <Icon
            icon={iconData.icon}
            style={{ color: iconData.color || 'grey' }}
          />
        )
      }
      if (link.type === 'url') {
        return <GetWrappedIcon name='link' />
      }
      return <GetWrappedIcon name={link.type} />
    }

    return undefined
  }

  const getLinkItem = () => {
    if (link.hasEnoughData) {
      return (
        <Chip
          sx={{ mr: 2 }}
          avatar={getLinkIcon()}
          label={link.title}
          onDelete={() => {
            item.links.replace(item.links.filter((lnk, index) => index !== idx))
          }}
        />
      )
    }
    return null
  }

  return getLinkItem()
})

const TextFiledLinkAttachments = ({ item }) => {
  return (
    <Grid container direction='row' spacing={2} sx={{ mb: 1, pl: 2 }}>
      {item.links &&
        item.links.length > 0 &&
        item.links.map((l, idx) => (
          <Grid key={`${l.id}-${idx}`} item>
            <TextFieldLinkChips link={l} item={item} idx={idx} />
          </Grid>
        ))}
    </Grid>
  )
}

TextFiledLinkAttachments.defaultProps = {
  item: undefined,
}

TextFiledLinkAttachments.propTypes = {
  item: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.shape({})),
    addLink: PropTypes.func,
  }),
}

export default observer(TextFiledLinkAttachments)
