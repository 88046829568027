import {
  action,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
} from 'mobx'

import Poll from '../Model/Poll'

import RootStore from './RootStore'
import StoreResourceBase from './StoreResourceBase'

import { slugMatchesId } from '../lib/slug'
import { handleSocketListener } from '../lib/socket'
import { PollProps } from '../types/poll'

export default class PollStore extends StoreResourceBase {
  polls: IObservableArray<Poll> = observable.array([])

  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      loadData: action,
      polls: observable,
      getBySlug: action,
      getModel: action,
    })

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
    reaction(() => this.polls, this.handleSocket)
  }

  loadData(data: PollProps[]) {
    this.polls = observable.array(
      data.map((poll: PollProps) => new Poll(this.rootStore, poll))
    )
    this.loadedResource = true
  }

  deletePoll = async (pollId: string) => {
    try {
      const pollToDelete = this.polls.find(p => p._id === pollId)

      if (pollToDelete) {
        await this.rootStore.client.polls.deletePoll({
          org_id: pollToDelete.org_id,
          _id: pollToDelete._id,
        })
        this.polls.replace(this.polls.filter(p => p._id !== pollId))
      }
    } catch (e) {
      /* swallow */
    }
    return true
  }

  getBySlug(slubId: string) {
    return this.polls.find(
      item =>
        slugMatchesId(slubId, item._id) &&
        item.org_id === this.rootStore.orgStore.currentOrg._id
    )
  }

  getModel(pollId?: string) {
    if (pollId) {
      const model = this.polls.find((poll: Poll) => poll._id === pollId)

      if (model) {
        return model
      }
    }
    return new Poll(this.rootStore, {
      org_id: this.rootStore.orgStore.currentOrg._id,
      author: this.rootStore.userStore._id,
    })
  }

  handleSocket = () => {
    if (this.rootStore.userStore._id) {
      handleSocketListener('polls', Poll, this.polls, this.rootStore)
    }
  }
}
