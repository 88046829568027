import FingerprintJS from '@fingerprintjs/fingerprintjs'

let fp

const getFingerPrintData = async () => {
  let fingerPrintData

  if (window && !fp) {
    fp = await FingerprintJS.load()
  }

  if (fp) {
    fingerPrintData = await fp.get()
    if (navigator) fingerPrintData.components.userAgent = navigator.userAgent
  }
  return fingerPrintData
}

export const getDeviceHash = async () => {
  const deviceInfo = await getFingerPrintData()
  return deviceInfo ? deviceInfo.visitorId : ''
}

export const getDeviceInfo = async () => {
  const deviceInfo = await getFingerPrintData()
  return deviceInfo || {}
}
