import React, { FC, useState, useEffect } from 'react'
import { Box, Button, Stack } from '@mui/material'
import { observer } from 'mobx-react'
import { IObservableArray } from 'mobx'

import EditorAttachmentItems from './EditorAttachmentItems'

import Link from '../../Model/Link'

import { areArraysTheSame } from '../../lib/utils'

const EditorAttachmentForm: FC<{
  filteredLinks?: string[]
  isForTodos?: boolean
  item?: { links: IObservableArray<Link>; getBlankLink: () => Link }
  setDisableSubmit?: (isDisabled: boolean) => void
}> = ({ filteredLinks, isForTodos, item, setDisableSubmit }) => {
  const [theInitialLinks] = useState(filteredLinks)

  // used to disable and enable the save button when liks change
  useEffect(() => {
    if (setDisableSubmit) {
      setDisableSubmit(areArraysTheSame(theInitialLinks, filteredLinks))
    }
  }, [filteredLinks])

  if (!item) {
    return null
  }

  return (
    <Stack spacing={2} alignItems='flex-start' justifyContent='flex-start'>
      {item.links &&
        item.links.length > 0 &&
        item.links.map((l, idx) => (
          <Box key={`${l.id}-${idx}`} width='100%'>
            <EditorAttachmentItems
              isForTodos={isForTodos}
              idx={idx}
              item={item}
              link={l}
            />
          </Box>
        ))}
      {(!item.links ||
        item.links.length === 0 ||
        item.links.every((link: Link) => link.hasEnoughData)) && (
        <Button
          data-qa='link-add'
          onClick={() => {
            item.links.push(item.getBlankLink())
          }}
          variant='contained'
        >
          Attach
          {item && item.links && item.links.length > 0 ? ' Another' : ''} Item
        </Button>
      )}
    </Stack>
  )
}

EditorAttachmentForm.defaultProps = {
  filteredLinks: [],
  isForTodos: false,
  item: undefined,
  setDisableSubmit: undefined,
}

export default observer(EditorAttachmentForm)
