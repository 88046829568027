import React, { FC, memo } from 'react'
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
} from '@mui/material'

import { GetWrappedIcon } from '../../../Icons'
import PermissionsIconPopupContent from '../../../PermissionsIconPopupContent/PermissionsIconPopupContent'

import Folder from '../../../../Model/Folder'
import File from '../../../../Model/File'

import { useRootStore } from '../../../../services/RootStoreContext'

const NodePermissionsTrigger: FC<{
  hideText?: boolean
  node: File | Folder
}> = ({ hideText, node }): JSX.Element => {
  const { orgStore } = useRootStore()
  const { currentOrg } = orgStore
  const { groups, isOrgAdmin } = currentOrg
  const allGroups = [
    isOrgAdmin ? { id: 'admins', name: 'Administrators' } : undefined,
    ...groups.map(g => ({
      id: g.id,
      name: g.name,
      members: g.members,
      isDeleted: g.isDeleted,
    })),
  ].filter(group => Boolean(group))
  const orgOnlyMembers = currentOrg
    ? currentOrg.members.filter(m => !m.isHostAdmin)
    : []
  const inheritedGroups = allGroups
    .filter(g => node.inheritedGroups && node.inheritedGroups.includes(g.id))
    .sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
  const inheritedUsers = orgOnlyMembers
    .filter(
      m => node.inheritedUsers && node.inheritedUsers.includes(m._id.toString())
    )
    .sort((a, b) => {
      const aFullName = `${a.fname} ${a.lname}`
      const bFullName = `${b.fname} ${b.lname}`
      if (aFullName < bFullName) return -1
      if (aFullName > bFullName) return 1
      return 0
    })

  const hasInheritedGroups = inheritedGroups && inheritedGroups.length > 0
  const hasInteritedUsers = inheritedUsers && inheritedUsers.length > 0
  const hasAnyPermissions =
    hasInheritedGroups ||
    hasInteritedUsers ||
    (node.users && node.users.length > 0) ||
    (node.groups && node.groups.length > 0)
  const icon = hasAnyPermissions ? (
    <GetWrappedIcon
      data-qa={`documents-open-permissions-${node.name}`}
      height={hideText ? 16 : undefined}
      icon={hideText ? undefined : 'mdi:lock'}
      name={hideText ? 'lock' : undefined}
      width={hideText ? 16 : undefined}
    />
  ) : (
    <GetWrappedIcon
      data-qa={`documents-open-permissions-${node.name}`}
      height={hideText ? 16 : undefined}
      icon={hideText ? undefined : 'mdi:lock-open'}
      name={hideText ? 'unlock' : undefined}
      width={hideText ? 16 : undefined}
    />
  )

  return hideText ? (
    <Tooltip
      id={`${node.permalink}-permissions-tooltip`}
      key={`${node.permalink}-permissions-tooltip`}
      title={<PermissionsIconPopupContent item={node} />}
    >
      <IconButton>{icon}</IconButton>
    </Tooltip>
  ) : (
    <MenuItem>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>Permissions</ListItemText>
    </MenuItem>
  )
}

NodePermissionsTrigger.defaultProps = {
  hideText: false,
}

export default memo(NodePermissionsTrigger)
