import { makeObservable, observable, override, toJS } from 'mobx'
import { generateID } from '../lib/utils'
import RootStore from '../stores/RootStore'

import ModelBase from './ModelBase'

export default class ContactCustomProperty extends ModelBase {
  constructor(
    rootStore: RootStore,
    property: { id: string; type: string; name: string } = {
      id: generateID(),
      name: '',
      type: 'string',
    }
  ) {
    super(rootStore)
    makeObservable(this, {
      id: observable,
      name: observable,
      type: observable,
      data: override,
    })

    Object.assign(this, property)
    this.id = this.id || generateID()
  }

  id = generateID()

  name = ''

  type = 'string'

  get data() {
    return {
      id: toJS(this.id),
      name: toJS(this.name),
      type: toJS(this.type),
    }
  }
}
