/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC } from 'react'
import { Quill } from 'react-quill'
import { Divider, SxProps } from '@mui/material'

import QuillToolbarStyle from './QuillToolbarStyle'

const FONT_FAMILY = ['Arial', 'Tahoma', 'Georgia', 'Impact', 'Verdana']

const FONT_SIZE = [
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '42px',
  '54px',
  '68px',
  '84px',
  '98px',
]
const HEADINGS = [
  'Heading 1',
  'Heading 2',
  'Heading 3',
  'Heading 4',
  'Heading 5',
  'Heading 6',
]

export function undoChange() {
  this.quill.history.undo()
}
export function redoChange() {
  this.quill.history.redo()
}

export function attachImage() {
  this.quill.attachImage()
}

const Size = Quill.import('attributors/style/size')
Size.whitelist = FONT_SIZE
Quill.register(Size, true)

const Font = Quill.import('attributors/style/font')
Font.whitelist = FONT_FAMILY
Quill.register(Font, true)

export const formats = [
  'align',
  'background',
  'blockquote',
  'bold',
  'bullet',
  'code',
  'code-block',
  'color',
  'direction',
  'font',
  'formula',
  'header',
  'image',
  'indent',
  'italic',
  'link',
  'list',
  'script',
  'size',
  'strike',
  'table',
  'underline',
  'video',
]

const QuillToolbar: FC<{ id: string } & SxProps> = ({
  id,
  ...other
}): JSX.Element => (
  <QuillToolbarStyle {...other}>
    <div id={id}>
      <div>
        <select className='ql-header' defaultValue=''>
          {HEADINGS.map((heading, index) => (
            <option key={heading} value={index + 1}>
              {heading}
            </option>
          ))}
          <option value=''>Normal</option>
        </select>
      </div>
      <div className='ql-formats'>
        <button type='button' className='ql-bold' />
        <button type='button' className='ql-italic' />
        <button type='button' className='ql-underline' />
        <button type='button' className='ql-strike' />
      </div>
      <div className='ql-formats'>
        <button type='button' className='ql-align' value='' />
        <button type='button' className='ql-align' value='center' />
        <button type='button' className='ql-align' value='right' />
        <button type='button' className='ql-align' value='justify' />
      </div>
      <div className='ql-formats'>
        <button type='button' className='ql-list' value='ordered' />
        <button type='button' className='ql-list' value='bullet' />
      </div>
      <div className='ql-formats'>
        <button type='button' className='ql-clean' />
      </div>
    </div>
    <Divider />
  </QuillToolbarStyle>
)

export default QuillToolbar
