export default function MuiChip(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        label: {
          ...theme.typography.body1,
        },
      },
    },
  }
}
