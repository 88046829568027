import React, { FC } from 'react'
import { styled } from '@mui/material/styles'
import { Avatar, Badge, Tooltip, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import PropTypes, { InferProps } from 'prop-types'

import { GetWrappedIcon } from '../../../components/Icons'
import { useRootStore } from '../../../services/RootStoreContext'
import { getUsersList, getGroupsList } from '../../../lib/getLists'
import UserAvatar from '../../../components/UserAvatar'
import ModelBase from '../../../Model/ModelBase'
import OrgGroup from '../../../Model/OrgGroup'

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 28,
  height: 28,
  fontSize: '0.75rem',
  border: `2px solid ${theme.palette.background.paper}`,
}))

const LeftSideListIconsProps = {
  item: PropTypes.instanceOf(ModelBase).isRequired,
}

const LeftSideListIcons: FC<InferProps<typeof LeftSideListIconsProps>> = ({
  item,
}): JSX.Element => {
  const creatorId = item.creator || item.author
  const users = item.users ? item.users.map(user => user) : []
  const groups = item.groups ? item.groups.map(g => g) : []
  const inheritedUsers = item.inheritedUsers
    ? item.inheritedUsers.map(user => user)
    : []
  const inheritedGroups = item.inheritedGroups
    ? item.inheritedGroups.map(g => g)
    : []

  const { orgStore, userStore } = useRootStore()
  const { currentOrg } = orgStore
  const currentUserId = userStore?._id?.toString()
  const hasOwnPermissions =
    (users && users.length > 0) || (groups && groups.length > 0)
  const allGroups = [...currentOrg.groups, ...currentOrg.hostGroups]
  if (item.is_global && currentOrg.isHostWithGlobalGroups) {
    currentOrg.config.globalGroups.forEach((g: OrgGroup) => allGroups.push(g))
  }

  const usersList =
    users && users.length > 0
      ? getUsersList(currentOrg.members, users, creatorId, currentUserId)
      : !hasOwnPermissions && inheritedUsers && inheritedUsers.length > 0
      ? getUsersList(
          currentOrg.members,
          inheritedUsers,
          creatorId,
          currentUserId
        )
      : []
  const groupsList =
    groups && groups.length > 0
      ? getGroupsList(allGroups, groups, currentUserId, currentOrg)
      : !hasOwnPermissions && inheritedGroups && inheritedGroups.length > 0
      ? getGroupsList(allGroups, inheritedGroups, currentUserId, currentOrg)
      : []

  if (groupsList.length > 0 && usersList.length > 0) {
    return (
      <Tooltip title={<Typography>{item.listTitle}</Typography>}>
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<SmallAvatar alt='12' src='12' children='+' />}
        >
          <Avatar>
            <GetWrappedIcon name='group' />
          </Avatar>
        </Badge>
      </Tooltip>
    )
  }

  if (groupsList.length === 1) {
    return (
      <Tooltip title={<Typography>{item.listTitle}</Typography>}>
        <Avatar>
          <GetWrappedIcon name='group' />
        </Avatar>
      </Tooltip>
    )
  }

  if (usersList.length > 1) {
    return (
      <Tooltip title={<Typography>{item.listTitle}</Typography>}>
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<SmallAvatar alt='12' src='12' children='+' />}
        >
          <UserAvatar user={usersList[0]} />
        </Badge>
      </Tooltip>
    )
  }

  if (usersList.length === 1) {
    return (
      <Tooltip title={<Typography>{item.listTitle}</Typography>}>
        <UserAvatar user={usersList[0]} />
      </Tooltip>
    )
  }

  return (
    <Tooltip title={<Typography>{item.listTitle}</Typography>}>
      <Avatar>
        <GetWrappedIcon name='group' />
      </Avatar>
    </Tooltip>
  )
}

LeftSideListIcons.propTypes = LeftSideListIconsProps

export default observer(LeftSideListIcons)
