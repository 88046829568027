import { Buffer } from 'buffer'

export const slugMatchesId = (slug, id) => {
  if (!slug || !id) return false
  return (
    slug.substring(slug.length - 8, slug.length) ===
    id.substring(id.length - 8, id.length)
  )
}

export const slugMatchesOrgId = (slug, org_id) => {
  if (!slug || !org_id) {
    return false
  }

  return (
    slug.substring(0, slug.length - 8) ===
    org_id.substring(org_id.length - 8, org_id.length)
  )
}

export const generateSlug = (org_id, id) => {
  if (!org_id || !id) return ''
  // Convert objectids to string
  org_id = org_id.toString()
  id = id.toString()
  const idSub = id.substring(id.length - 8, id.length)
  const org_idSub =
    org_id === 'all'
      ? org_id
      : org_id.substring(org_id.length - 8, org_id.length)
  return `${org_idSub}${idSub}`
}

export const generateDocumentSlug = (orgId, keyPathData = {}) =>
  Buffer.from(
    JSON.stringify({ orgId: orgId.toString(), ...keyPathData })
  ).toString('base64')

export const parseDocumentSlug = docSlug => {
  try {
    return JSON.parse(Buffer.from(docSlug, 'base64').toString() || '{}') || {}
  } catch (e) {
    return {}
  }
}

export const getSlugAndPath = () => {
  const path = location.hash.replace('#', '')
  const orgCheckRegex =
    /\/(?:feed|events|discussions|messages|polls|agenda-minutes|posts|todos|news-updates)\/(\w+)/

  if (path && path.match(orgCheckRegex)) {
    return {
      slug: path.match(orgCheckRegex)[1],
      path: path.match(orgCheckRegex)[0],
    }
  }

  return {
    slug: '',
    path: '',
  }
}
