/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */

import './wdyr'
import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { LicenseInfo } from '@mui/x-license-pro'
import { configure } from 'mobx'
import { observer } from 'mobx-react'
import { RouterProvider } from 'react-router-dom'

import routes from './routes'
import GlobalStyles from './theme/globalStyles'
import ThemeConfig from './theme'

import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator'

import { RootStoreContext, rootStore } from './services/RootStoreContext'

import { getDeviceHash } from './lib/deviceInfo'

configure({
  enforceActions: 'never',
})

LicenseInfo.setLicenseKey(
  'fe56073b24d9068e19a91e89501ea7edTz00MjUwNyxFPTE3MzU2MDMyMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y'
)

const App = () => {
  useEffect(() => {
    rootStore.commonStore.checkVersion().then(() => {
      getDeviceHash()
    })
  }, [])

  return (
    <RootStoreContext.Provider value={rootStore}>
      <ThemeConfig>
        <Box className='app' height='100vh' width='100vw'>
          <GlobalStyles />
          {!rootStore.appLoaded && (
            <Box height='100vh' width='100vw'>
              <LoadingIndicator />
            </Box>
          )}
          <RouterProvider router={routes} />
        </Box>
      </ThemeConfig>
    </RootStoreContext.Provider>
  )
}

export default observer(App)
