import {
  observable,
  computed,
  toJS,
  action,
  makeObservable,
  override,
} from 'mobx'
import moment from 'moment'

import ModelBase from './ModelBase'

import RootStore from '../stores/RootStore'

import { getIcon } from '../lib/fileIcons'
import { useLog } from '../lib/log'
import { generateDocumentSlug } from '../lib/slug'

const log = useLog()

const officeTypes = ['xlsx', 'docx', 'pptx', 'ppsx', 'doc', 'xls', 'ppt']

const fileProperties = [
  'name',
  'key',
  'modified',
  'groups',
  'users',
  'creator',
  'tags',
  'viewedBy',
  'disableComments',
  'isPublic',
  'previousFiles',
]

export default class File extends ModelBase {
  userId: string | undefined = undefined

  constructor(
    rootStore: RootStore,
    org_id: string | undefined,
    file: {
      isNew: boolean | undefined
      groups: string[] | undefined
      users: string[] | undefined
      inheritedGroups: string[] | undefined
      inheritedUsers: string[] | undefined
      viewedBy: string[] | undefined
      previousFiles: object[] | undefined
      shareUrl: string | undefined
      shareUrlExpiresAt: number | undefined
      isReadOnly: boolean | undefined
      path: string | undefined
      isInherited: boolean | undefined
    } = {
      isNew: undefined,
      groups: [],
      inheritedGroups: undefined,
      inheritedUsers: undefined,
      viewedBy: undefined,
      previousFiles: undefined,
      shareUrl: undefined,
      shareUrlExpiresAt: undefined,
      isReadOnly: undefined,
      users: [],
      path: undefined,
      isInherited: undefined,
    },
    userId: string | undefined = undefined
  ) {
    super(rootStore)

    makeObservable(this, {
      type: observable,
      name: observable,
      key: observable,
      modified: observable,
      groups: observable,
      users: observable,
      icon: override,
      iconColor: override,
      inheritedGroupsArray: observable,
      inheritedGroups: override,
      inheritedUsersArray: observable,
      inheritedUsers: override,
      isInherited: observable,
      guests: observable,
      isReadOnly: observable,
      creator: observable,
      org_id: observable,
      tags: observable,
      viewedBy: observable,
      disableComments: observable,
      previousFiles: observable,
      createdAt: computed,
      isNew: observable,
      isSaving: observable,
      downloadError: observable,
      data: override,
      download: action,
      preview: action,
      wasReplaced: action,
      posted: override,
    })

    fileProperties.forEach(prop => {
      this[prop] = file[prop]
    })
    this.isPublicShareAllowed = true
    this.org_id = org_id
    this.isNew = file.isNew
    this.userId = userId
    // Prevents rerender on traversal from showing new file indicator again
    setTimeout(() => {
      this.isNew = false
    }, 10000)
    this.groups = Array.isArray(file.groups) ? file.groups : []
    this.users = Array.isArray(file.users) ? file.users : []
    this.inheritedGroupsArray = file.inheritedGroups || []
    this.inheritedUsersArray = file.inheritedUsers || []
    this.viewedBy = file.viewedBy || []
    this.previousFiles = file.previousFiles || []
    this.shareUrl = file.shareUrl || ''
    this.shareUrlExpiresAt = file.shareUrlExpiresAt
    this.isReadOnly = file.isReadOnly || false
    this.path = (
      file && file.path && typeof file.path === 'string' ? file.path : '/'
    ).replace(/\/\//g, '/')
    this.isInherited = file.isInherited || false
  }

  modelCollection = 'documents'

  type = 'file'

  name = ''

  key = ''

  modified = Date.now()

  groups: string[] = []

  users: string[] = []

  guests: string[] = []

  isReadOnly = false

  creator: string | undefined = undefined

  org_id: string | undefined = undefined

  tags: string[] = []

  viewedBy: string[] = []

  inheritedGroupsArray: string[] = []

  path = '/'

  get icon(): string {
    const fileIcon = getIcon(this.key)
    return fileIcon.icon
  }

  get iconColor(): string {
    const fileIcon = getIcon(this.key)
    return fileIcon.color
  }

  get inheritedGroups(): string[] {
    return this.inheritedGroupsArray
  }

  inheritedUsersArray: string[] = []

  get inheritedUsers(): string[] {
    return this.inheritedGroupsArray
  }

  disableComments = false

  previousFiles: { key: string }[] = []

  get createdAt() {
    return this.modified
  }

  isNew = false

  isSaving = false

  isInherited = false

  downloadError: string | undefined = undefined

  getNotifyId = (): string => this.key

  getDownloadString = (): string => this.key

  get data() {
    return fileProperties.reduce(
      (acc, prop) => {
        acc[prop] = toJS(this[prop])
        return acc
      },
      { path: toJS(this.path) }
    )
  }

  download = async () => {
    this.downloadError = undefined
    const currentOrgId = this.rootStore.orgStore.currentOrg._id
    const data = this.userId
      ? { user_id: this.userId, file: this.key }
      : {
          org_id: currentOrgId,
          host_id: currentOrgId === this.org_id ? undefined : this.org_id,
          file: this.key,
        }
    try {
      const res = await this.rootStore.client.documents.getDocumentDownload(
        data,
        {
          path: this.path,
        }
      )
      log.code('doc002', data)
      return res
    } catch (e) {
      log.code('doc304', {
        error: e,
        data,
      })
      this.downloadError = 'Error downloading file'
    }

    return { data: '' }
  }

  preview = async () => {
    if (officeTypes.includes(this.key.split('.').pop())) {
      const currentOrgId = this.rootStore.orgStore.currentOrg._id
      const data = this.userId
        ? { user_id: this.userId, file: this.key }
        : {
            org_id: currentOrgId,
            host_id: currentOrgId === this.org_id ? undefined : this.org_id,
            file: this.key,
          }
      try {
        const res = await this.rootStore.client.documents.getDocumentPreview(
          data,
          {
            path: this.path,
          }
        )
        log.code('doc007', data)
        return res
      } catch (e) {
        log.code('doc304', {
          error: e,
          data,
        })
      }
    }

    return { data: '' }
  }

  get displayName(): string {
    return 'File'
  }

  get permalink(): string {
    return `/documents/${generateDocumentSlug(
      this.rootStore.orgStore.currentOrg._id,
      {
        download: this.key,
        path: this.path,
      }
    )}`
  }

  get posted(): string {
    return moment(this.modified).fromNow()
  }

  wasReplaced = () => {
    this.isNew = true
    setTimeout(() => {
      this.isNew = false
    }, 10000)
  }
}
