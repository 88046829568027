import 'regenerator-runtime'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'

const render = () => {
  const container = document.getElementById('app')
  if (container) {
    const root = createRoot(container)
    root.render(<App />)
  }
}

if (!window.cordova) {
  render()
} else {
  document.addEventListener('deviceready', render, false)
}

// // Request that user allow push notifications
const requestNotificationAccess = () => {
  const afterRequest = permission => {
    if (permission) {
      return true
    }
    return false
  }

  try {
    if ('safari' in window) {
      Notification.requestPermission(afterRequest)
    } else {
      Notification.requestPermission().then(afterRequest)
    }
  } catch (e) {
    /* no-op, browser doesn't support */
  }
}

requestNotificationAccess()
