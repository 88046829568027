import {
  action,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
} from 'mobx'

import Content from '../Model/Content'
import RootStore from './RootStore'

import { slugMatchesId } from '../lib/slug'
import { handleSocketListener } from '../lib/socket'

import { ContentProps } from '../types'
import StoreResourceBase from './StoreResourceBase'

export default class ContentStor extends StoreResourceBase {
  content: IObservableArray<Content> = observable.array([])

  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      loadData: action,
      content: observable,
      deleteContent: action,
      getModel: action,
    })

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
    reaction(() => this.content, this.handleSocket)
  }

  loadData(contentData: ContentProps[]) {
    this.content = observable.array(
      contentData.map(c => new Content(this.rootStore, c))
    )
    this.loadedResource = true
  }

  deleteContent = async (content: {
    org_id: string
    _id: string
    investments: boolean
  }) => {
    try {
      await this.rootStore.client.content.deleteContent(content)
      this.content.replace(this.content.filter(c => c._id !== content._id))
    } catch (e) {
      /* swallow */
    }
    return true
  }

  getModel(contentId?: string) {
    if (contentId) {
      const model = this.content.find(
        (cont: Content) =>
          cont._id === contentId || slugMatchesId(contentId, cont._id)
      )

      if (model) {
        return model
      }
    }

    return new Content(this.rootStore, {
      author: this.rootStore.userStore._id,
      groups: [],
      investments: !this.rootStore.orgStore.currentOrg.hideInvestments,
      is_global: this.rootStore.orgStore.currentOrg.isHost,
      isInProgress: true,
      org_id: this.rootStore.orgStore.currentOrg._id,
      type: undefined,
      users: [],
    })
  }

  handleSocket = () => {
    if (this.rootStore.userStore._id) {
      handleSocketListener('content', Content, this.content, this.rootStore)
    }
  }
}
