import { action, computed, makeObservable, observable, override } from 'mobx'

import ContactCustomProperty from '../Model/ContactCustomProperty'
import Contact from '../Model/Contact'
import ContactList from '../Model/ContactList'
import ModelBase from '../Model/ModelBase'
import RootStore from '../stores/RootStore'
import { useLog } from '../lib/log'

const log = useLog()

type ContactsType = {
  org_id: string | undefined
  custom_properties: ContactCustomProperty[]
  collection: {
    id?: string
    fname?: string
    lname?: string
    email?: string
    lists?: string[]
    custom_properties?: ContactCustomProperty[]
    created?: number
  }[]
  lists: ContactList[]
}

export default class ContactStore extends ModelBase {
  org_id: string | undefined = undefined

  lists: ContactList[] = []

  custom_properties: ContactCustomProperty[] = []

  collection: Contact[] = []

  loadedResource = false

  customPropertyTypes: {
    key: string
    value: string
    text: string
  }[] = [
    { key: 'string', value: 'string', text: 'Text' },
    { key: 'number', value: 'number', text: 'Number' },
    { key: 'date', value: 'date', text: 'Date' },
    { key: 'phone', value: 'phone', text: 'Phone' },
    { key: 'currency', value: 'currency', text: 'Currency' },
  ]

  constructor(
    rootStore: RootStore,
    contacts: ContactsType = {
      org_id: undefined,
      custom_properties: [],
      collection: [],
      lists: [],
    }
  ) {
    super(rootStore)
    makeObservable(this, {
      org_id: observable,
      lists: observable,
      custom_properties: observable,
      collection: observable,
      data: override,
      listsContacts: computed,
      updateArrayProperty: action,
      saveListItem: action,
      saveCustomProperty: action,
      saveContact: action,
      deleteArrayProperty: action,
      deleteList: action,
      deleteCustomProperty: action,
      deleteContact: action,
      loadData: action,
      save: action,
      resetStore: action,
      getContactCustomProperty: action,
      getContactModel: action,
      loadedResource: observable,
    })

    this.loadData(contacts)
  }

  loadData(contacts: ContactsType) {
    this.org_id = (contacts && contacts.org_id) || undefined
    this.lists = contacts.lists
      ? contacts.lists.map(l => new ContactList(this.rootStore, l))
      : []
    this.custom_properties = contacts.custom_properties
      ? contacts.custom_properties.map(
          c => new ContactCustomProperty(this.rootStore, c)
        )
      : []
    this.collection = contacts.collection
      ? contacts.collection.map(c => new Contact(this.rootStore, c))
      : []
    this.loadedResource = true
  }

  resetStore() {
    this.org_id = undefined
    this.custom_properties = []
    this.collection = []
    this.lists = []
  }

  getContactModel(data) {
    if (data.id) {
      const model = this.collection.find(
        collection => collection.id === data.id
      )
      if (model) {
        return model
      }
    }
    return new Contact(this.rootStore, data)
  }

  getContactCustomProperty(data) {
    if (data.id) {
      const model = this.collection.find(
        collection => collection.id === data.id
      )
      if (model) {
        return model
      }
    }
    return new ContactCustomProperty(this.rootStore, data)
  }

  get data() {
    return {
      org_id: this.org_id,
      lists: this.lists.map(l => l.data),
      custom_properties: this.custom_properties.map(p => p.data),
      collection: this.collection.map(c => c.data),
    }
  }

  get listsContacts() {
    return this.lists.map(list => {
      return {
        id: list.id,
        name: list.name,
        contacts: this.collection.filter(contact => {
          return contact.lists.includes(list.id)
        }),
      }
    })
  }

  /**
   * ALIAS FUNCTIONS TO SIMPLIFY ARRAY PROPERTY EDITING
   */

  updateArrayProperty = async (
    collectionName: string,
    data: ContactList | ContactCustomProperty | Contact,
    ChildModel:
      | typeof ContactList
      | typeof ContactCustomProperty
      | typeof Contact,
    prop = 'id'
  ) => {
    let createId: string | undefined
    const existing =
      this[collectionName] &&
      this[collectionName].find((l: object) => l[prop] === data[prop])
    // Check for dupe emails on update
    if (
      collectionName === 'collection' &&
      prop === 'id' &&
      existing &&
      existing.id !== data.id
    ) {
      throw new Error('Contact with that email already exists')
    }
    if (existing) {
      this[collectionName] = this[collectionName].map(l =>
        l[prop] === data[prop]
          ? new ChildModel(this.rootStore, data)
          : new ChildModel(this.rootStore, l)
      )
    } else {
      const newItem = new ChildModel(this.rootStore, data)
      createId = newItem.id
      this[collectionName].push(newItem)
    }
    try {
      await this.save()
    } catch (e) {
      // On fail, rollback model
      if (existing) {
        this[collectionName] = this[collectionName].map(l => {
          if (l[prop] === data[prop]) {
            return existing
          }
          return l
        })
      } else {
        this[collectionName] = this[collectionName].filter(
          (l: { id: string }) => l.id !== createId
        )
      }
      throw e
    }
  }

  saveListItem = async (data: ContactList) => {
    await this.updateArrayProperty('lists', data, ContactList)
  }

  saveCustomProperty = async (data: ContactCustomProperty) => {
    await this.updateArrayProperty(
      'custom_properties',
      data,
      ContactCustomProperty
    )
  }

  saveContact = async (data: Contact) => {
    await this.updateArrayProperty(
      'collection',
      data,
      Contact,
      data.id ? 'id' : 'email'
    )
  }

  /**
   * ALIAS FUNCTIONS TO SIMPLIFY DELETING ITEMS FROM ARRAY PROPERTIES
   */
  deleteArrayProperty = async (collectionName: string, id: string) => {
    this[collectionName] = this[collectionName].filter(
      (i: { id: string }) => i.id !== id
    )
    await this.save()
  }

  deleteList = async (id: string) => {
    await this.deleteArrayProperty('lists', id)
  }

  deleteCustomProperty = async (id: string) => {
    await this.deleteArrayProperty('custom_properties', id)
  }

  deleteContact = async (id: string) => {
    await this.deleteArrayProperty('collection', id)
  }

  /**
   * SAVE CONTACTS RECORD
   */
  save = async () => {
    try {
      await this.client.contacts.save(this.data)
      log.code('cts001')
    } catch (e) {
      const err = new Error()
      if (e.statusCode === 400) {
        log.code('cts201', { error: e.data })
        err.message = 'Invalid data'
        throw err
      }
      err.message = 'Error saving contacts'
      log.code('cts301', {})
      throw err
    }
  }
}
