import { FC } from 'react'
import { Theme, useMediaQuery } from '@mui/material'

const MHidden: FC<{
  children?: JSX.Element | null
  width:
    | 'xsDown'
    | 'smDown'
    | 'mdDown'
    | 'lgDown'
    | 'xlDown'
    | 'xsUp'
    | 'smUp'
    | 'mdUp'
    | 'lgUp'
    | 'xlUp'
}> = ({ width, children }): JSX.Element => {
  const breakpoint = width.substring(0, 2)

  const hiddenUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(breakpoint)
  )
  const hiddenDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(breakpoint)
  )

  if (width.includes('Down')) {
    return hiddenDown ? null : children
  }

  if (width.includes('Up')) {
    return hiddenUp ? null : children
  }

  return null
}

MHidden.defaultProps = {
  children: null,
}

export default MHidden
