import React, { FC } from 'react'
import { Box, Breakpoint, Container, Paper } from '@mui/material'

import Scrollbar from '../../Scrollbar'

const FormDrawerContent: FC<{
  children: JSX.Element | JSX.Element[]
  containerMaxWidth?: Breakpoint
}> = ({ children, containerMaxWidth }) => {
  return (
    <Box p={3}>
      <Container maxWidth={containerMaxWidth}>
        <Paper
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 'auto',
          }}
          variant='outlined'
        >
          <Scrollbar
            sx={{
              position: 'relative',
              p: 3,
            }}
          >
            {children}
          </Scrollbar>
        </Paper>
      </Container>
    </Box>
  )
}

FormDrawerContent.defaultProps = {
  containerMaxWidth: 'lg',
}

export default FormDrawerContent
