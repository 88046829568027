import APIResponseType from './APIResponseType'

export const createTempForActivation = (data: object): APIResponseType => ({
  url: `/orgs/v1/self-signup`,
  data,
  method: 'POST',
})

export const commitWithActivationCode = (
  activationCode: string
): APIResponseType => ({
  url: `/orgs/v1/self-signup`,
  data: { activationCode },
  method: 'PATCH',
})
