import { formatMoney } from './utils'

import { FeeType } from '../types'

export { formatMoney }

export const getFeeDescription = (fee: FeeType): string => {
  const isPercent = fee.feeType === 'percent'
  const { amount } = fee

  return `${!isPercent ? `$${formatMoney(amount)}` : amount}${
    fee.feeType === 'percent' ? '%' : ''
  } annually. Effective rate will be determined based on invoice dates.`
}
