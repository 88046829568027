import React, { FC } from 'react'
import TextField from '@mui/material/TextField'
import { observer } from 'mobx-react'
import moment from 'moment'

const getNewTime = (newValue: string, value: number | Date) => {
  const hour = moment(newValue, 'HH:mm').get('hour')
  const minutes = moment(newValue, 'HH:mm').get('minute')

  return moment(value).clone().hour(hour).minute(minutes).valueOf()
}

const TimePicker: FC<{
  dataQa?: string
  disabled?: boolean
  error?: boolean
  label?: string
  onChange: (newVal: number) => void
  required?: boolean
  value?: number | Date
}> = ({
  label,
  value,
  error,
  onChange,
  dataQa,
  disabled,
  required,
  ...rest
}): JSX.Element => {
  return (
    <TextField
      {...rest}
      data-qa={dataQa}
      value={value ? moment(value).format('HH:mm') : moment().format('HH:mm')}
      onChange={e => onChange(getNewTime(e.target.value, value))}
      type='time'
      error={error}
      label={label}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 min
      }}
      required={required}
      disabled={disabled}
      sx={{ width: '100%' }}
    />
  )
}

TimePicker.defaultProps = {
  dataQa: undefined,
  disabled: false,
  error: false,
  label: 'Time',
  required: true,
  value: 0,
}

export default observer(TimePicker)
