import React, { FC, memo } from 'react'
import { Button, ButtonProps } from '@mui/material'
import { GetWrappedIcon } from '../Icons'

const AddAnotherItem: FC<ButtonProps & { disabled?: boolean }> = (
  props
): JSX.Element => {
  const { children } = props
  return (
    <Button
      fullWidth
      color='primary'
      size='large'
      variant='contained'
      endIcon={<GetWrappedIcon name='create' />}
      {...props}
    >
      {children || 'Add Another Item'}
    </Button>
  )
}

AddAnotherItem.defaultProps = {
  disabled: false,
}

export default memo(AddAnotherItem)
