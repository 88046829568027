import { sortArrayAlpha } from '../utils/arrays'

export const getAdminsList = (orgMembers, currentUserId) =>
  orgMembers
    .map(m =>
      m.orgRole === 'admin' && !m.isHostAdmin
        ? {
            name: `${m.fname} ${m.lname}${
              m._id === currentUserId ? ' (You)' : ''
            }`,
            photo: m.photo,
            _id: m._id,
          }
        : false
    )
    .filter(m => Boolean(m))

export const getUsersList = (orgMembers, users, creatorId, userId) => {
  if (!users || users.length === 0) {
    return []
  }

  const compiled = users.map(u => {
    const user = orgMembers.find(m => m.orgRole !== 'disabled' && m._id === u)

    if (!user) {
      return null
    }

    if (![userId, creatorId].includes(u)) {
      return {
        name: `${user.fname} ${user.lname}`,
        photo: user.photo,
        _id: userId,
      }
    }

    if (u === userId) {
      return {
        name: `${user.fname} ${user.lname} (You)`,
        photo: user.photo,
        _id: userId,
      }
    }

    return null
  })

  const creatorRecord = orgMembers.find(m => m._id === creatorId)

  if (creatorRecord && creatorId !== userId) {
    compiled.push({
      name: `${creatorRecord.fname} ${creatorRecord.lname}`,
      photo: creatorRecord.photo,
      _id: creatorId,
    })
  }

  return compiled.filter(Boolean).sort((a, b) => sortArrayAlpha(a, b, 'name'))
}

export const getGroupsList = (orgGroups, groups, currentUserId, currentOrg) => {
  if (!groups || groups.length === 0) {
    return []
  }

  const { members: orgMembers } = currentOrg

  return groups
    .map(g => {
      if (g === 'admins') {
        return {
          id: 'admins',
          name: 'Administrators',
          members: getAdminsList(orgMembers, currentUserId),
        }
      }

      const group = orgGroups.find(oG => oG.id === g)

      if (group) {
        return {
          id: group.id,
          name: group.name,
          members: getUsersList(
            orgMembers,
            group.members,
            false,
            currentUserId
          ),
        }
      }

      if (currentOrg.isHostWithGlobalGroups) {
        return currentOrg.config.globalGroups.find(gg => gg.id === group)
      }

      return undefined
    })
    .filter(Boolean)
}
