import APIResponseType from './APIResponseType'

export const getDocuments = (org_id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/documents`,
  method: 'GET',
})

export const getUploadUrl = (data: {
  org_id: string
  filename: string
  publicAccess?: boolean | undefined
  path?: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/file`,
  method: 'POST',
  data,
})

export const getPublicUrl = (data: {
  org_id: string
  key: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/file/${
    typeof data?.key === 'string' ? data.key.replace(/\s/g, '<space>') : ''
  }/public`,
  method: 'GET',
})

export const getDocumentDownload = (
  data: {
    file: string
    host_id?: string
    org_id?: string
  },
  query?: object
): APIResponseType => ({
  url: `/orgs/v1/${data.org_id ? data.org_id : 'my-documents'}/file/${
    typeof data?.file === 'string' ? data.file.replace(/\s/g, '<space>') : ''
  }${data.host_id ? `/${data.host_id}` : ''}`,
  method: 'GET',
  params: query,
})

export const getDocumentPreview = (
  data: {
    file: string
    host_id?: string
    org_id?: string
  },
  query?: object
): APIResponseType => ({
  url: `/orgs/v1/${data.org_id ? `${data.org_id}` : 'my-documents'}/file/${
    typeof data?.file === 'string' ? data.file.replace(/\s/g, '<space>') : ''
  }/preview${data.host_id ? `/${data.host_id}` : ''}`,
  method: 'GET',
  params: query,
})

export const saveDocuments = (data: { org_id?: string }): APIResponseType => ({
  url: `/orgs/v1/${data.org_id ? `${data.org_id}/documents` : 'my-documents'}`,
  method: 'PATCH',
  data,
})

export const exportDocuments = (data: { org_id: string }): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/documents/export`,
  method: 'POST',
  data,
})

export const toggleGlobalFolderContent = (
  org_id: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/documents/global-folder-content`,
  method: 'PATCH',
  data,
})

export const markDocumentViewed = (data: {
  org_id: string
  key: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/documents/${data.key}/viewedby`,
  method: 'PATCH',
})

export const mergeUploadedDocuments = (data: {
  org_id?: string
  uploaded: object
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/documents/merge`,
  method: 'PATCH',
  data: data.uploaded,
})

export const createFolder = (data: {
  org_id: string
  path: string
  groups: string[]
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/documents/folder`,
  method: 'POST',
  data,
})

export const removeContent = (data: {
  org_id: string
  items: { key?: string; name: string; path?: string }[]
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/documents/content`,
  method: 'DELETE',
  data,
})

export const moveContent = (data: {
  org_id: string
  items: { key?: string; name: string; path?: string }[]
  destination: { name: string; path: string }
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/documents/content`,
  method: 'PATCH',
  data,
})

export const renameNode = (data: {
  org_id: string
  item: { key?: string; name: string; path?: string }
  name: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/documents/rename`,
  method: 'PATCH',
  data,
})
