/* eslint-disable no-case-declarations */
import { action, makeObservable, observable, override, toJS } from 'mobx'

import ModelBase from './ModelBase'
import OrganizationForm from './OrganizationForm'
import Todo from './Todo'
import RootStore from '../stores/RootStore'

const templateProperties = [
  '_id',
  'author',
  'name',
  'org_id',
  'payload',
  'type',
]

export default class Template extends ModelBase {
  typeOptions = [
    {
      key: 'agendaAndMinutes',
      text: 'Agenda & Minutes',
      value: 'agendaAndMinutes',
    },
    {
      key: 'todo',
      text: 'To-do',
      value: 'todo',
    },
    {
      key: 'organization-form',
      text: 'Form',
      value: 'organization-form',
    },
  ]

  constructor(rootStore: RootStore, template = {}) {
    super(rootStore)
    makeObservable(this, {
      _id: observable,
      author: observable,
      name: observable,
      org_id: observable,
      payload: observable,
      type: observable,
      data: override,
      save: action,
    })

    templateProperties.forEach(prop => {
      this[prop] = template[prop]
    })
  }

  modelCollection = 'templates'

  _id: string | undefined = undefined

  author: string | undefined = undefined

  name: string | undefined = undefined

  org_id: string | undefined = undefined

  payload: object | undefined = undefined

  type: string | undefined = undefined

  getNotifyId = (): string => this._id

  markAsRead = async () => true

  get data() {
    return templateProperties.reduce(
      (acc, prop) => {
        acc[prop] = toJS(this[prop])
        return acc
      },
      { _id: undefined, org_id: undefined, payload: undefined }
    )
  }

  get displayName(): string {
    return 'Template'
  }

  getModelFromPayload = (): OrganizationForm | Todo | undefined => {
    switch (this.type) {
      case 'organization-form':
        const form = new OrganizationForm(this.rootStore, this.payload)
        form._id = undefined
        form.org_id = undefined
        return form
      case 'todo':
        const todo = new Todo(this.rootStore, this.payload)
        todo._id = undefined
        todo.org_id = undefined
        return todo
      default:
        return undefined
    }
  }

  save = async () => {
    const reqData: {
      org_id: string
      _id?: string
      payload: { _id: undefined }
    } = this.data

    if (!reqData._id) {
      delete reqData._id
    }

    delete reqData.payload._id

    const { data } = await this.client.templates.save(reqData)

    if (!this._id) {
      this._id = data._id
    }

    this.rootStore.templateStore.updateTemplates(this)

    return this
  }
}
