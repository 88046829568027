import React, { FC, Fragment, useEffect, useState } from 'react'
import { Alert, Box, Button, Grid, InputLabel, Stack } from '@mui/material'
import moment from 'moment'
import { observer } from 'mobx-react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Checkbox, TextInput } from '../../Form'
import { DatePicker, TimePicker } from '../../DateTimePicker'
import HelpDialog from '../../HelpDialog/HelpDialog'

import Folder from '../../../Model/Folder'
import ModelBase from '../../../Model/ModelBase'

const now = moment({})
const timezoneAbbreviation = new Date()
  .toLocaleTimeString('en-us', { timeZoneName: 'short' })
  .split(' ')[2]

const SharePublic: FC<{
  isSaving: boolean
  record: ModelBase
  onGenerateUrl: () => void
}> = ({ isSaving, onGenerateUrl, record }): JSX.Element => {
  const [onClipboard, setOnClipboard] = useState(false)
  const [expiresError, setExpiresError] = useState('')
  const [shouldExpire, setShouldExpire] = useState(false)
  const [expiresDate, setExpiresDate] = useState(
    now.clone().add(1, 'days').format('YYYY-MM-DD')
  )
  const [expiresTime, setExpiresTime] = useState(now.clone().format('HH:mm'))

  useEffect(() => {
    if (
      record &&
      record.isPublic &&
      record.shareUrlExpiresAt &&
      record.shareUrlExpiresAt > 0
    ) {
      const expires = moment(record.shareUrlExpiresAt)
      setExpiresDate(expires.clone().format('YYYY-MM-DD'))
      setExpiresTime(expires.clone().format('HH:mm'))
      setShouldExpire(true)
    }
    return () => {
      setExpiresDate(now.clone().add(1, 'days').format('YYYY-MM-DD'))
      setExpiresTime(now.clone().format('HH:mm'))
      setShouldExpire(false)
    }
  }, [])
  if (!record || record instanceof Folder || record.model instanceof Folder) {
    return null
  }

  const isValidExpiration = (val: Date) => {
    if (moment(val).isBefore(moment())) {
      setExpiresError('You must select a date and time after now')

      return false
    }

    setExpiresError('')

    return true
  }
  const getURLSection = () => {
    if (record.isPublic) {
      const expiresSection = (
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <DatePicker
              label='Date'
              value={new Date(record.shareUrlExpiresAt || Date.now())}
              onChange={(newVal: Date) => {
                if (isValidExpiration(newVal)) {
                  record.shareUrlExpiresAt = +newVal.valueOf()
                }
              }}
              dataQa='public-expires-date'
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TimePicker
              required
              label={`Time (${timezoneAbbreviation})`}
              type='time'
              value={new Date(record.shareUrlExpiresAt || Date.now())}
              onChange={(newVal: Date) => {
                if (isValidExpiration(newVal)) {
                  record.shareUrlExpiresAt = +newVal.valueOf()
                }
              }}
              dataQa='public-expires-time'
            />
          </Grid>
          {Boolean(expiresError) && (
            <Grid item xs={12}>
              <Alert severity='error'>{expiresError}</Alert>
            </Grid>
          )}
        </Grid>
      )

      return (
        <Fragment>
          {shouldExpire && expiresSection}
          {record.isPublic && record.shareUrl && (
            <Box pt={2}>
              <TextInput
                label='Public URL'
                onFocus={e => e.target.select()}
                onClick={e => e.target.select()}
                onChange={() => true}
                readOnly
                value={record.shareUrl}
              />
              <Box pt={2}>
                <CopyToClipboard
                  text={record.shareUrl}
                  options={{ format: 'text/plain' }}
                  onCopy={() => {
                    setOnClipboard(true)
                    setTimeout(() => {
                      setOnClipboard(false)
                    }, 2000)
                  }}
                >
                  <Button data-qa='share-item-clipboard' variant='outlined'>
                    {onClipboard ? 'Copied!' : 'Copy Link'}
                  </Button>
                </CopyToClipboard>
              </Box>
            </Box>
          )}
        </Fragment>
      )
    }

    return null
  }

  return (
    <Stack spacing={3}>
      <InputLabel>
        Public:{' '}
        <HelpDialog content='Optional: Allow this item to be accessed via a Public URL that we generate' />
      </InputLabel>
      {record.isPublic ? (
        <Checkbox
          label='Disable Access At Specific Date / Time'
          checked={shouldExpire}
          onChange={() => {
            if (!shouldExpire) {
              record.shareUrlExpiresAt = +moment(
                `${expiresDate} ${expiresTime}`
              )
                .toDate()
                .valueOf()
            } else {
              record.shareUrlExpiresAt = undefined
            }
            setShouldExpire(
              Boolean(record.shareUrlExpiresAt && record.shareUrlExpiresAt > 0)
            )
          }}
          dataQa='share-item-disable-expiration'
        />
      ) : (
        <Button disabled={isSaving} onClick={onGenerateUrl} variant='contained'>
          Generate Public URL
        </Button>
      )}
      {getURLSection()}
    </Stack>
  )
}

export default observer(SharePublic)
