import React, { FC, useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { observer } from 'mobx-react'

import ModalDialog from '../ModalDialog/ModalDialog'
import { GetWrappedIcon } from '../Icons'
import EditorAttachmentForm from './EditorAttachmentForm'

import ModelBase from '../../Model/ModelBase'

const EditorAttachmentModal: FC<{
  isForTodos?: boolean
  item?: ModelBase
}> = (props): JSX.Element => {
  const { isForTodos, item } = props
  const [disableSubmit, setDisableSubmit] = useState(false)

  if (!item) {
    return null
  }

  return (
    <ModalDialog
      confirmActionDisabled={disableSubmit}
      onClose={() => {
        item.links.replace(item.links.filter(link => link.hasEnoughData))
      }}
      onOpen={() => {
        if (item && item.links && item.addLink && item.links.length === 0) {
          item.links.push(item.getBlankLink())
        }
      }}
      content={
        <EditorAttachmentForm
          {...props}
          filteredLinks={item.links
            .filter(link => link.hasEnoughData)
            .map(l => l.id || l.url)}
          isForTodos={isForTodos}
          setDisableSubmit={setDisableSubmit}
        />
      }
      fullWidth
      header='Attachments'
      trigger={
        <Tooltip title='Attach Links'>
          <IconButton size='small' data-qa='discussions-message-attachments'>
            <GetWrappedIcon name='attachment' />
          </IconButton>
        </Tooltip>
      }
    />
  )
}

EditorAttachmentModal.defaultProps = {
  isForTodos: false,
  item: undefined,
}

export default observer(EditorAttachmentModal)
