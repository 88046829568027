import React, { FC, MouseEvent } from 'react'
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
} from '@mui/material'

import { GetWrappedIcon } from '../Icons'

const GlobalContentFolder: FC<
  MenuItemProps & { onClick?: (event: MouseEvent) => void }
> = ({ onClick, ...rest }): JSX.Element => {
  return (
    <MenuItem onClick={onClick} {...rest}>
      <ListItemIcon>
        <GetWrappedIcon name='globalContentFolder' />
      </ListItemIcon>
      <ListItemText>Global Content Folder Settings</ListItemText>
    </MenuItem>
  )
}

GlobalContentFolder.defaultProps = {
  onClick: undefined,
}

export default GlobalContentFolder
