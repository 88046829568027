import { action, makeObservable, observable, reaction } from 'mobx'
import { Socket } from 'socket.io-client'

import RootStore from './RootStore'
import StoreResourceBase from './StoreResourceBase'

import { useLog } from '../lib/log'
import { getSocket } from '../lib/socket'

import { Alert } from '../types'

const log = useLog()

export default class AlertStore extends StoreResourceBase {
  loadingAlerts = false

  alerts: Alert[] = []

  alertsLastLoaded = false

  socket: Socket | undefined = undefined

  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      alerts: observable,
      getAlerts: action,
      setAlerts: action,
      loadingAlerts: observable,
      alertsLastLoaded: observable,
      archiveAlerts: action,
      deleteAlerts: action,
      deleteAllAlerts: action,
      dismissAlerts: action,
      markAlertAsViewed: action,
      markAlertsAsViewed: action,
      markAlertsUnread: action,
      unarchiveAlerts: action,
    })

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
  }

  setAlerts = (alerts: Alert[]) => {
    if (Array.isArray(alerts)) {
      this.alerts = alerts
        .sort((a: Alert, b: Alert) => {
          if (a.archivedAt || b.archivedAt) {
            return a.archivedAt > b.archivedAt
              ? 1
              : b.archivedAt > a.archivedAt
              ? -1
              : 0
          }
          if (a.viewedAt || b.viewedAt) {
            return a.viewedAt > b.viewedAt
              ? 1
              : b.viewedAt > a.viewedAt
              ? -1
              : 0
          }
          return b.timestamp - a.timestamp
        })
        .map((a: Alert) => ({
          ...a,
          org_name: this.rootStore.orgStore.getOrgNameFromList(a.org_id),
        }))
    } else {
      this.alerts = []
    }
  }

  getAlerts = async () => {
    if (this.loadingAlerts || !this.rootStore.commonStore.jwt) {
      return
    }

    try {
      this.loadingAlerts = true
      const { data } = await this.rootStore.client.alerts.getAlerts(
        this.rootStore.orgStore.currentOrg &&
          this.rootStore.orgStore.currentOrg.impersonatingId
      )
      this.setAlerts(data)
      this.loadedResource = true
      log.code('alt001')
    } catch (e) {
      this.loadedResource = true
      if (e.status && e.status === 401) {
        log.code('alt202', { error: e })
      } else {
        log.code('alt301', { error: e })
      }
    }
    this.loadingAlerts = false
  }

  handleSocket = () => {
    if (!this.socket && this.rootStore.userStore._id) {
      this.socket = getSocket()
      this.socket.onAny((type: string) => {
        const typesToIgnore = [
          'frontEndUpdated',
          'forceDisconnect',
          'investmentsQuarterlyLockdownComplete',
        ]

        if (!typesToIgnore.includes(type)) {
          this.getAlerts()
        }
      })
    }
  }

  archiveAlerts = async (alertsToUpdate: Alert[]) => {
    if (!this.rootStore.orgStore.currentOrg.isImpersonating) {
      try {
        const { data } = await this.rootStore.client.alerts.archiveAlert(
          alertsToUpdate
        )
        this.setAlerts(data.alerts)
      } catch (error) {
        log.code('alt302', { error })
      }
    }
  }

  deleteAlerts = async (alertsToUpdate: Alert[]) => {
    if (!this.rootStore.orgStore.currentOrg.isImpersonating) {
      try {
        const { data } = await this.rootStore.client.alerts.removeAlerts(
          alertsToUpdate
        )
        this.setAlerts(data.alerts)
      } catch (error) {
        log.code('alt302', { error })
      }
    }
  }

  deleteAllAlerts = async () => {
    if (!this.rootStore.orgStore.currentOrg.isImpersonating) {
      try {
        await this.rootStore.client.alerts.removeAlerts([])
        this.setAlerts([])
      } catch (error) {
        log.code('alt302', { error })
      }
    }
  }

  dismissAlerts = async () => {
    if (!this.rootStore.orgStore.currentOrg.isImpersonating) {
      try {
        const { data } = await this.rootStore.client.alerts.markAsViewed([])
        this.setAlerts(data.alerts)
      } catch (e) {
        /* no-op */
      }
    }
  }

  markAlertAsViewed = async (uid: string) => {
    if (!this.rootStore.orgStore.currentOrg.isImpersonating) {
      try {
        const alertToMark = this.alerts.find(alert => alert.uid === uid)
        if (!alertToMark) {
          return
        }
        const { data } = await this.rootStore.client.alerts.markAsViewed(
          alertToMark
        )
        this.setAlerts(data.alerts)
      } catch (error) {
        log.code('alt302', { error })
      }
    }
  }

  markAlertsAsViewed = async (alertsToUpdate: Alert[]) => {
    if (!this.rootStore.orgStore.currentOrg.isImpersonating) {
      try {
        const { data } = await this.rootStore.client.alerts.markAsViewed(
          alertsToUpdate
        )
        this.setAlerts(data.alerts)
        log.code('alt003', { alert })
      } catch (error) {
        log.code('alt302', { error })
      }
    }
  }

  markAlertsUnread = async (alertsToUpdate: Alert[]) => {
    if (!this.rootStore.orgStore.currentOrg.isImpersonating) {
      try {
        const { data } = await this.rootStore.client.alerts.markAlertUnread(
          alertsToUpdate
        )
        this.setAlerts(data.alerts)
        log.code('alt003', { alert })
      } catch (error) {
        log.code('alt302', { error })
      }
    }
  }

  unarchiveAlerts = async (alertsToUpdate: Alert[]) => {
    if (!this.rootStore.orgStore.currentOrg.isImpersonating) {
      try {
        const { data } = await this.rootStore.client.alerts.unarchiveAlert(
          alertsToUpdate
        )
        this.setAlerts(data.alerts)
      } catch (error) {
        log.code('alt302', { error })
      }
    }
  }
}
