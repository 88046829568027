import React, { FC, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  List,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react'

import { GetWrappedIcon } from '../Icons'

import GroupListItem from '../../components/SharedWithList/components/GroupListItem'
import UserListItem from '../../components/SharedWithList/components/UserListItem'

import { useRootStore } from '../../services/RootStoreContext'

import { OrgGroupProps, OrgMemberProps } from '../../types'

const getMemberData = (
  currentUserId: string,
  orgMembers: OrgMemberProps[],
  memberId: string
): (OrgMemberProps & { name: string }) | undefined => {
  if (currentUserId === memberId) {
    return undefined
  }

  const member = orgMembers.find(m => m._id === memberId)

  return member
    ? { ...member, name: `${member.fname} ${member.lname}` }
    : undefined
}

const getGroupData = (
  currentUserId: string,
  orgMembers: OrgMemberProps[],
  orgGroups: OrgGroupProps[],
  groupId: string
): {
  name: string
  isHostGroup: boolean
  members: (OrgMemberProps & { name: string })[]
} => {
  if (groupId === 'admins') {
    return {
      name: 'Administrators',
      isHostGroup: false,
      members: orgMembers
        .filter(
          m =>
            m.orgRole === 'admin' && !m.isHostAdmin && m._id !== currentUserId
        )
        .map(m => getMemberData(currentUserId, orgMembers, m._id))
        .filter(Boolean),
    }
  }

  const group = orgGroups.find(g => g.id === groupId)

  if (group) {
    return {
      name: group.name,
      isHostGroup: group.isHostGroup,
      members:
        group.members && group.members.length > 0
          ? group.members
              .map(
                m =>
                  m !== currentUserId &&
                  getMemberData(currentUserId, orgMembers, m)
              )
              .filter(Boolean)
          : [],
    }
  }

  return undefined
}

const NotificationRecipients: FC<{
  author: string
  itemTypeName: string
  users: string[]
  groups: string[]
}> = ({ author, itemTypeName, users, groups }): JSX.Element => {
  const {
    orgStore: { currentOrg },
    userStore: { _id: currentUserId },
  } = useRootStore()
  const [showMoreInformation, toggleShowMoreInformation] = useState(false)
  const parsedGroups = groups
    ? groups
        .map(g =>
          getGroupData(
            currentUserId,
            currentOrg.members,
            [...currentOrg.groups, ...currentOrg.hostGroups],
            g
          )
        )
        .filter(Boolean)
    : []
  const parsedUsers = users
    ? users
        .map(u => getMemberData(currentUserId, currentOrg.members, u))
        .filter(Boolean)
    : []

  if (parsedGroups.length > 0 || parsedUsers.length > 0) {
    const shouldAddAuthor =
      currentUserId !== author
        ? !parsedUsers.some(user => user._id === author) &&
          !parsedGroups.some(group =>
            group.members.some(groupMember => groupMember._id === author)
          )
        : false

    if (shouldAddAuthor) {
      parsedUsers.push(getMemberData(currentUserId, currentOrg.members, author))
    }
  }

  const isAuthor = author === currentUserId
  const canEditPermissions =
    itemTypeName === 'Message'
      ? isAuthor
      : currentOrg.isOrgAdmin || (currentOrg.isOrgContributor && isAuthor)

  if (parsedGroups.length === 0 && parsedUsers.length === 0) {
    return (
      <Box>
        <Box sx={{ mb: 1 }}>
          <Alert severity='warning'>
            A notification email will be sent to all members containing a link
            that will redirect to this {itemTypeName}.
          </Alert>
        </Box>
        {canEditPermissions && (
          <Alert severity='info'>
            To change who is notified and can access this {itemTypeName} close
            this dialog and edit the Recipients field.
          </Alert>
        )}
      </Box>
    )
  }
  const groupsText =
    parsedGroups.length > 0
      ? `${parsedGroups.length} group${parsedGroups.length === 1 ? '' : 's'}`
      : ''
  const usersText =
    parsedUsers.length > 0
      ? `${parsedGroups.length > 0 ? ' and ' : ''}${parsedUsers.length} user${
          parsedUsers.length === 1 ? '' : 's'
        }`
      : ''

  return (
    <Box>
      {!showMoreInformation ? (
        <Box>
          <Typography sx={{ mt: 1, mb: 1 }}>
            You are sending an email notification to {groupsText} {usersText}
          </Typography>
          <Button
            endIcon={<GetWrappedIcon name='email' />}
            onClick={() => toggleShowMoreInformation(true)}
            variant='outlined'
          >
            View Recipients
          </Button>
        </Box>
      ) : (
        <Box>
          <Paper variant='outlined'>
            <Alert severity='warning'>
              A notification email will be sent to {groupsText} {usersText}{' '}
              containing a link that will redirect to this {itemTypeName}.
            </Alert>
            <List
              subheader={
                <ListSubheader component='div' id='nested-list-subheader'>
                  Recipients list
                </ListSubheader>
              }
            >
              {parsedGroups.length > 0 &&
                parsedGroups.map(
                  g => g && <GroupListItem key={g.name} group={g} />
                )}
              {parsedUsers.filter(Boolean).length > 0 &&
                parsedUsers
                  .filter(Boolean)
                  .map(m => (
                    <UserListItem key={`${m.fname}-${m.lname}`} user={m} />
                  ))}
            </List>
          </Paper>
          {canEditPermissions && (
            <Alert severity='info' sx={{ mt: 2 }}>
              If you would like to change who is notified and can access this{' '}
              {itemTypeName} close this dialog and edit the Recipients field.
            </Alert>
          )}
        </Box>
      )}
    </Box>
  )
}

export default observer(NotificationRecipients)
