import React, { FC, Fragment, useMemo } from 'react'
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Theme,
  Typography,
  Tooltip,
  Box,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { observer } from 'mobx-react'
import { useNavigate, useParams } from 'react-router-dom'

import { GetWrappedIcon } from '../../components/Icons'
import ActivityIndicators from '../../components/ActivityIndicators/ActivityIndicators'
import OverflowPopup from '../../components/OverflowPopup/OverflowPopup'
import LeftSideListIcons from '../../pages/Messages/components/LeftSideListIcons'

import ModelBase from '../../Model/ModelBase'

import { useRootStore } from '../../services/RootStoreContext'
import { useTwoPanelSideNavContext } from '../../services/TwoPanelSideNavContext'

import useResponsive from '../../hooks/useResponsive'

import { slugMatchesId } from '../../lib/slug'

const RootStyle = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1, 0, 1, 1.5),
  transition: theme.transitions.create('all'),
}))

const LeftPanelListItem: FC<{
  item?: ModelBase
}> = ({ item }): JSX.Element => {
  const { openSidebar, setOpenSidebar } = useTwoPanelSideNavContext()
  const isDesktop = useResponsive('up', 'lg')
  const navigate = useNavigate()
  const { id } = useParams()
  const { orgStore, todoStore, userStore } = useRootStore()
  const { currentOrg } = orgStore

  let showSearchAndDetail = openSidebar

  if (!isDesktop && !id) {
    showSearchAndDetail = true
  }

  const icon = (
    <ListItemAvatar>
      {item.modelCollection === 'discussions' ? (
        <LeftSideListIcons item={item} />
      ) : (
        <Tooltip
          disableFocusListener={openSidebar}
          disableHoverListener={openSidebar}
          disableTouchListener={openSidebar}
          title={<Typography>{item.listTitle}</Typography>}
        >
          <Avatar>
            <GetWrappedIcon
              name={item.isInProgress ? 'pending' : item.modelCollection}
            />
          </Avatar>
        </Tooltip>
      )}
    </ListItemAvatar>
  )

  const time = item.posted || false
  const isUnread = useMemo(() => {
    if (item?.modelCollection === 'todos') {
      if (item?.isIncompleteByCurrentUser) {
        return true
      }
      return false
    }

    return Array.isArray(item?.readBy) && userStore?._id
      ? !item.readBy.includes(userStore?._id)
      : true
  }, [JSON.stringify(item?.readBy), todoStore.updatedOn, id])

  const getCreatorText = (): string => {
    const creator = item.createdForId || item.creator || item.author
    if (userStore._id === creator) {
      return 'Created By You'
    }
    const member = currentOrg.members.find(mem => mem._id === creator)
    if (member) {
      return `${member.fname} ${member.lname}`
    }
    return ''
  }

  return (
    <RootStyle
      divider
      onClick={() => {
        const goTo = item.permalink

        if (goTo && !goTo.includes(id)) {
          navigate(goTo)
        }

        if (!isDesktop && openSidebar) {
          setOpenSidebar(false)
        }
      }}
      selected={id && slugMatchesId(id, item.getNotifyId())}
      sx={{ width: 'inherit' }}
    >
      <Box sx={{ paddingLeft: !showSearchAndDetail ? '15px' : '0px' }}>
        {icon}
      </Box>

      {showSearchAndDetail && (
        <Fragment>
          <ListItemText
            secondary={<OverflowPopup content={getCreatorText()} />}
            primary={
              <OverflowPopup
                content={item.listTitle}
                variant={isUnread ? 'body1' : 'body2'}
                sx={{
                  color: isUnread ? 'textPrimary' : 'textSecondary',
                  fontWeight: isUnread
                    ? (theme: Theme) => theme.typography.fontWeightMedium
                    : undefined,
                }}
              />
            }
          />
          <ListItemSecondaryAction>
            <ActivityIndicators item={item} />
            <Typography
              component='div'
              sx={{ pl: 1, pr: 1, textAlign: 'right', width: '100%' }}
              variant='caption'
            >
              {time}
            </Typography>
          </ListItemSecondaryAction>
        </Fragment>
      )}
    </RootStyle>
  )
}

LeftPanelListItem.defaultProps = {
  item: undefined,
}

export default observer(LeftPanelListItem)
