import APIResponseType from './APIResponseType'

export const getMeeting = (org_id: string, id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/meetings/${id}`,
  method: 'GET',
})

export const getPassedMeeting = (
  org_id: string,
  id: string
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/meetings/${id}?isPassed=true`,
  method: 'GET',
})

export const getMeetingForRecording = (
  org_id: string,
  id: string
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/meetings/${id}?isContentPost=true`,
  method: 'GET',
})

export const getRecordingUrl = (
  orgId: string,
  meetingId: string
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/meetings/${meetingId}/recordingUrl`,
  method: 'GET',
})

export const getMeetingOrg = (id: string): APIResponseType => ({
  url: `/orgs/v1/meetings/${id}`,
  method: 'GET',
})
