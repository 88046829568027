import React, { FC } from 'react'
import { observer } from 'mobx-react'

import { useRootStore } from '../../services/RootStoreContext'

import ModelBase from '../../Model/ModelBase'
import ClientListModal from './ClientListModal'

const ClientSmartTagModal: FC<{
  forForm?: boolean
  item: ModelBase
  clientIds?: string[]
}> = ({ clientIds, forForm, item }) => {
  const {
    tagStore: { getTagsSelectedClients },
  } = useRootStore()

  const selectedClients: string[] =
    clientIds?.length > 0
      ? clientIds
      : getTagsSelectedClients(item?.permissionTags || []).map(
          client => client.value
        )

  if (forForm && selectedClients.length === 0) {
    return null
  }

  return <ClientListModal clientIds={selectedClients} forForm={forForm} />
}

ClientSmartTagModal.defaultProps = {
  forForm: true,
  clientIds: [],
}

export default observer(ClientSmartTagModal)
