import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx'

import ModelBase from '../ModelBase'

import RootStore from '../../stores/RootStore'

import {
  FeeAggregate,
  InvestmentFeeScheduleAssignmentProps,
  SelectOptionType,
} from '../../types'

import FeeSchedule from './FeeSchedule'

const flatFeeMinimumDescriptionAll =
  'Minimum Fee requirements will be for all accounts collectively. The Minimum Fee will then be applied to each account based on billable balance allocation'
const flatFeeMinimumDescriptionEach =
  'Minimum Fee applied each account if they do not meet the Minimum Fee'
const flatFeeDescriptionAll =
  'Flat amount will be for all accounts. The amount will then be applied to each account based on billable balance allocation'
const flatFeeDescriptionEach = 'Flat amount applied to each account'
const tierAggregateDescriptionAll =
  'Determine tiers based on aggregate of all accounts billable balance. If more than one tier is used, the fee will then be applied to each account based on billable balance allocation'
const tierAggregateDescriptionEach =
  "Determine tiers based on each account's billable balance"

export default class FeeScheduleAssignment extends ModelBase {
  modelCollection = 'investment_billing_profiles'

  constructor(
    rootStore: RootStore,
    profile?: InvestmentFeeScheduleAssignmentProps
  ) {
    super(rootStore)
    makeObservable(this, {
      setFeeSchedule: action,
      data: override,
      validationSchema: override,
      accountIds: observable,
      feeScheduleId: observable,
      flatFeeAggregate: observable,
      minimumFeeAggregate: observable,
      tierAggregate: observable,
      description: computed,
      flatFeeDescription: computed,
      feeScheduleOptions: computed,
      flatFeeOrMinimumAggregateOptions: computed,
      hasAnyFlatFee: computed,
      needsFlatFeeAggregate: computed,
      needsMiniumAggregate: computed,
      needsTierAggregate: computed,
      tierAggregateOptions: computed,
    })

    this.setProps(profile)
  }

  setProps = (data?: InvestmentFeeScheduleAssignmentProps) => {
    this.accountIds = observable.array(data?.accountIds || [])
    this.feeScheduleId = data?.feeScheduleId || ''
    this.flatFeeAggregate = data?.flatFeeAggregate || 'all'
    this.minimumFeeAggregate = data?.minimumFeeAggregate || 'all'
    this.tierAggregate = data?.tierAggregate || 'all'

    this.setFeeSchedule()
  }

  setFeeSchedule = () => {
    if (this.feeScheduleId) {
      this.feeSchedule =
        this.rootStore.investmentBillingStore.feeSchedules.find(
          schedule => schedule._id === this.feeScheduleId
        )
    }
  }

  accountIds: IObservableArray<string> = observable.array([])

  feeScheduleId = ''

  feeSchedule: FeeSchedule | undefined = undefined

  flatFeeAggregate: FeeAggregate = 'all'

  minimumFeeAggregate: FeeAggregate = 'all'

  tierAggregate: FeeAggregate = 'all'

  get hasAnyFlatFee(): boolean {
    return this?.feeSchedule?.hasAnyFlatFee && this.accountIds.length > 0
  }

  get needsFlatFeeAggregate(): boolean {
    return this?.feeSchedule?.hasFlatFee && this.accountIds.length > 0
  }

  get needsMiniumAggregate(): boolean {
    return this?.feeSchedule?.hasMinimumFee && this.accountIds.length > 0
  }

  get needsTierAggregate(): boolean {
    return this?.feeSchedule?.tiers?.length > 0 && this.accountIds.length > 0
  }

  get flatFeeDescription(): string {
    if (!this.hasAnyFlatFee) {
      return ''
    }

    if (this.flatFeeAggregate === 'all') {
      return this.feeSchedule.hasMinimumFee
        ? flatFeeMinimumDescriptionAll
        : flatFeeDescriptionAll
    }

    return this.feeSchedule.hasMinimumFee
      ? flatFeeMinimumDescriptionEach
      : flatFeeDescriptionEach
  }

  get description(): string[] {
    const fromFeeSchedule = this.feeSchedule?.description || []

    if (!Array.isArray(fromFeeSchedule)) {
      return [fromFeeSchedule, this.flatFeeDescription].filter(Boolean)
    }

    return [...fromFeeSchedule, this.flatFeeDescription].filter(Boolean)
  }

  get flatFeeOrMinimumAggregateOptions(): SelectOptionType[] {
    return this.hasAnyFlatFee
      ? [
          {
            description: this.feeSchedule.hasMinimumFee
              ? flatFeeMinimumDescriptionAll
              : flatFeeDescriptionAll,
            key: 'all',
            label: 'Aggregate of All Accounts',
            value: 'all',
          },
          {
            description: this.feeSchedule.hasMinimumFee
              ? flatFeeMinimumDescriptionEach
              : flatFeeDescriptionEach,
            key: 'each',
            label: 'Each Account',
            value: 'each',
          },
        ]
      : []
  }

  get tierAggregateOptions(): SelectOptionType[] {
    return this.accountIds.length > 0
      ? [
          {
            description: tierAggregateDescriptionAll,
            key: 'all',
            label: 'Aggregate of All Accounts',
            value: 'all',
          },
          {
            description: tierAggregateDescriptionEach,
            key: 'each',
            label: 'Each Account',
            value: 'each',
          },
        ]
      : []
  }

  get feeScheduleOptions(): SelectOptionType[] {
    return this.rootStore.investmentBillingStore.feeSchedules.map(
      feeSchedule => ({
        label: feeSchedule.name,
        key: feeSchedule._id,
        value: feeSchedule._id,
      })
    )
  }

  get data(): InvestmentFeeScheduleAssignmentProps {
    return {
      accountIds: this.accountIds.toJSON(),
      feeScheduleId: toJS(this.feeScheduleId),
      flatFeeAggregate: toJS(this.flatFeeAggregate),
      minimumFeeAggregate: toJS(this.minimumFeeAggregate),
      tierAggregate: toJS(this.tierAggregate),
    }
  }
}
