import React, { FC } from 'react'
import { Drawer, useTheme } from '@mui/material'

const FormDrawer: FC<{
  children: JSX.Element | JSX.Element[]
  isOpen: boolean
  onClose: () => void
}> = ({ children, isOpen, onClose }): JSX.Element => {
  const theme = useTheme()

  return isOpen ? (
    <Drawer
      anchor='right'
      onClose={onClose}
      open
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: '75%' },
          height: '100%',
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      {children}
    </Drawer>
  ) : null
}

export default FormDrawer
