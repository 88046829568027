import moment from 'moment'
import stockMarketHolidays from './stockMarketHolidays'

export const getMostRecentBusinessDay = (momentObj: moment.Moment | Date) => {
  if (moment.isDate(momentObj)) {
    momentObj = moment(momentObj)
  }

  if (moment.isMoment(momentObj)) {
    while (
      [0, 6].indexOf(momentObj.day()) !== -1 ||
      stockMarketHolidays.includes(momentObj.clone().format('YYYY-MM-DD'))
    ) {
      momentObj = momentObj.subtract(1, 'days')
    }
  }

  return momentObj
}

export const getNextBusinessDay = (momentObj: moment.Moment | Date) => {
  if (moment.isDate(momentObj)) {
    momentObj = moment(momentObj)
  }

  if (moment.isMoment(momentObj)) {
    while (
      [0, 6].indexOf(momentObj.day()) !== -1 ||
      stockMarketHolidays.includes(momentObj.clone().format('YYYY-MM-DD'))
    ) {
      momentObj = momentObj.add(1, 'days')
    }
  }

  return momentObj
}
