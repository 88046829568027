import APIResponseType from './APIResponseType'

export const remove = (orgId: string, toDoId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/to-dos/${toDoId}`,
  method: 'DELETE',
})

export const get = (orgId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/to-dos`,
  method: 'GET',
})

export const save = (data: {
  _id: string
  org_id?: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/to-dos${data._id ? `/${data._id}` : ''}`,
  method: data._id ? 'PATCH' : 'POST',
  data,
})

export const updateItemCompleted = (
  orgId: string,
  toDoId: string,
  itemId: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/to-dos/${toDoId}/items/${itemId}`,
  method: 'PATCH',
  data,
})

export const updateSubItemCompleted = (
  orgId: string,
  toDoId: string,
  itemId: string,
  subItemId: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/to-dos/${toDoId}/items/${itemId}/subItems/${subItemId}`,
  method: 'PATCH',
  data,
})

export const nudgeAssignees = (
  orgId: string,
  toDoId: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/to-dos/${toDoId}/nudge`,
  method: 'POST',
  data,
})
