import {
  action,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
} from 'mobx'

import Campaign from '../Model/Campaign'
import RootStore from './RootStore'
import StoreResourceBase from './StoreResourceBase'

import { useLog } from '../lib/log'
import { handleSocketListener } from '../lib/socket'

const log = useLog()

export default class CampaingStore extends StoreResourceBase {
  campaigns: IObservableArray<Campaign> = observable.array([])

  listenersLoaded = false

  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      loadData: action,
      campaigns: observable,
      deleteCampaign: action,
      getCampaignModel: action,
    })

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
    reaction(() => this.campaigns, this.handleSocket)
  }

  loadData(campaigns: Campaign[]) {
    this.campaigns = observable.array(
      campaigns.map(
        (campaign: Campaign) => new Campaign(this.rootStore, campaign)
      )
    )
    this.loadedResource = true
    return this
  }

  getCampaignModel(data: Campaign) {
    if (data._id) {
      const model = this.campaigns.find(
        collection => collection._id === data._id
      )
      if (model) {
        return model
      }
    }

    return new Campaign(this.rootStore, data)
  }

  deleteCampaign = async (id: string) => {
    if (
      this.rootStore.orgStore.currentOrg &&
      this.rootStore.orgStore.currentOrg._id
    ) {
      try {
        await this.rootStore.client.campaigns.deleteCampaign(
          this.rootStore.orgStore.currentOrg._id,
          id
        )
        this.campaigns.replace(this.campaigns.filter(c => c._id !== id))
        log.code('cmp003')
      } catch (e) {
        log.code('cmp302', { error: e })
      }
    }
    return true
  }

  handleSocket = () => {
    if (this.rootStore.userStore._id) {
      handleSocketListener(
        'campaigns',
        Campaign,
        this.campaigns,
        this.rootStore
      )
    }
  }
}
