import React, { FC, MouseEvent } from 'react'
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
} from '@mui/material'

import { GetWrappedIcon } from '../Icons'

const RestoreAction: FC<
  MenuItemProps & {
    onClick?: (event: MouseEvent) => void
    text?: string
  }
> = ({ onClick, text, ...rest }): JSX.Element => {
  return (
    <MenuItem onClick={onClick} {...rest}>
      <ListItemIcon>
        <GetWrappedIcon name='restore' />
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </MenuItem>
  )
}

RestoreAction.defaultProps = {
  onClick: undefined,
  text: 'Restore',
}

export default RestoreAction
