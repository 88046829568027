import { action, computed, makeObservable, observable } from 'mobx'
import { Socket } from 'socket.io-client'

import AlertStore from './AlertStore'
import AuthStore from './AuthStore'
import CampaignStore from './CampaignStore'
import Client from '../lib/client'
import CommonStore from './CommonStore'
import ContactStore from './ContactStore'
import ContentStore from './ContentStore'
import CRMStore from './CRMStore'
import DiscussionStore from './DiscussionStore'
import DocumentStore from './DocumentStore'
import EventStore from './EventStore'
import InvestmentBillingStore from './InvestmentBillingStore'
import OrgFormStore from './OrgFormStore'
import OrgStore from './OrgStore'
import PollStore from './PollStore'
import StarStore from './StarStore'
import TagStore from './TagStore'
import TemplateStore from './TemplateStore'
import TodoStore from './TodoStore'
import UserStore from './UserStore'

import ModelBase from '../Model/ModelBase'

import { getSocket } from '../lib/socket'

import { NotificationItem } from '../types'

export default class RootStore {
  publicRoutes = [
    'join-organization',
    'organization-signup',
    'login',
    'logout',
    'psi',
    'campaign',
  ]

  client = new Client()

  listeners: unknown[] = []

  socket: Socket | undefined = undefined

  appUpdated = false

  appLoaded = false

  commonStore: CommonStore

  authStore: AuthStore

  userStore: UserStore

  orgStore: OrgStore

  campaignStore: CampaignStore

  contactStore: ContactStore

  contentStore: ContentStore

  crmStore: CRMStore

  discussionStore: DiscussionStore

  documentStore: DocumentStore

  eventStore: EventStore

  investmentBillingStore: InvestmentBillingStore

  orgFormStore: OrgFormStore

  pollStore: PollStore

  starStore: StarStore

  tagStore: TagStore

  templateStore: TemplateStore

  todoStore: TodoStore

  alertStore: AlertStore

  listMap = {
    content: 'content',
    event: 'events',
    poll: 'polls',
    discussion: 'discussions',
    document: 'documents',
    form: 'orgForms',
  }

  constructor() {
    makeObservable(this, {
      addNotificationItem: action,
      appLoaded: observable,
      appUpdated: observable,
      allItems: computed,
      delayAppUpdatedRefresh: action,
      getItemByTypeAndId: action,
      handleAppConnectSocket: action,
      handleAppSocketForceDisconnet: action,
      loadApp: action,
      notificationItems: observable,
      socket: observable,
      removeNotificationItem: action,
    })
    // the order in which these are called matters
    // first is common,user,auth
    this.commonStore = new CommonStore(this)
    this.userStore = new UserStore(this)
    this.authStore = new AuthStore(this)
    // then org related stuff
    this.alertStore = new AlertStore(this)
    this.campaignStore = new CampaignStore(this)
    this.contactStore = new ContactStore(this)
    this.contentStore = new ContentStore(this)
    this.discussionStore = new DiscussionStore(this)
    this.documentStore = new DocumentStore(this)
    this.eventStore = new EventStore(this)
    this.investmentBillingStore = new InvestmentBillingStore(this)
    this.orgFormStore = new OrgFormStore(this)
    this.pollStore = new PollStore(this)
    this.starStore = new StarStore(this)
    this.tagStore = new TagStore(this)
    this.templateStore = new TemplateStore(this)
    this.todoStore = new TodoStore(this)
    // then orgStore
    this.orgStore = new OrgStore(this)
    this.crmStore = new CRMStore(this)
    // loadapp should always be the last thing
    this.loadApp()
  }

  loadApp() {
    this.orgStore.init()
  }

  setAppListeners() {
    if (!this.listeners || this.listeners.length === 0) {
      this.listeners = this.socket
        ? [
            this.socket.on('frontEndUpdated', () => {
              this.appUpdated = true
            }),
            this.socket.on(
              'investmentsQuarterlyLockdownComplete',
              (data: string) => {
                const { quarter } = JSON.parse(data)

                if (quarter) {
                  this.addNotificationItem({
                    success: true,
                    message: `Successfully locked down investments data for ${quarter}`,
                  })
                }
              }
            ),
            this.socket.onAny((type: string) => {
              const typesToIgnore = [
                'frontEndUpdated',
                'forceDisconnect',
                'investmentsQuarterlyLockdownComplete',
              ]
              if (!typesToIgnore.includes(type)) {
                this.alertStore.getAlerts()
              }
            }),
          ]
        : []
    }
  }

  handleAppConnectSocket = () => {
    this.socket = getSocket()
    this.setAppListeners()
  }

  handleAppSocketForceDisconnet = () => {
    if (this.socket) {
      if (this.socket.removeAllListeners) {
        this.socket.removeAllListeners()
      }

      this.socket.emit('forceDisconnect')
      this.socket = undefined
      this.listeners = []
    }
  }

  get allItems(): ModelBase[] {
    return [
      ...this.contentStore.content,
      ...this.discussionStore.discussions,
      ...this.eventStore.events,
      ...this.orgFormStore.orgForms,
      ...this.pollStore.polls,
      ...this.todoStore.todos,
    ]
  }

  notificationItems: NotificationItem[] = []

  addNotificationItem = (item: NotificationItem | undefined, delay = 5000) => {
    const invalid =
      !item ||
      typeof item === 'string' ||
      !item.message ||
      item.message.length === 0
    if (invalid) return
    item.id = Date.now()
    item.delay = delay
    this.notificationItems.unshift(item)
  }

  removeNotificationItem = (item: NotificationItem | undefined) => {
    if (!item) return
    this.notificationItems = this.notificationItems.filter(
      notification => notification.id !== item.id
    )
  }

  // this refreshes the app when a new deplay goes out
  delayAppUpdatedRefresh = (delayLength = 300000) => {
    this.appUpdated = false
    setTimeout(() => {
      this.appUpdated = true
    }, delayLength)
  }

  getItemByTypeAndId = (type: string, id: string): ModelBase | undefined => {
    switch (type) {
      case 'content':
        return this.contentStore.content.find(item => item._id === id)
      case 'events':
      case 'event':
        return this.eventStore.events.find(item => item._id === id)
      case 'polls':
      case 'poll':
        return this.pollStore.polls.find(item => item._id === id)
      case 'discussions':
      case 'discussion':
        return this.discussionStore.discussions.find(item => item._id === id)
      case 'todos':
        return this.todoStore.todos.find(item => item._id === id)
      case 'documents':
      case 'document':
        return this.documentStore.flattenedDocuments.find(
          item => item.getNotifyId() === id
        )
      case 'orgForms':
      case 'forms':
      case 'form':
        return this.orgFormStore.orgForms.find(item => item._id === id)
      default:
        return undefined
        break
    }
  }
}
