import {
  computed,
  IObservableArray,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx'

import { getFeeDescription } from '../../lib/investmentBilling'

import FeeBase from './FeeBase'

import RootStore from '../../stores/RootStore'

import { AssetOverrideProps, FeeTypeType, SelectOptionType } from '../../types'

export default class AssetOverride extends FeeBase {
  modelCollection = 'investment_billing_profiles'

  accountIds: IObservableArray<string> = observable.array([])

  amount = 0

  createdAt = Date.now()

  deletedAt = 0

  feeType: FeeTypeType = undefined

  rootStore: RootStore

  securityClass = ''

  securityId = ''

  constructor(rootStore: RootStore, data?: AssetOverrideProps) {
    super(data)

    this.rootStore = rootStore

    makeObservable(this, {
      data: override,
      accountIds: observable,
      securityClass: observable,
      securityId: observable,
      description: computed,
      securityClassOptions: computed,
      securityOptions: computed,
    })

    this.setProps(data)
  }

  setProps = (data?: AssetOverrideProps) => {
    this.accountIds = observable.array(data?.accountIds || [])
    this.createdAt = data?.createdAt || Date.now()
    this.deletedAt = data?.deletedAt || 0
    this.securityClass = data?.securityClass || ''
    this.securityId = data?.securityId || ''
    this.amount = data?.amount || 0
    this.feeType = data?.feeType
  }

  get data(): AssetOverrideProps {
    return {
      accountIds: this.accountIds.toJSON(),
      securityClass: toJS(this.securityClass),
      securityId: toJS(this.securityId)?.toString() || '',
      amount: +(toJS(this.amount) || 0),
      feeType: toJS(this.feeType),
    }
  }

  get securityClassOptions(): SelectOptionType[] {
    return this.rootStore.investmentBillingStore.securityClassOptions
  }

  get securityOptions(): SelectOptionType[] {
    return this.rootStore.investmentBillingStore.securityOptions
  }

  get description() {
    if (this.securityId) {
      const security = this.securityOptions.find(
        option => option.value === this.securityId
      )

      return security
        ? `${security ? `${security.label}: ` : ''}${getFeeDescription(this)}`
        : 'Select Asset or Asset Class'
    }

    if (this.securityClass) {
      const securityClass = this.securityClassOptions.find(
        option => option.value === this.securityClass
      )

      return securityClass
        ? `${
            securityClass ? `${securityClass.label}: ` : ''
          }${getFeeDescription(this)}`
        : 'Select Asset or Asset Class'
    }

    return 'Select Asset or Asset Class'
  }
}
