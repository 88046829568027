import APIResponseType from './APIResponseType'

export const getEvents = (org_id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/events`,
  method: 'GET',
})

export const getICS = (orgId: string, eventId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/events/${eventId}/ics`,
  method: 'GET',
})

export const notifyICS = (orgId: string, eventId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/events/${eventId}/ics/notify`,
  method: 'GET',
})

export const saveEvent = (
  data: { _id?: string; org_id: string },
  rsvpAction?: string,
  notify = false
): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/events${data._id ? `/${data._id}` : ''}${
    rsvpAction ? `?rsvp=${rsvpAction}` : ''
  }${notify ? `?notify=${notify}` : ''}`,
  method: data._id ? 'PATCH' : 'POST',
  data,
})

export const saveRSVP = (
  orgId: string,
  eventId: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/events/${eventId}/rsvp`,
  method: 'PATCH',
  data,
})

export const deleteEvent = (data: {
  _id: string
  org_id: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/events/${data._id}`,
  method: 'DELETE',
})
