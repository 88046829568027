import APIResponseType from './APIResponseType'

export const getEvents = (orgId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/crm/events`,
  method: 'GET',
})

export const getTasks = (orgId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/crm/tasks`,
  method: 'GET',
})

export const syncAccounts = (orgId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/crm/syncAccounts`,
  method: 'PATCH',
})
