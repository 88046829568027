import React from 'react'
import { Box } from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  sx?: object
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, sx, ...other } = props

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{ height: '100%', ...sx }}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

TabPanel.defaultProps = {
  children: null,
  sx: undefined,
}

export default TabPanel
