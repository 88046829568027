import React, { FC } from 'react'
import { observer } from 'mobx-react'
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
} from '@mui/material'

import { GetWrappedIcon } from '../../../components/Icons'
import DiscussionModal from '../../../components/DiscussionModal/DiscussionModal'

import CallToAction from '../../../Model/CallToAction'

const ActionCard: FC<{ action: CallToAction }> = ({ action }) => {
  return (
    <Card raised>
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography component='div' variant='h5'>
          {action.title}
        </Typography>
        <Box>
          <Typography variant='body2' color='text.secondary'>
            {action.description}
          </Typography>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <DiscussionModal
          roles={action.roles}
          groups={action.roles}
          users={[]}
          {...action.payload}
        >
          <Button
            color='success'
            startIcon={<GetWrappedIcon name='message' />}
            variant='outlined'
            size='small'
            sx={{ mb: 2 }}
          >
            {action.title}
          </Button>
        </DiscussionModal>
      </CardActions>
    </Card>
  )
}

export default observer(ActionCard)
