import { observable, computed, toJS, makeObservable } from 'mobx'

export default class MemberRecordProperty {
  constructor(props: { id?: string | undefined; value?: string | undefined }) {
    makeObservable(this, {
      id: observable,
      value: observable,
      data: computed,
    })

    this.id = props.id || undefined
    this.value = props.value || ''
  }

  id: string | undefined = undefined

  value: string | undefined = ''

  get data() {
    return toJS({
      id: this.id,
      value: this.value,
    })
  }
}
