import React, { FC, useState } from 'react'
import { Box, Button, IconButton, useTheme } from '@mui/material'
import { observer } from 'mobx-react'

import ConfirmNotificationOnSave from '../../../components/ConfirmNotificationOnSave/ConfirmNotificationOnSave'
import { GetWrappedIcon } from '../../../components/Icons'
import { SaveButton } from '../../../components/Buttons'
import Editor from '../../../components/QuillEditor'
import Discussion from '../../../Model/Discussion'
import DiscussionMessage from '../../../Model/DiscussionMessage'
import { useRootStore } from '../../../services/RootStoreContext'
import useResponsive from '../../../hooks/useResponsive'
import { generateID } from '../../../lib/utils'

const DiscussionComposerContainer: FC<{
  message: DiscussionMessage
  discussion: Discussion
  error?: boolean
  isNew?: boolean
  cancelEdit?: () => void
  handelMessageSaved?: () => void
}> = ({
  message,
  discussion,
  error,
  isNew,
  cancelEdit,
  handelMessageSaved,
}): JSX.Element => {
  const { addNotificationItem, allItems, orgStore, userStore } = useRootStore()
  const { currentOrg } = orgStore
  const { isImpersonating } = currentOrg
  const theme = useTheme()
  const isDesktop = useResponsive('up', 'md')
  const [tempDisId] = useState(discussion._id || generateID())
  const [tempMsgId, setTempMsgId] = useState(message?.id || generateID())
  const [isSaving, setIsSaving] = useState(false)

  if (!discussion || !message) {
    return null
  }

  const getParentItem = () =>
    discussion && discussion.parent && allItems && allItems.length > 0
      ? allItems.find(
          item =>
            item._id === discussion.parent &&
            item.modelCollection === discussion.parentCollection
        )
      : false
  const getUserPermissions = () => {
    if (discussion) {
      if (discussion.parent) {
        const parentItem = getParentItem()
        return parentItem ? parentItem.users : []
      }
      return discussion.users || []
    }
    return []
  }
  const getGroupPermissions = () => {
    if (discussion) {
      if (discussion.parent) {
        const parentItem = getParentItem()
        return parentItem ? parentItem.groups : []
      }
      return discussion.groups || []
    }
    return []
  }

  const handleChangeMessage = (value: string) => {
    message.text = value || ''
  }

  const submitDisabled =
    isImpersonating ||
    isSaving ||
    !message.text ||
    !discussion.topic ||
    discussion.topic.length < 1

  return (
    <Editor
      autoFocus={Boolean(discussion && discussion.topic && !discussion.parent)}
      error={error}
      id={`compose-message-${tempDisId}-${tempMsgId}`}
      item={message}
      onChange={handleChangeMessage}
      placeholder='Type a message'
      sx={{
        backgroundColor: isNew
          ? theme.palette.common.white
          : theme.palette.grey[200],
        flexGrow: 1,
        mt: 1,
      }}
      value={message.text}
    >
      {/* Box Holds the CANCEL and SEND buttons the other buttons are found inside the Editor component  */}
      <Box>
        {/* only visible when you are editing a message */}
        {!isNew && cancelEdit && (
          <Button onClick={cancelEdit} variant='outlined' sx={{ mr: 2 }}>
            Cancel
          </Button>
        )}
        {Boolean(discussion._id || discussion.parent) && (
          <ConfirmNotificationOnSave
            disabled={submitDisabled}
            usersState={getUserPermissions()}
            groupsState={getGroupPermissions()}
            item={discussion}
            onConfirm={async (notify: boolean) => {
              setIsSaving(true)
              try {
                if (isNew) {
                  await discussion.addMessage(message, userStore._id, notify)
                } else {
                  await message.save(notify)
                }

                setTempMsgId(message?.id || generateID())
              } catch (e) {
                addNotificationItem({
                  error: true,
                  message:
                    'Something went wrong when attempting to save message. Please contact support.',
                })
              } finally {
                if (handelMessageSaved) {
                  handelMessageSaved()
                }
              }

              setIsSaving(false)
            }}
          >
            {isDesktop ? (
              <SaveButton
                color='success'
                disabled={submitDisabled}
                data-qa='discussions-message-create-button'
                endIcon={<GetWrappedIcon name='send' />}
                isSaving={isSaving}
              />
            ) : (
              <IconButton
                disabled={submitDisabled}
                data-qa='discussions-message-create-button'
                color='success'
              >
                <GetWrappedIcon name='send' />
              </IconButton>
            )}
          </ConfirmNotificationOnSave>
        )}
      </Box>
    </Editor>
  )
}

DiscussionComposerContainer.defaultProps = {
  cancelEdit: undefined,
  error: false,
  isNew: false,
  handelMessageSaved: undefined,
}

export default observer(DiscussionComposerContainer)
