import React, { FC } from 'react'
import { Avatar, SxProps } from '@mui/material'
import { observer } from 'mobx-react'

import { getNameInitials, stringToColor } from '../../lib/utils'

const UserAvatar: FC<{
  sx?: SxProps
  user?:
    | string
    | {
        fname: string
        lname: string
        photo: string
        name: string
        label?: string
      }
}> = ({ user, sx }): JSX.Element => {
  if (typeof user === 'string') {
    return null
  }

  if (!user) {
    return <Avatar sx={sx}>New User</Avatar>
  }

  const { name, fname, lname, photo, label } = user
  const fullName = `${fname ? fname.trim() : ''}${
    lname ? ` ${lname.trim()}` : ''
  }`

  if (photo) {
    return <Avatar alt={fullName} src={photo} />
  }

  return (
    <Avatar
      sx={{ ...sx, backgroundColor: stringToColor(name || fullName) }}
      alt={name || fullName}
    >
      {getNameInitials(name || fullName || label || '')}
    </Avatar>
  )
}

UserAvatar.defaultProps = {
  sx: { width: 35, height: 35 },
  user: undefined,
}

export default observer(UserAvatar)
