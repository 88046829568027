import React, { FC } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
  DateRangePicker as MUIDateRangePicker,
  DateRangePickerProps,
} from '@mui/x-date-pickers-pro/DateRangePicker'
import { observer } from 'mobx-react'

const DateRangePicker: FC<
  Omit<DateRangePickerProps<Date>, 'renderInput'> & {
    dataQa?: string
    disabled?: boolean
    endText?: string
    onChange: (newVal: Date[]) => void
    startText?: string
    value: Date[]
  }
> = ({
  dataQa,
  disabled,
  endText,
  onChange,
  startText,
  value,
  ...rest
}): JSX.Element => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <MUIDateRangePicker
      {...rest}
      data-qa={dataQa}
      disabled={disabled}
      localeText={{ start: startText, end: endText }}
      onChange={onChange}
      value={value}
    />
  </LocalizationProvider>
)

DateRangePicker.defaultProps = {
  dataQa: undefined,
  disabled: false,
  endText: undefined,
  startText: undefined,
}

export default observer(DateRangePicker)
