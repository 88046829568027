import React, { FC, useCallback, useState } from 'react'
import { Stack } from '@mui/material'
import { observer } from 'mobx-react'

import ClientMultiSelect from './ClientMultiSelect'
import ClientTagPermissionFilter from './ClientTagPermissionFilter'
import Select from './Select'

import ModelBase from '../../Model/ModelBase'

const typeOptions = [
  {
    key: 'empty',
    label: 'Select One...',
    value: '',
  },
  {
    description: 'Limit visibility of this item to the Clients you select.',
    key: 'clients',
    label: 'By Client',
    value: 'clients',
  },
  {
    description:
      'Limit visibility of this item to the Clients that have the Tags you select. Keep in mind that Tags can be added and removed from a Client which could also change the visibility of this item.',
    key: 'tags',
    label: 'By Tag',
    value: 'tags',
  },
]

const TagClientPermissionSelector: FC<{
  item: ModelBase
  onChange: (newValue: string[], type: 'clients' | 'tags') => void
}> = ({ item, onChange }) => {
  const [typeValue, setTypeValue] = useState<string>(
    item.clientIds?.length > 0
      ? 'clients'
      : item.permissionTags?.length > 0
      ? 'tags'
      : ''
  )
  const handleTagsChange = useCallback((newValue: string[]) => {
    onChange(newValue, 'tags')
  }, [])
  const handleClientsChange = useCallback((newValue: string[]) => {
    onChange(newValue, 'clients')
  }, [])

  const typeSelect = (
    <Select
      label='Client or Tag Permissions'
      onChange={(_e, option) => {
        const { value } = option

        setTypeValue(value)

        if (!value) {
          onChange([], 'tags')
          onChange([], 'clients')
        }
      }}
      options={typeOptions}
      value={typeValue}
    />
  )

  if (typeValue === 'tags') {
    return (
      <Stack spacing={1}>
        {typeSelect}
        <ClientTagPermissionFilter
          item={item}
          onChange={handleTagsChange}
          helperText='Limit to Clients that have any of the selected Tag(s)'
        />
      </Stack>
    )
  }

  if (typeValue === 'clients') {
    return (
      <Stack spacing={1}>
        {typeSelect}
        <ClientMultiSelect
          onChange={handleClientsChange}
          value={item.clientIds.toJSON()}
        />
      </Stack>
    )
  }

  return typeSelect
}

export default observer(TagClientPermissionSelector)
