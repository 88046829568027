import React, { FC, memo, MouseEvent, useMemo } from 'react'
import { IconButton, Tooltip, useTheme } from '@mui/material'

import { GetWrappedIcon } from '../Icons'

export type OptionalActionTriggerProps = {
  color?: string
  content?: string
  dataQa?: string | undefined
  disabled?: boolean | undefined
  onClick?: (event?: MouseEvent) => void
}

export type ActionTriggerProps = OptionalActionTriggerProps & {
  content: string
  iconName: string
}

const ActionTrigger: FC<ActionTriggerProps> = ({
  content,
  dataQa = undefined,
  disabled = false,
  iconName,
  onClick = undefined,
  ...rest
}): JSX.Element | null => {
  const theme = useTheme()

  const actualOnClick = useMemo(() => {
    if (disabled) {
      return undefined
    }

    return onClick
  }, [disabled, onClick])

  if (!content || !iconName) {
    return null
  }

  const iconButton = disabled ? (
    <span>
      {' '}
      <IconButton data-qa={dataQa} onClick={actualOnClick}>
        <GetWrappedIcon
          name={iconName}
          {...rest}
          color={disabled ? theme.palette.action.disabled : rest.color}
        />
      </IconButton>
    </span>
  ) : (
    <IconButton data-qa={dataQa} onClick={actualOnClick}>
      <GetWrappedIcon
        name={iconName}
        {...rest}
        color={disabled ? theme.palette.action.disabled : rest.color}
      />
    </IconButton>
  )

  return (
    <Tooltip placement='top' title={content}>
      {iconButton}
    </Tooltip>
  )
}

ActionTrigger.defaultProps = {
  color: undefined,
  dataQa: undefined,
  disabled: false,
  onClick: undefined,
}

export default memo(ActionTrigger)
