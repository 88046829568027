import io, { Socket } from 'socket.io-client'

import { IObservableArray } from 'mobx'

import RootStore from '../stores/RootStore'
import Discussion from '../Model/Discussion'
import ModelBase from '../Model/ModelBase'
import Organization from '../Model/Organization'
import OrganizationForm from '../Model/OrganizationForm'
import StarItem from '../Model/StarItem'
import Todo from '../Model/Todo'

import { showNotification } from './utils'
import { generateDocumentSlug, generateSlug } from './slug'
import { useLog } from './log'

const { API_HOST } = _global
const { MODE } = import.meta.env

const log = useLog()

let socket: Socket

export const getSocket = () => {
  if (!socket || !socket.connected) {
    const jwt = localStorage ? localStorage.getItem('jwt') : false
    const isDev = MODE === 'development'

    if (jwt) {
      try {
        socket = io.connect(isDev ? window.location.origin : API_HOST, {
          transports: ['websocket'],
          query: {
            token: jwt,
          },
          secure: true,
          reconnection: true,
          rejectUnauthorized: false,
          path: `${isDev ? '/api' : ''}/realtime/ws`,
        })

        socket.on('connect_error', err => {
          console.log(`connect_error due to ${err.message}`, err)
        })
      } catch (e) {
        log.code('pft302', { error: e })
      }
    }
  }

  return socket
}

export const getListenersArray = (
  collectionsWithListeners,
  collectionCanRefresh,
  classObject,
  org,
  rootStore
) => {
  if (!socket) return []
  return Object.keys(collectionsWithListeners).map(item => {
    return socket.on(item, async itemData => {
      const Model = collectionsWithListeners[item]
      if (Model) {
        const data = JSON.parse(itemData)
        const isDiscussion = Model === Discussion
        const isTodoUpdate = Model === Todo
        const isFormUpdate = Model === OrganizationForm
        const isOrgUpdate = Model === Organization
        const modelData = data.data

        if (!modelData) return undefined
        if (
          data.model === 'documents' &&
          modelData.fromExport &&
          collectionCanRefresh &&
          collectionCanRefresh.documents &&
          typeof collectionCanRefresh.documents.fn === 'function'
        ) {
          await collectionCanRefresh.documents.fn(false)
          return showNotification(
            'Your document export is now available',
            'event',
            `documents/${generateDocumentSlug(modelData.org_id, {
              download: modelData.key,
            })}`,
            rootStore
          )
        }
        if (
          isTodoUpdate ||
          isFormUpdate ||
          isOrgUpdate ||
          (isDiscussion && org && org.isHost) ||
          (modelData.org_id && org && modelData.org_id === org._id)
        ) {
          const newItem = new Model(
            rootStore,
            modelData,
            isTodoUpdate ? org : undefined
          )
          if (Model === StarItem) {
            classObject.items = newItem
            return undefined
          }
          if (isOrgUpdate) {
            await newItem.getMembers(true)
          }

          const currentItems = classObject[item]
          const updateExisting = () => {
            classObject[item] = currentItems.map((toUpdate: ModelBase) => {
              if (toUpdate._id === newItem._id && toUpdate !== newItem) {
                if (isOrgUpdate) {
                  if (newItem.getMembers) {
                    newItem.getMembers(true)
                  }

                  if (classObject.setCurrentOrg) {
                    classObject.setCurrentOrg()
                  }
                }
                return newItem
              }
              return toUpdate
            })
          }
          switch (data.method) {
            case 'create':
              if (
                !currentItems.find(
                  (currentitem: ModelBase) => currentitem._id === newItem._id
                )
              ) {
                // We don't want to notify the person that just created it
                //
                const author = newItem && (newItem.author || newItem.creator)
                if (
                  author &&
                  author !== localStorage.getItem('currentUserId')
                ) {
                  try {
                    const title =
                      newItem.title ||
                      newItem.topic ||
                      newItem.question ||
                      newItem.name
                    showNotification(
                      `New ${newItem.displayName}${
                        title ? ` ${title}` : ''
                      } Created`,
                      'event',
                      `${item}/${generateSlug(newItem.org_id, newItem._id)}`,
                      rootStore
                    )
                  } catch (e) {
                    log.error({
                      action: 'showNotification',
                      error: e,
                    })
                  }
                }

                classObject[item].push(newItem)
              } else {
                updateExisting()
              }
              break
            case 'update':
            case 'formCompleted':
            case 'videoUpload':
            case 'updatedCompleted': // Todo
              /**
               * This is to cover the flow of not having permission
               * The permission being given
               */
              if (
                !currentItems.find(
                  currentitem => currentitem._id === newItem._id
                )
              ) {
                classObject[item].push(newItem)
              } else {
                updateExisting()
              }
              if (data.method === 'videoUpload') {
                const title =
                  newItem.title ||
                  newItem.topic ||
                  newItem.question ||
                  newItem.name
                showNotification(
                  `${newItem.displayName}${
                    title ? ` ${title}` : ''
                  } video processed`,
                  'event',
                  `${newItem.permalink}`,
                  rootStore
                )
              }
              break
            case 'delete':
              classObject[item] = currentItems.filter(
                currentItem => currentItem._id !== modelData._id
              )
              break
            case 'refresh':
              if (collectionCanRefresh[item]) {
                if (typeof collectionCanRefresh[item].fn === 'function') {
                  await collectionCanRefresh[item].fn(newItem)
                }
              }
              break
            case 'share':
              // eslint-disable-next-line no-case-declarations
              const { meta } = data.data
              // eslint-disable-next-line no-case-declarations
              const shareSlug =
                item === 'documents'
                  ? generateDocumentSlug(newItem.org_id, {
                      download: newItem.contents ? undefined : newItem.key,
                      path: newItem.contents
                        ? newItem.path
                        : newItem.parentPath,
                    })
                  : generateSlug(newItem.org_id, newItem._id)
              showNotification(
                `${meta.from.fname} ${meta.from.lname} - ${meta.message}`,
                'event',
                `${item}/${shareSlug}`,
                rootStore
              )
              break
            default:
          }
        }
      }
      return undefined
    })
  })
}

export const handleSocketListener = (
  type: string,
  Model: typeof ModelBase,
  existingItems: IObservableArray<ModelBase>,
  rootStore: RootStore
) => {
  if (socket) {
    const handleSocket = async (itemData: string) => {
      const { data, method } = JSON.parse(itemData)
      const modelInstance = new Model(
        rootStore,
        data,
        rootStore.orgStore.orgs.find(org => org._id === data.org_id)
      )

      const shouldContinue =
        data.org_id === rootStore.orgStore.currentOrg._id ||
        (data.is_global &&
          rootStore.orgStore.currentOrg.host === data.org_id) ||
        rootStore.orgStore.availableOrgs.some(
          org =>
            org.value === data.org_id &&
            org.host === rootStore.orgStore.currentOrg._id
        )

      if (shouldContinue) {
        const isUpdate = [
          'formCompleted',
          'update',
          'updatedCompleted',
          'videoUpload',
          'vote',
          'readBy',
        ].includes(method)

        if (method === 'create') {
          if (
            !existingItems.some(
              alreadyHere => alreadyHere._id === modelInstance._id
            )
          ) {
            existingItems.push(modelInstance)
          } else {
            existingItems.replace(
              existingItems.map(alreadyThere =>
                alreadyThere._id === modelInstance._id
                  ? modelInstance
                  : alreadyThere
              )
            )
          }

          showNotification(
            `New ${modelInstance.displayName} ${modelInstance.listTitle} Created`,
            'event',
            modelInstance.permalink,
            rootStore
          )
        } else if (isUpdate) {
          existingItems.replace(
            existingItems.map(alreadyThere =>
              alreadyThere._id === modelInstance._id
                ? modelInstance
                : alreadyThere
            )
          )

          if (method === 'videoUpload') {
            showNotification(
              `${modelInstance.displayName} ${modelInstance.listTitle} video processed`,
              'event',
              modelInstance.permalink,
              rootStore
            )
          }
        } else if (method === 'delete') {
          existingItems.replace(
            existingItems.filter(
              alreadyThere => alreadyThere._id === modelInstance._id
            )
          )
        }
      }
    }
    socket.off(type)
    socket.on(type, handleSocket)
  }
}
