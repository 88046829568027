import { ExternalCRMTask, UID } from '../types'

export default class CRMTask {
  constructor(
    task: ExternalCRMTask = {
      description: '',
      dueDate: '',
      isOwner: false,
      org_id: '',
      title: '',
      uid: '',
      url: '',
    }
  ) {
    Object.assign(this, task)
  }

  dueDate = ''

  description = ''

  isOwner = false

  org_id = ''

  title: string

  uid: UID

  url = ''
}
