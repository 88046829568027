import { observable, action, toJS, makeObservable } from 'mobx'

import ModelBase from './ModelBase'

import { getDeviceHash, getDeviceInfo } from '../lib/deviceInfo'
import { useLog } from '../lib/log'
import RootStore from '../stores/RootStore'

const log = useLog()

const guestProps = ['fname', 'lname', 'email', 'device_hashes']
export default class Guest extends ModelBase {
  _id: string | undefined = undefined

  fname = ''

  lname = ''

  email = ''

  device_hashes: string[] = []

  constructor(rootStore: RootStore, id: string) {
    super()
    this.rootStore = rootStore
    makeObservable(this, {
      _id: observable,
      fname: observable,
      lname: observable,
      email: observable,
      device_hashes: observable,
      loadFromId: action,
      save: action,
    })

    if (id) this.loadFromId(id)
  }

  load = (data: object) => {
    guestProps.forEach(prop => {
      if (this[prop] !== 'undefined' && data[prop] !== 'undefined') {
        this[prop] = data[prop]
      }
    })
  }

  loadFromId = async (id: string) => {
    if (id) {
      try {
        this._id = id
        const { data } = await this.rootStore.client.guests.getGuest(id)
        this.load(data)
        return data
      } catch (e) {
        this._id = undefined
        log.warn({ message: 'Unable to load guest from id', error: e })
      }
    }

    return false
  }

  save = async () => {
    const reqData = ['fname', 'lname', 'email'].reduce(
      (acc, prop) => {
        acc[prop] = toJS(this[prop])
        return acc
      },
      { deviceHash: undefined, deviceInfo: undefined }
    )
    const deviceInfo = await getDeviceInfo()
    reqData.deviceHash = await getDeviceHash()
    reqData.deviceInfo = deviceInfo.components

    try {
      const { data } = await this.rootStore.client.guests.createGuest(reqData)
      this._id = data._id.toString()
      this.load(data)
      return data
    } catch (e) {
      log.warn({ message: 'Unable to save guest', error: e })
    }

    return false
  }
}
