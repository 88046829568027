import React, { FC, memo, useCallback, useEffect } from 'react'
import { useTheme, styled } from '@mui/material/styles'
import { Box, Drawer, IconButton, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'

import { GetWrappedIcon } from '../../components/Icons'

import { useTwoPanelSideNavContext } from '../../services/TwoPanelSideNavContext'

import useResponsive from '../../hooks/useResponsive'

const ToggleButtonStyle = styled(props => (
  <IconButton disableRipple {...props} />
))(({ theme }) => ({
  left: 0,
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  top: theme.spacing(13),
  borderRadius: `0 12px 12px 0`,
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}))

const LeftSidePanel: FC<{
  children: JSX.Element | JSX.Element[]
  isList?: boolean
}> = ({ children, isList }): JSX.Element => {
  const theme = useTheme()
  const { id } = useParams()
  const isDesktop = useResponsive('up', 'lg')
  const { openSidebar, setOpenSidebar } = useTwoPanelSideNavContext()
  const DRAWER_WIDTH = isDesktop && !id ? 420 : !id ? '100%' : 360
  const COLLAPSE_WIDTH = 96

  const isCollapse = isDesktop && !openSidebar

  useEffect(() => {
    setOpenSidebar(isDesktop)
  }, [isDesktop])

  const handleToggleSidebar = useCallback(() => {
    setOpenSidebar(!openSidebar)
  }, [openSidebar])

  const getCollapseTrigger = () => {
    if (isList && openSidebar) {
      return null
    }

    return (
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        sx={{ height: theme.spacing(6) }}
      >
        <IconButton onClick={handleToggleSidebar}>
          {openSidebar ? (
            <GetWrappedIcon name='chevronLeft' />
          ) : (
            <GetWrappedIcon name='chevronRight' />
          )}
        </IconButton>
      </Stack>
    )
  }

  const getDrawerContent = () => (
    <>
      {isCollapse && (
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
            position: 'sticky',
            px: 2,
            py: 2,
            top: 0,
            zIndex: 1,
          }}
        >
          {getCollapseTrigger()}
        </Box>
      )}
      {isDesktop ? (
        children
      ) : (
        <Box
          width={!isDesktop ? DRAWER_WIDTH : '80vw'}
          sx={!isDesktop ? { overflowY: 'auto' } : undefined}
        >
          {children}
        </Box>
      )}
    </>
  )

  return (
    <>
      {!isDesktop && !!id && (
        <ToggleButtonStyle onClick={handleToggleSidebar}>
          <GetWrappedIcon name='list' />
        </ToggleButtonStyle>
      )}

      {isDesktop ? (
        <Drawer
          open={openSidebar}
          variant='persistent'
          sx={{
            width: DRAWER_WIDTH,
            transition: theme.transitions.create('width'),
            '& .MuiDrawer-paper': {
              position: 'static',
              width: DRAWER_WIDTH,
            },
            ...(isCollapse && {
              width: COLLAPSE_WIDTH,
              '& .MuiDrawer-paper': {
                width: COLLAPSE_WIDTH,
                position: 'static',
                transform: 'none !important',
                visibility: 'visible !important',
              },
            }),
          }}
        >
          {getDrawerContent()}
        </Drawer>
      ) : !id ? (
        getDrawerContent()
      ) : (
        <Drawer
          ModalProps={{ keepMounted: true }}
          open={openSidebar}
          onClose={handleToggleSidebar}
          sx={{
            '& .MuiDrawer-paper': { width: DRAWER_WIDTH },
          }}
        >
          {getDrawerContent()}
        </Drawer>
      )}
    </>
  )
}

LeftSidePanel.defaultProps = {
  isList: true,
}

export default memo(LeftSidePanel)
