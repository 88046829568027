import React, {
  FC,
  Fragment,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
  useMemo,
} from 'react'
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  DialogContentText,
} from '@mui/material'

import { useRootStore } from '../../services/RootStoreContext'

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
))

const ModalDialog: FC<{
  additionalActions?: ReactNode
  closeActionText?: string
  confirmAction?: ReactNode
  confirmActionDisabled?: boolean
  confirmActionText?: string
  content: ReactNode
  fullScreen?: boolean
  fullWidth?: boolean
  header?: ReactNode | string
  onClose?: (event: MouseEvent) => void
  onConfirm?: (event: MouseEvent) => void
  onOpen?: (event: MouseEvent) => void
  open?: boolean
  maxWidth?: 'auto' | 'sm' | 'md' | 'lg' | 'xl'
  trigger: ReactNode
  triggerDisabled?: boolean
}> = ({
  additionalActions,
  closeActionText,
  confirmAction,
  confirmActionDisabled,
  confirmActionText,
  content,
  fullScreen,
  fullWidth,
  header,
  onClose,
  onConfirm,
  onOpen,
  open,
  maxWidth,
  trigger,
  triggerDisabled,
}): JSX.Element => {
  const [modalVisible, toggleModal] = useState(open)
  const {
    orgStore: {
      currentOrg: { isImpersonating },
    },
  } = useRootStore()

  useEffect(() => {
    if (open !== modalVisible) {
      toggleModal(open)
    }
  }, [open])
  const handleClose = (e: MouseEvent) => {
    if (!open) {
      toggleModal(false)
    }
    onClose(e)
  }

  const handleConfirm = (e: MouseEvent) => {
    if (!open) {
      toggleModal(false)
    }
    onConfirm(e)
  }

  const getConfirmAction = () =>
    confirmAction || (
      <Button
        disabled={isImpersonating || confirmActionDisabled}
        onClick={handleConfirm}
        variant='contained'
      >
        {isImpersonating ? 'Disabled While Impersonating' : confirmActionText}
      </Button>
    )

  const handleTriggerClick = (event: MouseEvent) => {
    toggleModal(true)

    if (onOpen) {
      onOpen(event)
    }
  }

  const actualTrigger = useMemo(
    () =>
      React.isValidElement(trigger) ? (
        React.cloneElement(trigger, {
          onClick: async (e: MouseEvent) => {
            if (!triggerDisabled) {
              if (trigger.props && trigger.props.onClick) {
                await trigger.props.onClick(e)
              }
              handleTriggerClick(e)
              return true
            }
            return false
          },
        })
      ) : (
        <span onClick={handleTriggerClick} role='presentation'>
          {trigger}
        </span>
      ),
    [trigger]
  )

  const actualAdditionalActions = useMemo(
    () =>
      additionalActions && React.isValidElement(additionalActions)
        ? React.cloneElement(additionalActions, {
            onClick: async (e: MouseEvent) => {
              if (additionalActions.props && additionalActions.props.onClick) {
                await additionalActions.props.onClick(e)
              }
              handleClose(e)
            },
          })
        : null,
    [additionalActions]
  )

  return (
    <Fragment>
      {modalVisible && (
        <Dialog
          maxWidth={maxWidth}
          fullWidth={fullWidth}
          fullScreen={fullScreen}
          open={modalVisible}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle>{header}</DialogTitle>
          <DialogContent>
            <DialogContentText
              component='div'
              id='alert-dialog-slide-description'
            >
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{closeActionText}</Button>
            {actualAdditionalActions}
            {getConfirmAction()}
          </DialogActions>
        </Dialog>
      )}
      {actualTrigger}
    </Fragment>
  )
}

ModalDialog.defaultProps = {
  additionalActions: null,
  closeActionText: 'Cancel',
  confirmAction: null,
  confirmActionDisabled: false,
  confirmActionText: 'Save',
  fullScreen: false,
  fullWidth: true,
  header: null,
  onClose: () => true,
  onConfirm: () => true,
  onOpen: () => true,
  open: false,
  maxWidth: 'md',
  triggerDisabled: false,
}

export default ModalDialog
