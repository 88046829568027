import React, { FC } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import {
  DateTimePicker as MUIDateTimePicker,
  DateTimePickerProps,
} from '@mui/x-date-pickers-pro'
import { observer } from 'mobx-react'

const DateTimePicker: FC<
  Omit<DateTimePickerProps<Date>, 'renderInput'> & {
    dataQa?: string
    disabled?: boolean
    label?: string | JSX.Element | null
    error?: boolean
    onChange: (newVal: Date) => void
    required?: boolean
    value: Date
  }
> = ({
  dataQa,
  disabled,
  error,
  label,
  onChange,
  required,
  value,
  ...rest
}): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUIDateTimePicker
        {...rest}
        data-qa={dataQa}
        disabled={disabled}
        label={label}
        onChange={onChange}
        slotProps={{
          textField: {
            error,
            required,
          },
        }}
        value={value}
      />
    </LocalizationProvider>
  )
}

DateTimePicker.defaultProps = {
  dataQa: undefined,
  disabled: false,
  error: false,
  label: null,
  required: true,
}

export default observer(DateTimePicker)
