import React, { FC, Ref, useCallback, useMemo, useState } from 'react'
import {
  Box,
  ClickAwayListener,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
} from '@mui/material'
import { observer } from 'mobx-react'
import Autocomplete from '@mui/material/Autocomplete'
import { styled } from '@mui/material/styles'

import { GetWrappedIcon } from '../Icons'

import { getIcon } from '../../lib/fileIcons'

import File from '../../Model/File'
import Folder from '../../Model/Folder'
import ModelBase from '../../Model/ModelBase'
import OrgGroup from '../../Model/OrgGroup'
import OrgMember from '../../Model/OrgMember'

const PaperComponent = styled(Paper)(({ theme }) => ({
  boxShadow: theme.shadows[5],
}))

const normalizedSearchItems = (
  allItems: ModelBase[],
  flattenedDocuments: File[],
  flattenedFolders: Folder[],
  orgGroups: OrgGroup[],
  orgMembers: OrgMember[],
  hideMemberResults: boolean
): ModelBase[] =>
  [
    ...allItems,
    ...flattenedDocuments,
    ...flattenedFolders,
    ...(hideMemberResults || !orgMembers
      ? []
      : orgMembers.filter(m => !m.isHostAdmin && m.isActive)),
    ...(hideMemberResults || !orgGroups ? [] : orgGroups),
  ].sort((a, b) =>
    a.posted ? (a.posted > b.posted ? 1 : b.posted > a.posted ? -1 : 0) : 1
  )

const SearchOrgResources: FC<{
  allItems?: ModelBase[]
  flattenedDocuments?: File[]
  flattenedFolders?: Folder[]
  fullWidth?: boolean
  hideMemberResults?: boolean
  icon?: JSX.Element
  onFocus?: (event: object) => void
  onSelect?: (selected: ModelBase) => void
  orgGroups?: OrgGroup[]
  orgMembers?: OrgMember[]
  searchRef?: Ref<unknown>
}> = ({
  allItems,
  flattenedDocuments,
  flattenedFolders,
  fullWidth,
  hideMemberResults,
  onFocus,
  onSelect,
  orgGroups,
  orgMembers,
  searchRef,
}): JSX.Element => {
  const [keyword, setKeyword] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const items = normalizedSearchItems(
    allItems,
    flattenedDocuments,
    flattenedFolders,
    orgGroups,
    orgMembers,
    hideMemberResults
  )
  const options = useMemo(
    () =>
      items
        .filter(item => item && Boolean(item.getNotifyId))
        .map(item => ({
          key: item.getNotifyId(),
          label: item.listTitle,
          value: item.getNotifyId(),
        })),
    [items.length]
  )
  const onResultSelect = useCallback((data: ModelBase) => {
    setKeyword('')
    setIsOpen(false)

    if (onSelect) {
      onSelect(data)
    }
  }, [])

  return (
    <Box
      ref={searchRef}
      sx={{
        display: 'flex',
        width: fullWidth ? '100%' : { xs: '100%', md: '500px' },
      }}
    >
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <Autocomplete
          blurOnSelect
          disableClearable
          fullWidth
          multiple={false}
          onClick={() => setIsOpen(!isOpen)}
          onFocus={onFocus}
          open={isOpen}
          options={options}
          PaperComponent={PaperComponent}
          renderInput={params => (
            <TextField
              {...params}
              autoFocus
              fullWidth
              placeholder='Search...'
              sx={{
                mr: 1,
                fontWeight: 'fontWeightBold',
                background: 'white',
                borderRadius: '8px',
              }}
              type='search'
              InputProps={{
                ...params.InputProps,
                type: 'search',
                startAdornment: (
                  <InputAdornment position='start'>
                    <GetWrappedIcon name='search' />
                  </InputAdornment>
                ),
              }}
              value={keyword || ''}
              onChange={({ target: { value } }) => {
                setKeyword(value)
                setIsOpen(value.trim().length > 0)
              }}
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
          renderOption={(props, option) => {
            const thisItem = items
              .filter(item => item && Boolean(item.getNotifyId))
              .find(item => item.getNotifyId() === option.value)

            if (!thisItem) {
              return null
            }
            const iconData =
              thisItem.modelCollection === 'documents'
                ? {
                    icon: getIcon(thisItem.key || 'folder').icon,
                    color: getIcon(thisItem.key || 'folder').color || 'grey',
                  }
                : { name: thisItem.category }

            return (
              <ListItem {...props} disablePadding>
                <ListItemButton
                  divider
                  onClick={() => onResultSelect(thisItem)}
                >
                  <ListItemIcon>
                    <GetWrappedIcon {...iconData} />
                  </ListItemIcon>
                  <ListItemText primary={option.label} />
                  <ListItemSecondaryAction>
                    {thisItem.posted}
                  </ListItemSecondaryAction>
                </ListItemButton>
              </ListItem>
            )
          }}
          selectOnFocus
          size='small'
        />
      </ClickAwayListener>
    </Box>
  )
}

SearchOrgResources.defaultProps = {
  allItems: [],
  flattenedDocuments: [],
  flattenedFolders: [],
  fullWidth: false,
  hideMemberResults: false,
  icon: undefined,
  onFocus: null,
  onSelect: null,
  orgGroups: [],
  orgMembers: [],
  searchRef: undefined,
}

export default observer(SearchOrgResources)
