import React, {
  ChangeEvent,
  FC,
  HTMLAttributes,
  useCallback,
  useMemo,
} from 'react'
import { observer } from 'mobx-react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Chip,
  FilterOptionsState,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import { GetWrappedIcon } from '../Icons'

import ClientMultiSelectListBox from './ClientMultiSelectListBox'
import TextInput from './TextInput'

import { useRootStore } from '../../services/RootStoreContext'

import { SelectOptionType } from './types'
import ClientListModal from '../ModalDialog/ClientListModal'

const ClientMultiSelect: FC<{
  onChange: (newValue: string[]) => void
  value: string[]
}> = ({ onChange, value, ...rest }) => {
  const {
    orgStore: { availableOrgs, currentOrg },
    tagStore: { tags, getAvailableTags },
  } = useRootStore()

  const clientOptions: SelectOptionType[] = useMemo(
    () =>
      availableOrgs
        .filter(option => option.host === currentOrg._id)
        .map(o => ({ ...o, key: o.value })),
    [currentOrg?._id]
  )
  const clientTags = useMemo(() => getAvailableTags(), [tags.toJSON()])

  const filterOptions = useCallback(
    (
      options: SelectOptionType[],
      state: FilterOptionsState<SelectOptionType>
    ) => {
      const { inputValue } = state
      const lowered = inputValue.toLowerCase()

      return options.filter(
        option =>
          option.label.toLowerCase().includes(lowered) ||
          clientTags.some(
            tag =>
              tag.items.some(item => item.uid === option.value) &&
              tag.name.toLowerCase().includes(lowered)
          )
      )
    },
    [clientTags]
  )
  const handleChange = useCallback(
    (_event: ChangeEvent<HTMLElement>, newValue: SelectOptionType[]) => {
      onChange(newValue.map(option => option.value))
    },
    []
  )

  const listBoxProps = useMemo(
    () => ({
      options: clientOptions,
      currentValue: value,
      handleChange: onChange,
    }),
    [clientOptions, value]
  )

  return (
    <Autocomplete
      {...rest}
      disableCloseOnSelect
      filterOptions={filterOptions}
      limitTags={2}
      ListboxComponent={ClientMultiSelectListBox}
      ListboxProps={listBoxProps}
      multiple
      onChange={handleChange}
      openOnFocus
      options={clientOptions}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextInput
          {...params}
          label='Select Clients'
          helperText={
            value?.length > 0 ? (
              <ClientListModal clientIds={value} forForm />
            ) : (
              'Limit to specific Client(s)'
            )
          }
        />
      )}
      renderOption={(
        props: HTMLAttributes<HTMLLIElement>,
        option: SelectOptionType,
        state: AutocompleteRenderOptionState
      ) => {
        const { selected } = state
        const { value: clientId } = option
        const thisClientTags = clientTags.filter(tag =>
          tag.items.some(item => item.uid === clientId)
        )

        return (
          <ListItem
            {...props}
            secondaryAction={thisClientTags.map(tag => (
              <Chip
                key={`${clientId}-${tag._id}`}
                color='info'
                label={tag.name}
                size='small'
              />
            ))}
          >
            <ListItemIcon sx={{ alignItems: 'center' }}>
              <GetWrappedIcon
                name={selected ? 'checkboxSelected' : 'checkboxNotSelected'}
              />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </ListItem>
        )
      }}
      value={clientOptions.filter(option => value.includes(option.value))}
    />
  )
}

export default observer(ClientMultiSelect)
