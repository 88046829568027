import { observable, makeObservable } from 'mobx'

const addressProps = ['address1', 'address2', 'city', 'state', 'zip']

export default class OrgAddress {
  address1 = ''

  address2 = ''

  city = ''

  state = 'AL'

  zip = ''

  constructor(address = {}) {
    makeObservable(this, {
      address1: observable,
      address2: observable,
      city: observable,
      state: observable,
      zip: observable,
    })
    addressProps.forEach(prop => {
      if (address[prop]) {
        this[prop] = address[prop]
      }
    })
  }
}
