import React, { ChangeEvent, FC, memo, useCallback, useState } from 'react'
import {
  Accordion as MUIAccordion,
  AccordionActions,
  AccordionActionsProps,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  useTheme,
} from '@mui/material'
import OrganizationForm from '../../Model/OrganizationForm'
import { GetWrappedIcon } from '../Icons'

const Accordion: FC<
  Omit<AccordionProps, 'children'> & {
    actions?: JSX.Element | null
    actionsProps?: AccordionActionsProps | undefined
    defaultExpanded?: boolean
    details: JSX.Element | string | null
    detailsProps?: AccordionDetailsProps | undefined
    fullWidthSummary?: boolean
    hasLoaded?: boolean
    setHasLoaded?: (status: boolean) => void
    marginZero?: boolean
    summary: JSX.Element | JSX.Element[] | string | null
    summaryProps?: AccordionSummaryProps | undefined
    form?: OrganizationForm
    idx?: number
  }
> = ({
  actions,
  actionsProps,
  defaultExpanded,
  details,
  detailsProps,
  fullWidthSummary,
  marginZero,
  summary,
  summaryProps,
  ...rest
}): JSX.Element => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState(defaultExpanded)
  const handleExpand = useCallback(
    (event: ChangeEvent, expandedState: boolean) => {
      setExpanded(expandedState)

      if (typeof rest?.onChange === 'function') {
        rest.onChange(event, expandedState)
      }
    },
    [expanded]
  )

  return (
    <MUIAccordion
      {...rest}
      expanded={expanded}
      onChange={handleExpand}
      variant='outlined'
      style={{ marginBottom: theme.spacing(marginZero ? 0 : 2) }}
    >
      <AccordionSummary
        {...summaryProps}
        expandIcon={<GetWrappedIcon name='chevronDown' />}
        sx={{
          flexDirection: 'row-reverse',
          width: fullWidthSummary ? '100%' : undefined,
        }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails {...detailsProps}>{details}</AccordionDetails>
      {actions && (
        <AccordionActions {...actionsProps}>{actions}</AccordionActions>
      )}
    </MUIAccordion>
  )
}

Accordion.defaultProps = {
  actions: null,
  actionsProps: undefined,
  defaultExpanded: false,
  detailsProps: undefined,
  form: undefined,
  fullWidthSummary: false,
  hasLoaded: true,
  idx: undefined,
  marginZero: false,
  setHasLoaded: () => true,
  summaryProps: undefined,
}

export default memo(Accordion)
