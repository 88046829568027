import {
  action,
  computed,
  toJS,
  makeObservable,
  observable,
  override,
} from 'mobx'

import CampaignMessage from '../Model/CampaignMessage'
import RootStore from '../stores/RootStore'
import ModelBase from './ModelBase'

import { useLog } from '../lib/log'

const log = useLog()

const campaignProps = [
  '_id',
  'createdAt',
  'org_id',
  'title',
  'description',
  'contacts',
  'lists',
  'messages',
  'html',
  'attachments',
  'view_logs',
  'responses',
]

export default class Campaign extends ModelBase {
  constructor(
    rootStore: RootStore,
    campaign: {
      _id: string | undefined
      createdAt: number | undefined
      org_id: string | undefined
      title: string | undefined
      description: string | undefined
      contacts: string[] | undefined
      lists: string[] | undefined
      messages: CampaignMessage[]
      html: string | undefined
      attachments: { key: string; name: string }[] | undefined
      view_logs:
        | {
            contactId: string
            messageId: string
            start: number
            duration: number
          }[]
        | undefined
      responses:
        | {
            created: number
            contactId: string
            messageId: string
            response: string
          }[]
        | undefined
    }
  ) {
    super(rootStore)
    makeObservable(this, {
      _id: observable,
      createdAt: observable,
      org_id: observable,
      title: observable,
      description: observable,
      contacts: observable,
      lists: observable,
      messages: observable,
      html: observable,
      attachments: observable,
      view_logs: observable,
      responses: observable,
      data: override,
      save: action,
      id: computed,
    })

    campaignProps.forEach(prop => {
      this[prop] = campaign[prop]
    })
    // Mapped model instances
    this.messages = campaign.messages
      ? campaign.messages.map(message => new CampaignMessage(message))
      : []
  }

  _id: string | undefined = undefined

  createdAt = Date.now()

  org_id: string | undefined = undefined

  title = ''

  description = ''

  contacts: string[] = []

  lists: string[] = []

  messages: CampaignMessage[] = []

  html = ''

  attachments: { key: string; name: string }[] = []

  view_logs: {
    contactId: string
    messageId: string
    start: number
    duration: number
  }[] = []

  responses: {
    created: number
    contactId: string
    messageId: string
    response: string
  }[] = []

  get data() {
    const data = campaignProps.reduce(
      (acc, prop) => {
        acc[prop] = toJS(this[prop])
        return acc
      },
      { messages: undefined, attachments: undefined }
    )
    // Mapped model props
    data.messages = this.messages.map(message => message.data)
    data.attachments = this.attachments.filter(
      attachment => attachment.key && attachment.key.length > 0
    )
    return data
  }

  get id(): string {
    return this.getNotifyId()
  }

  save = async () => {
    try {
      const res = await this.client.campaigns.saveCampaign(this.data)
      this._id = res.data._id
      log.code('cmp001')
    } catch (e) {
      const err = new Error()
      if (e.statusCode === 400) {
        log.code('cmp201', { error: e.data })
        err.message = 'Invalid data'
        throw err
      }
      err.message = 'Error saving campaign'
      log.code('cmp301', { error: e })
      throw err
    }
  }
}
