import { observable, action, makeObservable } from 'mobx'
import ModelBase from './ModelBase'
import { useLog } from '../lib/log'
import RootStore from '../stores/RootStore'

const log = useLog()

const windowHeight = window.outerHeight
const windowWidth = window.outerWidth

const browser = (agent => {
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'Edge'
    case agent.indexOf('edg') > -1:
      return 'Chromium'
    case agent.indexOf('opr') > -1 && !!window.opr:
      return 'Opera'
    case agent.indexOf('chrome') > -1 && !!window.chrome:
      return 'Chrome'
    case agent.indexOf('trident') > -1:
      return 'Internet Explorer'
    case agent.indexOf('firefox') > -1:
      return 'Firefox'
    case agent.indexOf('safari') > -1:
      return 'Safari'
    default:
      return 'Other'
  }
})(window.navigator.userAgent.toLowerCase())

export default class VisitTimer extends ModelBase {
  constructor(rootStore: RootStore, org_id: string, userId: string) {
    super(rootStore)
    makeObservable(this, {
      org_id: observable,
      userId: observable,
      timerStart: observable,
      pauseStart: observable,
      pauses: observable,
      recordPause: action,
      reset: action,
      recordVisit: action,
    })

    this.org_id = org_id
    this.userId = userId
    this.timerStart = Date.now()
    this.startEventListeners()
  }

  org_id: string | undefined = undefined

  userId: string | undefined = undefined

  timerStart: number | undefined = undefined

  pauseStart: number | undefined = undefined

  pauses: number[] = []

  recordPause = () => {
    if (document.hidden) {
      this.pauseStart = Date.now()
    } else {
      this.pauses.push(Date.now() - this.pauseStart)
    }
  }

  reset = (org_id: string, userId: string) => {
    this.org_id = org_id
    this.userId = userId
    this.timerStart = Date.now()
    this.pauseStart = undefined
    this.pauses = []
    this.stopEventListeners()
    this.startEventListeners()
  }

  startEventListeners = () => {
    window.addEventListener('beforeunload', this.recordVisit)
    document.addEventListener('visibilitychange', this.recordPause)
  }

  stopEventListeners = () => {
    window.removeEventListener('beforeunload', this.recordVisit)
    document.removeEventListener('visibilitychange', this.recordPause)
  }

  recordVisit = async () => {
    const pauseDurations =
      this.pauses.length > 0 ? this.pauses.reduce((acc, p) => acc + p, 0) : 0
    const duration = Date.now() - this.timerStart - pauseDurations
    try {
      if (!duration || duration < 1000) return // Don't record immediate bouncies
      if (!this.userId || !this.org_id)
        throw new Error('Missing user or org id')
      await this.client.auditLogs.createLog(
        this.org_id,
        'organization',
        undefined,
        {
          action: 'visit',
          userId: this.userId,
          at: Date.now(),
          data: { duration, browser, windowWidth, windowHeight },
        }
      )
      log.code('aud001', { type: 'organization visit', id: this.org_id })
    } catch (e) {
      log.code('aud301', {
        type: 'organization visit',
        id: this.org_id,
        error: e,
      })
    }
  }
}
