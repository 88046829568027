import React, { FC } from 'react'
import { IconButton } from '@mui/material'

import { GetWrappedIcon } from '../Icons'

import ActionTrigger, { OptionalActionTriggerProps } from './ActionTrigger'

const EditTrigger: FC<OptionalActionTriggerProps & { disabled?: boolean }> = ({
  content = 'Edit',
  dataQa,
  disabled,
  ...rest
}): JSX.Element | null =>
  disabled ? (
    <IconButton {...rest} data-qa={dataQa} disabled>
      <GetWrappedIcon name='edit' />
    </IconButton>
  ) : (
    <ActionTrigger
      {...rest}
      content={content}
      dataQa={dataQa}
      iconName='edit'
    />
  )

EditTrigger.defaultProps = {
  disabled: false,
}

export default EditTrigger
