import React, { FC } from 'react'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'

import { GetWrappedIcon } from '../Icons'

const HelpDialog: FC<
  IconButtonProps & {
    content: JSX.Element | string
    color?:
      | 'inherit'
      | 'default'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning'
  }
> = ({ content, ...rest }): JSX.Element => (
  <Tooltip title={content}>
    <IconButton {...rest}>
      <GetWrappedIcon name='help' />
    </IconButton>
  </Tooltip>
)

HelpDialog.defaultProps = {
  color: 'default',
}

export default HelpDialog
