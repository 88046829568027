import React, { FC, Fragment } from 'react'
import { Card, Stack, Typography } from '@mui/material'
import Dropzone from 'react-dropzone'

import { GetWrappedIcon } from '../../Icons'

import useResponsive from '../../../hooks/useResponsive'
import { useRootStore } from '../../../services/RootStoreContext'

type DropZoneType = {
  handleUpload: (e: { target: { files: File[]; value: '' } }) => void
  isUploading: boolean
}

const DocumentUploaderDropContainer: FC<DropZoneType> = ({
  handleUpload,
  isUploading,
}): JSX.Element => {
  const isDesktop = useResponsive('up', 'md')
  const {
    orgStore: {
      currentOrg: { isImpersonating },
    },
  } = useRootStore()

  return (
    <Dropzone
      onDrop={acceptedFiles => {
        handleUpload({ target: { files: acceptedFiles, value: '' } })
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Card
          variant='outlined'
          sx={{
            backgroundColor: 'background.default',
            borderStyle: 'dashed',
            cursor: 'pointer',
            display: 'flex',
            height: '10rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          {...getRootProps()}
        >
          <div id='upload-files-button'>
            <input
              {...getInputProps()}
              disabled={isImpersonating}
              style={{ display: 'none' }}
            />
            <Stack direction='row' spacing={1}>
              {isUploading ? (
                <Fragment>
                  <GetWrappedIcon name='uploadProgress' />
                  <Typography>Uploading...</Typography>
                </Fragment>
              ) : (
                <Fragment>
                  <GetWrappedIcon name='upload' />
                  <Typography>
                    {`${
                      isDesktop ? 'Drag and drop or click ' : 'Click '
                    } to upload files.${
                      isImpersonating ? ' Disabled while impersonating.' : ''
                    }`}
                  </Typography>
                </Fragment>
              )}
            </Stack>
          </div>
        </Card>
      )}
    </Dropzone>
  )
}

export default DocumentUploaderDropContainer
