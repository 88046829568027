import React, { FC, useMemo } from 'react'
import { Box, Divider, Paper, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react'

import Accordion from '../../../../components/Accordion/Accordion'
import {
  InvestmentReportsList,
  PerformanceProviderSelect,
  useFormContext,
} from '../../../../components/Form'

import OrgAccounts from './components/OrgAccounts'
import OrgGlobalGroups from './components/OrgGlobalGroups'
import OrgToggles from './components/OrgToggles'
import RelationshipManagement from './components/RelationshipManagement'

import { useRootStore } from '../../../../services/RootStoreContext'

const AdminOrganizationProfileControls: FC = (): JSX.Element => {
  const { orgStore } = useRootStore()
  const { currentOrg } = orgStore

  const { model: orgData } = useFormContext()

  const investmentStuffs = useMemo(() => {
    if (orgData.hideInvestments) {
      return null
    }

    return (
      <Accordion
        details={
          <Stack spacing={2}>
            <PerformanceProviderSelect org={orgData} />
            <InvestmentReportsList org={orgData} />
          </Stack>
        }
        summary={<Typography>Performance Provider &amp; Reports</Typography>}
      />
    )
  }, [orgData])

  const { isHost, relationshipManagement } = currentOrg

  if (!isHost) {
    return null
  }

  return (
    <Box>
      <Divider>
        <Typography variant='h3'>Administrative</Typography>
      </Divider>
      <Box pb={3}>
        <Typography variant='body2'>
          Only visible to Host Administrators
        </Typography>
      </Box>
      <Paper elevation={2}>
        {orgData.host &&
          relationshipManagement &&
          relationshipManagement.roles.length > 0 && (
            <Accordion
              details={<RelationshipManagement />}
              summary={<Typography>Relationship Management</Typography>}
            />
          )}

        <Accordion
          details={<OrgToggles />}
          summary={<Typography>Resources...</Typography>}
        />
        <Accordion
          details={<OrgAccounts orgData={orgData} />}
          summary={<Typography>Account Numbers / IDs</Typography>}
        />
        {investmentStuffs}
        <Accordion
          details={<OrgGlobalGroups hostOrg={currentOrg} />}
          summary={<Typography>Global Groups</Typography>}
        />
      </Paper>
    </Box>
  )
}

export default observer(AdminOrganizationProfileControls)
