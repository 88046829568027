export const flattenDocs = (discussions, stars, docs, type = 'documents') => {
  const flat = []
  const hasContent =
    (discussions || type !== 'documents') &&
    docs &&
    docs.contents &&
    docs.contents.length > 0

  if (hasContent) {
    const recurseTree = (node, parentPath = '') => {
      return node.forEach(cur => {
        if (cur.type === 'folder') {
          recurseTree(cur.contents, `${parentPath}/${cur.name}`)
        } else {
          flat.push(cur)
        }
      })
    }
    recurseTree(docs.contents || [])
  }
  return flat
}

export const flattenFolders = docs => {
  const flat = []
  const hasContent = docs && docs.contents && docs.contents.length > 0
  if (hasContent) {
    const recurseTree = node => {
      return node.forEach(cur => {
        if (cur.type === 'folder') {
          flat.push(cur)
          recurseTree(cur.contents)
        }
      })
    }
    recurseTree(docs.contents || [])
  }
  return flat
}

// For finding path-based node
export const buildLookup = documents => {
  if (!documents) return new Map()
  const parents = []
  const lookup = new Map()

  function recurse(docs) {
    if (!docs) return []
    return docs
      .map(doc => {
        if (doc) {
          const absolutePath = ['', ...parents, doc.name].join('/')
          lookup.set(absolutePath, doc)
          if (doc.contents) {
            parents.push(doc.name)
            recurse(doc.contents)
            parents.pop()
          }
          return doc
        }
        return false
      })
      .filter(d => Boolean(d))
  }
  recurse(documents.contents)
  return lookup
}

export const blobToFileForUpload = theBlob =>
  fetch(theBlob.blobUri())
    .then(res => res.arrayBuffer())
    .then(buf => ({
      name: theBlob.filename(),
      file: new File([buf], theBlob.filename(), { type: theBlob.blob().type }),
    }))

export const getFileNameFromKey = (key: string) => {
  if (typeof key === 'string') {
    const extSplit = key.split('.')
    const ext = extSplit[extSplit.length - 1]
    const filenameSplit = key.split('-')
    delete filenameSplit[filenameSplit.length - 1]
    return `${filenameSplit
      .filter(Boolean)
      .join(filenameSplit.length > 1 ? '-' : '')}.${ext}`
  }
  return key
}
