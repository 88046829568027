import {
  InvestmentBillingProfileProps,
  InvestmentFeeScheduleProps,
} from '../../../types'
import APIResponseType from './APIResponseType'

export const saveBillingProfile = (
  data: InvestmentBillingProfileProps
): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/investmentBillings/billingProfiles${
    data._id ? `/${data._id}` : ''
  }`,
  method: data?._id ? 'PATCH' : 'POST',
  data,
})

export const getClientsWithoutBillingProfiles = (
  org_id: string
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/investmentBillings/billingProfiles/missing`,
  method: 'GET',
})

export const getBillingProfiles = (org_id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/investmentBillings/billingProfiles`,
  method: 'GET',
})

export const getFeeSchedules = (org_id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/investmentBillings/feeSchedules`,
  method: 'GET',
})

export const saveFeeSchedule = (
  data: InvestmentFeeScheduleProps
): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/investmentBillings/feeSchedules${
    data._id ? `/${data._id}` : ''
  }`,
  method: data?._id ? 'PATCH' : 'POST',
  data,
})

export const generateInvoices = (orgId: string, data: object) => ({
  url: `/orgs/v1/${orgId}/investmentBillings/invoices`,
  method: 'POST',
  data,
})

export const approveInvoice = (orgId: string, invoiceId: string) => ({
  url: `/orgs/v1/${orgId}/investmentBillings/invoices/${invoiceId}/approve`,
  method: 'PATCH',
})

export const unapproveInvoice = (orgId: string, invoiceId: string) => ({
  url: `/orgs/v1/${orgId}/investmentBillings/invoices/${invoiceId}/unapprove`,
  method: 'PATCH',
})

export const approveInvoices = (orgId: string, data: object) => ({
  url: `/orgs/v1/${orgId}/investmentBillings/invoices/approve`,
  method: 'PATCH',
  data,
})

export const deleteInvoices = (orgId: string, data: object) => ({
  url: `/orgs/v1/${orgId}/investmentBillings/invoices`,
  method: 'DELETE',
  data,
})

export const deleteInvoice = (orgId: string, invoiceId: string) => ({
  url: `/orgs/v1/${orgId}/investmentBillings/invoices/${invoiceId}`,
  method: 'DELETE',
})
