import {
  observable,
  action,
  computed,
  toJS,
  makeObservable,
  override,
} from 'mobx'
import moment from 'moment'
import * as yup from 'yup'

import AgendaLinks from './AgendaLinks'
import Link from './Link'
import MinutesLinks from './MinutesLinks'
import ModelBase from './ModelBase'

import RootStore from '../stores/RootStore'

import { EventProps } from '../types'

import { useLog } from '../lib/log'

const log = useLog()

export default class Event extends ModelBase {
  modelCollection = 'events'

  constructor(
    rootStore: RootStore,
    event: EventProps = {
      _id: undefined,
      org_id: undefined,
      author: undefined,
      title: undefined,
      summary: undefined,
      rsvps: undefined,
      currentUserId: undefined,
      phone: undefined,
      extension: undefined,
      link: undefined,
      location: undefined,
      agenda: '',
      minutes: undefined,
      groups: undefined,
      users: undefined,
      guests: undefined,
      isPublic: undefined,
      has_meeting: undefined,
      record_meeting: undefined,
      meeting_id: undefined,
      isEditing: undefined,
      tags: undefined,
      links: undefined,
      readBy: undefined,
      meeting: undefined,
      disable_comments: undefined,
      agendaLinks: undefined,
      minutesLinks: undefined,
      createdAt: undefined,
      start: undefined,
      end: undefined,
      recurringString: undefined,
      recurringParentEventId: undefined,
      deletedRecurringDates: undefined,
      originalRecurringStart: undefined,
      updateCount: 0,
      isInProgress: undefined,
    }
  ) {
    super(rootStore)
    makeObservable(this, {
      _id: observable,
      createdAt: observable,
      org_id: observable,
      author: observable,
      title: observable,
      summary: observable,
      start: observable,
      end: observable,
      rsvps: observable,
      phone: observable,
      extension: observable,
      link: observable,
      location: observable,
      agenda: observable,
      agendaLinks: observable,
      minutes: observable,
      minutesLinks: observable,
      groups: observable,
      users: observable,
      guests: observable,
      has_meeting: observable,
      record_meeting: observable,
      meeting_id: observable,
      meeting: observable,
      tags: observable,
      links: observable,
      readBy: observable,
      disable_comments: observable,
      currentUserId: observable,
      isEditing: observable,
      data: override,
      videoConferenceAvailable: computed,
      userRSVPStatus: computed,
      isPassed: computed,
      hasEnding: observable,
      hasStarted: computed,
      hasRecordings: computed,
      rsvpCurrentUser: action,
      sendRsvpEmail: action,
      getPassedMeeting: action,
      sendRsvpSuccess: observable,
      isSaving: observable,
      saveSuccess: observable,
      saveError: observable,
      save: action,
      hasAgendaOrMinutes: computed,
      recurringString: observable,
      recurringParentEventId: observable,
      deletedRecurringDates: observable,
      originalRecurringStart: observable,
      isInProgress: observable,
      isHappeningNow: computed,
      validationSchema: override,
      setInitialStartEnd: action,
    })

    this.isPublicShareAllowed = true
    this.createdAt = event.createdAt || Date.now()
    this.hasEnding = true
    const initDate = moment().seconds(0).milliseconds(0)
    this.start = event.start
      ? moment(event.start).valueOf()
      : initDate.clone().add(1, 'days').hour(12).startOf('hour').valueOf()
    this.end = event.end
      ? moment(event.end).valueOf()
      : initDate.clone().add(1, 'days').hour(13).startOf('hour').valueOf()

    this._id = event._id || undefined
    this.org_id = event.org_id || ''
    this.author = event.author || undefined
    this.title = event.title || ''
    this.summary = event.summary || '<p><br></p>'
    this.rsvps = event.rsvps || {}
    this.currentUserId =
      event.currentUserId || this.rootStore?.userStore?._id || undefined
    this.phone = event.phone || ''
    this.extension = event.extension || ''
    this.link = event.link || ''
    this.location = event.location || ''
    this.agenda = event.agenda || ''
    this.minutes = event.minutes || ''
    this.groups = event.groups || []
    this.users = event.users || []
    this.guests = event.guests || []
    this.isPublic = event.isPublic || false
    this.has_meeting = event.has_meeting
    this.record_meeting = event.record_meeting || false
    this.meeting_id = event.meeting_id
    this.isEditing = !this._id
    this.tags = event.tags || []
    const links = observable.array(
      event.links ? event.links.map(link => new Link(link, this.org_id)) : []
    )
    this.links = links
    this.readBy = event.readBy || []
    this.meeting = event.meeting || undefined
    this.disable_comments = Boolean(event.disable_comments) || false
    this.agendaLinks = new AgendaLinks(rootStore, {
      event_id: this._id,
      links: event.agendaLinks || [],
      org_id: this.org_id,
    })
    this.minutesLinks = new MinutesLinks(rootStore, {
      event_id: this._id,
      links: event.minutesLinks || [],
      org_id: this.org_id,
    })
    this.recurringString = event.recurringString || ''
    this.recurringParentEventId = event.recurringParentEventId || undefined
    this.deletedRecurringDates = event.deletedRecurringDates || []
    this.originalRecurringStart = event.originalRecurringStart || undefined
    this.updateCount = +event.updateCount || 0
    this.isInProgress = event.isInProgress || false
  }

  _id: string | undefined = undefined

  createdAt: number | undefined = undefined

  org_id: string | undefined = undefined

  author: string | undefined = undefined

  title = ''

  summary = '<p><br></p>'

  start: number | undefined = undefined

  end: number | undefined = undefined

  rsvps: { [key: string]: string } = {}

  phone = ''

  extension = ''

  link = ''

  location = ''

  agenda = ''

  agendaLinks: AgendaLinks = undefined

  minutes = ''

  minutesLinks: MinutesLinks = undefined

  groups: string[] = []

  users: string[] = []

  guests: string[] = []

  has_meeting = false

  record_meeting = false

  meeting_id: string | undefined = undefined

  meeting: { zoom: { id: string; recordings: object[] } } | undefined =
    undefined

  tags: string[] = []

  readBy: string[] = []

  disable_comments = false

  hasEnding = true

  currentUserId: string | undefined = undefined

  isEditing = false

  recurringString = ''

  recurringParentEventId: string | undefined = undefined

  deletedRecurringDates: string[] = []

  originalRecurringStart: number | undefined = undefined

  updateCount = 0

  isInProgress: boolean | undefined = false

  get data(): EventProps {
    const data = [
      '_id',
      'org_id',
      'author',
      'title',
      'summary',
      'phone',
      'extension',
      'links',
      'location',
      'agenda',
      'minutes',
      'groups',
      'users',
      'has_meeting',
      'record_meeting',
      'tags',
      'readBy',
      'disable_comments',
      'isPublic',
      'start',
      'end',
      'recurringString',
      'recurringParentEventId',
      'deletedRecurringDates',
      'originalRecurringStart',
      'updateCount',
      'isInProgress',
    ].reduce(
      (acc, key) => {
        if (key === 'title') {
          acc.title = this.title ? toJS(this.title).trim() : ''
        } else {
          acc[key] = toJS(this[key]) || undefined
        }

        return acc
      },
      {
        rsvps: undefined,
        start: undefined,
        end: undefined,
        has_meeting: undefined,
        record_meeting: undefined,
        disable_comments: undefined,
        links: undefined,
        agendaLinks: undefined,
        minutesLinks: undefined,
        title: undefined,
        recurringString: undefined,
        recurringParentEventId: undefined,
        deletedRecurringDates: undefined,
        originalRecurringStart: undefined,
        isInProgress: undefined,
      }
    )
    data.rsvps = JSON.parse(JSON.stringify(this.rsvps))
    data.deletedRecurringDates = JSON.parse(
      JSON.stringify(this.deletedRecurringDates)
    )
    data.has_meeting = this.has_meeting || false
    data.record_meeting = this.record_meeting || false
    data.disable_comments = Boolean(this.disable_comments || false)
    data.links = this.links.map(link => link.data)
    data.agendaLinks = this.agendaLinks.data
    data.minutesLinks = this.minutesLinks.data
    data.recurringString = this.recurringString || ''
    data.recurringParentEventId = this.recurringParentEventId || undefined
    data.isInProgress = this.isInProgress || false
    return data
  }

  get videoConferenceAvailable(): boolean {
    const getTimeBuffer = (min: number) => 1000 * 60 * min
    const startBuffer = this.start - getTimeBuffer(30)
    const endBuffer = this.end + getTimeBuffer(120)
    return Boolean(Date.now() > startBuffer && Date.now() < endBuffer)
  }

  get userRSVPStatus(): string | boolean {
    if (typeof this.currentUserId === 'undefined') {
      return false
    }
    switch (this.rsvps[this.currentUserId]) {
      case 'attending':
        return 'attend'
      case 'attending_remote':
        return 'attend remotely'
      case 'maybe':
        return 'possibly attend'
      case 'not_attending':
        return 'not be attending'
      default:
        return false
    }
  }

  get isPassed(): boolean {
    return this._id && this.end < Date.now()
  }

  get hasStarted(): boolean {
    return this._id && this.start < Date.now()
  }

  get isHappeningNow(): boolean {
    return this.start <= Date.now() + 1000 * 60 * 5 && this.end >= Date.now()
  }

  get hasRecordings(): boolean {
    return (
      this._id &&
      this.end < Date.now() &&
      this.has_meeting &&
      this.record_meeting &&
      this.meeting &&
      this.meeting.zoom &&
      this.meeting.zoom.recordings.length > 0
    )
  }

  rsvpCurrentUser = async (rsvp: 'attending' | 'not_attending' | 'maybe') => {
    try {
      await this.client.events.saveRSVP(this.org_id, this._id.toString(), {
        rsvp,
      })
      if (typeof this.currentUserId !== 'undefined') {
        this.rsvps[this.currentUserId] = rsvp
      }

      log.code('evt002', { rsvp })
    } catch (error) {
      log.code('evt301', { error })
    }
  }

  setInitialStartEnd = async (day: moment.Moment) => {
    const startMoment = moment()
      .startOf('day')
      .isSame(day.clone().startOf('day'))
      ? moment().add(2, 'hour').minute(0)
      : day.clone().hour(12)
    const endMoment = moment().startOf('day').isSame(day.clone().startOf('day'))
      ? moment().add(3, 'hour').minute(0)
      : day.clone().hour(13)

    this.end = endMoment.seconds(0).milliseconds(0).valueOf()
    this.start = startMoment.seconds(0).milliseconds(0).valueOf()
  }

  sendRsvpEmail = async () => {
    const { status } = await this.client.events.saveEvent(
      this.data,
      false,
      'rsvp'
    )

    if (status === 200) {
      this.sendRsvpSuccess = true
    }

    return this.sendRsvpSuccess
  }

  getPassedMeeting = async () => {
    if (this.has_meeting && this.meeting_id && this.isPassed && !this.meeting) {
      try {
        const res = await this.client.meetings.getPassedMeeting(
          this.org_id,
          this.meeting_id
        )
        this.meeting = res.data
      } catch (e) {
        this.meeting = undefined
      }
    }
  }

  sendRsvpSuccess = false

  isSaving = false

  saveSuccess: string | undefined = undefined

  saveError: string | undefined = undefined

  get validationSchema() {
    return yup.object().shape({
      title: yup.string().required(),
      end: yup.number().moreThan(yup.ref('start')).required(),
      summary: yup
        .mixed()
        .notOneOf(['<p><br></p>', ''], 'Summary is a required field')
        .required(),
      start: yup.number().required(),
      org_id: yup.string().required(),
      phone: yup.string(),
      extension: yup.number(),
      location: yup.string(),
      agenda: yup.string(),
      minutes: yup.string(),
      record_meeting: yup.boolean(),
      has_meeting: yup.boolean(),
      disable_comments: yup.boolean(),
      isInProgress: yup.boolean(),
    })
  }

  save = async (rsvpAction = false, sendNotification = false) => {
    this.isSaving = true
    this.saveSuccess = undefined
    this.saveError = undefined
    this.isEditing = Boolean(!rsvpAction)

    if (this.data.start > this.data.end) {
      this.saveError = 'End time must be after start time'
      this.isSaving = false
      return
    }
    try {
      const { data } = await this.client.events.saveEvent(
        this.data,
        rsvpAction,
        sendNotification
      )
      this._id = data._id
      this.meeting_id = data.meeting_id
      this.isSaving = false
      this.saveSuccess = 'Event saved successfully'

      // Add to events store if dne other wise update
      //
      const {
        eventStore: { events },
      } = this.rootStore
      const foundIndex = events ? events.findIndex(e => e._id === this._id) : -1

      if (foundIndex !== -1) {
        events[foundIndex] = this
        //  if the event was changed from recurring to non-curring make sure to remove  child events from the ui
        if (!this.recurringString) {
          const toBeRemoved = events.filter(
            item => item.recurringParentEventId === this._id
          )
          if (toBeRemoved.length) {
            toBeRemoved.forEach(item => {
              events.splice(
                events.findIndex(e => e._id === item._id),
                1
              )
            })
          }
        }
      } else if (events) {
        events.push(this)
      }

      log.code('evt001', { id: data._id })
    } catch (e) {
      this.isSaving = false
      if (e.response && e.response.status === 400) {
        this.saveError = 'Please ensure all fields are properly filled out'
        log.code('evt201', { error: e.response })
        return
      }
      this.saveError =
        'There was an error saving the event, please contact support'
      log.code('evt301', { error: e.response })
    }
  }

  clearAgendaAndMinutes = () => {
    this.agenda = ''
    this.minutes = ''
    this.agendaLinks.links.clear()
    this.minutesLinks.links.clear()
  }

  get displayName(): string {
    return 'Event'
  }

  get permissionsName(): string {
    return 'Attendee'
  }

  get hasAgendaOrMinutes(): boolean {
    return (
      (this.agenda && this.agenda.length > 0) ||
      (this.minutes && this.minutes.length > 0) ||
      this.agendaLinks.length > 0 ||
      this.minutesLinks.length > 0
    )
  }

  downloadICS = async () => this.client.events.getICS(this.org_id, this._id)

  notifyICS = async () => this.client.events.notifyICS(this.org_id, this._id)

  getinkTitle = (): string =>
    `${this.title}: ${moment(this.start).format('ddd M/DD/YY, h:mm A')}`
}
