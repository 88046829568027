import React, { FC } from 'react'
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { observer } from 'mobx-react'
import PropTypes, { InferProps } from 'prop-types'

import UserListItem from './UserListItem'

import { GetWrappedIcon } from '../../Icons'

const GroupListItemType = {
  group: PropTypes.shape({
    name: PropTypes.string,
    members: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, photo: PropTypes.string })
    ),
  }).isRequired,
}

const GroupListItem: FC<InferProps<typeof GroupListItemType>> = ({
  group,
}): JSX.Element => {
  const [open, setOpen] = React.useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <GetWrappedIcon name='group' />
        </ListItemIcon>
        <ListItemText primary={group.name} />
        {open ? (
          <GetWrappedIcon name='chevronDown' />
        ) : (
          <GetWrappedIcon name='chevronUp' />
        )}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {group.members.map((user: { name: string; photo: string }) => {
            return (
              <UserListItem
                key={`${user.name}-${user.photo}`}
                user={user}
                sx={{ pl: 4 }}
              />
            )
          })}
        </List>
      </Collapse>
    </>
  )
}

GroupListItem.propTypes = GroupListItemType

export default observer(GroupListItem)
