import React, { memo } from 'react'
import {
  Checkbox as MUICheckbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material'
import PropTypes, { InferProps } from 'prop-types'

const Checkbox = ({
  checked,
  dataQa,
  disabled,
  helperText,
  label,
  onChange,
  ...rest
}: InferProps<typeof Checkbox.propTypes>) => {
  return (
    <FormControl {...rest}>
      <FormControlLabel
        disabled={disabled}
        label={label}
        control={
          <MUICheckbox
            checked={checked}
            data-qa={dataQa}
            disabled={disabled}
            onChange={onChange}
          />
        }
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

Checkbox.defaultProps = {
  dataQa: undefined,
  disabled: false,
  helperText: undefined,
  label: undefined,
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  dataQa: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
}

export default memo(Checkbox)
