import {
  action,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
} from 'mobx'

import Tag from '../Model/Tag'
import { AvailableOrg, TagProps } from '../types'

import RootStore from './RootStore'
import StoreResourceBase from './StoreResourceBase'

import { handleSocketListener } from '../lib/socket'

export default class TagStore extends StoreResourceBase {
  tags: IObservableArray<Tag> = observable.array([])

  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      loadData: action,
      getModel: action,
      getTags: action,
      tags: observable,
      getAvailableTags: action,
    })

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
    reaction(() => this.tags, this.handleSocket)
  }

  getTagsSelectedClients = (permissionTags: string[]): AvailableOrg[] => {
    const { availableOrgs, currentOrg } = this.rootStore.orgStore

    return availableOrgs.filter(org =>
      this.getAvailableTags()
        .filter((orgTag: Tag) =>
          permissionTags.includes(orgTag.name.toLowerCase())
        )
        .some(orgTag =>
          orgTag?.items?.some(
            orgTagItem =>
              orgTagItem.collection === currentOrg.modelCollection &&
              orgTagItem.uid === org.value
          )
        )
    )
  }

  getAvailableTags = (): Tag[] => {
    const { currentOrg } = this.rootStore.orgStore

    return this.tags?.length > 0
      ? this.tags.filter(
          orgTag =>
            (orgTag.targetCollection === currentOrg.modelCollection &&
              orgTag.items.length > 0) ||
            orgTag?.items?.some(
              orgTagItem => orgTagItem.collection === currentOrg.modelCollection
            )
        )
      : []
  }

  getTags = async () => {
    try {
      const { data } = await this.rootStore.client.tags.getTags(
        this.rootStore.orgStore.currentOrg._id
      )

      this.loadData(data)
      this.loadedResource = true
    } catch (e) {
      this.loadedResource = true
    }
  }

  loadData(data: TagProps[] | undefined) {
    this.tags = observable.array(
      (data || []).map((tag: TagProps) => new Tag(this.rootStore, tag))
    )
  }

  getModel = (tag: Tag | TagProps | undefined): Tag => {
    if (tag && tag._id) {
      return this.tags.find(alreadyHere => alreadyHere._id === tag._id)
    }

    return new Tag(this.rootStore, {
      org_id: this.rootStore.orgStore.currentOrg._id,
    })
  }

  handleSave = (tag: Tag) => {
    if (this.tags.some(alreadyHere => alreadyHere._id === tag._id)) {
      this.tags.replace(
        this.tags.map(alreadyHere =>
          alreadyHere._id === tag._id ? tag : alreadyHere
        )
      )
    } else {
      this.tags.push(tag)
    }
  }

  handleSocket = () => {
    if (this.rootStore.userStore._id) {
      handleSocketListener('tags', Tag, this.tags, this.rootStore)
    }
  }
}
