import React, { FC } from 'react'

import ActionTrigger, { OptionalActionTriggerProps } from './ActionTrigger'

const RemoveTrigger: FC<OptionalActionTriggerProps> = ({
  content = 'Delete',
  dataQa = undefined,
  ...rest
}): JSX.Element | null => (
  <ActionTrigger
    {...rest}
    content={content}
    dataQa={dataQa}
    iconName='delete'
  />
)

export default RemoveTrigger
