import React, {
  FC,
  ChangeEvent,
  FocusEvent,
  HTMLAttributes,
  useCallback,
} from 'react'
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Chip,
  TextField,
  Paper,
  styled,
} from '@mui/material'
import { observer } from 'mobx-react'

import { SelectOptionType } from './types'

const PaperComponent: FC = styled(Paper)(({ theme }) => ({
  boxShadow: theme.shadows[5],
}))

const MultiSelect: FC<{
  dataQa?: string
  disableClearable?: boolean
  disabled?: boolean
  filterOptions?: (
    options: SelectOptionType[],
    params: { inputValue: string }
  ) => SelectOptionType[]
  error?: string
  filterSelectedOptions?: boolean
  freeSolo?: boolean
  groupBy?: () => string
  helperText?: JSX.Element
  label?: string
  name?: string
  noOptionsMessage?: string
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChange: (event: ChangeEvent<HTMLSelectElement>, newValues: string[]) => void
  options: SelectOptionType[]
  placeholder?: string
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: SelectOptionType
  ) => JSX.Element
  renderTags?: (
    options: SelectOptionType[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => JSX.Element | JSX.Element[]
  required?: boolean
  value?: (string | SelectOptionType)[]
}> = ({
  dataQa,
  disabled,
  error,
  filterOptions,
  filterSelectedOptions,
  groupBy,
  helperText,
  label,
  name,
  noOptionsMessage,
  onBlur,
  onChange,
  options,
  placeholder,
  renderOption,
  renderTags,
  required,
  value,
  ...rest
}): JSX.Element => {
  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLSelectElement>,
      selectedOptions: SelectOptionType[]
    ) => {
      event.target.name = name
      onChange(
        event,
        selectedOptions.map(opt => opt.value)
      )
    },
    [value]
  )

  const actualVal =
    options?.length > 0 && value?.length > 0
      ? value.map(val =>
          typeof val === 'string'
            ? options.find(option => option.value === val)
            : val
        )
      : []

  return (
    <Autocomplete
      {...rest}
      data-qa={dataQa}
      PaperComponent={PaperComponent}
      disabled={disabled || (options.length === 0 && !rest.freeSolo)}
      disableCloseOnSelect
      filterOptions={filterOptions}
      filterSelectedOptions={filterSelectedOptions}
      fullWidth
      getOptionLabel={(option: { label?: string; text: string } | string) =>
        option?.label || option?.text || option || ''
      }
      groupBy={groupBy}
      isOptionEqualToValue={(option, currentValue) =>
        option.value === currentValue?.value
      }
      multiple
      onChange={handleChange}
      openOnFocus
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          error={Boolean(error)}
          helperText={error || helperText}
          label={label}
          name={name}
          onBlur={onBlur}
          placeholder={options.length === 0 ? noOptionsMessage : placeholder}
          value={actualVal.join(',')}
        />
      )}
      renderOption={renderOption}
      renderTags={(val, getTagProps) => {
        if (renderTags) {
          return renderTags(val, getTagProps)
        }

        return val.map((option, index) => {
          const labelText =
            option && option.label
              ? option.label
              : options.find(o => o.value === option)?.label

          if (labelText) {
            return (
              <Chip
                key={option}
                variant='outlined'
                label={labelText}
                {...getTagProps({ index })}
              />
            )
          }
          return null
        })
      }}
      required={required}
      value={actualVal}
    />
  )
}

MultiSelect.defaultProps = {
  dataQa: undefined,
  disableClearable: false,
  disabled: false,
  error: undefined,
  filterSelectedOptions: false,
  freeSolo: undefined,
  groupBy: undefined,
  helperText: undefined,
  label: undefined,
  filterOptions: undefined,
  name: undefined,
  noOptionsMessage: 'No Options...',
  onBlur: undefined,
  placeholder: undefined,
  renderOption: undefined,
  required: undefined,
  renderTags: undefined,
  value: [],
}

export default observer(MultiSelect)
