import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  toJS,
} from 'mobx'

import { captilizeWords, formatMoney, generateID } from '../lib/utils'
import { sortArrayAlpha } from '../utils/arrays'
import { TagCriteriumProps } from '../types'
import Organization from './Organization'

const baseColumnFieldOptions = [
  {
    description: 'Total assets under management',
    group: 'Investments',
    key: 'tagCriteriumOptionTotalAUM',
    label: 'Total Assets Under Management',
    type: 'number',
    value: 'aum',
  },
  {
    description: 'Assets under management by custodial account',
    group: 'Investments',
    key: 'tagCriteriumOptionAccountAUM',
    label: 'Account Assets Under Management',
    type: 'number',
    value: 'aumByAccount',
  },
  {
    description: 'Number of custodial accounts managed',
    group: 'Investments',
    key: 'tagCriteriumOptionClientAccounts',
    label: 'Investment Account Count',
    type: 'number',
    value: 'accounts_count',
  },
  {
    description: "Client's name",
    group: 'Client',
    key: 'tagCriteriumOptionClientName',
    label: 'Name',
    type: 'string',
    value: 'legal_business_name',
  },
  {
    description: "Client's city",
    group: 'Client',
    key: 'tagCriteriumOptionClientCity',
    label: 'City',
    type: 'string',
    value: 'city',
  },
  {
    description: "Client's state",
    group: 'Client',
    key: 'tagCriteriumOptionClientState',
    label: 'State',
    type: 'string',
    value: 'state',
  },
  {
    description: "Client's zip",
    group: 'Client',
    key: 'tagCriteriumOptionClientZip',
    label: 'Postal Code',
    type: 'string',
    value: 'zip',
  },
  {
    description: 'Number of users for a client',
    group: 'Client',
    key: 'tagCriteriumOptionClientUsers',
    label: 'User Count',
    type: 'number',
    value: 'user_count',
  },
  {
    description: 'Static tag(s) assigned to a client',
    group: 'Tags',
    key: 'containsOtherTags',
    label: 'Static Tags',
    type: 'string',
    value: 'tags',
  },
]

export default class TagCriterium {
  constructor(
    criterium: TagCriteriumProps = {
      id: generateID(),
      operatorValue: 'equals',
      columnField: '',
      values: [],
    }
  ) {
    makeObservable(this, {
      getColumnFieldOptions: action,
      setEndingValue: action,
      setSingleValue: action,
      setStartingValue: action,
      data: computed,
      endingValue: computed,
      operatorOptions: computed,
      shouldHaveValues: computed,
      startingValue: computed,
      summary: computed,
      targetOption: computed,
      columnField: observable,
      id: observable,
      operatorValue: observable,
      values: observable,
    })

    Object.assign(this, criterium)
  }

  operatorValue = 'equals'

  id = generateID()

  columnField = ''

  values: IObservableArray<string> = observable.array([])

  getColumnFieldOptions(org?: Organization) {
    let options = baseColumnFieldOptions.filter(
      option => option.group !== 'Investments'
    )

    if (!org || !org.hideInvestments) {
      const typesFromLS = localStorage.getItem('firmHoldingTypes')
      const subTypesFromLS = localStorage.getItem('firmHoldingSubTypes')
      const firmHoldingTypes = (
        typesFromLS ? JSON.parse(typesFromLS) || [] : []
      ).sort((a: { label: string }, b: { label: string }) =>
        sortArrayAlpha(a, b, 'label')
      )
      const firmHoldingSubTypes = (
        subTypesFromLS ? JSON.parse(subTypesFromLS) || [] : []
      ).sort((a: { label: string }, b: { label: string }) =>
        sortArrayAlpha(a, b, 'label')
      )

      options = [
        ...baseColumnFieldOptions,
        ...firmHoldingTypes.map(
          (option: { key: string; label: string; value: string }) => ({
            group: 'Investment Holdings Type Value',
            key: `${option.key}_value`,
            value: `${option.value}_value`,
            label: `${option.label} Value`,
            type: 'number',
          })
        ),
        ...firmHoldingTypes.map(
          (option: { key: string; label: string; value: string }) => ({
            group: 'Investment Holdings Type Allocation Percent',
            key: `${option.key}_percent`,
            value: `${option.value}_percent`,
            label: `${option.label} Percentage`,
            type: 'number',
          })
        ),
        ...firmHoldingSubTypes.map(
          (option: { key: string; label: string; value: string }) => ({
            group: 'Investment Holdings Sub Type Value',
            key: `${option.key}_value`,
            value: `${option.value}_value`,
            label: `${option.label} Value`,
            type: 'number',
          })
        ),
        ...firmHoldingSubTypes.map(
          (option: { key: string; label: string; value: string }) => ({
            group: 'Investment Holdings Sub Type Allocation Percent',
            key: `${option.key}_percent`,
            value: `${option.value}_percent`,
            label: `${option.label} Percentage`,
            type: 'number',
          })
        ),
      ]
    }

    return options.sort((a, b) => sortArrayAlpha(a, b, 'group'))
  }

  get data() {
    if (this.values.length === 0 && this.shouldHaveValues) {
      throw new Error('Must have at least one value')
    }

    if (this.operatorValue === 'between') {
      if (this.startingValue > this.endingValue) {
        const target = this.targetOption
        const errorMessage = target
          ? `Starting value must be less than ending value for ${target.label}`
          : 'Starting value must be less than ending value'

        throw new Error(errorMessage)
      }
    }

    return {
      id: toJS(this.id),
      operatorValue: toJS(this.operatorValue),
      columnField: toJS(this.columnField),
      values: toJS(this.values),
    }
  }

  get endingValue() {
    return this.values[1] || ''
  }

  get operatorOptions() {
    const target = this.targetOption

    return (
      target?.type === 'number'
        ? ['between']
        : target.value === 'tags'
        ? ['contains', 'isEmpty', 'isNotEmpty', 'isAnyOf']
        : [
            'contains',
            'endsWith',
            'equals',
            'isEmpty',
            'isNotEmpty',
            'isAnyOf',
            'startsWith',
          ]
    )
      .map(o => ({
        value: o,
        key: o,
        label: captilizeWords(o.split(/(?=[A-Z])/).join(' ')),
      }))
      .sort((a, b) => sortArrayAlpha(a, b, 'label'))
  }

  get shouldHaveValues() {
    return !['isEmpty', 'isNotEmpty'].includes(this.operatorValue)
  }

  get startingValue() {
    return this.values[0] || ''
  }

  setSingleValue = (value: string) => {
    this.values = observable.array([value])

    return this
  }

  setStartingValue = (value: string) => {
    this.values[0] = value

    return this
  }

  setEndingValue = (value: string) => {
    this.values[1] = value

    return this
  }

  get summary() {
    const target = this.targetOption

    if (target) {
      return `${target.label} ${captilizeWords(
        this.operatorValue.split(/(?=[A-Z])/).join(' ')
      )}${this.shouldHaveValues ? ': ' : ''}${
        target.type === 'number'
          ? this.operatorValue === 'between' && this.values.length === 2
            ? `${formatMoney(this.startingValue)} and ${formatMoney(
                this.endingValue
              )}`
            : this.values.map(val => formatMoney(toJS(val))).join(', ')
          : this.values.map(val => captilizeWords(toJS(val))).join(', ')
      }`
    }

    return 'New Criterium...'
  }

  get targetOption() {
    return this.getColumnFieldOptions().find(
      tO => tO.value === this.columnField
    )
  }
}
