import APIResponseType from './APIResponseType'

export const gernerateShortenedUrl = (
  org_id: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/shorties`,
  method: 'POST',
  data,
})
export const gernerateShortenedUserUrl = (
  userId: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/users/${userId}/shorties`,
  method: 'POST',
  data,
})

export const getShorty = (id: string, guestId: string): APIResponseType => ({
  url: `/orgs/v1/shorties/${id}${guestId ? `?guestId=${guestId}` : ''}`,
  method: 'GET',
  data: {},
})
