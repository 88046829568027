import React from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { Icon, Tooltip, TableCell, Typography } from '@mui/material'

const ActionsCell = ({
  className,
  colSpan,
  items,
}: InferProps<typeof ActionsCell.propTypes>) => {
  if (!items || items.length === 0) return null

  const renderItem = item => {
    if (!item || !item.content) return null
    const getPopup = () => {
      const { popup } = item
      if (popup) {
        if (popup.trigger) {
          const { content, icon, trigger } = popup
          return (
            <Tooltip
              placement='top'
              title={
                trigger ||
                (icon && (
                  <Icon
                    children={icon.name}
                    color={icon.color}
                    style={icon.style}
                    className={icon.className}
                  />
                ))
              }
            >
              <Typography color='common.white'>{content}</Typography>
            </Tooltip>
          )
        }

        return item.popup
      }
      return null
    }

    return (
      <span key={item.key}>
        {item.content}
        {getPopup()}
      </span>
    )
  }

  return (
    <TableCell
      colSpan={colSpan}
      className={`list-table-actions${className ? ` ${className}` : ''}`}
      align='center'
    >
      {items.map(renderItem)}
    </TableCell>
  )
}

ActionsCell.defaultProps = {
  className: undefined,
  colSpan: undefined,
}

ActionsCell.propTypes = {
  className: PropTypes.string,
  colSpan: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      key: PropTypes.string.isRequired,
      popup: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.shape({
          content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
          icon: PropTypes.shape({
            name: PropTypes.string,
            className: PropTypes.string,
            color: PropTypes.string,
            style: PropTypes.shape({}),
          }),
          trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        }),
      ]),
    })
  ).isRequired,
}

export default ActionsCell
