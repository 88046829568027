import React, { FC, useCallback } from 'react'

import ActionTrigger, { OptionalActionTriggerProps } from './ActionTrigger'

import { useRootStore } from '../../services/RootStoreContext'

const ChangeOrgTrigger: FC<OptionalActionTriggerProps & { orgId?: string }> = ({
  dataQa = undefined,
  orgId,
  ...rest
}): JSX.Element | null => {
  const {
    orgStore: {
      availableOrgs,
      currentOrg: { _id: currentOrgId },
    },
  } = useRootStore()

  const handleOnClick = useCallback(() => {
    const a = window.document.createElement('a')
    a.href = `${window.location.origin}/#/dashboard?changeId=${orgId}`
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }, [availableOrgs, orgId])

  if (orgId && currentOrgId !== orgId) {
    const org = availableOrgs.find(availableOrg => availableOrg.value === orgId)

    if (org) {
      return (
        <ActionTrigger
          {...rest}
          content={`Login in to ${org.label} on a new tab`}
          dataQa={dataQa}
          iconName='changeOrg'
          onClick={handleOnClick}
        />
      )
    }
  }

  return null
}

ChangeOrgTrigger.defaultProps = {
  orgId: undefined,
}

export default ChangeOrgTrigger
