import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { Tooltip, Typography } from '@mui/material'

import './OverflowPopup.scss'

const OverflowPopup: FC<{
  content: string | JSX.Element | (() => JSX.Element)
  fullWidth?: boolean
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit'
  sx?: object
}> = ({ content, fullWidth, variant, sx }): JSX.Element => {
  const element = useRef<HTMLElement>()
  const [isOverflowed, setIsOverflowed] = useState(false)

  const getIsOverflowed = () => {
    if (element.current) {
      return element.current.scrollWidth > element.current.clientWidth
    }

    return false
  }

  useEffect(() => {
    setIsOverflowed(getIsOverflowed())
  }, [])

  const actualContent = useMemo(
    () => (typeof content === 'function' ? content() : content),
    [content]
  )

  return (
    <Tooltip
      disableHoverListener={!isOverflowed}
      title={
        <Typography color='common.white' component='span'>
          {actualContent}
        </Typography>
      }
    >
      <Typography
        className={`popup-text${isOverflowed ? ' is-overflowed' : ''}${
          fullWidth ? ' full-width' : ''
        }`}
        component='span'
        ref={element}
        variant={variant}
        onMouseEnter={() => setIsOverflowed(getIsOverflowed)}
        sx={isOverflowed ? { cursor: 'default', ...sx } : sx}
      >
        {actualContent}
      </Typography>
    </Tooltip>
  )
}

OverflowPopup.defaultProps = {
  fullWidth: false,
  variant: undefined,
  sx: {},
}

export default memo(OverflowPopup)
