import React from 'react'

export const TwoPanelSideNavContext = React.createContext<{
  openSidebar: boolean
  setOpenSidebar: (isOpen: boolean) => void
}>({
  openSidebar: false,
  setOpenSidebar: undefined,
})

/* Hook to use store in any functional component */
export const useTwoPanelSideNavContext = () =>
  React.useContext(TwoPanelSideNavContext)
