import { Theme } from '@mui/material'
import { computed, makeObservable, observable, override } from 'mobx'
import moment from 'moment'

import ModelBase from './ModelBase'

import RootStore from '../stores/RootStore'

import { OrgMemberProps } from '../types'

export default class OrgMember extends ModelBase {
  constructor(
    rootStore: RootStore,
    member: OrgMemberProps = {
      isHostAdmin: false,
      isHostUser: false,
      isInvitedOrPending: false,
      orgRole: 'user',
      initialOrgRole: 'user',
      _id: '',
      isDeleted: false,
      isExpired: false,
      expiresAt: 0,
      fname: '',
      lastSeen: 0,
      lname: '',
      emails: [],
      groups: [],
    }
  ) {
    super(rootStore)
    makeObservable(this, {
      _id: observable,
      fname: observable,
      groups: observable,
      lname: observable,
      expiresAt: observable,
      emails: observable,
      isDeleted: observable,
      isExpired: observable,
      isHostAdmin: observable,
      isHostUser: observable,
      initialOrgRole: observable,
      lastSeen: observable,
      orgRole: observable,
      phone: observable,
      photo: observable,
      fullName: computed,
      isActive: computed,
      category: override,
      listTitle: override,
      permalink: override,
    })

    Object.assign(this, member)
  }

  isHostAdmin = false

  isHostUser = false

  orgRole = 'user'

  initialOrgRole = 'user'

  isDeleted = false

  isExpired = false

  isInvitedOrPending = false

  expiresAt = 0

  fname = ''

  lastSeen = 0

  lname = ''

  emails: string[] = []

  declare groups: string[]

  photo?: string = undefined

  phone?: string = undefined

  getOrgRoleStatusDescriptor = (
    theme: Theme
  ): { icon: string; content: string; color: string } => {
    const descriptors: {
      [key: string]: { icon: string; content: string; color: string }
    } = {
      user: {
        icon: 'user',
        content: 'Active Member',
        color: theme.palette.success.main,
      },
      contributor: {
        icon: 'user',
        content: 'Active Contributor',
        color: theme.palette.success.main,
      },
      admin: {
        icon: 'user',
        content: 'Active Administrator',
        color: theme.palette.success.main,
      },
      disabled: {
        icon: 'clock',
        content: 'Disabled',
        color: theme.palette.error.main,
      },
      deleted: {
        icon: 'delete',
        content: 'Disabled',
        color: theme.palette.error.main,
      },
      willExpire: {
        icon: 'clock',
        content: `Membership set to expire ${moment(this.expiresAt).format(
          'L'
        )}`,
        color: theme.palette.warning.dark,
      },
      expired: {
        icon: 'clock',
        content: 'Disabled due to membership expiration',
        color: theme.palette.error.main,
      },
      'pending-user': {
        icon: 'warning',
        content: 'Pending Invitation',
        color: theme.palette.warning.main,
      },
      'pending-contributor': {
        icon: 'warning',
        content: 'Pending Invitation',
        color: theme.palette.warning.main,
      },
      'pending-admin': {
        icon: 'warning',
        content: 'Pending Invitation',
        color: theme.palette.warning.main,
      },
      'invited-user': {
        icon: 'pause',
        content: 'Waiting for user to accept invite',
        color: theme.palette.action.disabled,
      },
      'invited-contributor': {
        icon: 'pause',
        content: 'Waiting for user to accept invite',
        color: theme.palette.action.disabled,
      },
      'invited-admin': {
        icon: 'pause',
        content: 'Waiting for user to accept invite',
        color: theme.palette.action.disabled,
      },
    }

    return (
      descriptors[
        this.expiresAt > Date.now()
          ? 'willExpire'
          : this.isExpired
            ? 'expired'
            : this.isDeleted
              ? 'deleted'
              : this.initialOrgRole || this.orgRole
      ] || {
        icon: 'warning',
        content: 'Unknown',
        color: theme.palette.error.main,
      }
    )
  }

  get category(): string {
    return 'member'
  }

  get displayName(): string {
    return 'Member'
  }

  get fullName(): string {
    return `${this.fname} ${this.lname}`
  }

  get isActive(): boolean {
    return ['admin', 'contributor', 'user'].includes(this.orgRole)
  }

  get listTitle(): string {
    return this.fullName
  }

  get permalink(): string {
    return `/directory/members/${this._id}`
  }
}
