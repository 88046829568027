import React, { FC } from 'react'
import { Stack, Typography, useTheme } from '@mui/material'

import { CancelButton } from '../../Buttons'

const FormDrawerHeader: FC<{
  children?: JSX.Element | JSX.Element[]
  onClose: () => void
  title?: string
}> = ({ children, onClose, title }): JSX.Element => {
  const theme = useTheme()
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      sx={{
        backgroundColor: 'background.paper',
        borderBottom: `1px solid ${theme.palette.divider}`,
        pb: 2,
        pt: 2,
        pl: 3,
        pr: 3,
        position: 'sticky',
        top: 0,
        zIndex: 2,
      }}
    >
      <Stack direction='row'>
        <CancelButton onClick={onClose} />
        {title && (
          <Typography variant='h4' component='div'>
            {title}
          </Typography>
        )}
      </Stack>
      {children}
    </Stack>
  )
}

FormDrawerHeader.defaultProps = {
  children: undefined,
  title: undefined,
}

export default FormDrawerHeader
