import React, { FC, memo } from 'react'
import { IconButton } from '@mui/material'

import { GetWrappedIcon } from '../Icons'

const GrabButton: FC<{ sx?: object }> = (props): JSX.Element => {
  const { sx } = props
  return (
    <IconButton {...props} disableRipple sx={{ pl: 1, cursor: 'grab', ...sx }}>
      <GetWrappedIcon name='grab' />
    </IconButton>
  )
}

GrabButton.defaultProps = {
  sx: undefined,
}

export default memo(GrabButton)
