import {
  action,
  computed,
  makeObservable,
  IObservableArray,
  observable,
  override,
  toJS,
} from 'mobx'

import ModelBase from './ModelBase'
import TagCriterium from '../Model/TagCriterium'
import TagItem from '../Model/TagItem'
import RootStore from '../stores/RootStore'
import { TagProps } from '../types/tag'

export default class Tag extends ModelBase {
  targetCollectionOptions = [
    {
      key: 'client',
      label: 'Clients',
      value: 'organizations',
    },
  ]

  typeOptions = [
    {
      description: 'Manually applied',
      key: 'simpleTag',
      label: 'Static',
      value: 'simple',
    },
    {
      description: 'Dynamically applied based on criteria',
      key: 'smartTag',
      label: 'Dynamic',
      value: 'smart',
    },
  ]

  constructor(rootStore: RootStore, tag?: TagProps) {
    super(rootStore)
    makeObservable(this, {
      addItem: action,
      process: action,
      removeItem: action,
      remove: action,
      save: action,
      data: override,
      displayType: computed,
      isSmart: computed,
      _id: observable,
      criteria: observable,
      items: observable,
      name: observable,
      org_id: observable,
      targetCollection: observable,
      type: observable,
    })

    this._setFromApiResponse(tag)
  }

  criteria: IObservableArray<TagCriterium> = observable.array([])

  items: IObservableArray<TagItem> = observable.array([])

  name = ''

  org_id = ''

  targetCollection = 'organizations'

  type: 'smart' | 'simple' = 'simple'

  createdAt = Date.now()

  updatedAt = Date.now()

  modelCollection = 'tags'

  getNotifyId = (): string => this._id

  markAsRead = async () => true

  _setFromApiResponse = (tag?: TagProps) => {
    if (tag) {
      Object.assign(this, tag)
    }

    this.criteria = observable.array(
      Array.isArray(tag?.criteria)
        ? tag.criteria.map(criterium => new TagCriterium(criterium))
        : []
    )

    this.items = observable.array(
      Array.isArray(tag?.items) ? tag.items.map(item => new TagItem(item)) : []
    )
  }

  addCriterium = () => {
    this.criteria.push(new TagCriterium())
  }

  addItem = (newItem: TagItem) => {
    if (newItem.uid && newItem.collection) {
      if (
        !this.items.some(
          item =>
            item.uid === newItem.uid && item.collection === newItem.collection
        )
      ) {
        this.items.push(new TagItem(newItem))
      }
    }
  }

  process = async () => {
    if (this._id && this.org_id) {
      try {
        const {
          data: { queued },
        } = await this.client.tags.process(this.org_id, this._id)
        return queued
      } catch (e) {
        //
      }
    }

    return false
  }

  remove = async (tags: IObservableArray<this>) => {
    if (this._id && this.org_id) {
      await this.client.tags.remove(this.org_id, this._id)
      tags.replace(tags.filter(tag => tag._id !== this._id))
    }

    return tags
  }

  removeItem = (itemToRemove: TagItem) => {
    if (itemToRemove) {
      this.items.remove(itemToRemove)
    }
  }

  save = async () => {
    if (this.isSmart) {
      if (this.criteria.length === 0) {
        throw new Error('Must have criteria')
      }
    }

    const { data } = await this.client.tags.saveTag(this.data)

    this._setFromApiResponse(data)

    this.rootStore.tagStore.handleSave(this)

    return this
  }

  get data(): TagProps {
    const { isSmart } = this

    if (!isSmart) {
      this.criteria.clear()
    }

    return {
      _id: this._id ? toJS(this._id) : undefined,
      criteria: this.criteria.map(criterium => criterium.data).filter(Boolean),
      items: toJS(this.items),
      name: toJS(this.name),
      org_id: toJS(this.org_id),
      targetCollection: isSmart ? toJS(this.targetCollection) : '',
      type: toJS(this.type),
    }
  }

  get displayName(): string {
    return 'Tag'
  }

  get isSmart(): boolean {
    return this.type === 'smart'
  }

  get displayType(): string {
    const type = this.typeOptions.find(option => option.value === this.type)

    return type?.label || 'Static'
  }
}
