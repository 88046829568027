import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Box } from '@mui/material'
import { observer } from 'mobx-react'
import { ViewModel } from 'mobx-utils'

import EditAction from '../../Actions/EditAction'
import ModalDialog from '../../ModalDialog/ModalDialog'
import { TextInput } from '../../Form'

import Documents from '../../../Model/Documents'
import File from '../../../Model/File'
import Folder from '../../../Model/Folder'

const RenameNode: FC<{
  documents: Documents
  node: ViewModel<File | Folder>
}> = ({ documents, node }): JSX.Element => {
  const [nodeExtension, setNodeExtension] = useState('')
  const [nodeName, setNodeName] = useState('')
  const { nameAlreadyExists, renameNode } = documents
  const isForbiddenFolderName =
    documents && node.type === 'folder'
      ? documents.forbiddenFolderNames.includes(
          nodeName.toString().toLowerCase()
        )
      : false
  const nodeNameExists =
    nameAlreadyExists && nameAlreadyExists(nodeName) && nodeName !== node.name

  useEffect(() => {
    const ext = node.type !== 'folder' ? node.name.split('.').pop() : ''
    setNodeExtension(ext)
    setNodeName(node.name.replace(`.${ext}`, ''))
  }, [node])

  const handleSubmit = () => {
    const newName = `${nodeName}${
      nodeExtension && nodeExtension.length > 0 ? `.${nodeExtension}` : ''
    }`

    renameNode(node, newName.trim())
  }

  const onChange = useMemo(
    () => (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event
      if (node.type === 'folder') {
        setNodeName(value.replace(/\//g, ''))
      } else {
        setNodeName(value)
      }
    },
    [node?.type]
  )
  const onKeyDown = useMemo(() => (e: KeyboardEvent) => e.stopPropagation(), [])
  const onKeyPress = useMemo(
    () => (e: KeyboardEvent) => {
      if (
        e.key === 'Enter' &&
        nodeName.length > 0 &&
        !nodeNameExists &&
        !isForbiddenFolderName
      ) {
        handleSubmit()
      }
    },
    []
  )

  if (node.isGlobalFolder) {
    return null
  }

  return (
    <ModalDialog
      confirmActionDisabled={Boolean(
        nodeName.length === 0 || nodeNameExists || isForbiddenFolderName
      )}
      confirmActionText='Rename'
      content={
        <Box mt={3}>
          <TextInput
            label='New Name'
            value={nodeName}
            onChange={onChange}
            onKeyDown={onKeyDown}
            autoFocus
            onKeyPress={onKeyPress}
            data-qa='documents-rename-input'
          />
        </Box>
      }
      header={`Rename ${node.type === 'file' ? 'File' : 'Folder'}`}
      onConfirm={handleSubmit}
      trigger={<EditAction text='Rename' />}
    />
  )
}

export default observer(RenameNode)
