/* eslint-disable import/prefer-default-export */
import APIResponseType from './APIResponseType'

export const track = (
  org_id: string,
  type: string,
  typeid: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/readby/${type}/${typeid}`,
  method: 'PATCH',
  data,
})
