import { Theme } from '@mui/material'
import Backdrop from './Backdrop'
import Button from './Button'
import Card from './Card'
import Chip from './Chip'
import IconButton from './IconButton'
import Link from './Link'
import Lists from './Lists'
import Paper from './Paper'
import Table from './Table'
import Tooltip from './Tooltip'
import Typography from './Typography'

export default function ComponentsOverrides(theme: Theme) {
  return {
    ...Backdrop(theme),
    ...Button(theme),
    ...Card(theme),
    ...Chip(theme),
    ...IconButton(theme),
    ...Link(),
    ...Lists(theme),
    ...Paper(),
    ...Table(theme),
    ...Tooltip(theme),
    ...Typography(theme),
  }
}
