import { createContext, Dispatch, useContext, SetStateAction } from 'react'

import { OrgProps } from '../../../types'

type ClientsMissingBillingProfileContextProps = {
  clientsMissingBillingProfile: OrgProps[]
  missingBillingProfilesAlertOpen: boolean
  setMissingBillingProfilesAlertOpen: Dispatch<SetStateAction<boolean>>
}

export const ClientsMissingBillingProfileContext =
  createContext<ClientsMissingBillingProfileContextProps>({
    clientsMissingBillingProfile: [],
    missingBillingProfilesAlertOpen: false,
    setMissingBillingProfilesAlertOpen: undefined,
  })

const useClientsMissingBillingProfile = () =>
  useContext(ClientsMissingBillingProfileContext)

export default useClientsMissingBillingProfile
