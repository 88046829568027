import React, { FC, memo } from 'react'
import { Box } from '@mui/material'

import loading from './images/loading.gif'
import list from './images/list.gif'
import chat from './images/chat.gif'
import checkbox from './images/checkbox.gif'
import gears from './images/gears.gif'
import directory from './images/directory.gif'
import chart from './images/chart.gif'
import warning from './images/warning.gif'
import refresh from './images/refresh.gif'

const images = {
  loading,
  list,
  chat,
  checkbox,
  gears,
  directory,
  chart,
  warning,
  refresh,
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// https://icons8.com/animated-icons

const Animation: FC<{
  name:
    | 'loading'
    | 'list'
    | 'chat'
    | 'checkbox'
    | 'gears'
    | 'directory'
    | 'chart'
    | 'warning'
    | 'refresh'
  width: string | number
  height: string | number
  text?: string
  subtext?: JSX.Element | string
}> = ({ name, width, height, text, subtext }): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        marginRight: 'auto',
        backgroundColor: 'inherit',
      }}
    >
      <img
        alt='name'
        style={{
          width,
          height,
        }}
        src={images[name]}
      />
      {text && <div className='text'>{text}</div>}
      {subtext && <div className='subtext'>{subtext}</div>}
    </Box>
  )
}

Animation.defaultProps = {
  text: null,
  subtext: null,
}

export default memo(Animation)
