import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  runInAction,
  toJS,
  reaction,
} from 'mobx'
import moment from 'moment'

import Link from './Link'
import ModelBase from './ModelBase'
import Organization from './Organization'
import RootStore from '../stores/RootStore'
import { generateID } from '../lib/utils'

const toDoItemProperties = [
  'completed',
  'author',
  'description',
  'expiresAt',
  'groups',
  'id',
  'links',
  'name',
  'requireAll',
  'users',
  'chainedTo',
]

export default class TodoItem extends ModelBase {
  org: Organization | undefined = undefined

  constructor(
    rootStore: RootStore,
    toDoItem: {
      links: Link[]
      groups: string[]
      users: string[]
      author: string | undefined
    } = {
      links: [],
      groups: [],
      users: [],
      author: '',
    },
    org: Organization | undefined = undefined
  ) {
    super(rootStore)

    makeObservable(this, {
      completed: observable,
      author: observable,
      description: observable,
      expiresAt: observable,
      id: observable,
      groups: observable,
      links: observable,
      name: observable,
      requireAll: observable,
      users: observable,
      markUserComplete: action,
      markUserIncomplete: action,
      setOrg: action,
      isComplete: computed,
      someComplete: computed,
      canSave: computed,
      data: override,
      markAllComplete: action,
      markAllIncomplete: action,
      userIsAssignee: action,
      chainedTo: observable,
      isDisabledByChained: action,
      chainItem: action,
      getUserStatus: action,
      getCompleteColor: action,
      getMyStatusColor: action,
      handleStoreUpdate: action,
    })

    this.org = org
    this.org_id = this.org?._id
    toDoItemProperties.forEach(prop => {
      if (toDoItem[prop] !== undefined) {
        if (prop === 'links') {
          if (Array.isArray(toDoItem.links)) {
            this.links = toDoItem.links.map(
              link => new Link(link, org && org._id)
            )
          }
        } else if (prop !== 'subItems') {
          this[prop] = toDoItem[prop]
        }
      }
    })
    /**
     * this is used to get a count of completed todos
     */
    reaction(() => this.isComplete, this.handleStoreUpdate)
    reaction(() => this.completed.length, this.handleStoreUpdate)
  }

  completed: { at: number; userId: string }[] = []

  author = ''

  description = ''

  expiresAt = 0

  id = ''

  groups: string[] = []

  links: Link[] = []

  name = ''

  requireAll = true

  users: string[] = []

  chainedTo = ''

  handleStoreUpdate = () => {
    this.rootStore.todoStore.updatedOn = moment().valueOf()
  }

  markUserComplete = async (toDoId: string, userId: string) => {
    if (!this.completed.some(completed => completed.userId === userId)) {
      this.completed.push({ userId, at: Date.now() })
    }

    if (this.org && this.org._id && toDoId) {
      await this.client.todos.updateItemCompleted(
        this.org._id,
        toDoId,
        this.id,
        {
          completed: toJS(this.completed),
        }
      )
    }
    return true
  }

  markAllComplete = async (toDoId: string, userIds: string[]) => {
    userIds.forEach(userId => {
      if (!this.completed.some(completed => completed.userId === userId)) {
        this.completed.push({ userId, at: Date.now() })
      }
    })

    if (this.org && this.org._id && toDoId) {
      await this.client.todos.updateItemCompleted(
        this.org._id,
        toDoId,
        this.id,
        {
          completed: toJS(this.completed),
        }
      )
    }
    return true
  }

  markUserIncomplete = async (toDoId: string, userId: string) => {
    runInAction(() => {
      this.completed = this.completed.filter(
        completed => completed.userId !== userId
      )
    })

    if (this.org && this.org._id && toDoId) {
      await this.client.todos.updateItemCompleted(
        this.org._id,
        toDoId,
        this.id,
        {
          completed: toJS(this.completed),
        }
      )
    }
    return true
  }

  markAllIncomplete = async (toDoId: string) => {
    this.completed = []
    if (this.org && this.org._id && toDoId) {
      await this.client.todos.updateItemCompleted(
        this.org._id,
        toDoId,
        this.id,
        {
          completed: [],
        }
      )
    }
    return true
  }

  setOrg(org: Organization) {
    runInAction(() => {
      this.org = org
      this.org_id = this.org._id
    })
  }

  checkUserComplete = (userId: string): boolean =>
    this.completed.some(complete => complete.userId === userId)

  getUserCompletedAt = (userId: string): number => {
    const completeStatus = this.completed.find(c => c.userId === userId)
    if (completeStatus) {
      return completeStatus.at
    }
    return 0
  }

  hasCompletePermission = (userId: string) => {
    const hasUsers = this.users && this.users.length > 0
    const hasGroups = this.groups && this.groups.length > 0
    if (hasUsers || hasGroups) {
      const inUsers = this.users && this.users.includes(userId)
      const inGroups = this.groups
        ? this.org
          ? this.groups.some(g =>
              this.org.members.some(
                member =>
                  member._id === userId && member.groups.some(mg => mg === g)
              )
            )
          : false
        : false
      if (!inUsers && !inGroups) return null
      return true
    }
    // if they are both empty then everyone is required
    return true
  }

  get isComplete(): boolean {
    if (!this.requireAll) return this.someComplete
    const itemComplete = () => {
      const usersComplete =
        this.users.length > 0 ? this.users.every(this.checkUserComplete) : true
      const groupsComplete =
        this.groups.length > 0
          ? this.groups.every(group => {
              const orgGroup =
                this.org && this.org.groups.find(g => g.id === group)
              return orgGroup && orgGroup.members.every(this.checkUserComplete)
            })
          : true

      // if no groups and no users then is assined to all org members
      if (
        this.completed.length > 0 &&
        !this.users.length &&
        !this.groups.length &&
        this.requireAll &&
        this.org
      ) {
        return this.org.members
          .filter(m => !m.isHostUser && m.orgRole !== 'disabled')
          .map(m => m._id)
          .every(this.checkUserComplete)
      }

      if (this.completed.length > 0) {
        return this.requireAll
          ? this.users.length > 0 || this.groups.length > 0
            ? usersComplete && groupsComplete
            : this.org
            ? this.org.members
                .filter(m => !m.isHostUser && m.orgRole !== 'disabled')
                .map(m => m._id)
                .every(this.checkUserComplete)
            : this.checkUserComplete(this.rootStore?.userStore?._id)
          : usersComplete && groupsComplete
      }
      return false
    }

    return itemComplete()
  }

  get someComplete(): boolean {
    const someItemComplete = (): boolean => {
      return this.completed.length > 0
    }
    return someItemComplete()
  }

  get canSave(): boolean {
    return Boolean(this.name)
  }

  get data() {
    if (!this.id) {
      this.id = generateID()
    }

    return toDoItemProperties.reduce(
      (acc, prop) => {
        if (prop === 'links') {
          acc.links = this.links.map(link => link.data)
        } else {
          acc[prop] = toJS(this[prop])
        }
        return acc
      },
      { links: undefined, completed: [] }
    )
  }

  get allAssinees() {
    if (this.org && this.org.members) {
      const hasUsers = this.users && this.users.length > 0
      const hasGroups = this.groups && this.groups.length > 0
      // remove all host members when all org members are selected
      if (!hasUsers && !hasGroups) {
        return this.org.members
          .filter(m => !m.isHostUser && m.orgRole !== 'disabled')
          .map(user => ({
            _id: user._id,
            name: `${user.fname} ${user.lname}`,
            hasItemComplete: this.checkUserComplete(user._id),
          }))
      }

      let allMembersInGroup: string[] = []
      if (hasGroups) {
        this.groups.forEach(groupId => {
          // check to see if the assigned group is a client group if not check to see if is a host group
          let foundGroup = this.org.groups.find(g => g.id === groupId)
          if (!foundGroup) {
            foundGroup = this.org.hostGroups.find(
              host_g => host_g.id === groupId
            )
          }

          if (foundGroup && foundGroup.members) {
            allMembersInGroup = allMembersInGroup.concat(
              foundGroup.members.slice()
            )
          }
        })
      }

      const uniqueIds: string[] = [
        ...new Set([...allMembersInGroup, ...this.users]),
      ]

      return this.org.members
        .filter(m => uniqueIds.includes(m._id))
        .map(user => ({
          _id: user._id,
          name: `${user.fname} ${user.lname}`,
          hasItemComplete: this.checkUserComplete(user._id),
        }))
    }
    return []
  }

  // returns the name of the blocker if blocked
  isDisabledByChained(items: this[]): boolean | string {
    // the first item should not be disabled
    if (items[0].id === this.id) {
      return false
    }

    if (this.chainedTo) {
      const foundItem = items.find(item => item.id === this.chainedTo)
      return foundItem && !foundItem.isComplete ? foundItem.name : false
    }
    return false
  }

  userIsAssignee = (userId: string) =>
    this.allAssinees.some(u => u._id === userId)

  chainItem = (chainedToId = '') => {
    this.chainedTo = chainedToId
  }

  getUserStatus = (userId: string) => {
    if (
      this.checkUserComplete(userId) &&
      this.allAssinees.some(u => u._id === userId)
    ) {
      return 'Completed'
    }

    if (
      !this.checkUserComplete(userId) &&
      this.allAssinees.some(u => u._id === userId) &&
      this.isComplete
    ) {
      return 'No Action Required'
    }

    if (
      !this.checkUserComplete(userId) &&
      this.allAssinees.some(u => u._id === userId) &&
      !this.isComplete
    ) {
      return 'Pending Completion'
    }

    return 'No Action Required'
  }

  get status(): string {
    return this.isComplete
      ? 'Completed'
      : this.someComplete
      ? 'Partially Complete'
      : 'Incomplete'
  }

  getCompleteColor = (status: string): string => {
    if (status === 'Partially Complete') {
      return 'warning'
    }

    if (status === 'Completed') {
      return 'success'
    }

    return 'error'
  }

  getMyStatusColor = (status: string) => {
    if (status === 'No Action Required') {
      return 'info'
    }

    if (status === 'Not Started') {
      return 'default'
    }

    if (status === 'Completed') {
      return 'success'
    }

    return 'warning'
  }
}
