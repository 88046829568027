import React, { ChangeEventHandler, FC } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { observer } from 'mobx-react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const masks: {
  [key: string]: {
    defaultPlaceholder: string
    mask: (string | RegExp)[]
  }
} = {
  activationCode: {
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    defaultPlaceholder: 'XXX-XXX',
  },
  currency: {
    mask: createNumberMask({
      prefix: '$',
      allowLeadingZeroes: false,
      allowDecimal: true,
    }),
    defaultPlaceholder: 'Order Price',
  },
  date: {
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    defaultPlaceholder: 'MM/DD/YYYY',
  },
  integer: {
    mask: createNumberMask({ prefix: '', includeThousandsSeparator: false }),
    defaultPlaceholder: '0',
  },
  number: {
    mask: createNumberMask({ prefix: '', includeThousandsSeparator: true }),
    defaultPlaceholder: '0',
  },
  phone: {
    mask: [
      '(',
      /[1-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    defaultPlaceholder: '(123) 456-7890',
  },
  ssn: {
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    defaultPlaceholder: 'xxx-xx-xxxx',
  },
  taxId: {
    mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    defaultPlaceholder: 'xx-xxxxxxx',
  },
  time: {
    mask: [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ' ', /a|p/, 'm'],
    defaultPlaceholder: 'HH:MM am/pm',
  },
  zip: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
    defaultPlaceholder: '55555',
  },
}

const sanitizeMask = (mask: string, value: string | number): string => {
  if (mask === 'phone') {
    return value.toString().replace(/\D/g, '')
  }

  if (['number', 'integer', 'currency'].includes(mask)) {
    return value.toString().replace(/[^0-9.]/g, '')
  }

  if (mask === 'zip') {
    return value.toString().replace(/_/g, '')
  }

  return value.toString() || ''
}

const MaskedFormInput: FC<
  {
    autoFocus?: boolean
    dataQa?: string
    disabled?: boolean
    error?: boolean
    helperText?: string | JSX.Element | undefined
    label?: string
    mask?:
      | 'activationCode'
      | 'currency'
      | 'date'
      | 'integer'
      | 'number'
      | 'phone'
      | 'ssn'
      | 'string'
      | 'taxId'
      | 'time'
      | 'zip'
    onChange: ChangeEventHandler<HTMLInputElement>
    placeholder?: string
    required?: boolean
    type?: string
    value?: string | number
  } & TextFieldProps
> = ({
  autoFocus,
  dataQa,
  disabled,
  error,
  helperText,
  label,
  mask,
  onChange,
  placeholder,
  required,
  type,
  value,
  ...rest
}): JSX.Element => (
  <TextField
    {...rest}
    children={
      masks[mask] && mask !== 'string' ? (
        <MaskedInput
          autoFocus={autoFocus}
          mask={masks[mask].mask}
          placeholder={placeholder || masks[mask].defaultPlaceholder}
          value={value || ''}
          disabled={disabled}
          type={type || 'text'}
        />
      ) : (
        <input
          autoFocus={autoFocus}
          placeholder={placeholder || ''}
          disabled={disabled}
          type={type || 'text'}
        />
      )
    }
    data-qa={dataQa}
    error={error}
    fullWidth
    helperText={helperText}
    label={label}
    onChange={e =>
      onChange({
        ...e,
        target: {
          ...e.target,
          name: e?.target?.name || rest?.name || undefined,
          value: sanitizeMask(mask, e.target.value),
        },
      })
    }
    required={Boolean(required)}
    type={type}
    value={value}
  />
)

MaskedFormInput.defaultProps = {
  autoFocus: false,
  dataQa: undefined,
  disabled: false,
  error: false,
  label: undefined,
  helperText: undefined,
  mask: 'string',
  placeholder: undefined,
  required: false,
  type: undefined,
  value: '',
}

export default observer(MaskedFormInput)
