import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { ViewModel } from 'mobx-utils'

import { OrganizationGroupUserSelect } from '../../../Form'

import { useRootStore } from '../../../../services/RootStoreContext'

import File from '../../../../Model/File'
import Folder from '../../../../Model/Folder'

const ManageNodePermissionsForm: FC<{
  disablePermissionsEdit?: boolean
  node: ViewModel<File | Folder>
  trigger: JSX.Element
}> = ({ node, trigger, disablePermissionsEdit }): JSX.Element => {
  const {
    orgStore: {
      currentOrg: { groups, members, isOrgContributor, isOrgAdmin },
    },
    userStore: user,
  } = useRootStore()

  const allGroups = [
    isOrgAdmin ? { id: 'admins', name: 'Administrators' } : undefined,
    ...groups.map(g => ({
      id: g.id,
      name: g.name,
      members: g.members,
      isDeleted: g.isDeleted,
    })),
  ].filter(group => Boolean(group))
  const orgOnlyMembers = members ? members.filter(m => !m.isHostAdmin) : []
  const inheritedGroups = allGroups
    .filter(g => node.inheritedGroups && node.inheritedGroups.includes(g.id))
    .sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
  const inheritedUsers = orgOnlyMembers
    .filter(
      m => node.inheritedUsers && node.inheritedUsers.includes(m._id.toString())
    )
    .sort((a, b) => {
      const aFullName = `${a.fname} ${a.lname}`
      const bFullName = `${b.fname} ${b.lname}`
      if (aFullName < bFullName) return -1
      if (aFullName > bFullName) return 1
      return 0
    })

  const hasInheritedGroups = inheritedGroups && inheritedGroups.length > 0
  const hasInteritedUsers = inheritedUsers && inheritedUsers.length > 0
  const availableMembersFromGroups = orgOnlyMembers.filter(m =>
    hasInheritedGroups
      ? inheritedGroups.some(group => m.groups.includes(group.id))
      : !hasInteritedUsers
  )
  const availableMembersFromUsers = orgOnlyMembers.filter(m =>
    availableMembersFromGroups.includes(m)
      ? false
      : hasInteritedUsers
      ? inheritedUsers.includes(m)
      : !hasInheritedGroups
  )
  const availableMembers = [
    ...availableMembersFromGroups,
    ...availableMembersFromUsers,
  ]
  const availableGroups = allGroups.filter(
    g => g.id !== 'admins' && !inheritedGroups.find(iG => iG.id === g.id)
  )
  const hasOwnPermissions =
    (node.users && node.users.length > 0) ||
    (node.groups && node.groups.length > 0)
  const canEditPermissions =
    !disablePermissionsEdit &&
    (isOrgAdmin ||
      (isOrgContributor &&
        node.creator &&
        node.creator.toString() === user._id.toString()))

  return canEditPermissions ? (
    <OrganizationGroupUserSelect
      endIcon={false}
      user={user}
      groups={availableGroups}
      item={node}
      members={availableMembers}
      inheritedGroups={
        hasOwnPermissions ? undefined : inheritedGroups.map(iG => iG.id)
      }
      inheritedUsers={hasOwnPermissions ? undefined : inheritedUsers}
      trigger={trigger}
      hideRenderList
      allowSave
    />
  ) : null
}

ManageNodePermissionsForm.defaultProps = {
  disablePermissionsEdit: false,
}

export default observer(ManageNodePermissionsForm)
