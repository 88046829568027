import APIResponseType from './APIResponseType'

export const createGuest = (data: object): APIResponseType => ({
  url: '/orgs/v1/guests',
  method: 'POST',
  data,
})

export const getGuests = (): APIResponseType => ({
  url: '/orgs/v1/guests',
  method: 'GET',
  data: {},
})

export const getGuest = (id: string): APIResponseType => ({
  url: `/orgs/v1/guests/${id}`,
  method: 'GET',
  data: {},
})

export const getGuestMeeting = (
  org_id: string,
  id: string,
  guestId: string
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/meetings/${id}/guests/${guestId}`,
  method: 'GET',
})
