import {
  observable,
  action,
  computed,
  reaction,
  toJS,
  makeObservable,
  override,
} from 'mobx'
import * as yup from 'yup'

import RootStore from './RootStore'

import Guest from '../Model/Guest'
import ModelBase from '../Model/ModelBase'

import { getDeviceHash } from '../lib/deviceInfo'
import { useLog } from '../lib/log'

const log = useLog()
export const userProfileProps = [
  'additional_info',
  'company',
  'company_url',
  'devices',
  'device_hashes',
  'emails',
  'fname',
  'linkedin_url',
  'lname',
  'receive_all_child_org_alerts',
  'organizations',
  'phone',
  'photo',
  'notifications_disabled',
  'sso',
  'lastDismissedAlerts',
  'userDigestEmailsDisabled',
]

export default class UserStore extends ModelBase {
  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this, {
      _id: observable,
      guest: observable,
      fname: observable,
      lname: observable,
      email: observable,
      emails: observable,
      phone: observable,
      signupOrg: observable,
      company: observable,
      company_url: observable,
      linkedin_url: observable,
      receive_all_child_org_alerts: observable,
      additional_info: observable,
      photo: observable,
      sso: observable,
      lastDismissedAlerts: observable,
      profileSaving: observable,
      profileError: observable,
      profileSuccess: observable,
      profileMsg: observable,
      notifications_disabled: observable,
      device_hashes: observable,
      devices: observable,
      validationErrors: observable,
      initials: computed,
      getKnownDevices: action,
      data: override,
      handleChangeUserPasswordSubmit: action,
      saveUserSuccess: observable,
      saveUserError: observable,
      handleUseGoogleSSOsuccess: action,
      ssoError: observable,
      ssoWarning: observable,
      ssoSuccess: observable,
      googleDisabled: observable,
      appleDisabled: observable,
      changePasswordDisabledMessage: computed,
      formData: override,
      validationSchema: override,
      userDigestEmailsDisabled: observable,
      getAuthedUserData: action,
      isNewUserSignup: observable,
    })

    this.guest = new Guest(this.rootStore, localStorage.getItem('guestId'))
    this.isNewUserSignup = Boolean(localStorage.getItem('newSignup'))

    // the id wont become available until the token is processed
    reaction(
      () => this._id,
      () => this.getKnownDevices()
    )
  }

  _id: string | undefined = undefined

  fname = ''

  lname = ''

  role = 'user'

  email = localStorage.getItem('inviteEmail') || ''

  emails: string[] =
    localStorage.getItem('inviteEmail') !== null
      ? [localStorage.getItem('inviteEmail')]
      : []

  phone = ''

  signupOrg = localStorage.getItem('inviteOrg') || ''

  isNewUserSignup = false

  company = ''

  company_url = ''

  linkedin_url = ''

  receive_all_child_org_alerts = false

  additional_info = ''

  photo = ''

  lastDismissedAlerts: number | undefined = undefined

  profileSaving = false

  profileError = false

  profileSuccess = false

  profileMsg: string | undefined = undefined

  notifications_disabled: string[] = []

  device_hashes: string[] = []

  devices: {
    platform: { value: string }
    vendor: { value: string }
    timezone: { value: string }
    ipData: {
      address: string
      city: string
      state: string
      country: string
    }
    hash: string
    userAgent: string
    updatedAt: number
  }[] = []

  validationErrors: object = {}

  saveUserSuccess: string | undefined = undefined

  saveUserError: string | undefined = undefined

  sso: {
    google?: string
    apple?: string
  } = {}

  ssoError: string | undefined = undefined

  ssoSuccess: string | undefined = undefined

  ssoWarning: string | undefined = undefined

  googleDisabled = false

  appleDisabled = false

  guest: Guest = undefined

  userDigestEmailsDisabled = false

  get data() {
    return userProfileProps.reduce(
      (acc, prop) => {
        acc[prop] = toJS(this[prop])
        return acc
      },
      {
        _id: toJS(this._id),
        devices: toJS(this.devices),
        notifications_disabled: toJS(this.notifications_disabled) || [],
      }
    )
  }

  get changePasswordDisabledMessage(): string {
    if (this.sso && this.sso.google) {
      return 'User is using Google authentication'
    }
    if (this.sso && this.sso.google) {
      return 'User is using Apple authentication'
    }
    return ''
  }

  reset = () => {
    this._id = ''
    this.fname = ''
    this.lname = ''
    this.role = 'user'
    this.email = localStorage.getItem('inviteEmail') || ''
    this.emails =
      localStorage.getItem('inviteEmail') !== null
        ? [localStorage.getItem('inviteEmail')]
        : []
    this.phone = ''
    this.isNewUserSignup = Boolean(localStorage.getItem('newSignup'))
    this.company = ''
    this.company_url = ''
    this.linkedin_url = ''
    this.receive_all_child_org_alerts = false
    this.additional_info = ''
    this.photo = ''
    this.lastDismissedAlerts = undefined
    this.profileSaving = false
    this.profileError = false
    this.profileSuccess = false
    this.profileMsg = undefined
    this.notifications_disabled = []
    this.device_hashes = []
    this.devices = []
    this.userDigestEmailsDisabled = false
  }

  loadUserData = (userData: typeof this) => {
    this.reset()

    if (userData) {
      Object.keys(userData).forEach(key => {
        this[key] = userData[key]
      })
    }

    this.isNewUserSignup = Boolean(localStorage.getItem('newSignup'))
  }

  getAuthedUserData = async (_id: string = this._id) => {
    try {
      const res = await this.rootStore.client.user.getUser(_id)
      localStorage.setItem('currentUserId', _id)
      this.email = res.data.emails[0]
      localStorage.setItem('email', this.email)
      this.loadUserData(res.data)
      return Promise.resolve(res.data)
    } catch (e) {
      await this.rootStore.authStore.logout()
      return Promise.reject(new Error(e))
    }
  }

  get initials() {
    return `${this.fname.substring(0, 1)} ${this.lname.substring(0, 1)}`
  }

  /**
   * used to change the user from regular loging
   * to google user account
   *  */
  handleUseGoogleSSOsuccess = async res => {
    try {
      const { email } = res.profileObj
      if (!this.emails.includes(email)) {
        this.emails.unshift(email)
        if (!this.sso) {
          this.sso = {}
        }

        if (!this.sso.google) {
          this.sso.google = email
        }
        await this.save()
        this.email = email
      }
      localStorage.setItem('email', res.profileObj.email)
      this.ssoSuccess = `You can login using your Google account '${res.profileObj.email}'`

      // New user signup
      if (localStorage.getItem('newSignup')) {
        // Let the set timeout up top handle redirect / etc
        this.ssoSuccess = true
      }
    } catch (e) {
      this.ssoError = e.message
      this.googleDisabled = true
    }
  }

  handleUseAppleSSOsuccess = async res => {
    try {
      const { authorization } = res
      let email = res.user ? res.user.email : false
      const tokenId = authorization ? authorization.id_token : false

      if (!email && tokenId) {
        const decoded = await this.rootStore.authStore.decodeAppleToken(tokenId)
        email = decoded && decoded.email
      }
      if (email) {
        if (!this.emails.includes(email)) {
          this.emails.unshift(email)
        }
        if (!this.sso) {
          this.sso = {}
        }
        if (!this.sso.apple) {
          this.sso.apple = email
        }
        this.save()
        this.email = email
        localStorage.setItem('email', email)
        this.ssoSuccess = 'You can login using your Apple account'
      } else {
        this.ssoError =
          'Unable to use your Apple account. Please select another option'
        this.appleDisabled = true
      }
    } catch (e) {
      this.ssoError = e.message
      this.appleDisabled = true
    }
  }

  handleChangeUserPasswordSubmit = async (
    values: { password: string },
    actions: { setSubmitting: (val: boolean) => void }
  ) => {
    await this.save(values.password)
    actions.setSubmitting(false)
  }

  getKnownDevices = async () => {
    try {
      if (this._id) {
        const { data } = await this.rootStore.client.user.getDevices(
          this._id.toString()
        )
        this.devices = data
        this.device_hashes = this.devices.map(device => device.hash)
      }
    } catch (e) {
      //
    }
  }

  updateProfile = async (userState: typeof this) => {
    // Convert from mobx to standard JS object
    const data = userProfileProps.reduce(
      (acc, prop) => {
        acc[prop] = toJS(userState[prop])
        return acc
      },
      { _id: undefined }
    )
    data._id = userState._id

    delete data.devices
    delete data.organizations

    try {
      const { data: updateRes } = await this.rootStore.client.user.update(data)
      // If updating own profile
      if (this._id === userState._id) {
        const response = await this.rootStore.client.user.reissueToken()
        this.rootStore.commonStore.parseJWT(response.data)
      }

      log.code('usr005', {})

      return updateRes
    } catch (e) {
      let errorMessage = 'Error saving profile information'

      // Handle prop validation errors
      if (e.response.status === 400 && Array.isArray(e.response.data)) {
        errorMessage =
          'Please make sure all fields are filled out correctly and emails are valid'
        log.code('usr204', { error: e.response })
      } else if (
        e.response.status === 409 &&
        typeof e.response.data === 'string' &&
        e.response.data.startsWith('Email already in use')
      ) {
        errorMessage = e.response.data
        log.code('usr204', { error: e.response })
      } else {
        log.code('usr304', { error: e.response })
      }

      throw new Error(errorMessage)
    }
  }

  get validationSchema(): yup.Schema<object> {
    return yup.object().shape({
      phone: yup.string().matches(/^[0-9]\d{9}$/, {
        message: 'Please enter valid number. ex:4679876535',
        excludeEmptyString: false,
      }),
      fname: yup.string().required(),
      lname: yup.string().required(),
    })
  }

  save = async (newPassword?: string) => {
    const data = userProfileProps.reduce(
      (acc, prop) => {
        acc[prop] = toJS(this[prop])
        return acc
      },
      { _id: undefined, password: undefined, device_hashes: undefined }
    )
    data._id = this._id
    const changingPassword = newPassword

    if (changingPassword) {
      data.password = newPassword
      data.device_hashes = [await getDeviceHash()]
    } else {
      delete data.password
      delete data.device_hashes
    }

    delete data.devices
    delete data.organizations
    this.saveUserSuccess = 'Changes saved successfully'
    try {
      const res = await this.rootStore.client.user.update(data)
      if (changingPassword) {
        this.device_hashes = res.data.device_hashes
        this.devices = res.data.devices
      }
    } catch (e) {
      let errorMessage = 'Could not save, please contact support'

      if (
        e.response.status === 409 &&
        typeof e.response.data === 'string' &&
        e.response.data.startsWith('Email already in use')
      ) {
        errorMessage = e.response.data
      }
      // Handle prop validation errors
      if (e.response.status === 400 && Array.isArray(e.response.data)) {
        errorMessage = 'Please make sure all fields are filled out correctly'
      }

      throw new Error(errorMessage)
    }
  }
}
