import React, {
  forwardRef,
  ForwardRefRenderFunction,
  MouseEvent,
  useCallback,
} from 'react'
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListProps,
} from '@mui/material'

import { GetWrappedIcon } from '../Icons'

import { SelectOptionType } from './types'

const ClientMultiSelectListBox: ForwardRefRenderFunction<
  HTMLUListElement,
  ListProps & {
    children: JSX.Element[]
    currentValue: string[]
    handleChange: (newVals: string[]) => void
    options: SelectOptionType[]
  }
> = ({ children, currentValue, handleChange, options, ...rest }, ref) => {
  const currentSearchNames = (children || [])
    .map(child => child.key)
    .filter(Boolean)
  const valuesToAdd = options
    .filter(
      option =>
        !currentValue.includes(option.value) &&
        currentSearchNames.includes(option.label)
    )
    .map(option => option.value)
  const handleSelectAllClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()

      handleChange([...currentValue, ...valuesToAdd])
    },
    [currentValue, valuesToAdd]
  )

  return (
    <List {...rest} ref={ref}>
      {valuesToAdd.length > 0 ? (
        <ListItemButton onClick={handleSelectAllClick}>
          <ListItemIcon sx={{ alignItems: 'center' }}>
            <GetWrappedIcon name='checkboxSelected' />
          </ListItemIcon>
          <ListItemText primary={`Select All (${valuesToAdd?.length || 0})`} />
        </ListItemButton>
      ) : null}
      {children}
    </List>
  )
}

export default forwardRef(ClientMultiSelectListBox)
