import React, { cloneElement, FC, Fragment, useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import CreateDiscussionPanel from '../../pages/Messages/components/CreateDiscussionPanel'

import ConfirmNotificationOnSave from '../ConfirmNotificationOnSave/ConfirmNotificationOnSave'
import { SaveButton } from '../Buttons'
import { FormDrawer, FormDrawerContent, FormDrawerHeader } from '../Form'

import { useRootStore } from '../../services/RootStoreContext'

const DiscussionModal: FC<{
  children: JSX.Element
  groups?: string[]
  roles?: string[]
  topic?: string
  users?: string[]
  text?: string
}> = ({ children, groups, topic, users, text, roles }): JSX.Element => {
  const navigate = useNavigate()
  const { addNotificationItem, discussionStore, userStore } = useRootStore()
  const [isOpen, setIsOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [discussion, setDiscussion] = useState(
    discussionStore.getDiscussionModel({ topic, users, groups })
  )
  const [message, setMessage] = useState(discussion.getMessageModel({ text }))

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])
  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const saveMessage = async (notify: boolean) => {
    try {
      if (!discussion.hasPermissions) {
        throw new Error('You must select Recipient(s)')
      }

      setIsSaving(true)

      await discussion.addMessage(message, userStore._id, notify)

      if (discussion) {
        navigate(discussion.permalink)
      }

      const newDiscussion = discussionStore.getDiscussionModel({
        topic,
        users,
        groups,
      })
      setDiscussion(newDiscussion)
      setMessage(discussion.getMessageModel({}))
      handleClose()
    } catch (e) {
      addNotificationItem({
        error: true,
        message: e.message,
      })
    }
    setIsSaving(false)
  }
  return (
    <Fragment>
      {cloneElement(children, {
        onClick: handleOpen,
      })}
      <FormDrawer isOpen={isOpen} onClose={handleClose}>
        <FormDrawerHeader title='Create Message' onClose={handleClose}>
          <ConfirmNotificationOnSave
            disabled={isSaving}
            usersState={discussion.users}
            groupsState={discussion.groups}
            item={discussion}
            onConfirm={saveMessage}
          >
            <SaveButton
              data-qa='discussion-button-save'
              disabled={
                !discussion.hasPermissions || !discussion.topic || !message.text
              }
              isSaving={isSaving}
            />
          </ConfirmNotificationOnSave>
        </FormDrawerHeader>
        <FormDrawerContent>
          <CreateDiscussionPanel
            discussion={discussion}
            message={message}
            roles={roles}
          />
        </FormDrawerContent>
      </FormDrawer>
    </Fragment>
  )
}

DiscussionModal.defaultProps = {
  groups: [],
  roles: [],
  topic: '',
  text: '',
  users: [],
}

export default observer(DiscussionModal)
