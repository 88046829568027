import APIResponseType from './APIResponseType'

export const getPolls = (org_id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/polls`,
  method: 'GET',
})

export const savePoll = (
  data: { org_id: string },
  id?: string,
  sendNotification = false
): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/polls${id ? `/${id}` : ''}${
    sendNotification ? '?notify=true' : ''
  }`,
  method: id ? 'PATCH' : 'POST',
  data,
})

export const toggleArchived = (
  orgId: string,
  pollId: string
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/polls/${pollId}/archive`,
  method: 'PATCH',
})

export const deletePoll = (data: {
  org_id: string
  _id: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/polls/${data._id}`,
  method: 'DELETE',
})

export const answerPoll = (
  data: object,
  org_id: string,
  poll_id: string
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/polls/${poll_id}/answer`,
  method: 'PATCH',
  data,
})
