export default [
  {
    label: 'AL - Alabama',
    value: 'AL',
    key: 'AL',
  },
  {
    label: 'AK - Alaska',
    value: 'AK',
    key: 'AK',
  },
  {
    label: 'AS - American Samoa',
    value: 'AS',
    key: 'AS',
  },
  {
    label: 'AZ - Arizona',
    value: 'AZ',
    key: 'AZ',
  },
  {
    label: 'AR - Arkansas',
    value: 'AR',
    key: 'AR',
  },
  {
    label: 'CA - California',
    value: 'CA',
    key: 'CA',
  },
  {
    label: 'CO - Colorado',
    value: 'CO',
    key: 'CO',
  },
  {
    label: 'CT - Connecticut',
    value: 'CT',
    key: 'CT',
  },
  {
    label: 'DE - Delaware',
    value: 'DE',
    key: 'DE',
  },
  {
    label: 'DC - District Of Columbia',
    value: 'DC',
    key: 'DC',
  },
  {
    label: 'FL - Florida',
    value: 'FL',
    key: 'FL',
  },
  {
    label: 'GA - Georgia',
    value: 'GA',
    key: 'GA',
  },
  {
    label: 'HI - Hawaii',
    value: 'HI',
    key: 'HI',
  },
  {
    label: 'ID - Idaho',
    value: 'ID',
    key: 'ID',
  },
  {
    label: 'IL - Illinois',
    value: 'IL',
    key: 'IL',
  },
  {
    label: 'IN - Indiana',
    value: 'IN',
    key: 'IN',
  },
  {
    label: 'IA - Iowa',
    value: 'IA',
    key: 'IA',
  },
  {
    label: 'KS - Kansas',
    value: 'KS',
    key: 'KS',
  },
  {
    label: 'KY - Kentucky',
    value: 'KY',
    key: 'KY',
  },
  {
    label: 'LA - Louisiana',
    value: 'LA',
    key: 'LA',
  },
  {
    label: 'ME - Maine',
    value: 'ME',
    key: 'ME',
  },
  {
    label: 'MD - Maryland',
    value: 'MD',
    key: 'MD',
  },
  {
    label: 'MA - Massachusetts',
    value: 'MA',
    key: 'MA',
  },
  {
    label: 'MI - Michigan',
    value: 'MI',
    key: 'MI',
  },
  {
    label: 'MN - Minnesota',
    value: 'MN',
    key: 'MN',
  },
  {
    label: 'MS - Mississippi',
    value: 'MS',
    key: 'MS',
  },
  {
    label: 'MO - Missouri',
    value: 'MO',
    key: 'MO',
  },
  {
    label: 'MT - Montana',
    value: 'MT',
    key: 'MT',
  },
  {
    label: 'NE - Nebraska',
    value: 'NE',
    key: 'NE',
  },
  {
    label: 'NV - Nevada',
    value: 'NV',
    key: 'NV',
  },
  {
    label: 'NH - New Hampshire',
    value: 'NH',
    key: 'NH',
  },
  {
    label: 'NJ - New Jersey',
    value: 'NJ',
    key: 'NJ',
  },
  {
    label: 'NM - New Mexico',
    value: 'NM',
    key: 'NM',
  },
  {
    label: 'NY - New York',
    value: 'NY',
    key: 'NY',
  },
  {
    label: 'NC - North Carolina',
    value: 'NC',
    key: 'NC',
  },
  {
    label: 'ND - North Dakota',
    value: 'ND',
    key: 'ND',
  },
  {
    label: 'OH - Ohio',
    value: 'OH',
    key: 'OH',
  },
  {
    label: 'OK - Oklahoma',
    value: 'OK',
    key: 'OK',
  },
  {
    label: 'OR - Oregon',
    value: 'OR',
    key: 'OR',
  },
  {
    label: 'PA - Pennsylvania',
    value: 'PA',
    key: 'PA',
  },
  {
    label: 'PR - Puerto Rico',
    value: 'PR',
    key: 'PR',
  },
  {
    label: 'RI - Rhode Island',
    value: 'RI',
    key: 'RI',
  },
  {
    label: 'SC - South Carolina',
    value: 'SC',
    key: 'SC',
  },
  {
    label: 'SD - South Dakota',
    value: 'SD',
    key: 'SD',
  },
  {
    label: 'TN - Tennessee',
    value: 'TN',
    key: 'TN',
  },
  {
    label: 'TX - Texas',
    value: 'TX',
    key: 'TX',
  },
  {
    label: 'UT - Utah',
    value: 'UT',
    key: 'UT',
  },
  {
    label: 'VT - Vermont',
    value: 'VT',
    key: 'VT',
  },
  {
    label: 'VA - Virginia',
    value: 'VA',
    key: 'VA',
  },
  {
    label: 'WA - Washington',
    value: 'WA',
    key: 'WA',
  },
  {
    label: 'WV - West Virginia',
    value: 'WV',
    key: 'WV',
  },
  {
    label: 'WI - Wisconsin',
    value: 'WI',
    key: 'WI',
  },
  {
    label: 'WY - Wyoming',
    value: 'WY',
    key: 'WY',
  },
  {
    label: 'AB - Alberta',
    value: 'AB',
    key: 'AB',
  },
  {
    label: 'BC - British Columbia',
    value: 'BC',
    key: 'BC',
  },
  {
    label: 'MB - Manitoba',
    value: 'MB',
    key: 'MB',
  },
  {
    label: 'NB - New Brunswick',
    value: 'NB',
    key: 'NB',
  },
  {
    label: 'NL - Newfoundland and Labrador',
    value: 'NL',
    key: 'NL',
  },
  {
    label: 'NS - Nova Scotia',
    value: 'NS',
    key: 'NS',
  },
  {
    label: 'NT - Northwest Territories',
    value: 'NT',
    key: 'NT',
  },
  {
    label: 'NU - Nunavut',
    value: 'NU',
    key: 'NU',
  },
  {
    label: 'ON - Ontario',
    value: 'ON',
    key: 'ON',
  },
  {
    label: 'PE - Prince Edward Island',
    value: 'PE',
    key: 'PE',
  },
  {
    label: 'QC - Québec',
    value: 'QC',
    key: 'QC',
  },
  {
    label: 'SK - Saskatchewan',
    value: 'SK',
    key: 'SK',
  },
  {
    label: 'YT - Yukon',
    value: 'YT',
    key: 'YT',
  },
]
