/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */

import './wdyr'
import React, { lazy } from 'react'
import { createHashRouter, Navigate, Outlet } from 'react-router-dom'

import AuthLayout from './layouts/Authenticated'
import ErrorPage from './components/ErrorPage'
import { TwoPanelView } from './layouts/TwoPanelView'

import InvestmentBilling from './pages/InvestmentBilling'

const Page = lazy(() => import('./layouts/Page'))
const EventBody = lazy(() => import('./pages/Events/EventBody'))
const Calendar = lazy(() => import('./components/Calendar/Calendar'))
const ContentBody = lazy(() => import('./pages/Content/ContentBody'))
const DiscussionPanelDetails = lazy(
  () => import('./pages/Messages/components/DiscussionPanelDetails')
)
const AssistanceContainer = lazy(
  () => import('./pages/Assistance/AssistanceContainer')
)
const PollBody = lazy(() => import('./pages/Polls/PollBody'))
const PublicRoute = lazy(() => import('./components/PublicRoute'))
const SecureRoute = lazy(() => import('./components/SecureRoute'))
const Analytics = lazy(() => import('./pages/Analytics'))

const CampaignView = lazy(() => import('./pages/CampaignView/CampaignView'))
const ContentContainer = lazy(() => import('./pages/Content/ContentContainer'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const Directory = lazy(() => import('./pages/Directory/Directory'))
const Documents = lazy(() => import('./pages/Documents'))
const Investments = lazy(() => import('./pages/Investments/Investments'))
const JoinOrganization = lazy(
  () => import('./pages/JoinOrganization/JoinOrganization')
)
const Login = lazy(() => import('./pages/Login/Login'))
const Messages = lazy(() => import('./pages/Messages'))
const NotificationCenter = lazy(() => import('./pages/NotificationCenter'))
const OrgFormsContainer = lazy(() => import('./pages/OrgForms'))
const Outreach = lazy(() => import('./pages/Outreach/Outreach'))
const OutreachCampaigns = lazy(
  () => import('./pages/Outreach/components/Campaigns/List')
)
const OutreachContacts = lazy(
  () => import('./pages/Outreach/components/Contacts/List')
)
const OutreachLists = lazy(
  () => import('./pages/Outreach/components/Lists/List')
)
const OutreachSettings = lazy(
  () => import('./pages/Outreach/components/Settings/Settings')
)
const PollsContainer = lazy(() => import('./pages/Polls/PollsContainer'))
const PublicItem = lazy(() => import('./pages/PublicItem/PublicItem'))
const Settings = lazy(() => import('./pages/Settings/Settings'))
const Tags = lazy(() => import('./pages/Tags'))
const Todos = lazy(() => import('./pages/Todos/Todos'))
const Clients = lazy(() => import('./pages/ClientManagement'))
const Assistance = lazy(() => import('./pages/Assistance'))

const InvestmentBillingFeeSchedules = lazy(
  () => import('./pages/InvestmentBilling/FeeSchedules')
)
const InvestmentBillingBillingProfiles = lazy(
  () => import('./pages/InvestmentBilling/BillingProfiles')
)
const InvestmentBillingInvoices = lazy(
  () => import('./pages/InvestmentBilling/Invoices')
)

const ClientsList = lazy(
  () => import('./pages/ClientManagement/components/Clients')
)
const Configurations = lazy(
  () => import('./pages/ClientManagement/components/Configurations')
)
const InvestmentReportSettings = lazy(
  () => import('./pages/ClientManagement/components/InvestmentReportSettings')
)
const RelationshipManagement = lazy(
  () => import('./pages/ClientManagement/components/RelationshipManagement')
)
const ContactManagement = lazy(
  () => import('./pages/ClientManagement/components/ContactManagement')
)

export const ParentOutlet = () => {
  return <Outlet />
}

const router = createHashRouter([
  {
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'login',
        element: <PublicRoute component={Login} title='Login' />,
      },
      {
        path: 'join-organization',
        element: <PublicRoute component={JoinOrganization} title='Join' />,
      },
      {
        path: 'psi',
        element: <ParentOutlet />,
        children: [
          {
            path: ':id',
            element: <PublicRoute component={PublicItem} title='Shared Item' />,
          },
          {
            index: true,
            element: <PublicRoute component={PublicItem} title='Shared Item' />,
          },
        ],
      },
      {
        path: 'campaigns',
        element: <ParentOutlet />,
        children: [
          {
            path: ':orgid',
            element: <PublicRoute component={CampaignView} title='Campaign' />,
            children: [
              {
                path: ':id',
                element: (
                  <PublicRoute component={CampaignView} title='Campaign' />
                ),
              },
            ],
          },
          {
            index: true,
            element: <PublicRoute component={CampaignView} title='Campaign' />,
          },
        ],
      },
      {
        path: 'dashboard',
        element: <SecureRoute component={Dashboard} title='Dashboard' />,
      },
      {
        path: 'directory',
        element: <SecureRoute component={Directory} title='Directory' />,
        children: [
          {
            path: ':type',
            element: <SecureRoute component={Directory} title='Directory' />,
            children: [
              {
                path: ':id',
                element: (
                  <SecureRoute component={Directory} title='Directory' />
                ),
              },
            ],
          },
          {
            index: true,
            element: <SecureRoute component={Directory} title='Directory' />,
          },
        ],
      },
      {
        path: 'settings',
        element: <ParentOutlet />,
        children: [
          {
            path: ':view',
            element: <SecureRoute component={Settings} title='Settings' />,
            children: [
              {
                path: 'my-profile',
                element: <SecureRoute component={Settings} title='Settings' />,
              },
              {
                path: 'password',
                element: <SecureRoute component={Settings} title='Settings' />,
              },
              {
                path: 'organization-profile',
                element: <SecureRoute component={Settings} title='Settings' />,
              },
            ],
          },
          {
            index: true,
            element: <SecureRoute component={Settings} title='Settings' />,
          },
        ],
      },
      {
        path: 'investments',
        element: (
          <SecureRoute component={Investments} title='Investment Reports' />
        ),
        children: [
          {
            element: (
              <SecureRoute component={Investments} title='Investment Reports' />
            ),
            path: 'reports',
          },
          {
            element: (
              <SecureRoute
                component={Investments}
                title='Investment Invoices'
              />
            ),
            path: 'invoices',
          },
        ],
      },
      {
        path: 'analytics',
        element: <ParentOutlet />,
        children: [
          {
            path: 'reports',
            element: <SecureRoute component={Analytics} title='Analytics' />,
          },
          {
            path: 'logs',
            element: <SecureRoute component={Analytics} title='Analytics' />,
          },
          {
            index: true,
            element: <SecureRoute component={Analytics} title='Analytics' />,
          },
        ],
      },
      {
        path: 'outreach',
        element: <SecureRoute component={Outreach} title='Outreach' />,
        children: [
          {
            path: 'campaigns',
            element: <OutreachCampaigns />,
          },
          {
            path: 'contacts',
            element: <OutreachContacts />,
          },
          {
            path: 'lists',
            element: <OutreachLists />,
          },
          {
            path: 'settings',
            element: <OutreachSettings />,
          },
          {
            index: true,
            element: <OutreachCampaigns />,
          },
        ],
      },
      {
        path: 'forms',
        element: <SecureRoute component={OrgFormsContainer} title='Forms' />,
      },
      {
        path: 'tags',
        element: <SecureRoute component={Tags} title='Tags' />,
      },
      {
        path: 'todos',
        element: <SecureRoute component={Todos} title='To-Dos' />,
        children: [
          {
            path: ':id',
            element: <SecureRoute component={Todos} title='To-Dos' />,
          },
        ],
      },
      {
        path: 'posts',
        element: <SecureRoute component={ParentOutlet} title='Posts' />,
        children: [
          {
            index: true,
            element: <TwoPanelView left={<ContentContainer />} />,
          },
          {
            path: ':id',
            element: (
              <TwoPanelView
                left={<ContentContainer />}
                right={<ContentBody />}
              />
            ),
          },
        ],
      },
      {
        path: 'events',
        element: <SecureRoute component={ParentOutlet} title='Events' />,
        children: [
          {
            index: true,
            element: (
              <Page>
                <Calendar />
              </Page>
            ),
          },
          {
            path: ':id',
            element: (
              <Page>
                <EventBody />
              </Page>
            ),
          },
        ],
      },
      {
        path: 'news-updates',
        element: (
          <SecureRoute component={ParentOutlet} title='News &amp; Updates' />
        ),
        children: [
          {
            index: true,
            element: <TwoPanelView left={<ContentContainer />} />,
          },
          {
            path: ':id',
            element: (
              <TwoPanelView
                left={<ContentContainer />}
                right={<ContentBody />}
              />
            ),
          },
        ],
      },
      {
        path: 'polls',
        element: <SecureRoute component={ParentOutlet} title='Polls' />,
        children: [
          {
            index: true,
            element: <TwoPanelView left={<PollsContainer />} />,
          },
          {
            path: ':id',
            element: (
              <TwoPanelView left={<PollsContainer />} right={<PollBody />} />
            ),
          },
        ],
      },
      {
        path: 'messages',
        element: <SecureRoute component={ParentOutlet} title='Messages' />,
        children: [
          {
            index: true,
            element: (
              <TwoPanelView
                left={<Messages />}
                right={<AssistanceContainer />}
              />
            ),
          },
          {
            path: ':id',
            element: (
              <TwoPanelView
                left={<Messages />}
                right={<DiscussionPanelDetails />}
                hideScrollBar
              />
            ),
          },
        ],
      },
      {
        path: 'assistance',
        element: <SecureRoute component={Assistance} title='Assistance' />,
      },
      {
        path: 'client-management',
        element: <Clients />,
        children: [
          {
            path: 'clients',
            element: (
              <SecureRoute component={ClientsList} title='Client Management' />
            ),
          },
          {
            path: 'configurations',
            element: (
              <SecureRoute
                component={Configurations}
                title='Client Management'
              />
            ),
          },
          {
            path: 'investment-report-settings',
            element: (
              <SecureRoute
                component={InvestmentReportSettings}
                title='Client Management'
              />
            ),
          },
          {
            path: 'relationship-management',
            element: (
              <SecureRoute
                component={RelationshipManagement}
                title='Client Management'
              />
            ),
          },
          {
            path: 'contact-management',
            element: (
              <SecureRoute
                component={ContactManagement}
                title='Client Management'
              />
            ),
          },
          {
            index: true,
            element: <Navigate replace to='/client-management/clients' />,
          },
        ],
      },
      {
        path: 'investment-billing',
        element: <InvestmentBilling />,
        children: [
          {
            path: 'fee-schedules',
            element: (
              <SecureRoute
                component={InvestmentBillingFeeSchedules}
                title='Investment Billing Fee Schedules'
              />
            ),
          },
          {
            path: 'profiles',
            element: (
              <SecureRoute
                component={InvestmentBillingBillingProfiles}
                title='Investment Billing Profiles'
              />
            ),
          },
          {
            path: 'invoices',
            element: (
              <SecureRoute
                component={InvestmentBillingInvoices}
                title='Investment Billing Invoices'
              />
            ),
          },
        ],
      },
      {
        path: 'notification-center',
        element: (
          <SecureRoute
            component={NotificationCenter}
            title='Notification Center'
          />
        ),
      },
      {
        path: 'documents',
        element: <SecureRoute component={Documents} title='Documents' />,
        children: [
          {
            path: ':docSlug',
            element: <SecureRoute component={Documents} title='Documents' />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate replace to='/dashboard' />,
  },
])

export default router
