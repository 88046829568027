import { makeObservable, observable, override } from 'mobx'

import Link from './Link'
import ModelBase from './ModelBase'

import RootStore from '../stores/RootStore'

import { LinkProps } from '../types'

export default class MinuteLinks extends ModelBase {
  event_id = ''

  constructor(
    rootStore: RootStore,
    data: { links?: LinkProps[]; event_id: string; org_id: string }
  ) {
    super(rootStore)

    makeObservable(this, {
      data: override,
      links: observable,
    })

    this.event_id = data.event_id
    this.org_id = data.org_id
    this.links = observable.array(
      data.links.map(link => new Link(link, this.org_id))
    )
  }

  get data() {
    return this.links.map(link => link.data)
  }

  getNotifyId = () => this.event_id
}
