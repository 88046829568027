import {
  action,
  IObservableArray,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx'

import Link from './Link'
import ModelBase from './ModelBase'

import RootStore from '../stores/RootStore'
import { useLog } from '../lib/log'
import { generateID } from '../lib/utils'
import { DiscussionMessageProps, LinkProps } from '../types'

const log = useLog()

const messageProps = ['id', 'user_id', 'timestamp', 'text', 'readBy']

export default class DiscussionMessage extends ModelBase {
  constructor(
    rootStore: RootStore,
    message: DiscussionMessageProps,
    org_id: string,
    discussion_id: string
  ) {
    super(rootStore)
    makeObservable(this, {
      org_id: observable,
      discussion_id: observable,
      id: observable,
      user_id: observable,
      timestamp: observable,
      text: observable,
      links: observable,
      readBy: observable,
      data: override,
      messageSaving: observable,
      messageSaveSuccess: observable,
      messageSaveError: observable,
      save: action,
      delete: action,
    })

    const links = observable.array(
      message.links ? message.links.map(link => new Link(link, org_id)) : []
    )
    this.links = links
    this.discussion_id = discussion_id
    this.id = message.id || generateID()
    this.org_id = org_id || this.rootStore.orgStore.currentOrg._id
    this.text = message.text || ''
    this.user_id = message.user_id || this.rootStore.userStore._id
    this.timestamp = message.timestamp || Date.now()
    this.readBy = observable.array(message.readBy || [])
  }

  modelCollection = 'discussions'

  org_id: string | undefined = undefined

  discussion_id: string | undefined = undefined

  id: string | undefined = undefined

  user_id: string | undefined = undefined

  timestamp = Date.now()

  text = ''

  links: IObservableArray<Link> = observable.array([])

  readBy: IObservableArray<string> = observable.array([])

  get data(): DiscussionMessageProps {
    return messageProps.reduce(
      (acc: { [key: string]: string }, prop) => {
        acc[prop] = toJS(this[prop])
        return acc
      },
      { links: this.links.map(link => link.data) }
    )
  }

  messageSaving = false

  messageSaveSuccess: string | undefined = undefined

  messageSaveError: string | undefined = undefined

  /**
   * use when saving an exisiting DiscussionMessage,
   *  use save on the Discussion model when addeing a new message
   */
  save = async (notify: boolean) => {
    this.messageSaving = true
    this.messageSaveSuccess = undefined
    this.messageSaveError = undefined

    if (!this.text) {
      this.messageSaveError = 'Message cannot be blank'
      return
    }
    try {
      const res = await this.client.discussions.saveMessage(
        this.org_id,
        this.discussion_id,
        this.id,
        this.text,
        this.links
          .filter(l => (l.type && l.id) || (l.url && l.title))
          .map(l => l.data),
        notify
      )
      this.messageSaveSuccess = 'Message saved successfully'
      this.messageSaving = false
      const { id, text, timestamp, user_id, links } = res.data
      this.id = this.id ? this.id : id
      this.text = text
      this.timestamp = timestamp
      this.user_id = user_id
      this.links = links.map((l: LinkProps) => new Link(l, this.org_id))
      log.code('dsc004', { message: this.data })
      this.rootStore.discussionStore.refreshDiscussion(this.discussion_id)
    } catch (e) {
      this.messageSaveError = 'Could not save message'
      this.messageSaving = false
      log.code(e.statusCode === 400 ? 'dsc202' : 'dsc305', { error: e })
    }
  }

  delete = async () => {
    return this.client.discussions.deleteMessage(
      this.org_id,
      this.discussion_id,
      this.id
    )
  }
}
