import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { observer } from 'mobx-react'
import { ViewModel } from 'mobx-utils'

import ConfirmNotificationOnSave from '../ConfirmNotificationOnSave/ConfirmNotificationOnSave'
import { GetWrappedIcon } from '../Icons'
import OrganizationGroupUserLabel from '../OrganizationGroupUserLabel/OrganizationGroupUserLabel'
import PermissionsWarningMessage from '../PermissionsWarningMessage/PermissionsWarningMessage'
import PermissionsIconPopupContent from '../PermissionsIconPopupContent/PermissionsIconPopupContent'
import UserAvatar from '../UserAvatar'
import UserChip from '../UserChip'
import ListMultiSelect from './ListMultiSelect'

import { useRootStore } from '../../services/RootStoreContext'

import Discussion from '../../Model/Discussion'
import ModelBase from '../../Model/ModelBase'
import Organization from '../../Model/Organization'
import OrgGroup from '../../Model/OrgGroup'
import OrgMember from '../../Model/OrgMember'

import { sortArrayAlpha } from '../../utils/arrays'

type OptionType = {
  data?: {
    id?: string
    members?: string[]
    orgRole?: string
  }
  disabled?: boolean
  fname?: string
  icon?: string | JSX.Element
  label: string
  lname?: string
  key?: string
  value: string
  photo?: string
}

const OrganizationGroupUserSelect: FC<{
  allowSave?: boolean
  disableAllOrgMembers?: boolean
  disabled?: boolean
  disableTrigger?: boolean
  displayUserChips?: boolean
  endIcon?: boolean
  getConfirm?: boolean
  groups: OrgGroup[]
  groupsOnChange?: (newValues: string[]) => void
  groupsState?: string[]
  helperText?: string
  hideAdminGroup?: boolean
  hideGroups?: boolean
  hideLock?: boolean
  hideMembers?: boolean
  hideRenderList?: boolean
  includeHostGroups?: boolean
  inheritedGroups?: string[]
  inheritedUsers?: string[]
  item?: ModelBase | ViewModel<ModelBase>
  label?: JSX.Element | string
  members: OrgMember[]
  org?: Organization
  permissionsName?: string
  placeholder?: string
  trigger?: JSX.Element
  triggerButtonText?: string
  usersState?: string[]
  roles?: string[]
  helperTextColor?: string
  diplayTriggerOnly?: boolean
  handleBlur?: () => void
  usersOnChange?: (newValues: string[]) => void
}> = ({
  roles,
  allowSave,
  disabled,
  disableAllOrgMembers,
  disableTrigger,
  displayUserChips,
  endIcon,
  getConfirm,
  groups,
  groupsOnChange,
  groupsState,
  helperText,
  hideAdminGroup,
  hideGroups,
  hideMembers,
  hideRenderList,
  includeHostGroups,
  inheritedGroups,
  inheritedUsers,
  item,
  label,
  members,
  org,
  permissionsName,
  trigger,
  triggerButtonText,
  usersOnChange,
  usersState,
  diplayTriggerOnly,
  handleBlur,
  helperTextColor,
}): JSX.Element => {
  const theme = useTheme()
  const { orgStore, userStore, documentStore } = useRootStore()
  const { documents } = documentStore
  const { currentOrg } = orgStore
  const { isOrgAdmin, isOrgContributor, impersonatingId } = currentOrg

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const actualOrg = org || currentOrg
  const currentUserId = impersonatingId || userStore?._id
  const existingItem = item && item.getNotifyId && item.getNotifyId()
  const isMessage = item?.modelCollection === 'discussions'

  const getText = (info: string | object) =>
    typeof info === 'string' ? (
      <Typography>{info}</Typography>
    ) : (
      <OrganizationGroupUserLabel
        currentUserId={currentUserId}
        groups={groups}
        item={info}
        members={members}
      />
    )
  const currentMember = members && members.find(m => m._id === currentUserId)
  const orgOnlyMembers = members ? members.filter(m => !m.isHostAdmin) : []
  const checkedAllMembers = false

  const hostMemberOptions =
    members && currentMember && currentMember.isHostAdmin
      ? [
          ...members
            .filter(m => m.isHostAdmin && !m.isDeleted && !m.isExpired)
            .map(u => ({
              key: u._id,
              value: u._id,
              text: `${u.fname} ${u.lname}`,
              icon: `${
                actualOrg && actualOrg.hostName
                  ? `${actualOrg.hostName} `
                  : 'Host '
              }Members`,
              photo: u.photo,
              data: u,
              disabled: false,
              label: `${u.fname} ${u.lname}`,
              groups: u.groups,
            }))
            .sort((a, b) => sortArrayAlpha(a, b, 'label')),
        ]
      : []

  const hostGroupOptions =
    includeHostGroups && actualOrg.hostGroups
      ? [
          ...actualOrg.hostGroups
            .filter(g => g.id)
            .map(g => ({
              key: g.id,
              value: g.id,
              text: g,
              icon: `${
                actualOrg && actualOrg.hostName
                  ? `${actualOrg.hostName} `
                  : 'Host '
              }Groups`,
              data: g,
              disabled: g.isDeleted || roles.includes(g.id),
              label: g.name,
            }))
            .sort((a, b) => sortArrayAlpha(a, b, 'label')),
        ]
      : []
  const memberOptions =
    hideMembers ||
    (isOrgContributor && !isOrgAdmin && !(item instanceof Discussion))
      ? []
      : [
          ...orgOnlyMembers
            .filter(u =>
              currentOrg.hideMembers
                ? isOrgAdmin || u.orgRole === 'admin'
                : true
            )
            .filter(
              u =>
                existingItem ||
                (!u.isDeleted &&
                  !u.isExpired &&
                  ![u.orgRole, u.initialOrgRole].includes('disabled'))
            )
            .map(u => ({
              key: u._id,
              value: u._id,
              text: `${u.fname} ${u.lname}`,
              icon: 'Members',
              photo: u.photo,
              data: u,
              disabled:
                u.isDeleted ||
                u.isExpired ||
                [u.orgRole, u.initialOrgRole].includes('disabled'),
              label: `${u.fname} ${u.lname}`,
              groups: u.groups,
            }))
            .sort((a, b) => sortArrayAlpha(a, b, 'label')),
          ...hostMemberOptions,
        ]
  const adminGroupPayload = {
    name: 'Administrators',
    members: memberOptions
      .filter(mem => mem.data && mem.data.orgRole === 'admin')
      .map(mem => mem.data._id),
  }

  const groupOptions =
    hideGroups || !groups
      ? []
      : [
          ...hostGroupOptions,
          isOrgAdmin && groups && !groups.some(g => g.id === 'admins')
            ? {
                key: 'admins',
                value: 'admins',
                text: adminGroupPayload,
                icon: 'Groups',
                data: adminGroupPayload,
                label: 'Administrators',
              }
            : undefined,
          ...groups
            .filter((g: OrgGroup) => {
              if (g.id) {
                if (g.isGlobalGroup) {
                  return (
                    currentMember &&
                    (currentMember.isHostUser ||
                      g.members.some(
                        memberId => memberId === currentMember._id
                      ))
                  )
                }

                return existingItem || !g.isDeleted
              }

              return false
            })
            .map(g => ({
              key: g.id,
              value: g.id,
              text: g,
              icon: 'Groups',
              data: g,
              disabled: g.isDeleted,
              label: g.name,
            }))
            .sort((a, b) => sortArrayAlpha(a, b, 'label')),
        ].filter(
          group =>
            Boolean(group) && (hideAdminGroup ? group.value !== 'admins' : true)
        )

  const groupsValue = (item ? item.groups : groupsState) || []
  const usersValue = (item ? item.users : usersState) || []
  const selectedGroupValues = groupsValue.map((group: string) =>
    groupOptions.find(g => g.value === group)
  )
  const selectedUserValues = usersValue.map((member: string) =>
    memberOptions.find(m => m.value === member)
  )
  const allValues = [...selectedGroupValues, ...selectedUserValues]

  const filterAllOptions = (option: { disabled?: boolean; value: string }) =>
    option &&
    option.value &&
    (existingItem ? !option.disabled : true) &&
    (item && item.model instanceof Discussion
      ? option.value !== currentUserId
      : true)

  const allOptions = useMemo(
    () => [...groupOptions, ...memberOptions].filter(filterAllOptions),
    [groupOptions, memberOptions]
  )

  const getLabel = () => (typeof label === 'string' ? `${label}:` : label)

  const getOptionIcon = (option: OptionType) => {
    if (option.icon === 'Groups') {
      return (
        <Avatar sx={{ width: 34, height: 34 }}>
          <GetWrappedIcon name='group' width='24px' height='24px' />
        </Avatar>
      )
    }
    if (option.icon === 'Members') {
      return <UserAvatar user={option} />
    }

    if (
      option.icon.includes('Host') ||
      (actualOrg &&
        actualOrg.hostName &&
        option.icon.includes(actualOrg.hostName))
    ) {
      return <GetWrappedIcon name='stars' />
    }
    return null
  }

  const renderOption = useCallback(
    (props, option, state) => {
      let memberOfGroup = []

      if (option.icon !== 'Groups') {
        memberOfGroup = allValues
          .filter(
            g => g?.data?.members && g.data.members.includes(option.value)
          )
          .map(g => (
            <Typography key={`${g.value}-member-option`}>{g.label}</Typography>
          ))
      }

      const isDisabled = Boolean(
        checkedAllMembers || memberOfGroup.length || option.disabled
      )

      if (isDisabled) {
        if (
          !option.disabled ||
          !allValues.some(val => val.value === option.value)
        ) {
          props.onClick = e => e.stopPropagation()
        }
      }

      // do not display hostGroups that are not supposed to be visible to member orgs
      const hide =
        option.value.length !== 24 &&
        option?.data?.isHostGroup &&
        !option?.data?.isVisibleToMemberOrgs

      if (hide) {
        return null
      }

      return (
        <ListItemButton {...props} disabled={isDisabled}>
          <ListItemIcon sx={{ alignItems: 'center' }}>
            <GetWrappedIcon
              name={state.selected ? 'checkboxSelected' : 'checkboxNotSelected'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Stack alignItems='center' direction='row' spacing={1}>
                {getOptionIcon(option)}
                {getText(option.text)}
              </Stack>
            }
          />
        </ListItemButton>
      )
    },
    [allOptions, allValues]
  )

  const labelNode = getLabel()
  const labelAndIcon = labelNode ? (
    <Box sx={{ display: 'inline-flex' }}>
      <InputLabel>{labelNode}</InputLabel>
    </Box>
  ) : null

  const handleChange = useCallback(
    (_event: ChangeEvent, newValues: OptionType[]) => {
      const newSelectedValues = newValues.map(selection => selection.value)
      const values = newSelectedValues.reduce((acc, val) => {
        if (typeof val === 'string') {
          acc.push(val)
        } else {
          acc.push(val.value)
        }
        return acc
      }, [])

      const newUserValues = values.filter(value => value && value.length === 24)
      const newGroupValues = values.filter(
        value => value && !newUserValues.includes(value)
      )
      if (groupsOnChange && typeof groupsOnChange === 'function') {
        groupsOnChange(newGroupValues)
      } else if (groupsState && groupsState.setter) {
        groupsState.setter(newGroupValues)
      } else {
        item.groups = newGroupValues
      }
      if (usersOnChange && typeof usersOnChange === 'function') {
        usersOnChange(newUserValues)
      } else if (usersState && usersState.setter) {
        usersState.setter(newUserValues)
      } else {
        item.users = newUserValues
      }
    },
    [allValues]
  )

  const onConfirmClose = (status: string | undefined) => {
    if (status !== 'success') {
      item.reset()
      setIsConfirmOpen(false)
    }
  }

  const getPermissionsWarning = () => {
    if (item) {
      if (
        (inheritedGroups && inheritedGroups.length > 0) ||
        (inheritedUsers && inheritedUsers.length > 0)
      ) {
        return (
          <Alert severity='info'>
            <PermissionsIconPopupContent item={item} />
          </Alert>
        )
      }

      if (allValues.length === 0) {
        if (isMessage) {
          return <Alert severity='error'>You must select Recipient(s)</Alert>
        }

        return <Alert severity='info'>All Members</Alert>
      }

      return (
        <PermissionsWarningMessage
          currentOrg={currentOrg}
          groupsValue={groupsValue}
          isOrgAdmin={isOrgAdmin}
          usersValue={usersValue}
          item={item}
        />
      )
    }
    return null
  }

  const getTrigger = () => {
    if (hideRenderList) {
      return (
        <Button
          disabled={disabled}
          color='inherit'
          endIcon={endIcon ? <GetWrappedIcon name='chevronRight' /> : null}
          sx={{ padding: 0 }}
        >
          {triggerButtonText}
        </Button>
      )
    }

    const getListItems = () => {
      if (!allValues.length && disableAllOrgMembers) {
        return null
      }

      const getChip = (option?: OptionType) => {
        if (!option) {
          return null
        }

        if (item && option.icon === 'Members') {
          const isGroupMember = item.groups.some(g => {
            if (g === 'admins' && option.data.orgRole === 'admin') {
              return true
            }

            return option.groups.includes(g)
          })

          if (isGroupMember) {
            return null
          }

          if (option) {
            return <UserChip user={option} key={`${option.key}-chip`} />
          }
        }

        return (
          <Chip
            avatar={getOptionIcon(option)}
            key={`${option.key}-chip`}
            label={option.label}
            variant='outlined'
            sx={{ mr: 0.5, mt: 1 }}
          />
        )
      }

      if (!allValues.length) {
        if (isMessage) {
          return <Alert severity='error'>You must select Recipient(s)</Alert>
        }

        return (
          <Box sx={{ ml: 2, mr: 2 }}>
            {getChip({
              label: 'All members',
              icon: 'Groups',
              value: '',
            })}
          </Box>
        )
      }

      if (displayUserChips) {
        return (
          <Box sx={{ ml: 2, mr: 2 }}>
            {(allValues || []).map(option => getChip(option))}
          </Box>
        )
      }

      const displayListItem = (listItem, idx: number, length: number) => {
        if (
          !listItem ||
          (!actualOrg.isHost &&
            listItem?.data?.isHostGroup &&
            !listItem?.data?.isVisibleToMemberOrgs)
        ) {
          return null
        }

        return (
          <ListItem key={listItem.value} divider={idx + 1 !== length}>
            <ListItemIcon>{getOptionIcon(listItem)}</ListItemIcon>
            <ListItemText primary={listItem.label} />
          </ListItem>
        )
      }

      return (
        <Box>
          {(allValues || []).map((option, idx: number) =>
            displayListItem(option, idx, allValues.length)
          )}
        </Box>
      )
    }

    return (
      <Paper variant='outlined'>
        {diplayTriggerOnly ? (
          <Button disabled={disabled} variant='outlined' sx={{ mr: 1 }}>
            {triggerButtonText ||
              `Edit ${
                permissionsName ||
                (item && item.permissionsName ? item.permissionsName : 'Member')
              }s`}
          </Button>
        ) : (
          <List
            subheader={
              <ListSubheader sx={{ mt: 1 }} id='nested-list-subheader'>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  {labelAndIcon && <Box>{labelAndIcon}</Box>}
                  <Button disabled={disabled} variant='outlined' sx={{ mr: 1 }}>
                    {triggerButtonText ||
                      `Edit ${
                        permissionsName ||
                        (item && item.permissionsName
                          ? item.permissionsName
                          : 'Member')
                      }s`}
                  </Button>
                </Stack>
              </ListSubheader>
            }
          >
            {getListItems()}
          </List>
        )}
      </Paper>
    )
  }

  const onConfirmOrSave = async (notify: boolean) => {
    if (item && item.getNotifyId && item.getNotifyId() && item.model) {
      const enabledOptions = allOptions.filter(option => !option.disabled)

      // All means none
      if (
        [allOptions.length, enabledOptions.length].includes(allValues.length) ||
        enabledOptions.every(opt => allValues.includes(opt.value))
      ) {
        item.groups = []
        item.users = []
      }

      item.submit()

      if (item.modelCollection !== 'documents') {
        await item.save(notify)
      } else {
        await documents.save()
      }
    }
  }

  const canSave = isMessage || allowSave

  return (
    <Box>
      <Stack spacing={1}>
        <ListMultiSelect
          roles={roles}
          allowCheckAll={!disableAllOrgMembers}
          allowSave={canSave}
          disabled={disabled}
          value={allValues}
          options={allOptions}
          onChange={handleChange}
          onClose={(fromSave: boolean) => {
            if (handleBlur) {
              handleBlur()
            }

            if (item && item.getNotifyId && item.getNotifyId() && item.model) {
              const shouldSave =
                !item.model.groups.every((groupVal: string) =>
                  item.groups.includes(groupVal)
                ) ||
                !item.groups.every((groupVal: string) =>
                  item.model.groups.includes(groupVal)
                ) ||
                !item.model.users.every((userVal: string) =>
                  item.users.includes(userVal)
                ) ||
                !item.users.every((userVal: string) =>
                  item.model.users.includes(userVal)
                )

              if (shouldSave) {
                if (canSave) {
                  if (fromSave) {
                    // eslint-disable-next-line no-lonely-if
                    if (item.modelCollection === 'discussions') {
                      setIsConfirmOpen(true)
                    } else {
                      onConfirmOrSave(false)
                    }
                  } else {
                    onConfirmClose('false')
                  }
                } else {
                  setIsConfirmOpen(true)
                }
              } else {
                onConfirmClose('false')
              }
            }
          }}
          renderOption={renderOption}
          trigger={trigger || getTrigger()}
          getHeader={getPermissionsWarning}
          disableTrigger={disableTrigger}
        />
        {helperText && (
          <Typography
            variant='caption'
            style={{
              marginTop: theme.spacing(0.2),
              marginLeft: theme.spacing(2),
            }}
            color={helperTextColor ?? theme.palette.text.secondary}
          >
            {helperText}
          </Typography>
        )}
      </Stack>
      {getConfirm && item && (
        <ConfirmNotificationOnSave
          onClose={onConfirmClose}
          currentUserId={currentUserId}
          disabled={
            !item ||
            item.modelCollection === 'documents' ||
            currentOrg.isImpersonating
          }
          currentOrg={currentOrg}
          item={item}
          open={isConfirmOpen}
          onConfirm={onConfirmOrSave}
        >
          <span />
        </ConfirmNotificationOnSave>
      )}
    </Box>
  )
}

OrganizationGroupUserSelect.defaultProps = {
  allowSave: false,
  disableAllOrgMembers: false,
  disabled: false,
  disableTrigger: false,
  displayUserChips: false,
  endIcon: true,
  getConfirm: false,
  groupsOnChange: null,
  groupsState: undefined,
  helperText: undefined,
  hideAdminGroup: false,
  hideGroups: false,
  hideLock: false,
  hideMembers: false,
  hideRenderList: false,
  includeHostGroups: false,
  inheritedGroups: [],
  inheritedUsers: [],
  item: undefined,
  label: 'Recipients',
  org: undefined,
  permissionsName: undefined,
  placeholder: 'All Members',
  trigger: undefined,
  triggerButtonText: undefined,
  usersOnChange: null,
  usersState: undefined,
  roles: [],
  diplayTriggerOnly: false,
  handleBlur: undefined,
  helperTextColor: null,
}

export default observer(OrganizationGroupUserSelect)
