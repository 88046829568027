import React, { FC, Fragment, useState } from 'react'
import { observer } from 'mobx-react'
import {
  Alert,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'

import { GetWrappedIcon } from '../../Icons'
import ConfirmDialoag from '../../ConfirmDialog/ConfirmDialog'
import ModalDialog from '../../ModalDialog/ModalDialog'

import Documents from '../../../Model/Documents'
import File from '../../../Model/File'
import Folder from '../../../Model/Folder'

import { useRootStore } from '../../../services/RootStoreContext'

import { useLog } from '../../../lib/log'

const log = useLog()

const ReplaceNode: FC<{
  documents: Documents
  node: File | Folder
}> = ({ documents, node }): JSX.Element => {
  const {
    userStore: user,
    orgStore: {
      currentOrg: { isOrgAdmin },
    },
  } = useRootStore()
  const [isSaving, setIsSaving] = useState(false)
  const [confirmOpen, toggleConfim] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [warningMessage, setWarningMessage] = useState('')
  const [sucessMessage, setSuccessMessage] = useState('')
  const [file, setFile] = useState(undefined)

  if (node.type === 'folder' || !isOrgAdmin) {
    return null
  }

  const handleSave = async () => {
    setIsSaving(true)
    try {
      await documents.replaceFile(file, user, node.key)
      setFile(undefined)
      setErrorMessage('')
      setWarningMessage('')
      setSuccessMessage('')
      setIsSaving(false)
    } catch (e) {
      log.warn({ message: 'Something went wrong replacing a file', erorr: e })
      setErrorMessage('Something went wrong. Please try again.')
      setIsSaving(false)
    }
  }

  const getModalText = () => {
    const replacementCount = node.previousFiles.length
    return `Replace your file with a new one. ${
      replacementCount > 0
        ? `So far this file has been replaced ${replacementCount} time${
            replacementCount > 1 ? 's' : ''
          }`
        : 'This file has never been replaced'
    }.`
  }

  const name = node.name.replace('.html', '')

  return (
    <Fragment>
      <ConfirmDialoag
        open={confirmOpen}
        onConfirm={handleSave}
        onCancel={() => toggleConfim(false)}
        header={`Replace ${name}`}
        content={`Are you sure you want to replace '${name}' with ${
          file ? `'${file.name}'` : ''
        }?${warningMessage ? ' They are two different types of files.' : ''}`}
        trigger={<span />}
      />
      <ModalDialog
        confirmActionDisabled={isSaving || !file}
        confirmActionText='Replace'
        content={
          <div>
            <Typography>{getModalText()}</Typography>
            <Button
              component='label'
              variant='outlined'
              sx={{ marginTop: '2rem' }}
            >
              <input
                id='upload-replace-file-button'
                type='file'
                onChange={e => {
                  if (e.target && e.target.files && e.target.files[0]) {
                    setErrorMessage('')
                    setWarningMessage('')
                    setSuccessMessage(
                      `'${e.target.files[0].name}' uploaded. Click Replace to save changes.`
                    )
                    setFile(e.target.files[0])
                  }
                }}
                hidden
              />
              Click to Upload Replacement File
            </Button>
            {Boolean(errorMessage) && (
              <Alert severity='error'>{errorMessage}</Alert>
            )}
            {Boolean(warningMessage) && (
              <Alert severity='warning'>{warningMessage}</Alert>
            )}
            {Boolean(sucessMessage) && (
              <Alert severity='success'>{sucessMessage}</Alert>
            )}
          </div>
        }
        header={`Replace File ${name}`}
        onConfirm={() => toggleConfim(true)}
        trigger={
          <MenuItem>
            <ListItemIcon>
              <GetWrappedIcon name='replace' />
            </ListItemIcon>
            <ListItemText>Replace File</ListItemText>
          </MenuItem>
        }
      />
    </Fragment>
  )
}

export default observer(ReplaceNode)
