import React, {
  ChangeEvent,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Chip,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from '@mui/material'
import { observer } from 'mobx-react'

import { ActionTrigger } from '../ActionTriggers'
import { SaveButton } from '../Buttons'
import {
  FormDrawer,
  FormDrawerContent,
  FormDrawerHeader,
  MultiSelect,
} from '../Form'
import { GetWrappedIcon } from '../Icons'

import ModelBase from '../../Model/ModelBase'
import Tag from '../../Model/Tag'

import { useRootStore } from '../../services/RootStoreContext'

const TagAction: FC<{ forMenu?: boolean; item: ModelBase }> = ({
  item,
  forMenu,
  ...rest
}): JSX.Element => {
  const [existingTags, setExistingTags] = useState<Tag[] | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const {
    addNotificationItem,
    tagStore: { tags },
  } = useRootStore()
  useEffect(
    () => () => {
      setExistingTags(undefined)
    },
    []
  )
  const handleClose = useCallback(() => {
    setIsOpen(false)
    setIsSaving(false)
  }, [])
  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const trigger = useMemo(() => {
    if (!item.canAddTags) {
      return null
    }

    if (forMenu) {
      return (
        <MenuItem {...rest} onClick={handleOpen}>
          <ListItemIcon>
            <GetWrappedIcon name='tag' />
          </ListItemIcon>
          <ListItemText>Tags</ListItemText>
        </MenuItem>
      )
    }

    return (
      <ActionTrigger content='Edit Tags' iconName='tag' onClick={handleOpen} />
    )
  }, [forMenu, item])

  if (item && item.modelCollection) {
    const itemTags = tags.filter(tag =>
      tag.items.some(
        tagItem =>
          item.modelCollection === tagItem.collection &&
          tagItem.uid === item.getNotifyId()
      )
    )

    if (!existingTags) {
      setExistingTags(itemTags)
    }

    const otherTags = tags.filter(
      tag =>
        !tag.isSmart &&
        !tag.items.some(
          tagItem =>
            item.modelCollection === tagItem.collection &&
            tagItem.uid === item.getNotifyId()
        )
    )
    const tagOptions = [
      ...itemTags
        .filter(tag => !tag.isSmart)
        .map(tag => ({
          label: tag.name,
          value: tag._id,
          key: tag._id,
        })),
      ...otherTags.map(tag => ({
        label: tag.name,
        value: tag._id,
        key: tag._id,
      })),
    ]

    const getSmartTagSection = () => {
      const smartTags = itemTags.filter(tag => tag.isSmart)

      if (smartTags.length > 0) {
        return (
          <Stack>
            <InputLabel>Smart Tags</InputLabel>
            <Stack direction='row' spacing={1}>
              {smartTags.map(tag => (
                <Chip
                  color='info'
                  key={`${tag._id}-tag-${item.getNotifyId()}`}
                  label={tag.name}
                />
              ))}
            </Stack>
          </Stack>
        )
      }

      return null
    }

    return (
      <Fragment>
        {trigger}
        <FormDrawer onClose={handleClose} isOpen={isOpen}>
          <FormDrawerHeader
            onClose={handleClose}
            title={`Edit ${item.listTitle} Tags`}
          >
            <SaveButton
              isSaving={isSaving}
              onClick={async () => {
                setIsSaving(true)
                Promise.all(
                  itemTags.map(itemTag =>
                    itemTag.save().catch(e => {
                      addNotificationItem({ error: true, message: e.message })
                      throw e
                    })
                  )
                ).then(() => {
                  Promise.all(
                    existingTags
                      .filter(
                        existingTag =>
                          !itemTags.some(
                            itemTag => itemTag._id === existingTag._id
                          )
                      )
                      .map(existingTag =>
                        existingTag.save().catch(e => {
                          addNotificationItem({
                            error: true,
                            message: e.message,
                          })
                          throw e
                        })
                      )
                  ).then(handleClose)
                })
              }}
            />
          </FormDrawerHeader>
          <FormDrawerContent>
            <Stack>
              <MultiSelect
                filterSelectedOptions
                label='Tags'
                onChange={(_event: ChangeEvent, newValue: string[]) => {
                  if (Array.isArray(newValue)) {
                    tags
                      .filter(tag => tag.type !== 'smart')
                      .forEach(tag => {
                        if (newValue.some(value => value === tag._id)) {
                          tag.addItem({
                            collection: item.modelCollection,
                            uid: item.getNotifyId(),
                          })
                        } else {
                          tag.removeItem(
                            tag.items.find(
                              itemTag =>
                                itemTag.collection === item.modelCollection &&
                                itemTag.uid === item.getNotifyId()
                            )
                          )
                        }
                      })
                  }
                }}
                options={tagOptions}
                placeholder='Select Tags...'
                value={itemTags
                  .filter(itemTag => !itemTag.isSmart)
                  .map(itemTag => itemTag._id)}
              />
              {getSmartTagSection()}
            </Stack>
          </FormDrawerContent>
        </FormDrawer>
      </Fragment>
    )
  }

  return null
}

TagAction.defaultProps = {
  forMenu: false,
}

export default observer(TagAction)
