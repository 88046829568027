import APIResponseType from './APIResponseType'
import { OrgFormProps } from '../../../types'

export const remove = (orgId: string, formId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/organization-forms/${formId}`,
  method: 'DELETE',
})

export const get = (orgId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/organization-forms`,
  method: 'GET',
})

export const save = (data: OrgFormProps): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/organization-forms${
    data._id ? `/${data._id}` : ''
  }`,
  method: data._id ? 'PATCH' : 'POST',
  data,
})

export const saveResponse = (
  orgId: string,
  formId: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/organization-forms/${formId}/responses`,
  method: 'POST',
  data,
})

export const getUploadUrl = (data: {
  org_id: string
  filename?: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/file`,
  method: 'POST',
  data,
})

export const getDocumentDownload = (data: {
  org_id: string
  file: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/file/${data.file}`,
  method: 'GET',
})
