import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  toJS,
  IObservableArray,
} from 'mobx'
import moment from 'moment'

import ModelBase from './ModelBase'
import Link from './Link'

import { useLog } from '../lib/log'

import RootStore from '../stores/RootStore'

import { PollProps } from '../types'

const log = useLog()

const dataProps = [
  'org_id',
  'question',
  'type',
  'options',
  'allowOther',
  'answers',
  'groups',
  'users',
  'tags',
  'starred',
  'links',
  'readBy',
  'summary',
  'anonymous',
  'expiresAt',
  'disable_comments',
  'receive_response_notifications',
]

export default class Poll extends ModelBase {
  constructor(
    rootStore: RootStore,
    poll: PollProps = {
      _id: undefined,
      author: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      links: undefined,
      org_id: undefined,
      receive_response_notifications: false,
    }
  ) {
    super(rootStore)
    makeObservable(this, {
      _id: observable,
      author: observable,
      createdAt: observable,
      expiresAt: observable,
      org_id: observable,
      question: observable,
      type: observable,
      options: observable,
      allowOther: observable,
      answers: observable,
      groups: observable,
      users: observable,
      tags: observable,
      starred: observable,
      links: observable,
      readBy: observable,
      anonymous: observable,
      disable_comments: observable,
      expiresIn: computed,
      data: override,
      isPassed: computed,
      hasEnding: computed,
      savingAnswer: observable,
      answerError: observable,
      clickAnswer: action,
      isSaving: observable,
      saveSuccess: observable,
      saveError: observable,
      validationErrors: observable,
      save: action,
      toggleArchived: action,
      receive_response_notifications: observable,
      findAllAssignees: computed,
      dateError: observable,
      summary: observable,
    })

    dataProps.forEach(prop => {
      if (poll[prop]) {
        this[prop] = poll[prop]
      }
    })
    this._id = poll._id
    this.archivedAt = poll.archivedAt || 0
    this.createdAt = poll.createdAt || Date.now()
    this.updatedAt = poll.updatedAt || Date.now()
    this.author = poll.author
    this.links = observable.array(
      poll.links ? poll.links.map(link => new Link(link, this.org_id)) : []
    )
  }

  modelCollection = 'polls'

  _id: string | undefined = undefined

  createdAt = Date.now()

  updatedAt = Date.now()

  expiresAt = 0

  org_id: string | undefined = undefined

  author: string | undefined = undefined

  question = ''

  type = 'single'

  options = ['First Option', 'Second Option']

  allowOther = false

  answers: { answer: string; uid: string }[] = []

  groups: string[] = []

  users: string[] = []

  tags: string[] = []

  starred = false

  links: IObservableArray<Link> = observable.array([])

  readBy: string[] = []

  anonymous = false

  disable_comments = false

  receive_response_notifications = false

  summary = ''

  dateError = ''

  get expiresIn() {
    return this.expiresAt ? moment(this.expiresAt).fromNow() : undefined
  }

  get data() {
    return {
      org_id: toJS(this.org_id),
      links: this.links.map(link => link.data),
      question: toJS(this.question).trim(),
      type: toJS(this.type),
      options: toJS(this.options).filter(Boolean),
      allowOther: toJS(this.allowOther),
      answers: toJS(this.answers),
      groups: toJS(this.groups),
      users: toJS(this.users),
      tags: toJS(this.tags),
      readBy: toJS(this.readBy),
      summary: toJS(this.summary),
      anonymous: toJS(this.anonymous),
      archivedAt: toJS(this.archivedAt),
      expiresAt: toJS(this.expiresAt),
      disable_comments: toJS(this.disable_comments),
      receive_response_notifications: toJS(this.receive_response_notifications),
    }
  }

  get isPassed() {
    return Boolean(this.expiresAt && moment(this.expiresAt) < moment({}))
  }

  get hasEnding() {
    return Boolean(this.expiresAt)
  }

  savingAnswer = false

  answerError: string | undefined = undefined

  clickAnswer = async (answer: string, uid: string) => {
    this.savingAnswer = true
    let answers

    if (this.type === 'single') {
      answers = toJS(this.answers).filter(a => a.uid !== uid)
      answers.push({ answer, uid })
    }

    if (this.type === 'multiple') {
      answers = toJS(this.answers)

      const selectedIdx = toJS(this.answers).findIndex(
        a => a.uid === uid && a.answer === answer
      )
      if (selectedIdx > -1) {
        answers.splice(selectedIdx, 1)
      } else {
        answers.push({ answer, uid })
      }
    }

    this.answerError = undefined

    try {
      const {
        data: { answers: newAnswers },
      } = await this.client.polls.answerPoll(
        { uid, answer },
        this.org_id,
        this._id
      )
      this.answers = newAnswers
      log.code('pol002', { uid, answer })
      this.savingAnswer = false
    } catch (e) {
      this.answerError = 'Could not save answer, please contact support'
      log.code('pol302', { error: e })
      this.savingAnswer = false
    }
  }

  isSaving = false

  saveSuccess: string | undefined = undefined

  saveError: string | undefined = undefined

  validationErrors: { type: string | undefined; question: string | undefined } =
    { type: undefined, question: undefined }

  async save(sendNotification = false) {
    this.isSaving = true
    this.saveSuccess = undefined
    this.saveError = undefined

    try {
      const res = await this.client.polls.savePoll(
        this.data,
        this._id,
        sendNotification
      )
      this._id = res.data._id

      const {
        pollStore: { polls },
      } = this.rootStore

      // Add to polls store if dne other wise update
      //
      const foundIndex = polls ? polls.findIndex(p => p._id === this._id) : -1

      if (foundIndex !== -1) {
        polls[foundIndex] = this
      } else if (polls) {
        polls.unshift(this)
      }

      this.isSaving = false
      this.saveSuccess = 'Poll saved successfully'
      log.code('pol001', { id: res.data._id })
      this.expiresAt = res.data.expiresAt || 0
    } catch (e) {
      this.isSaving = false
      if (e.response && e.response.status === 400) {
        this.saveError = 'Please ensure all fields are properly filled out'
        this.validationErrors = e.response.data.reduce((acc, i) => {
          acc[i.key] = i.message
          return acc
        }, {})
        log.code('pol201', { error: e.response })
        return
      }
      this.saveError =
        'There was an error saving the poll, please contact support'
      log.code('pol301', { error: e.response })
    }
  }

  toggleArchived = async () => {
    const { data } = await this.client.polls.toggleArchived(
      this.org_id,
      this._id
    )

    this.archivedAt = data.archivedAt

    return data
  }

  get displayName() {
    return 'Poll'
  }

  get permissionsName() {
    return 'Voter'
  }

  get findAllAssignees() {
    if (this.rootStore.orgStore && this.rootStore.orgStore.currentOrg) {
      const { currentOrg } = this.rootStore.orgStore
      const hasUsers = this.users && this.users.length > 0
      const hasGroups = this.groups && this.groups.length > 0
      // remove all host members when all org members are selected
      if (!hasUsers && !hasGroups) {
        return currentOrg.members
          .filter(m => !m.isHostUser && m.orgRole !== 'disabled')
          .map(user => user._id)
      }

      let allMembersInGroup: string[] = []
      if (hasGroups) {
        this.groups.forEach(groupId => {
          // check to see if the assigned group is a client group if not check to see if is a host group
          let foundGroup = currentOrg.groups.find(g => g.id === groupId)
          if (!foundGroup) {
            foundGroup = currentOrg.hostGroups.find(
              host_g => host_g.id === groupId
            )
          }

          if (foundGroup && foundGroup.members) {
            allMembersInGroup = allMembersInGroup.concat(
              foundGroup.members.slice()
            )
          }
        })
      }

      const uniqueIds: string[] = [
        ...new Set([...allMembersInGroup, ...this.users]),
      ]

      return currentOrg.members
        .filter(m => uniqueIds.includes(m._id))
        .map(user => user._id)
    }
    return []
  }
}
