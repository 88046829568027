import APIResponseType from './APIResponseType'

export const getDiscussions = (org_id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/discussions`,
  method: 'GET',
})

export const saveDiscussion = (
  data: { org_id: string; _id?: string },
  notify?: boolean
): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/discussions/${data._id ? data._id : ''}${
    notify ? '?notify=true' : ''
  }`,
  method: data._id ? 'PATCH' : 'POST',
  data,
})

export const deleteDiscussion = (data: {
  org_id: string
  _id: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/discussions/${data._id}`,
  method: 'DELETE',
})

export const saveMessage = (
  org_id: string,
  discussion_id: string,
  message_id: string,
  text: string,
  links: object[],
  notify: boolean
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/discussions/${discussion_id}/message${
    message_id ? `/${message_id}` : ''
  }${notify ? '?notify=true' : ''}`,
  method: message_id ? 'PATCH' : 'POST',
  data: { text, links },
})

export const deleteMessage = (
  org_id: string,
  discussion_id: string,
  message_id: string
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/discussions/${discussion_id}/message/${message_id}`,
  method: 'DELETE',
})

export const getDiscussion = (
  orgId: string,
  discussionId: string
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/discussions/${discussionId}`,
  method: 'GET',
})

export const toggleArchived = (
  orgId: string,
  discussionId: string
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/discussions/${discussionId}/archive`,
  method: 'PATCH',
})
