import React, { FC, Fragment, forwardRef, useEffect, useState } from 'react'
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Slide,
  SlideProps,
} from '@mui/material'

const Transition = forwardRef((props: SlideProps, ref) => (
  <Slide direction='up' ref={ref} {...props} />
))

const ConfirmDialog: FC<{
  additionalActions?: JSX.Element | JSX.Element[] | null
  closeActionText?: string
  confirmActionText?: string
  content: string | JSX.Element
  header?: string | JSX.Element | null
  maxWidth?: Breakpoint | false
  onCancel?: () => void
  onConfirm?: (() => void) | null
  open?: boolean
  trigger: string | JSX.Element
}> = ({
  additionalActions,
  closeActionText,
  confirmActionText,
  content,
  header,
  maxWidth,
  onCancel,
  onConfirm,
  open,
  trigger,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(open)
  useEffect(() => {
    if (open !== isOpen) {
      setIsOpen(open)
    }
  }, [open])

  const onClick = () => {
    setIsOpen(true)
  }

  const actualTrigger = React.isValidElement(trigger) ? (
    React.cloneElement(trigger, {
      onClick,
    })
  ) : (
    <span onClick={onClick} role='presentation'>
      {trigger}
    </span>
  )

  return (
    <Fragment>
      {actualTrigger}
      <Dialog
        maxWidth={maxWidth}
        open={isOpen}
        TransitionComponent={Transition}
      >
        <DialogTitle>{header}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              await onCancel()
              setIsOpen(false)
            }}
            variant='outlined'
          >
            {closeActionText}
          </Button>
          {onConfirm && (
            <Button
              autoFocus
              onClick={async () => {
                await onConfirm()
                setIsOpen(false)
              }}
              variant={additionalActions ? 'outlined' : 'contained'}
            >
              {confirmActionText}
            </Button>
          )}
          {additionalActions}
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

ConfirmDialog.defaultProps = {
  additionalActions: null,
  closeActionText: 'Cancel',
  confirmActionText: 'Confirm',
  header: null,
  onCancel: () => true,
  onConfirm: null,
  open: false,
  maxWidth: 'md',
}

export default ConfirmDialog
