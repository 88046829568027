import {
  observable,
  computed,
  override,
  toJS,
  action,
  makeObservable,
  IObservableArray,
} from 'mobx'
import { Palette } from '@mui/material'
import axios from 'axios'

import ModelBase from './ModelBase'
import Organization from './Organization'
import OrganizationFormField from '../Model/OrganizationFormField'

import RootStore from '../stores/RootStore'

import { FormField, OrgFormProps, OrgFormResponse, PDFFormMap } from '../types'

import { useLog } from '../lib/log'
import { generateID } from '../lib/utils'

const log = useLog()

export const individualColorIdentifier: (keyof Pick<
  Palette,
  'info' | 'success' | 'error' | 'primary' | 'secondary'
>)[] = ['info', 'success', 'error', 'primary', 'secondary']

export default class OrganizationForm extends ModelBase {
  inputTypeOptions = [
    {
      key: 'bool',
      value: 'bool',
      label: 'Yes / No',
    },
    {
      key: 'string',
      value: 'string',
      label: 'User Input',
    },
    {
      key: 'enum',
      value: 'enum',
      label: 'Select one from a list of options',
    },
    {
      key: 'enum_multi',
      value: 'enum_multi',
      label: 'Select multiple from a list of options',
    },
    {
      key: 'file',
      value: 'file',
      label: 'File',
    },
    {
      key: 'section',
      value: 'section',
      label: 'Form section header, creates new page in form',
    },
  ]

  constructor(rootStore: RootStore, form: OrgFormProps) {
    super(rootStore)
    makeObservable(this, {
      _id: observable,
      author: observable,
      createdAt: observable,
      fields: observable,
      name: observable,
      org_id: observable,
      org: observable,
      responses: observable,
      archived: observable,
      pdfFormKey: observable,
      pdfFileURL: observable,
      pdfFormName: observable,
      pdfFormMappings: observable,
      pdfFileURLError: observable,
      pdfSaveRequired: observable,
      thankYouMessage: observable,
      templateId: observable,
      updatedAt: observable,
      data: override,
      isInvalid: computed,
      groupSelection: computed,
      orgName: computed,
      addField: action,
      removeField: action,
      saveResponse: action,
      remove: action,
      archive: action,
      restore: action,
      downloadPDFFile: action,
      isUploading: observable,
      uploadError: observable,
      uploadPDFForm: action,
      save: action,
      category: override,
      contacts: observable,
      updatePdfMapping: action,
      isMultiAssignee: observable,
      assigneeIDList: observable,
      toggleMultiAssignee: action,
      getMemberDetailsById: action,
      setOrgById: action,
      copyFieldForIndividual: action,
      syncCopiedFields: action,
      assigneeOptions: computed,
      templateDefaultAssigneeCount: observable,
    })

    this.loadData(form)
  }

  loadData = (form: OrgFormProps) => {
    if (form) {
      this._id = form._id || this._id
      this.author = form.author || this.author
      this.fields.replace(
        (form.fields || []).map(field => new OrganizationFormField(field))
      )
      this.name = form.name || this.name
      this.org_id = form.org_id || this.org_id
      this.responses = observable.array(form.responses || [])
      this.archived = form.archived || this.archived
      this.pdfFormKey = form.pdfFormKey || this.pdfFormKey
      this.pdfFormName = form.pdfFormName || this.pdfFormName
      this.pdfFormMappings.replace(form.pdfFormMappings || [])
      this.thankYouMessage = form.thankYouMessage || this.thankYouMessage
      this.contacts = form.contacts || this.contacts
      this.isMultiAssignee = form.isMultiAssignee || this.isMultiAssignee
      this.assigneeIDList.replace(form.assigneeIDList || [])
      this.templateId = form.templateId || this.templateId
      this.templateId = form.templateId || undefined
      this.createdAt = form.createdAt || 0
      this.updatedAt = form.updatedAt || 0
      this.templateDefaultAssigneeCount = form.templateDefaultAssigneeCount || 1
    }
  }

  templateDefaultAssigneeCount = 1

  modelCollection = 'organization-forms'

  _id: string | undefined = undefined

  author: string | undefined = undefined

  createdAt = 0

  fields: IObservableArray<OrganizationFormField> = observable.array([])

  name = ''

  org_id: string | undefined = undefined

  org: Organization | undefined = undefined

  responses: IObservableArray<OrgFormResponse> = observable.array([])

  archived = false

  pdfFormKey: string | undefined = undefined

  pdfFormName: string | undefined = undefined

  pdfFormMappings: IObservableArray<PDFFormMap> = observable.array([])

  thankYouMessage: string | undefined = ''

  templateId: string | undefined = undefined // This is simply for tracking edits and collection merges

  updatedAt = 0

  contacts: { groups: string[]; users: string[] } = { groups: [], users: [] }

  assigneeIDList: IObservableArray<string> = observable.array([]) // order matters

  isMultiAssignee = false

  pdfFileURL: string | undefined = undefined

  pdfFileURLError: string | undefined = undefined

  isUploading = false

  uploadError = false

  pdfSaveRequired = false

  saveError: string | undefined = undefined

  getNotifyId = (): string => this._id

  get data(): OrgFormProps {
    return {
      _id: toJS(this._id),
      author: toJS(this.author),
      fields: this.fields.map(field => field.data),
      name: toJS(this.name),
      org_id: toJS(this.org_id),
      responses: toJS(this.responses),
      archived: toJS(this.archived),
      pdfFormKey: toJS(this.pdfFormKey),
      pdfFormName: toJS(this.pdfFormName),
      pdfFormMappings: toJS(this.pdfFormMappings),
      thankYouMessage: toJS(this.thankYouMessage),
      contacts: toJS(this.contacts),
      isMultiAssignee: toJS(this.isMultiAssignee),
      assigneeIDList: toJS(this.assigneeIDList),
      templateDefaultAssigneeCount: toJS(this.templateDefaultAssigneeCount),
    }
  }

  async setOrgById(orgId: string = this.org_id) {
    this.org = await this.rootStore.orgStore.getOrgById(orgId)

    if (this.org) {
      this.org.getMembers()
    }
  }

  get displayName(): string {
    return 'Form'
  }

  get category(): string {
    return 'orgForms'
  }

  get isInvalid(): boolean {
    return (
      this.fields.some(f => f.isInvalid) ||
      this.name.length === 0 ||
      (this.contacts.groups.length === 0 && this.contacts.users.length === 0)
    )
  }

  get orgName(): string {
    return this.org
      ? this.org.legal_business_name
      : this.rootStore.orgStore.availableOrgs.find(
          org => org.value === this.org_id
        )?.label || ''
  }

  get groupSelection() {
    if (this.org) {
      return this.org.hostGroups.filter(g => g.isVisibleToMemberOrgs)
    }

    return this.rootStore.orgStore.currentOrg.groups.filter(
      g => g.isVisibleToMemberOrgs
    )
  }

  get assigneeOptions(): { value: string; key: string; label: string }[] {
    return this.org && this.org.members && this.org.members.length > 0
      ? this.org.members
          .filter(member => {
            if (this.assigneeIDList.includes(member._id)) {
              return false
            }
            return true
          })
          .map(member => ({
            value: member._id,
            key: member._id,
            label: `${member.fname} ${member.lname}`,
          }))
      : []
  }

  addAssigneeToList(id: string) {
    this.assigneeIDList.push(id)

    if (this.fields.length === 1 && !this.fields.every(field => field.name)) {
      this.fields.clear()
      this.addField()
    }
  }

  removeAssigneeFromList(id: string) {
    this.assigneeIDList.replace(
      this.assigneeIDList.filter(assigneeId => assigneeId !== id)
    )
  }

  getMemberDetailsById(id: string): { id: string; name: string } {
    const member = this.org.members.find(m => m._id === id)
    return { id: member._id, name: `${member.fname} ${member.lname}` }
  }

  addField(field?: FormField) {
    const newField = new OrganizationFormField(field)
    this.fields.push(newField)

    if (this.isMultiAssignee) {
      this.assigneeIDList.forEach((_person, idx) => {
        if (idx > 0) {
          this.copyFieldForIndividual(newField.id, newField, idx, undefined)
        }
      })
    }

    return newField
  }

  removeField(id: string, overrideMap = false) {
    let hasMap = false

    if (this.pdfFormMappings) {
      if (this.isFieldMapped(id)) {
        hasMap = true
      }
    }

    if (overrideMap || !hasMap) {
      this.fields.replace(
        this.fields.filter(field => field.id !== id && !field.id.includes(id))
      )
    } else {
      this.rootStore.addNotificationItem({
        message: `Please remove this field mapping below before deleting this field`,
        error: true,
      })
    }
  }

  copyFieldForIndividual(
    fieldGroupId: string,
    field: OrganizationFormField,
    individualIndex: number,
    oldCopy?: OrganizationFormField
  ) {
    const fieldCopy = { ...toJS(field) }
    // each option has to have an individual id even if the fields have the same configuration
    // Create new field option ids when copying another field
    // if the oldCopy is passed DO NOT generate new option ids
    if (fieldCopy.options && fieldCopy.options.length > 0) {
      const newOps = observable.array(
        fieldCopy.options.map((opt, idx) => {
          const newId = generateID()
          opt.key =
            oldCopy && oldCopy.options[idx] ? oldCopy.options[idx].key : newId
          opt.value =
            oldCopy && oldCopy.options[idx] ? oldCopy.options[idx].value : newId
          return opt
        })
      )
      fieldCopy.options = newOps
    }

    // if the old fields is passed keep the old id
    // if is a new field allow a new id to be created
    const newField = new OrganizationFormField({
      ...fieldCopy,
      individualIndex,
      id: oldCopy ? fieldCopy.id : `${fieldGroupId}-copy-${generateID()}`,
    })

    if (oldCopy) {
      newField.id = oldCopy.id
    }

    // inserts the new field right after the current field
    const insertIndex = this.fields.findIndex(_field => _field.id === field.id)

    if (insertIndex !== -1) {
      this.fields.splice(insertIndex + 1, 0, newField)
    } else {
      this.fields.push(newField)
    }
  }

  syncCopiedFields(
    fieldGroupId: string,
    field: OrganizationFormField,
    releatedFields: OrganizationFormField[]
  ) {
    releatedFields.forEach((f: OrganizationFormField) => {
      // remove all related fields except for the field being copied from
      // and those that have mapping
      const hasMapping = !this.isFieldMapped(f.id)

      if (f.id !== field.id) {
        if (hasMapping) {
          this.removeField(f.id)
        } else {
          this.removeField(f.id, true)
        }
        // we only allow the id to be changed if the fields are not mapped
        // so pass in the old field so that the old ids can be used
        this.copyFieldForIndividual(
          fieldGroupId,
          field,
          +f.individualIndex,
          hasMapping ? f : undefined
        )
      }
    })
  }

  updatePdfMapping = (newMappings: PDFFormMap[]) => {
    this.pdfFormMappings.replace(newMappings)
  }

  isFieldMapped = (fieldId: string): boolean =>
    this.pdfFormMappings.some(
      mappedField => mappedField?.lastDroppedItem?.portalFieldId === fieldId
    )

  saveResponse = async (
    values: { [key: string]: unknown } | object[],
    userId: string,
    contactId?: string,
    isInProgress?: boolean
  ) => {
    if (this.org_id && this._id && values) {
      const response = { values, userId, contactId, isInProgress }
      if (!Array.isArray(values)) {
        response.values = Object.keys(values).map(value => ({
          fieldId: value,
          value: values[value],
        }))
      }
      const { data } = await this.client.organizationForms.saveResponse(
        this.org_id,
        this._id,
        { response }
      )
      this.responses.replace(data.responses)
      return data
    }

    throw new Error('Form is not complete')
  }

  remove = async () => {
    if (this._id && this.org_id) {
      await this.client.organizationForms.remove(this.org_id, this._id)
      this.rootStore.orgFormStore.orgForms.remove(this)
    }
  }

  archive = async () => {
    this.archived = true
    await this.save()
  }

  restore = async () => {
    this.archived = false
    await this.save()
  }

  downloadPDFFile = async (currentOrg: Organization) => {
    this.pdfFileURLError = undefined
    try {
      const data = {
        org_id: currentOrg._id,
        host_id: currentOrg.host || undefined,
        file: this.pdfFormKey,
      }
      const res = await this.client.documents.getDocumentDownload(data)
      this.pdfFileURL = res.data
      log.code('ogf002', data)
    } catch (e) {
      log.code('ogf303', {
        error: e,
        data: {
          org_id: this.org_id,
          file: this.pdfFormKey,
        },
      })
      this.pdfFileURLError = 'Error downloading file'
    }
  }

  toggleMultiAssignee() {
    this.isMultiAssignee = !this.isMultiAssignee

    if (this.isMultiAssignee) {
      this.assigneeIDList.clear()
    }
  }

  uploadPDFForm = async (
    file: { size: number; name: string; webkitRelativePath: string },
    hostOrgId?: string | undefined
  ) => {
    this.isUploading = true
    this.uploadError = false
    this.saveError = undefined

    try {
      const { data: requestData } =
        await this.client.organizationForms.getUploadUrl({
          filename: file.name,
          org_id: hostOrgId,
        })
      const { key } = requestData

      delete requestData.key

      // Uploads the pdf to a bucket
      await axios.request({
        ...requestData,
        data: file,
      })

      this.pdfFormKey = key
      this.pdfFormName = file.name
      this.pdfFileURL = undefined
      this.isUploading = false
      this.pdfSaveRequired = true
    } catch (e) {
      this.saveError = 'Could not upload file'
      this.uploadError = true
      this.isUploading = false
      log.code('ogf302', { error: e })
      throw new Error(`Failed to upload ${file.name}`)
    }
  }

  save = async () => {
    const reqData = this.data
    this.pdfSaveRequired = false
    if (!reqData._id) {
      delete reqData._id
    }

    try {
      const { data } = await this.client.organizationForms.save(reqData)

      this._id = data._id
      this.author = data.author
      this.createdAt = data.createdAt

      if (
        !this.rootStore.orgFormStore.orgForms.some(
          form => form._id === this._id
        )
      ) {
        this.rootStore.orgFormStore.orgForms.push(this)
      } else {
        // refreshes orgform store so that all state is consistent on save
        this.rootStore.orgFormStore.orgForms.replace(
          this.rootStore.orgFormStore.orgForms.map(piece =>
            piece._id === this._id ? this : piece
          )
        )
      }
      log.code('ogf001', { data: this.data })
      return data
    } catch (e) {
      log.code('ogf301', { error: e })
      throw new Error('Unable to process request')
    }
  }
}
