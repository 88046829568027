import React, { FC, memo } from 'react'
import { TextField, TextFieldProps } from '@mui/material'

const TextInput: FC<TextFieldProps & { fullWidth?: boolean }> = (
  props
): JSX.Element => <TextField {...props} />

TextInput.defaultProps = {
  fullWidth: true,
}

export default memo(TextInput)
