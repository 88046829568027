import React, { FC, useCallback, useState, useRef, useEffect } from 'react'
import { List, ListItemText, ListSubheader, Stack, Switch } from '@mui/material'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'

import LeftPanelListItem from './LeftPanelListItem'

import ModelBase from '../../Model/ModelBase'

import { slugMatchesId } from '../../lib/slug'

const LeftPanelArchivedList: FC<{
  items?: (ModelBase & {
    created?: number
    createdAt?: number
    modified?: number
    updated?: number
    updatedAt?: number
  })[]
  searchTerm?: string
}> = ({ items, searchTerm }): JSX.Element => {
  const [showArchived, setShowArchived] = useState(false)
  const listRef = useRef(null)
  const { id } = useParams()

  const toggleShowArchived = useCallback(() => {
    setShowArchived(!showArchived)

    if (!showArchived) {
      setTimeout(() => {
        listRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        })
      }, 5)
    }
  }, [showArchived])
  const hasArchived = items.some(item => item.isArchived)
  const actualList = items
    .filter(item => item.isArchived)
    .filter(item => {
      if (searchTerm) {
        const lowered = searchTerm.toLowerCase()
        return item.listTitle.toLowerCase().includes(lowered)
      }

      return showArchived
    })
    .sort((a, b) => {
      const aTime = +(
        a.modified ||
        a.updatedAt ||
        a.updated ||
        a.created ||
        a.createdAt
      )
      const bTime = +(
        b.modified ||
        b.updatedAt ||
        b.updated ||
        b.created ||
        b.createdAt
      )

      return aTime > bTime ? -1 : bTime > aTime ? 1 : 0
    })

  useEffect(() => {
    if (
      hasArchived &&
      !showArchived &&
      items.some(
        item => item.isArchived && slugMatchesId(id, item.getNotifyId())
      )
    ) {
      setTimeout(toggleShowArchived, 5)
    }
  }, [id])

  if (!hasArchived) {
    return null
  }

  return (
    <List
      ref={listRef}
      subheader={
        <ListSubheader sx={{ textAlign: 'center', p: 2 }}>
          <Stack direction='row' justifyContent='center' alignItems='center'>
            <ListItemText primary='Show Archived' sx={{ flex: 'unset' }} />
            <Switch
              edge='end'
              onChange={toggleShowArchived}
              checked={showArchived}
              inputProps={{
                'aria-labelledby': 'switch-list-label-archived',
              }}
            />
          </Stack>
        </ListSubheader>
      }
    >
      {actualList.map(item => (
        <LeftPanelListItem key={item.getNotifyId()} item={item} />
      ))}
    </List>
  )
}

LeftPanelArchivedList.defaultProps = {
  items: [],
  searchTerm: '',
}

export default observer(LeftPanelArchivedList)
