/* eslint-disable no-useless-escape */
import { observable, override, action, computed, makeObservable } from 'mobx'
import * as yup from 'yup'
import { useLog } from '../lib/log'
import ModelBase from '../Model/ModelBase'
import RootStore from './RootStore'

const log = useLog()

export default class AuthStore extends ModelBase {
  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this, {
      email: observable,
      password: observable,
      passwordConfirm: observable,
      invalidPasswordMessage: observable,
      passwordNeverSetMessage: observable,
      loginError: observable,
      loginMsg: observable,
      authenticateWithGoogle: action,
      authenticateWithApple: action,
      decodeAppleToken: action,
      logout: action,
      isAuthenticated: computed,
      validationSchema: override,
      usePassword: observable,
      handleUsePasswordClick: action,
      handleEmailPasswordFormAuthenticationSubmit: action,
      handleAutoLoginInAuthenticationFormSubmit: action,
      handleFormSubmissionAuthenticationError: action,
      setAuthState: action,
      handleAuthSSOsuccess: action,
      formData: override,
      handleResponseTokenData: action,
      setUserAsAuthenticated: action,
      isHandlingAuthentication: observable,
    })
  }

  usePassword = Boolean(+localStorage.getItem('loginWithPassword'))

  email =
    localStorage.getItem('inviteEmail') || localStorage.getItem('email') || ''

  password = ''

  passwordConfirm = ''

  invalidPasswordMessage =
    'Invalid password. If you forgot your password click the "Forgot Password?" button below.'

  passwordNeverSetMessage =
    'You have not yet set a password. Click "Forgot Password?" button below to get an email to set your password'

  loginError: string | undefined = undefined

  loginMsg: string | undefined = undefined

  isHandlingAuthentication = false

  // changes the state from using a password or not
  handleUsePasswordClick = () => {
    this.usePassword = !this.usePassword
    localStorage.setItem('loginWithPassword', (+this.usePassword).toString())
  }

  // when the toekn is assigned the user
  // is officially logged in
  setUserAsAuthenticated = () => {
    this.rootStore.commonStore.jwt = localStorage.getItem('jwt')
  }

  setAuthState = (authAction: 'start' | 'end') => {
    if (authAction === 'start') {
      this.loginError = undefined
      this.isHandlingAuthentication = true
      this.rootStore.appLoaded = false
      return
    }
    if (authAction === 'end') {
      this.isHandlingAuthentication = false
      this.rootStore.appLoaded = true
    }
  }

  handleFormSubmissionAuthenticationError = (error: {
    response: { data: string }
    message: string
  }) => {
    const loginError =
      error.response && error.response.data
        ? error.response.data === 'Invalid password'
          ? this.invalidPasswordMessage
          : error.response.data === 'No password set'
          ? this.passwordNeverSetMessage
          : error.response.data
        : error.message
        ? error.message
        : 'Something went wrong. Please contact support'
    log.code('usr201', {
      email: this.email,
      error: error.response,
      message: loginError,
    })

    return loginError
  }

  handleAuthSSOsuccess = async (email: string, type: 'google' | 'apple') => {
    try {
      if (email) {
        // Ensure or add to user record and save
        if (!this.rootStore.userStore.sso) {
          this.rootStore.userStore.sso = {}
        }
        if (!this.rootStore.userStore.sso[type]) {
          this.rootStore.userStore.sso[type] = email
          try {
            // Don't block, just do
            this.rootStore.userStore.save()
          } catch (e) {
            /* no-op, will try again */
          }
        }
      }

      localStorage.setItem('email', email)
      this.setUserAsAuthenticated()
    } catch (e) {
      this.rootStore.userStore.ssoError = e.message
    }
  }

  handleResponseTokenData = async (jwt: string) => {
    const res = await this.rootStore.commonStore.parseJWT(jwt)
    await this.rootStore.userStore.getAuthedUserData(res._id)
    await this.rootStore.orgStore.handleInitTasks()

    if (!this.rootStore.orgStore.allItemsLoaded) {
      await this.rootStore.orgStore.fetchAllItems()
    }
  }

  handleEmailPasswordFormAuthenticationSubmit = async (actions: {
    setIsSubmitting: (value: boolean) => void
  }) => {
    try {
      this.setAuthState('start')

      const { data } = await this.rootStore.client.user.authenticate({
        email: this.email,
        password: this.password,
        deviceHash: this.rootStore.commonStore.deviceHash,
        deviceInfo: this.rootStore.commonStore.deviceInfo.components,
      })

      this.password = undefined
      await this.handleResponseTokenData(data)

      actions.setIsSubmitting(false)
    } catch (e) {
      actions.setIsSubmitting(false)
      this.handleAuthenticationError(e)
    } finally {
      this.setAuthState('end')
    }
  }

  handleAutoLoginInAuthenticationFormSubmit = async (
    actions: {
      setStatus: (status: { [key: string]: string }) => void
      setIsSubmitting: (value: boolean) => void
    },
    destination = '/dashboard'
  ) => {
    try {
      this.setAuthState('start')
      await this.rootStore.client.user.sendAutoLoginLink({
        email: this.email,
        deviceHash: this.rootStore.commonStore.deviceHash,
        destination: destination.replace(/\/\//g, '/'),
      })
      actions.setStatus({
        loginMsg: `A login link has been sent to ${this.email}. Please follow the link to login.`,
      })
      actions.setIsSubmitting(false)

      this.setAuthState('end')
      return true
    } catch (e) {
      const loginError =
        (e.response && e.response.data) ||
        `Could not send a login link to ${this.email}`

      actions.setIsSubmitting(false)
      this.setAuthState('end')
      log.code('usr201', {
        email: this.email,
        error: e.response,
        message: loginError,
      })
      this.handleAuthenticationError(e)
    }

    return false
  }

  get isAuthenticated() {
    if (
      this.rootStore.commonStore.jwt &&
      !this.isHandlingAuthentication &&
      this.rootStore.appLoaded &&
      this.rootStore.orgStore.currentOrg
    ) {
      return true
    }
    return false
  }

  handleAuthenticationError = (error: {
    response: { data: string }
    message: string
  }) => {
    const loginError = this.handleFormSubmissionAuthenticationError(error)
    this.loginError = loginError
    log.code('usr201', {
      email: this.email,
      error: error.response,
      message: loginError,
    })

    throw new Error(loginError)
  }

  authenticateWithGoogle = async (tokenId: string) => {
    try {
      this.setAuthState('start')
      // Call to auth endpoint
      const { data } = await this.rootStore.client.user.authenticateWithGoogle({
        tokenId,
        deviceHash: this.rootStore.commonStore.deviceHash,
        deviceInfo: this.rootStore.commonStore.deviceInfo.components,
      })
      await this.handleResponseTokenData(data)
      return true
    } catch (e) {
      this.handleAuthenticationError(e)
    } finally {
      this.setAuthState('end')
    }

    return undefined
  }

  authenticateWithApple = async (tokenId: string) => {
    try {
      this.setAuthState('start')
      // Call to auth endpoint
      const { data } = await this.rootStore.client.user.authenticateWithApple({
        tokenId,
        deviceHash: this.rootStore.commonStore.deviceHash,
        deviceInfo: this.rootStore.commonStore.deviceInfo.components,
      })

      await this.handleResponseTokenData(data)
      return true
    } catch (e) {
      this.handleAuthenticationError(e)
    } finally {
      this.setAuthState('end')
    }
    return undefined
  }

  decodeAppleToken = async (tokenId: string) => {
    const { data } = await this.rootStore.client.user.decodeAppleToken({
      tokenId,
    })
    return data
  }

  get validationSchema() {
    return yup.object().shape({
      email: yup.string().email().required(),
      password: yup.lazy(() => {
        return this.usePassword
          ? yup.string().required().min(6)
          : yup.mixed().notRequired()
      }),
    })
  }

  get formData() {
    return {
      email: this.email,
      password: this.password,
      usePassword: this.usePassword,
    }
  }

  logout = async () => {
    const loginWithPassword = localStorage.getItem('loginWithPassword')
    localStorage.clear()
    localStorage.setItem('loginWithPassword', loginWithPassword)

    if (this.rootStore.commonStore.visitTimer) {
      await this.rootStore.commonStore.visitTimer.recordVisit()
      this.rootStore.commonStore.visitTimer = undefined
    }

    if (this.rootStore.commonStore.token) {
      this.rootStore.commonStore.token = undefined
    }

    if (this.rootStore.commonStore.jwt) {
      this.rootStore.commonStore.jwt = undefined
    }

    await this.rootStore.handleAppSocketForceDisconnet()
    this.rootStore.orgStore.resetAppStore()
  }
}
