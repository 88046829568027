import {
  action,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
} from 'mobx'

import Discussion from '../Model/Discussion'
import RootStore from './RootStore'
import { useLog } from '../lib/log'
import { handleSocketListener } from '../lib/socket'
import StoreResourceBase from './StoreResourceBase'

const log = useLog()

export default class DiscussionStore extends StoreResourceBase {
  discussions: IObservableArray<Discussion> = observable.array([])

  listenersLoaded = false

  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      loadData: action,
      discussions: observable,
      getDiscussions: action,
      addDiscussions: action,
      deleteDiscussion: action,
      getDiscussionModel: action,
      handleSavedDiscussion: action,
    })

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
    reaction(() => this.discussions, this.handleSocket)
  }

  loadData(data) {
    this.discussions = observable.array(
      this.rootStore.orgStore.currentOrg.shouldShowMessages
        ? data.map(d => new Discussion(this.rootStore, d))
        : []
    )
    this.loadedResource = true
  }

  addDiscussions(newDiscussions) {
    this.discussions = observable.array([
      ...this.discussions,
      ...newDiscussions.map(d => new Discussion(this.rootStore, d)),
    ])
  }

  getDiscussionModel(data: {
    _id?: string
    groups?: string[]
    topic?: string
    users?: string[]
  }): Discussion {
    if (data?._id) {
      const discussion = this.discussions.find(d => d._id === data._id)

      if (discussion) {
        return discussion
      }
    }

    return new Discussion(this.rootStore, {
      org_id: this.rootStore.orgStore.currentOrg._id,
      author: this.rootStore.userStore._id,
      messages: [],
      groups: [],
      users: [],
      topic: '',
      ...data,
    })
  }

  getDiscussions = async () => {
    if (
      this.rootStore.orgStore.currentOrg &&
      this.rootStore.orgStore.currentOrg._id
    ) {
      try {
        const { data } = await this.rootStore.client.discussions.getDiscussions(
          this.rootStore.orgStore.currentOrg._id
        )
        this.loadData(data)
        return true
      } catch (e) {
        //
      }
    }

    return false
  }

  deleteDiscussion = async (id: string) => {
    if (
      this.rootStore.orgStore.currentOrg &&
      this.rootStore.orgStore.currentOrg._id
    ) {
      try {
        this.rootStore.client.discussions.deleteDiscussion({
          org_id: this.rootStore.orgStore.currentOrg._id,
          _id: id,
        })
        this.discussions.replace(this.discussions.filter(d => d._id !== id))
        log.code('dsc005', { id })
      } catch (e) {
        log.code('dsc302', { error: e })
      }
    }
    return true
  }

  markDiscussionMessagesRead = async (
    discussion: Discussion,
    userId = this.rootStore.userStore._id
  ) => {
    if (discussion && discussion.messages) {
      discussion.messages.forEach(async m => {
        if (!m.readBy || !m.readBy.includes(userId)) {
          discussion.markAsRead(userId).catch(() => true)
        }
      })
    }
  }

  handleSavedDiscussion = async (discussion: Discussion) => {
    try {
      if (!this.discussions.some(d => d._id === discussion._id)) {
        this.discussions.push(discussion)
        return {
          type: 'success',
          message: `Successfully created message: ${discussion.topic}`,
        }
      }
      return {
        type: 'success',
        message: `Successfully updated discussion: ${discussion.topic}`,
      }
    } catch (e) {
      const error = new Error(
        'Could not create message, please contact support'
      )
      error.type = 'error'
      return Promise.reject(error)
    }
  }

  refreshDiscussion = async (discussionId: string) => {
    this.discussions
      .find(discussion => discussion._id === discussionId)
      ?.refresh()
  }

  handleSocket = () => {
    if (this.rootStore.userStore._id) {
      handleSocketListener(
        'discussions',
        Discussion,
        this.discussions,
        this.rootStore
      )
      this.listenersLoaded = true
    }
  }
}
