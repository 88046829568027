import React, { FC, useCallback } from 'react'
import { Alert, Link, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import ClientDrawer from '../../pages/ClientManagement/components/ClientDrawer'

import OrganizationGroupUserSelect from './OrganizationGroupUserSelect'

import { useRootStore } from '../../services/RootStoreContext'

import Todo from '../../Model/Todo'
import OrganizationForm from '../../Model/OrganizationForm'

const HostGroupSelection: FC<{
  isTemplate?: boolean
  model: Todo | OrganizationForm
  handleBlur?: () => void
  helperTextColor?: string
  helperText?: string
}> = ({ isTemplate, model, handleBlur, helperTextColor, helperText }) => {
  const navigate = useNavigate()
  const { orgStore } = useRootStore()
  const { currentOrg } = orgStore
  const {
    isHost,
    isOrgAdmin,
    relationshipManagement: { visibileRoleOptions },
  } = currentOrg

  const usersOnChange = useCallback(() => {
    return false
  }, [])
  const groupsOnChange = useCallback((newGroups: string[]) => {
    model.contacts.groups = newGroups
  }, [])

  if (!model || !isHost || isTemplate || (!model.org && !isTemplate)) {
    return null
  }

  if (model.groupSelection.length === 0) {
    if (isOrgAdmin) {
      if (model.templateId) {
        return null
      }

      if (visibileRoleOptions.length === 0) {
        return (
          <Alert severity='error'>
            <Typography>
              You must have at least one role that is not hidden assigned to
              this Client.
            </Typography>
            <Typography>
              <Link
                onClick={() => {
                  navigate('/client-management/relationship-management')
                }}
              >
                Click Here
              </Link>{' '}
              to create or update a role.
            </Typography>
          </Alert>
        )
      }

      return (
        <Alert severity='error'>
          <Typography>
            You must have at least one role that is not hidden assigned to a
            member that is assigned to this Client.
          </Typography>
          <Typography>
            <ClientDrawer org={model.org} type='edit-link' /> to assign someone
            a role in the &quot;Relationship Management&quot; section.
          </Typography>
        </Alert>
      )
    }
  }

  return (
    <OrganizationGroupUserSelect
      disableAllOrgMembers
      hideAdminGroup
      hideLock
      members={[]}
      groups={model.groupSelection}
      groupsState={
        (model.data && model.data.contacts && model.data.contacts.groups) || []
      }
      groupsOnChange={groupsOnChange}
      helperText={
        helperText ??
        'The point of contact for any questions as well any notifications.'
      }
      label={
        <span>
          Contact/Owner {!isTemplate && <span className='required'>*</span>}
        </span>
      }
      triggerButtonText='Edit Contacts'
      usersOnChange={usersOnChange}
      usersState={[]}
      handleBlur={handleBlur}
      helperTextColor={helperTextColor}
    />
  )
}

HostGroupSelection.defaultProps = {
  isTemplate: false,
  handleBlur: undefined,
  helperTextColor: null,
  helperText: null,
}

export default observer(HostGroupSelection)
