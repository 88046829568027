import React, { FC } from 'react'
import { Grid, TextField } from '@mui/material'
import { observer } from 'mobx-react'

import { StateSelect, useFormContext } from '../../components/Form'

const AddressEditor: FC = () => {
  const {
    handleChange,
    handleBlur,
    isFieldTouched,
    getError,
    setFieldTouched,
    setFieldValue,
    model: currentOrgState,
  } = useFormContext()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label='Street Address'
          placeholder='Street address'
          required
          name='address.address1'
          value={currentOrgState.address.address1}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(
            isFieldTouched('address.address1') && getError('address.address1')
          )}
          helperText={
            isFieldTouched('address.address1') && getError('address.address1')
          }
          data-qa='address-street-1-input'
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label='Suite'
          placeholder='Suite, unit, floor, building, etc.'
          name='address.address2'
          value={currentOrgState.address.address2}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(
            isFieldTouched('address.address2') && getError('address.address2')
          )}
          helperText={
            isFieldTouched('address.address2') && getError('address.address2')
          }
          data-qa='address-street-2-input'
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label='City'
          placeholder='City'
          type='text'
          required
          name='address.city'
          value={currentOrgState.address.city}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(
            isFieldTouched('address.city') && getError('address.city')
          )}
          helperText={
            isFieldTouched('address.city') && getError('address.city')
          }
          data-qa='address-city-input'
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <StateSelect
          value={currentOrgState.address.state}
          onChange={(_e, { value }) => {
            setFieldValue('address.state', value)
            setFieldTouched('address.state', true)
          }}
          required
          data-qa='address-state-dropdown'
          error={Boolean(
            isFieldTouched('address.state') && getError('address.state')
          )}
          onBlur={handleBlur}
          helperText={
            isFieldTouched('address.state') && getError('address.state')
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label='Zip Code'
          placeholder='Zip Code'
          type='number'
          required
          name='address.zip'
          value={currentOrgState.address.zip}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(
            isFieldTouched('address.zip') && getError('address.zip')
          )}
          helperText={isFieldTouched('address.zip') && getError('address.zip')}
          data-qa='address-zipcode-input'
        />
      </Grid>
    </Grid>
  )
}

export default observer(AddressEditor)
