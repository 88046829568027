import { createContext, useContext } from 'react'

export const FormContext = createContext(undefined)
FormContext.displayName = 'FormContext'

export const FormProvider = FormContext.Provider
export const FormConsumer = FormContext.Consumer

const useFormContext = () => {
  const context = useContext(FormContext)

  if (!context) {
    console.error(
      'Form context is not undefined, please verify where you are calling useFormContext() as child of a <MobxForm> component.'
    )
  }

  return context
}

export default useFormContext
