import React, { FC } from 'react'
import { Link, List, ListItemText } from '@mui/material'
import { observer } from 'mobx-react'

import ModalDialog from '../ModalDialog/ModalDialog'

import { useRootStore } from '../../services/RootStoreContext'

import { AvailableOrg } from '../../types'

const ClientListModal: FC<{
  clientIds: string[]
  forForm: boolean
}> = ({ clientIds, forForm }) => {
  const {
    orgStore: { availableOrgs },
  } = useRootStore()

  const selectedClients: AvailableOrg[] = availableOrgs.filter(client =>
    clientIds?.includes(client.value)
  )

  if (selectedClients.length === 0) {
    return null
  }

  return (
    <ModalDialog
      closeActionText='Close'
      confirmAction={<span />}
      content={
        <List>
          {selectedClients.map(client => (
            <ListItemText
              key={`${client.value}-list-item`}
              primary={client.label}
            />
          ))}
        </List>
      }
      trigger={
        <Link>
          {selectedClients.length}
          {forForm ? ' Client(s)' : ''}
        </Link>
      }
      triggerDisabled={selectedClients.length === 0}
    />
  )
}

export default observer(ClientListModal)
