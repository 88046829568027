import APIResponseType from './APIResponseType'

export const getStars = (org_id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/stars`,
  method: 'GET',
})

export const saveStars = (data: {
  org_id: string
  items: unknown[]
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/stars`,
  method: 'PATCH',
  data,
})
