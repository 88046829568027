import GoogleAnalytics from 'react-ga'
import axios from 'axios'

import logCodes from './logCodes'

const { MODE } = import.meta.env

GoogleAnalytics.initialize('UA-133649478-3')

const getBrowser = () => {
  const ua = navigator.userAgent
  let tem
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return { name: 'IE', version: tem[1] || '' }
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/)
    if (tem != null) {
      return { name: 'Opera', version: tem[1] }
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  // eslint-disable-next-line no-cond-assign
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1])
  }
  return {
    name: M[0],
    version: M[1],
  }
}

// Environments
const isProd = Boolean(MODE === 'production')

// Ooooh, pretty
const logToConsole = (opts: {
  severity: 'event' | 'info' | 'warn' | 'error'
}) => {
  const color = {
    event: 'green',
    info: 'blue',
    warn: 'yellow',
    error: 'red',
  }[opts.severity]
  console.log(
    `%c>> ${opts.severity.toUpperCase()}:`,
    `color: ${color}; font-weight: 800;`,
    opts
  )
}

const validate = (opts: object) => {
  const validKeys = [
    'severity',
    'action',
    'category',
    'label',
    'session',
    'data',
    'version',
  ]
  return Object.keys(opts).reduce((acc, key) => {
    if (!validKeys.includes(key)) {
      acc.push(key)
    }

    return acc
  }, [])
}

type logType = {
  code: (code: string, payload?: object) => void
  event: (opts: object) => void
  info: (opts: object) => void
  warn: (opts: object) => void
  error: (opts: object) => void
  record: (opts: object) => void
}

// Methods!!!
const log = {
  code: (code: string, payload = {}) => {
    try {
      log.record(Object.assign(logCodes[code], { data: payload }))
    } catch (e) {
      log.warn({
        action: 'log',
        category: 'platform',
        label: 'invalid code',
        data: {
          code,
          payload,
          url: (location.hash.replace('#', '') + location.search).toLowerCase(),
        },
      })
    }
  },
  event: (opts: { severity: string }) => {
    opts.severity = 'info'
    log.record(opts)
  },
  info: (opts: { severity: string }) => {
    opts.severity = 'info'
    log.record(opts)
  },
  warn: (opts: { severity: string }) => {
    opts.severity = 'warn'
    log.record(opts)
  },
  error: (opts: { severity: string }) => {
    opts.severity = 'error'
    log.record(opts)
  },

  record: (opts: {
    session?: never
    action: never
    label: never
    category: never
    data?: never
    severity: never
    version?: never
  }) => {
    try {
      const ecioStoreFromStorage = localStorage.getItem('ecioStore')
      const ecioStore = ecioStoreFromStorage && JSON.parse(ecioStoreFromStorage)
      opts.session = {
        user: ecioStore ? ecioStore.user : {},
        organization: ecioStore ? ecioStore.organization : {},
        browser: Object.assign(getBrowser(), {
          resolution: { width: window.innerWidth, height: window.innerHeight },
        }),
        ipData: ecioStore ? ecioStore.ipData : {},
        userAgent:
          ecioStore && ecioStore.deviceInfo
            ? ecioStore.deviceInfo.userAgent
            : undefined,
      }
      // Add url
      const defaultData = {
        url: (location.hash.replace('#', '') + location.search).toLowerCase(),
      }
      // Format pageview
      if (opts.action === 'pageview') {
        const title = document.title.split('-')
        title.pop()
        opts.label = title.join().trim()
        opts.category = 'platform'
      }
      opts.data = opts.data
        ? Object.assign(opts.data, defaultData)
        : defaultData
      // Ensure only valid keys/props are logged
      const validation = validate(opts)
      if (validation.length !== 0) {
        console.warn(
          `Invalid properties in log.${opts.severity}`,
          validation.join(', '),
          opts
        )
      }
      opts.version =
        ecioStore && ecioStore.version ? ecioStore.version : 'missing'
      const { severity, action, category, label } = opts
      // Track pageviews to GA
      if (action === 'pageview' && isProd) {
        GoogleAnalytics.pageview(
          (location.hash.replace('#', '') + location.search).toLowerCase()
        )
      }
      // Track events to GA
      if (severity === 'event' && isProd) {
        GoogleAnalytics.event({ action, category, label })
      }
      // Log out
      if (!isProd) {
        logToConsole(opts)
      }
      // Send to log function
      axios
        .post(`${_global.API_HOST}/applogs/v1/log`, opts, {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` },
        })
        .catch(e => {
          console.warn('LOGGING ERROR', e)
        })
    } catch (e) {
      console.warn('LOGGING ERROR', e)
    }
  },
}

export const useLog = (): logType => log

export default useLog
