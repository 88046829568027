import APIResponseType from './APIResponseType'

export const getAlerts = (
  impersonatingId: string | false | undefined
): APIResponseType => ({
  url: `/auth/v1/user/alerts${
    impersonatingId ? `?impersonatingId=${impersonatingId}` : ''
  }`,
  method: 'GET',
})

export const removeAlerts = (data: object): APIResponseType => ({
  url: '/auth/v1/user/alerts',
  method: 'DELETE',
  data,
})

export const archiveAlert = (data: object): APIResponseType => ({
  url: '/auth/v1/user/alerts/archive',
  method: 'PATCH',
  data,
})

export const unarchiveAlert = (data: object): APIResponseType => ({
  url: '/auth/v1/user/alerts/unarchive',
  method: 'PATCH',
  data,
})

export const markAsViewed = (data: object): APIResponseType => ({
  url: '/auth/v1/user/alerts/viewed',
  method: 'PATCH',
  data,
})

export const markAlertUnread = (data: object): APIResponseType => ({
  url: '/auth/v1/user/alerts/unread',
  method: 'PATCH',
  data,
})
