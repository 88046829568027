import React, { FC, useState, Fragment } from 'react'
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Divider,
  Link,
} from '@mui/material'
import { observer } from 'mobx-react'

import FileManager from '../FileManager/FileManager'
import OverflowPopup from '../OverflowPopup/OverflowPopup'
import ModalDialog from '../ModalDialog/ModalDialog'

import { captilizeWords } from '../../lib/utils'

import { useRootStore } from '../../services/RootStoreContext'

const FileManagerModal: FC<{
  hideHostOrgDocs?: boolean
  onFileSelect: (
    path: string,
    node: object,
    isHostOrgDocuments: boolean
  ) => void
  toggleVisible: (newVal: boolean) => void
  visible: boolean
}> = ({
  hideHostOrgDocs,
  onFileSelect,
  toggleVisible,
  visible,
}): JSX.Element => {
  const [path, setPath] = useState('/')
  const [isHostOrgDocuments, setIsHostOrgDocuments] = useState(false)
  const { orgStore, todoStore } = useRootStore()
  const { currentOrg } = orgStore
  const { isHostUser } = currentOrg
  const handleClose = () => {
    toggleVisible(false)
    setPath('/')
  }

  const crumbs = [
    '',
    ...path.split('/').filter(crumb => crumb && crumb.length > 0),
  ]
  const lastCrumbIdx = crumbs.length - 1

  return (
    <ModalDialog
      open={visible}
      header='Select File'
      content={
        <Fragment>
          {(isHostUser || Boolean(todoStore.childOrg)) && !hideHostOrgDocs && (
            <Fragment>
              <ButtonGroup fullWidth>
                <Button
                  onClick={() => {
                    setIsHostOrgDocuments(false)
                    setPath('/')
                  }}
                  variant={!isHostOrgDocuments ? 'contained' : undefined}
                >
                  <OverflowPopup
                    content={`From ${
                      todoStore.childOrg
                        ? todoStore.childOrg.legal_business_name
                        : currentOrg.legal_business_name
                    }`}
                  />
                </Button>
                <Button
                  onClick={() => {
                    setIsHostOrgDocuments(true)
                    setPath('/')
                  }}
                  variant={isHostOrgDocuments ? 'contained' : undefined}
                >
                  <OverflowPopup
                    content={`From ${
                      todoStore.childOrg
                        ? currentOrg.legal_business_name
                        : currentOrg.hostName
                    } Documents`}
                  />
                </Button>
              </ButtonGroup>
              <Divider />
            </Fragment>
          )}
          <Box pl={2} pt={2}>
            <Breadcrumbs
              maxItems={2}
              itemsBeforeCollapse={0}
              itemsAfterCollapse={1}
              sx={{
                display: 'inline-flex',
                justifyContent: 'flex-start',
                lineHeight: 1,
                width: '100%',
                '& .MuiBreadcrumbs-ol': {
                  flexWrap: 'nowrap',
                  maxWidth:
                    crumbs.length > 2 ? { xs: '50%', md: '85%' } : undefined,
                  '& .MuiBreadcrumbs-li': {
                    maxWidth: { xs: '15rem', md: '25rem' },
                  },
                },
              }}
            >
              {crumbs.map((crumb, idx) => (
                <Box key={crumb}>
                  {idx === lastCrumbIdx ? (
                    <OverflowPopup
                      key={`${crumb}-last-crumb-file-manager-modal`}
                      content={captilizeWords(
                        crumb === '' ? 'Documents' : crumb
                      )}
                      fullWidth
                      variant='h6'
                      sx={{
                        color: idx === 0 ? 'primary.main' : undefined,
                        cursor: 'default',
                        fontWeight: idx === 0 ? 600 : undefined,
                      }}
                    />
                  ) : (
                    <Link
                      key={`${crumb}-link-file-manager-modal`}
                      onClick={() =>
                        setPath(
                          `/${crumbs
                            .filter((c, cIdx) => idx >= cIdx && c.length > 0)
                            .join('/')}`
                        )
                      }
                      underline='hover'
                    >
                      <OverflowPopup
                        content={captilizeWords(
                          crumb === '' ? 'Documents' : crumb
                        )}
                        fullWidth
                        variant='h6'
                        sx={{
                          cursor: 'pointer',
                          fontWeight: idx === 0 ? 600 : undefined,
                        }}
                      />
                    </Link>
                  )}
                </Box>
              ))}
            </Breadcrumbs>
          </Box>
          <FileManager
            path={path}
            onFolderClick={({ node }) => {
              setPath(node.path)
            }}
            onFileClick={({ node }) => {
              onFileSelect(
                `${path}/${node.name}`.replace(/\/\//g, '/'),
                node,
                isHostOrgDocuments
              )
              handleClose()
            }}
            isHostOrgDocuments={isHostOrgDocuments}
            embedded
            disableBulkActions
          />
        </Fragment>
      }
      confirmAction={<span />}
      fullWidth
      maxWidth='lg'
      trigger={<span />}
      onClose={handleClose}
    />
  )
}

FileManagerModal.defaultProps = {
  hideHostOrgDocs: false,
}

export default observer(FileManagerModal)
