import React, { memo } from 'react'
import { Tooltip, Typography } from '@mui/material'
import PropTypes, { InferProps } from 'prop-types'

import { sortArrayAlpha } from '../../utils/arrays'

const OrganizationGroupUserLabel = ({
  members,
  groups,
  item,
  currentUserId,
}: InferProps<typeof OrganizationGroupUserLabel.propTypes>) => {
  const getMemberInactiveText = mem => {
    if (mem.isDeleted) {
      return 'Removed'
    }
    if (mem.isExpired) {
      return 'Membership Expired'
    }
    if (mem.orgOrle === 'disabled') {
      return 'Disabled'
    }
    return null
  }
  if (item && item.members) {
    const groupUsers = members.filter(m => item.members.includes(m._id))

    if (groupUsers.length > 0) {
      const currentGroup = groups.find(g => g.id === item.id)
      const isDeleted = currentGroup && currentGroup.isDeleted
      const activeMembers = groupUsers
        .filter(
          mem => !mem.isDeleted && !mem.isExpired && mem.orgOrle !== 'disabled'
        )
        .sort((a, b) => sortArrayAlpha(a, b, 'fullName'))

      const inactiveMembers = groupUsers
        .filter(
          mem => mem.isDeleted || mem.isExpired || mem.orgOrle === 'disabled'
        )
        .sort((a, b) => sortArrayAlpha(a, b, 'fullName'))
      let content
      if (isDeleted) {
        content = <Typography>This group is no longer active</Typography>
      } else {
        content = activeMembers.map(member => (
          <Typography key={member._id}>{member.fullName}</Typography>
        ))
        if (inactiveMembers.length > 0) {
          if (activeMembers.length > 0) {
            content.push(<br key='inactive-break' />)
          }

          inactiveMembers.forEach(member => {
            const inacitveText = getMemberInactiveText(member)
            content.push(
              <Typography key={member._id}>
                {member.fullName}
                {inacitveText ? ` - ${inacitveText}` : ''}
              </Typography>
            )
          })
        }
      }
      return (
        <Tooltip title={content}>
          <Typography>{`${item.name}${
            isDeleted ? ' - Removed' : ''
          }`}</Typography>
        </Tooltip>
      )
    }

    return <Typography>{item.name}</Typography>
  }

  if (item && item.fname && item.lname) {
    const inactiveText = getMemberInactiveText(item)
    const fullName =
      item._id && item._id === currentUserId
        ? `${item.fname} ${item.lname} (You)`
        : `${item.fname} ${item.lname}`
    return (
      <Typography>
        {fullName}
        {inactiveText ? ` - ${inactiveText}` : ''}
      </Typography>
    )
  }

  return item && item.name ? <Typography>{item.name}</Typography> : null
}

OrganizationGroupUserLabel.defaultProps = {
  currentUserId: '',
  members: [],
  groups: [],
  item: undefined,
}

OrganizationGroupUserLabel.propTypes = {
  currentUserId: PropTypes.string,
  item: PropTypes.shape({
    _id: PropTypes.string,
    fname: PropTypes.string,
    lname: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    id: PropTypes.string,
    isDeleted: PropTypes.bool,
    isHostGroup: PropTypes.bool,
  }),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      fname: PropTypes.string,
      lname: PropTypes.string,
    })
  ),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      isDeleted: PropTypes.bool,
    })
  ),
}

export default memo(OrganizationGroupUserLabel)
