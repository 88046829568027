import { action, makeObservable, observable, override } from 'mobx'
import axios from 'axios'
import moment from 'moment'
import RootStore from '../stores/RootStore'
import File from '../Model/File'
import Folder from '../Model/Folder'
import ModelBase from './ModelBase'

import { blobToFileForUpload } from '../lib/documentsHelper'

import { useLog } from '../lib/log'

import { sortArrayAlpha } from '../utils/arrays'

const log = useLog()

export default class Documents extends ModelBase {
  forbiddenFolderNames = ['exports']

  constructor(
    rootStore: RootStore,
    documents: {
      org_id: string | undefined
      contents: Array<File | Folder> | undefined
    } = { org_id: undefined, contents: undefined }
  ) {
    super(rootStore)

    makeObservable(this, {
      _id: observable,
      org_id: observable,
      contents: observable,
      name: observable,
      groups: observable,
      bulkSelectedList: observable,
      currentDirectory: observable,
      currentPath: observable,
      data: override,
      creatingNewFolder: observable,
      folderCreateSuccess: observable,
      folderCreateError: observable,
      inheritedGroups: override,
      inheritedUsers: override,
      createFolder: action,
      downloadError: observable,
      downloadFile: action.bound,
      uploads: observable,
      replaceFile: action.bound,
      uploadSizes: observable,
      uploadProgress: observable,
      uploadError: observable,
      uploadFiles: action.bound,
      uploadToStorage: action.bound,
      uploadTemplateImage: action,
      deleteNode: action,
      bulkDeleteSelected: action,
      bulkMoveSelected: action,
      bulkExportSelected: action,
      isSaving: observable,
      saved: observable,
      save: action,
      saveError: observable,
    })

    this.org_id = documents.org_id
    this.setContents(documents.contents)
    this.currentDirectory = this
  }

  _id: string | undefined = undefined

  org_id: string | undefined = undefined

  contents: Array<File | Folder> = []

  setContents = (contents: Array<File | Folder> | undefined) => {
    this.contents = contents
      ? contents
          .map(obj =>
            obj.contents
              ? new Folder(
                  this.rootStore,
                  obj.isHostFolder
                    ? this.rootStore.orgStore.currentOrg.host
                    : this.org_id,
                  { ...obj, path: '' }
                )
              : new File(this.rootStore, this.org_id, { ...obj, path: '/' })
          )
          .sort((a, b) => {
            if (a.type === 'folder' && b.type === 'folder') {
              const aIsHostOrInherited = a.isHostFolder || a.isInherited
              const bIsHostOrInherited = b.isHostFolder || b.isInherited

              if (aIsHostOrInherited && bIsHostOrInherited) {
                return sortArrayAlpha(a, b, 'name')
              }

              const { isGlobalFolder: aIsGlobal } = a
              const { isGlobalFolder: bIsGlobal } = b

              if (aIsGlobal && bIsGlobal) {
                return sortArrayAlpha(a, b, 'name')
              }

              if (
                !aIsHostOrInherited &&
                !aIsGlobal &&
                !bIsHostOrInherited &&
                !bIsGlobal
              ) {
                return sortArrayAlpha(a, b, 'name')
              }

              return (
                (aIsHostOrInherited ? -2 : aIsGlobal ? -1 : 0) +
                (bIsHostOrInherited ? 2 : bIsGlobal ? 1 : 0)
              )
            }

            if (a.type === 'folder') {
              if (b.type === 'file') {
                return -1
              }
            }
            if (a.type === 'file' && b.type === 'folder') {
              return 1
            }

            return sortArrayAlpha(a, b, 'name')
          })
      : []
  }

  name = 'Documents'

  isReadOnly = false

  isHostFolder = false

  memberCanUpload = true

  groups: string[] = []

  bulkSelectedList: (File | Folder)[] = []

  currentDirectory: Folder | this = this

  currentPath = [this]

  get data() {
    return {
      org_id: this.org_id,
      contents: this.contents.map(c => c.data).filter(Boolean),
    }
  }

  creatingNewFolder = false

  folderCreateSuccess = false

  folderCreateError = false

  downloadError: string | undefined = undefined

  saveError: string | undefined = undefined

  get inheritedGroups(): string[] {
    return this.currentPath.reduce((acc, node) => {
      return node.groups && node.groups.length > 0
        ? [...acc, ...node.groups]
        : acc
    }, [])
  }

  get inheritedUsers(): string[] {
    return this.currentPath.reduce((acc, node) => {
      return node.users && node.users.length > 0 ? [...acc, ...node.users] : acc
    }, [])
  }

  createFolder = async (name: string, isAdminOnly = false) => {
    if (this.forbiddenFolderNames.includes(name.toLowerCase().trim())) {
      this.folderCreateError = true
      return false
    }
    this.creatingNewFolder = true
    this.isSaving = true
    this.saved = false

    const path = `${
      (this.currentDirectory && this.currentDirectory.path) || ''
    }/${name}`.replace(/\/\//g, '/')

    try {
      const { data } = await this.client.documents.createFolder({
        org_id: this.org_id,
        path,
        groups: isAdminOnly ? ['admins'] : [],
      })
      this.setContents(data.contents)
      this.creatingNewFolder = false
      this.folderCreateSuccess = true
      setTimeout(() => {
        this.folderCreateSuccess = false
      }, 10)
      log.code('doc003')
    } catch (e) {
      this.creatingNewFolder = false
      this.folderCreateError = true
      log.code('doc305', { error: e })
    }

    this.isSaving = false
    this.saved = true

    return true
  }

  nameAlreadyExists = (name: string) =>
    this.currentDirectory && this.currentDirectory.contents
      ? this.currentDirectory.contents.find(d => d.name === name)
      : false

  ensureUniqueName = (name: string): string => {
    if (this.nameAlreadyExists(name)) {
      const ext = name.split('.').pop()
      return this.ensureUniqueName(`${name.replace(`.${ext}`, '')}_copy.${ext}`)
    }
    return name
  }

  async downloadFile(node: { key: string }) {
    this.downloadError = undefined
    try {
      const data = {
        org_id: this.org_id,
        file: node.key,
      }
      const res = await this.client.documents.getDocumentDownload(data, {
        path: this.currentDirectory.path,
      })
      log.code('doc002', data)
      window.open(res.data, '_blank')
    } catch (e) {
      log.code('doc304', {
        error: e,
        data: {
          org_id: this.org_id,
          file: node.key,
        },
      })
      this.downloadError = 'Error downloading file'
    }
  }

  uploads: string[] = []

  removeFromUploads(file: { name: string }) {
    this.uploads = this.uploads.filter(f => f !== file.name)
  }

  async replaceFile(
    file: { name: string; file: object },
    user: { _id: string },
    replaceKey: string,
    isInternal = false
  ) {
    this.saveError = undefined
    if (!replaceKey)
      throw new Error('You must provide the key you want to replace')
    this.uploads.push(file.name)
    let uploadRes

    try {
      const path = this.currentDirectory.path || '/'

      uploadRes = await this.client.documents.getUploadUrl({
        filename: file.name,
        path,
        org_id: this.org_id,
        publicAccess: undefined,
      })
    } catch (e) {
      this.saveError = 'Could not upload file'
      log.code('doc302', { error: e })
      throw new Error('ERR:Upload')
    }

    try {
      const { data: requestData } = uploadRes
      const { key } = requestData
      delete requestData.key

      await axios.request({
        ...requestData,
        data: file.file,
        onUploadProgress: p => {
          this.uploadProgress[0] = p.loaded
        },
      })

      this.uploadProgress = []
      const existingDirectory = this.currentDirectory
        ? this.currentDirectory.contents
        : this.contents
      existingDirectory.forEach(item => {
        if (item.key === replaceKey) {
          const { data } = item
          // Internal documents will undergo more "live" changes, a history on this
          // would be fairly unmanageable for the end-user so just always replace the
          // file.
          if (isInternal) {
            item.key = key
          } else {
            delete data.previousFiles
            item.previousFiles.push(data)
            item.modified = Date.now()
            item.key = key
            item.name = file.name
            item.creator = user ? user._id.toString() : false
            item.viewedBy = user ? [user._id.toString()] : []
            item.wasReplaced()
          }
        }
      })
      this.removeFromUploads(file)
      await this.save()
      log.code('doc008', {
        name: file.name,
        replaced: replaceKey,
      })
      try {
        await this.client.auditLogs.createLog(
          this.org_id,
          'documents',
          replaceKey,
          {
            action: 'replace',
            userId: user._id,
            at: Date.now(),
            data: { name: file.name, replacement: replaceKey },
          }
        )
      } catch (e) {
        /* no-op */
      }
      return key
    } catch (e) {
      this.saveError = 'Could not replace file'
      this.removeFromUploads(file)
      log.code('doc306', { error: e })
      throw new Error('ERR:Replace')
    }
  }

  uploadSizes: number[] = []

  uploadProgress: number[] = []

  uploadError: { fileErrors: string[]; message: string | undefined } = {
    fileErrors: [],
    message: undefined,
  }

  async uploadFiles(
    files: { size: number; name: string; webkitRelativePath: string }[],
    currentPath: string,
    userId: string
  ) {
    this.saved = false
    this.uploadError = {
      fileErrors: [],
      message: undefined,
    }
    this.uploadSizes = []
    this.uploadProgress = []
    const uploadList: {
      file?: object
      size: number
      name: string
      path: string
    }[] = Array.from(files).map((file, idx) => {
      this.uploadSizes[idx] = file.size
      return {
        file,
        name: file.name,
        size: file.size,
        path: `${currentPath}/${file.webkitRelativePath}`.replace(
          `/${file.name}`,
          ''
        ),
      }
    })

    const uploaded: {
      file?: object
      size: number
      name: string
      path: string
      key: string
    }[] = []
    await Promise.all(
      uploadList.map(async (file, idx) => {
        try {
          const key = await this.uploadToStorage(file, idx, false)
          delete file.file
          uploaded.push({ ...file, key })
          try {
            await this.client.auditLogs.createLog(
              this.org_id,
              'documents',
              key,
              {
                action: 'upload',
                userId,
                at: Date.now(),
                data: { name: file.name },
              }
            )
          } catch (e) {
            /* no-op */
          }
        } catch (e) {
          this.uploadError.message = 'Could not upload the following file(s):'
          this.uploadError.fileErrors.push(`${file.path}/${file.name}`)
          log.code('doc302', { error: e })
        }
      })
    )
    try {
      const { data } = await this.client.documents.mergeUploadedDocuments({
        org_id: this.org_id,
        uploaded,
      })
      this.setContents(data.contents)
      this.saved = true
      setTimeout(() => {
        this.saved = false
      }, 10)
    } catch (e) {
      this.uploadError.message =
        'Error saving files, please retry or contact support'
      log.code('doc302', { error: e })
      return undefined
    }
    this.uploadSizes = []
    this.uploadProgress = []
    return uploaded
  }

  async uploadToStorage(
    file: { name: string; file: object; path?: string; size: number },
    idx: number,
    publicAccess: boolean | undefined = undefined
  ) {
    let uploadRes

    try {
      const path = file.path || this.currentDirectory.path || '/'

      uploadRes = await this.client.documents.getUploadUrl({
        filename: file.name,
        org_id: this.org_id,
        path,
        publicAccess,
      })
    } catch (e) {
      this.saveError = 'Could not upload file'
      log.code('doc302', { error: e })
      throw new Error('ERR:Upload')
    }

    try {
      const { data } = uploadRes
      const { key } = data
      delete data.key

      await axios.request({
        ...data,
        data: file.file,
        onUploadProgress: p => {
          this.uploadProgress[idx] = p.loaded
        },
      })

      return key
    } catch (e) {
      log.code('doc303', { error: e })
      throw new Error(`Failed to upload ${file.name}`)
    }
  }

  uploadTemplateImage = async (blob: object) => {
    const key = await this.uploadToStorage(
      await blobToFileForUpload(blob),
      0,
      true
    )
    const { data } = await this.client.documents.getPublicUrl({
      key,
      org_id: this.org_id,
    })
    return data.url
  }

  deleteNode = async (node: Folder | File) => {
    this.isSaving = true
    this.saved = false

    try {
      const { data } = await this.client.documents.removeContent({
        org_id: this.org_id,
        items: [{ key: node.key, name: node.name, path: node.path }],
      })
      this.setContents(data.contents)
    } catch (e) {
      //
    }

    this.isSaving = false
    this.saved = true

    log.code(node.type === 'folder' ? 'doc005' : 'doc006', {
      name: node.name,
    })

    return this.currentDirectory.path || '/'
  }

  renameNode = async (node: Folder | File, name: string) => {
    this.isSaving = true
    this.saved = false

    try {
      const { data } = await this.client.documents.renameNode({
        org_id: this.org_id,
        item: { key: node.key, name: node.name, path: node.path },
        name,
      })
      this.setContents(data.contents)
    } catch (e) {
      //
    }

    this.isSaving = false
    this.saved = true

    log.code(node.type === 'folder' ? 'doc005' : 'doc006', {
      name: node.name,
    })
  }

  bulkDeleteSelected = async () => {
    this.isSaving = true
    this.saved = false

    try {
      const { data } = await this.client.documents.removeContent({
        org_id: this.org_id,
        items: this.bulkSelectedList.map(node => ({
          key: node.key,
          name: node.name,
          path: node.path,
        })),
      })
      this.setContents(data.contents)
    } catch (e) {
      this.isSaving = false
      this.saved = true
      throw e
    }

    this.isSaving = false
    this.saved = true

    this.bulkSelectedList = []

    return true
  }

  bulkMoveSelected = async (node: Folder) => {
    this.isSaving = true
    this.saved = false

    try {
      const { data } = await this.client.documents.moveContent({
        org_id: this.org_id,
        items: this.bulkSelectedList.map(item => item.data),
        destination: { name: node.name, path: node.path },
      })
      this.setContents(data.contents)
    } catch (e) {
      this.isSaving = false
      this.saved = true
      throw e
    }

    this.isSaving = false
    this.saved = true

    this.bulkSelectedList = []

    return true
  }

  bulkExportSelected = async (name: string) => {
    const res = await this.client.documents.exportDocuments({
      data: this.bulkSelectedList.map(i => i.data),
      org_id: this.org_id.toString(),
      name,
      // So it's in their local time rather than server time
      requestedAt: moment().format('YYYY-MM-DD-HH-mm-ss'),
    })
    this.bulkSelectedList = []

    return res
  }

  isSaving = false

  saved = false

  path = '/'

  save = async () => {
    this.saved = false
    this.isSaving = true
    const { data } = await this.client.documents.saveDocuments(this.data)
    this.setContents(data.contents)
    this.bulkSelectedList = []
    this.isSaving = false
    this.saved = true
    setTimeout(() => {
      this.saved = false
    }, 10)
  }
}
