import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
} from 'mobx'

import CRMEvent from '../Model/CRMEvent'
import CRMTask from '../Model/CRMTask'

import RootStore from './RootStore'
import StoreResourceBase from './StoreResourceBase'

import { ExternalCRMEvent, ExternalCRMTask } from '../types'

export default class CRMStore extends StoreResourceBase {
  events: IObservableArray<CRMEvent> = observable.array([])

  eventsUrl = ''

  tasks: IObservableArray<CRMTask> = observable.array([])

  tasksUrl = ''

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this, {
      crmEvents: computed,
      crmTasks: computed,
      events: observable,
      eventsUrl: observable,
      tasks: observable,
      tasksUrl: observable,
      getData: action,
      getEvents: action,
      getTasks: action,
      reset: action,
    })
  }

  get crmEvents() {
    if (this.rootStore.orgStore.currentOrg.isHost) {
      return this.events.toJSON()
    }

    if (this.rootStore.orgStore.isHostUser) {
      return this.events
        .toJSON()
        .filter(
          event => event.org_id === this.rootStore.orgStore.currentOrg._id
        )
    }

    return []
  }

  get crmTasks() {
    if (this.rootStore.orgStore.currentOrg.isHost) {
      return this.tasks.toJSON()
    }

    if (this.rootStore.orgStore.isHostUser) {
      return this.tasks
        .toJSON()
        .filter(task => task.org_id === this.rootStore.orgStore.currentOrg._id)
    }

    return []
  }

  getEvents = async () => {
    const {
      orgStore: {
        currentOrg: {
          _id: currentOrgId,
          externalCRMProvider,
          isHost,
          isHostUser,
        },
      },
    } = this.rootStore

    if (externalCRMProvider && (isHost || isHostUser)) {
      try {
        const { data } = await this.rootStore.client.crm.getEvents(currentOrgId)

        if (data && data.data.length > 0) {
          this.eventsUrl = data.url

          this.events.replace(
            data.data.map((event: ExternalCRMEvent) => new CRMEvent(event))
          )
        }
      } catch (e) {
        //
      }
    }
  }

  getTasks = async () => {
    const {
      orgStore: {
        currentOrg: {
          _id: currentOrgId,
          externalCRMProvider,
          isHost,
          isHostUser,
        },
      },
    } = this.rootStore

    if (externalCRMProvider && (isHost || isHostUser)) {
      try {
        const { data } = await this.rootStore.client.crm.getTasks(currentOrgId)

        if (data && data.data.length > 0) {
          this.tasksUrl = data.url

          this.tasks.replace(
            data.data.map((task: ExternalCRMTask) => new CRMTask(task))
          )
        }
      } catch (e) {
        //
      }
    }
  }

  reset = () => {
    this.events.clear()
    this.tasks.clear()
  }

  getData = () => {
    this.reset()
    this.getEvents()
    this.getTasks()
    this.loadedResource = true
  }
}
