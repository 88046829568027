import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx'

import ModelBase from './ModelBase'

import RootStore from '../stores/RootStore'

import { OrgGroupProps } from '../types'

export default class OrgGroup extends ModelBase {
  constructor(
    rootStore: RootStore,
    group: OrgGroupProps = {
      id: undefined,
      isGlobalGroup: undefined,
      isHostGroup: undefined,
      isHidden: false,
      members: [],
      name: undefined,
    }
  ) {
    super(rootStore)
    makeObservable(this, {
      adminsCanEdit: observable,
      deletedAt: observable,
      error: observable,
      id: observable,
      isGlobalGroup: observable,
      isHidden: observable,
      isHostGroup: observable,
      isVisibleToMemberOrgs: observable,
      members: observable,
      name: observable,
      success: observable,
      addMember: action,
      removeMember: action,
      data: override,
      listTitle: override,
      category: override,
      permalink: override,
      isDeleted: computed,
    })

    this.adminsCanEdit =
      !group.isHostGroup && !group.isGlobalGroup && group.id !== 'admins'
    Object.assign(this, group)
  }

  id: string | undefined = undefined

  name = ''

  members: string[] = []

  error: string | undefined = undefined

  success = false

  deletedAt = 0

  isVisibleToMemberOrgs = false

  isHidden = false

  isHostGroup = false

  isGlobalGroup = false

  adminsCanEdit = false

  addMember(id: string) {
    this.members.push(id)
  }

  removeMember(id: string) {
    this.members = this.members.filter(m => m !== id)
  }

  getNotifyId = (): string => this.id

  get category(): string {
    return 'group'
  }

  get isDeleted() {
    return +this.deletedAt > 0
  }

  get permalink() {
    return `/directory/groups/${this.id}`
  }

  get listTitle(): string {
    return this.name
  }

  get data() {
    const data = [
      'deletedAt',
      'id',
      'isGlobalGroup',
      'isVisibleToMemberOrgs',
      'members',
      'name',
    ].reduce(
      (
        acc,
        key:
          | 'deletedAt'
          | 'id'
          | 'isGlobalGroup'
          | 'isVisibleToMemberOrgs'
          | 'members'
          | 'name'
      ) => {
        acc[key] = toJS(this[key])

        if (typeof acc[key] === 'string') {
          acc[key] = acc[key].trim()
        }

        return acc
      },
      {
        deletedAt: 0,
        id: undefined,
        isGlobalGroup: undefined,
        isVisibleToMemberOrgs: undefined,
        members: [],
        name: undefined,
      }
    )
    return data
  }

  get displayName() {
    return 'Group'
  }
}
