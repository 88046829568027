import { makeObservable, observable, override, toJS } from 'mobx'
import { generateID } from '../lib/utils'
import RootStore from '../stores/RootStore'

import ModelBase from './ModelBase'

const contactProps: string[] = [
  'id',
  'fname',
  'lname',
  'email',
  'lists',
  'custom_properties',
  'created',
]

export default class Contact extends ModelBase {
  constructor(
    rootStore: RootStore,
    contact: {
      id?: string
      fname?: string
      lname?: string
      email?: string
      lists: string[]
      custom_properties?: { id: string; type: string; name: string }[]
      created?: number
    }
  ) {
    super(rootStore)
    makeObservable(this, {
      id: observable,
      fname: observable,
      lname: observable,
      email: observable,
      created: observable,
      lists: observable,
      custom_properties: observable,
      data: override,
    })

    contactProps.forEach(prop => {
      this[prop] = contact[prop]
    })
    this.id = this.id || generateID()
    this.lists = contact.lists || []
    this.custom_properties = contact.custom_properties || []
  }

  id = generateID()

  fname = ''

  lname = ''

  email = ''

  created = Date.now()

  lists: string[] = []

  custom_properties: { id: string; type: string; name: string }[] = []

  get data() {
    return contactProps.reduce((acc: { [key: string]: unknown }, prop) => {
      acc[prop] = toJS(this[prop])
      return acc
    }, {})
  }
}
