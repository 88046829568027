import React, { FC, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import DocumentsListTable from './components/DocumentsListTable'
import DocumentUploader from './components/DocumentUploader'

import { useRootStore } from '../../services/RootStoreContext'

import Documents from '../../Model/Documents'
import Folder from '../../Model/Folder'

const FileManager: FC<{
  disableBulkActions?: boolean
  embedded?: boolean
  isHostOrgDocuments?: boolean
  onFileClick?: ((data: { node: File }) => void) | undefined
  onFolderClick?: ((data: { node: Folder }) => void) | undefined
  path?: string
}> = ({
  disableBulkActions,
  embedded,
  isHostOrgDocuments,
  onFileClick,
  onFolderClick,
  path,
}): JSX.Element => {
  const navigate = useNavigate()
  const {
    documentStore: {
      allFlattenedFolders,
      documents,
      flattendHostFolders,
      hostOrgDocuments,
    },
    orgStore: { allItemsLoaded, currentOrg, isHostUser },
    todoStore: { childOrg },
  } = useRootStore()
  const { _id: currentOrgId, isImpersonating, isOrgAdmin } = currentOrg
  const docs = isHostOrgDocuments
    ? hostOrgDocuments
    : childOrg
    ? childOrg.resources.documents
    : documents
  const [currentDirectory, setCurrentDirectory] = useState<
    Documents | Folder | undefined
  >(docs)

  useEffect(() => {
    setCurrentDirectory(
      ['', '/'].includes(path)
        ? docs
        : isHostOrgDocuments
        ? flattendHostFolders.find(folder => folder.path === path)
        : allFlattenedFolders.find(folder => folder.path === path)
    )
  }, [path, isHostOrgDocuments, docs?.saved])

  if (
    !docs ||
    (isHostOrgDocuments && (!hostOrgDocuments || isImpersonating)) ||
    !documents ||
    !allItemsLoaded
  ) {
    return null
  }

  const currentPath = path && path.length > 1 ? path : ''

  if (path && path.length > 1 && !currentDirectory && !embedded) {
    navigate('/documents')
  }

  if (currentDirectory) {
    docs.currentDirectory = currentDirectory
  }

  const getDocUploader = () => {
    if (
      (currentOrg.config && !currentOrg.config.disableMemberDocumentUploads) ||
      isOrgAdmin
    ) {
      if (
        currentDirectory &&
        currentDirectory.isReadOnly &&
        !currentDirectory.memberCanUpload &&
        (currentDirectory.org_id !== currentOrgId || !isHostUser)
      ) {
        return null
      }

      return (
        <DocumentUploader
          currentPath={currentPath}
          documents={docs}
          isHostOrgDocuments={isHostOrgDocuments}
        />
      )
    }
    return null
  }
  return (
    <Box
      width='100%'
      height='100%'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      {docs && (
        <React.Fragment>
          <DocumentsListTable
            currentDirectory={currentDirectory}
            disableBulkActions={disableBulkActions}
            documents={docs}
            embedded={embedded}
            isHostOrgDocuments={isHostOrgDocuments}
            onFolderClick={onFolderClick}
            onFileClick={onFileClick}
          />
          {getDocUploader()}
        </React.Fragment>
      )}
    </Box>
  )
}

FileManager.defaultProps = {
  disableBulkActions: false,
  embedded: false,
  isHostOrgDocuments: false,
  onFileClick: undefined,
  onFolderClick: undefined,
  path: '/',
}

export default observer(FileManager)
