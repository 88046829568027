import React, { FC, useMemo, useState } from 'react'
import { Box, Button } from '@mui/material'
import { observer } from 'mobx-react'

import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog'
import Select from './Select'
import { useRootStore } from '../../services/RootStoreContext'
import Organization from '../../Model/Organization'
import { PerformanceProviderOptions } from '../../types'

const PerformanceProviderSelect: FC<{ org?: Organization }> = ({
  org,
}): JSX.Element => {
  const { orgStore, addNotificationItem } = useRootStore()
  const { currentOrg } = orgStore
  const { isImpersonating, isOrgAdmin } = currentOrg

  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const orgToUpdate = org || currentOrg
  const { hideInvestments, isHost } = orgToUpdate
  const canChangeProvider =
    hideInvestments || isImpersonating
      ? false
      : isHost
      ? isOrgAdmin
      : orgToUpdate.host === currentOrg._id

  const { config } = orgToUpdate
  const { performanceReporting } = config
  const { provider } = performanceReporting

  const selectOptions = useMemo(() => {
    const labels = Object.values(PerformanceProviderOptions)
    const selectValues = Object.keys(PerformanceProviderOptions)
    return labels.map((label, idx) => ({
      label,
      key: label,
      value: selectValues[idx],
    }))
  }, [])

  const linkAndConfirm = useMemo(() => {
    if (isHost) {
      return (
        <ConfirmDialog
          content='Are you sure you want to change your provider? This will be the new default for all clients. Once you select a new provider your settings will be updated.'
          onConfirm={() => setIsDisabled(false)}
          trigger={
            <Button sx={{ marginTop: 2 }} variant='outlined'>
              Change Provider
            </Button>
          }
        />
      )
    }
    return null
  }, [isHost])

  if (!canChangeProvider) {
    return null
  }

  return (
    <Box width='100%'>
      <Select
        disabled={isHost ? isDisabled : false}
        label='Provider'
        onChange={(e, { value }) => {
          if (canChangeProvider) {
            orgToUpdate.config.performanceReporting.provider = value
            orgToUpdate
              .save()
              .then(() => {
                addNotificationItem({
                  success: true,
                  message: `Successfully changed Performance Provider to ${currentOrg.performanceReportingProviderDisplayName}`,
                })
              })
              .catch(() => {
                addNotificationItem({
                  error: true,
                  message: `Failed to change Performance Provider to ${currentOrg.performanceReportingProviderDisplayName}`,
                })
              })
          }
        }}
        options={selectOptions}
        required
        value={provider}
      />
      {linkAndConfirm}
    </Box>
  )
}

PerformanceProviderSelect.defaultProps = {
  org: undefined,
}

export default observer(PerformanceProviderSelect)
