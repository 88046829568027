import {
  action,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
} from 'mobx'

import Event from '../Model/Event'
import RootStore from './RootStore'
import StoreResourceBase from './StoreResourceBase'

import { EventProps } from '../types'

import { useLog } from '../lib/log'
import { handleSocketListener } from '../lib/socket'

const log = useLog()

export default class EventStore extends StoreResourceBase {
  events: IObservableArray<Event> = observable.array([])

  currentEvent: Event | null = undefined

  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      loadData: action,
      events: observable,
      addEvents: action,
      deleteEvent: action,
      currentEvent: observable,
      getMeeting: action,
      getMeetingForRecording: action,
      getModel: action,
    })

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
    reaction(() => this.events, this.handleSocket)
  }

  loadData(data: (Event | EventProps)[]) {
    this.loadedResource = false

    this.events.replace(
      observable.array(
        data
          .sort(
            (a: { start: number }, b: { start: number }) => b.start - a.start
          )
          .map(e =>
            e instanceof Event
              ? e
              : new Event(this.rootStore, {
                  ...e,
                  currentUserId: this.rootStore.userStore._id,
                })
          )
      )
    )
    this.loadedResource = true
  }

  addEvents(newEvents: EventProps[]) {
    this.loadData([
      ...this.events,
      ...newEvents.map(e => new Event(this.rootStore, e)),
    ])
  }

  getModel(_id?: string): Event {
    if (_id) {
      const model = this.events.find((event: Event) => event._id === _id)

      if (model) {
        return model
      }
    }
    return new Event(this.rootStore, {
      org_id: this.rootStore.orgStore.currentOrg?._id || undefined,
      author: this.rootStore.userStore?._id || undefined,
      currentUserId: this.rootStore.userStore?._id || undefined,
      isInProgress: true,
    })
  }

  deleteEvent = async (eventId: string) => {
    try {
      // can either be a recurring child or the parent event
      // if it contains a recurringString is the parent
      const deletedEvent = this.events.find(e => e._id === eventId)

      await this.rootStore.client.events.deleteEvent(deletedEvent)
      const { data } = await this.rootStore.client.events.getEvents(
        this.rootStore.orgStore.currentOrg?._id
      )

      this.loadData(data)
    } catch (e) {
      /* swallow */
    }
    return true
  }

  getMeetingForRecording = async (meetingId: string) => {
    const meetingRes =
      await this.rootStore.client.meetings.getMeetingForRecording(
        this.rootStore.orgStore.currentOrg._id,
        meetingId
      )
    log.code('mtg001', { data: meetingRes })
    return meetingRes.data
  }

  getMeeting = async (id: string) => {
    if (this.rootStore.userStore?.guest?._id) {
      const meetingRes = await this.rootStore.client.guests.getGuestMeeting(
        this.currentEvent?.org_id,
        this.currentEvent?.meeting_id,
        this.rootStore.userStore.guest._id
      )
      log.code('mtg002', { data: meetingRes })
      return meetingRes.data
    }

    if (
      !this.rootStore.orgStore.currentOrg ||
      !this.rootStore.orgStore.currentOrg._id
    ) {
      return {}
    }

    try {
      const meetingId = id || this.currentEvent?.meeting_id

      if (!meetingId) {
        return {}
      }

      if (
        this.currentEvent?.org_id !== this.rootStore.orgStore.currentOrg._id
      ) {
        const res =
          await this.rootStore.client.meetings.getMeetingOrg(meetingId)

        if (res?.data && this.rootStore.orgStore.currentOrg._id !== res.data) {
          await this.rootStore.orgStore.changeOrg(res.data)
        }
      }

      const meetingRes = await this.rootStore.client.meetings.getMeeting(
        this.rootStore.orgStore.currentOrg._id,
        meetingId
      )
      log.code('mtg001', { data: meetingRes })

      return meetingRes.data
    } catch (e) {
      log.code('mtg301', {
        error: e,
        org: this.rootStore.orgStore.currentOrg,
      })
      throw new Error('Could not join meeting')
    }
  }

  handleSocket = () => {
    if (this.rootStore.userStore._id) {
      handleSocketListener('events', Event, this.events, this.rootStore)
    }
  }
}
