import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'

const DocumentListTableNoRowsOverlay: FC = (): JSX.Element => (
  <Box
    alignItems='center'
    display='flex'
    flexGrow={1}
    height='100%'
    justifyContent='center'
  >
    <Typography>No Documents...</Typography>
  </Box>
)

export default DocumentListTableNoRowsOverlay
