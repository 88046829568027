import {
  IObservableArray,
  toJS,
  makeObservable,
  observable,
  override,
} from 'mobx'

import ModelBase from './ModelBase'

import RootStore from '../stores/RootStore'

import { CallToActionProps } from '../types'

import { generateID } from '../lib/utils'

export default class CallToAction extends ModelBase {
  typeOptions = [
    {
      key: 'message',
      label: 'Message',
      value: 'message',
    },
  ]

  constructor(
    rootStore: RootStore,
    action: CallToActionProps = {
      description: '',
      id: '',
      payload: {},
      roles: [],
      title: '',
      type: 'message',
    }
  ) {
    super(rootStore)

    makeObservable(this, {
      description: observable,
      id: observable,
      payload: observable,
      roles: observable,
      title: observable,
      type: observable,
      data: override,
    })

    this.description = action.description || this.description
    this.id = action.id || ''
    this.payload = action.payload || this.payload
    this.roles = observable.array(action.roles || [])
    this.title = action.title || this.title
    this.type = action.type || this.type
  }

  modelCollection = 'contactManagement'

  description = ''

  id = ''

  payload: { [key: string]: string } = {}

  roles: IObservableArray<string> = observable.array([])

  title = ''

  type = 'message'

  get data() {
    return {
      description: toJS(this.description),
      id: this.id ? toJS(this.id) : generateID(),
      payload: toJS(this.payload),
      roles: toJS(this.roles),
      title: toJS(this.title),
      type: toJS(this.type),
    }
  }
}
