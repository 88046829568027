import OrgAddress from '../Model/OrgAddress'
import OrgGroup from '../Model/OrgGroup'

export type UserType = {
  _id: string
  role: string
  orgRole?: string
  deletedAt?: number
  expiresAt?: number
  joinedAt?: number
}

export type AvailableOrg = {
  host: string
  isDisabled: boolean
  isHost: boolean
  label: string
  logo: string
  value: string
}

export type HostGlobalGroup = {
  adminsCanEdit: boolean
  allowInvestmentInvoicing: boolean
  allowInvestmentReports: boolean
  id: string
  isHidden: boolean
  name: string
}

export type FirmHoldingOption = {
  key: string
  label: string
  value: string
}

export const PerformanceReportingReportsOptions = {
  'allocation-by-class': 'Asset Class Allocation',
  'allocation-by-sector': 'Equity Sector Allocation',
  'allocation-detail': 'Allocation Detail',
  'allocation-targets': 'Allocation Targets',
  benchmarks: 'Benchmarks Performance',
  'bond-holdings': 'Bond Holdings',
  'historical-summary': 'YOY Historical Summary',
  'projected-income': 'Projected Income',
  performance: 'Performance Table',
  'performance-line-annual': 'Annual Performance Graph',
  'performance-line-inception': 'Since Inception Performance Graph',
  'strategy-performance': 'Strategies Performance',
  summary: 'Summary',
} as const

export const PerformanceProviderOptions = {
  '': 'No Provider',
  addepar: 'Addepar',
  bridge: 'Bridge',
  'first-rate': 'GreenHill',
} as const

export const ExternalCRMOptions = {
  '': 'No Provider',
  salesforce: 'Salesforce',
  'wealth-box': 'Wealth Box',
} as const

export type ExternalCRM = {
  provider: keyof typeof ExternalCRMOptions
}

export type OrgGroupProps = {
  id?: string | undefined
  isGlobalGroup?: boolean | undefined
  isHidden?: boolean | undefined
  isHostGroup?: boolean | undefined
  members?: string[]
  name?: string | undefined
}

export type OrgConfig = {
  allowEmbedMeetings: boolean
  allowHostDiscussions: boolean
  branding: {
    urlBase: string | undefined
    email?: {
      from?: { email?: string; name?: string }
      replyTo?: { email?: string; name?: string }
    }
  }
  disableMemberDocumentUploads: boolean
  globalGroups: HostGlobalGroup[] | undefined
  globalFolders:
    | { id: string; name: string; allowUploads: boolean; groups: string[] }[]
    | undefined
  inviteTemplate: string | undefined
  useHostInviteTemplate: boolean
  performanceReporting: {
    allowDateRange: boolean
    reports: (keyof typeof PerformanceReportingReportsOptions)[]
    provider: keyof typeof PerformanceProviderOptions
  }

  externalCRM: ExternalCRM
  showInvestmentInvoicing: boolean
  themeProperties: {
    body_font: string | boolean | undefined
    heading_font: string | boolean | undefined
    theme: string | boolean | undefined
  }
}

export type OrgMemberProps = {
  _id: string
  isHostAdmin: boolean
  isHostUser: boolean
  isInvitedOrPending?: boolean
  orgRole: string
  isDeleted: boolean
  deletedAt: number
  isExpired: boolean
  initialOrgRole: string
  role: string
  expiresAt: number
  fname: string
  lastSeen: number
  lname: string
  emails: string[]
  groups: string[]
  photo?: string
  phone?: string
  joinedAt?: number
}

export type InvestmentReportsMetaData = {
  commentary: string
  disclaimer: string
  quarterEnding: string | number
}

export type OrgProps = {
  _id?: string
  address: OrgAddress | undefined
  config: OrgConfig
  groups: unknown[] | undefined
  external_contact_id: string
  hideEvents: boolean
  hideForms: boolean
  hideInvestmentBilling?: boolean
  hideInvestments: boolean
  hideMembersByHost: boolean
  hideMessages: boolean
  hidePolls: boolean
  hidePosts: boolean
  hideTodos: boolean
  host: string | undefined
  hostGlobalGroups: HostGlobalGroup[]
  hostGroups: OrgGroup[] | undefined
  host_admins: string[]
  host_name: string | undefined
  legal_business_name: string
  showAnalytics: boolean
  showNewReports: boolean
  showOutreach: boolean
  isEmailDigestEnabled: boolean
  defaultPostId: string
  members?: OrgMemberProps[]
}
