import React, {
  FC,
  FocusEventHandler,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  Box,
  FormHelperText,
  IconButton,
  Stack,
  styled,
  Tooltip,
} from '@mui/material'

import { GetWrappedIcon } from '../Icons'
import {
  EditorAttachmentModal,
  TextFieldLinkAttachments,
} from '../EditorAttachmentComponents'
import QuillToolbar from './QuillToolbar'
import QuillEditorTextarea from './QuillEditorTextarea'

import ModelBase from '../../Model/ModelBase'

import useResponsive from '../../hooks/useResponsive'
import useHeight from '../../hooks/useHeight'

const RootStyle = styled('div')(
  ({ theme, ownerState: { maximized, windowHeight } }) => ({
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px ${theme.palette.divider}`,
    '& .ql-container.ql-snow': {
      borderColor: 'transparent',
      ...theme.typography.body1,
      fontFamily: theme.typography.fontFamily,
    },
    '& .ql-editor': {
      overflowY: 'auto',
      maxHeight: windowHeight > 600 ? '450' : '145px',
      minHeight: maximized ? '40vh' : '120px',
      marginTop: 'auto',
      '&.ql-blank::before': {
        fontStyle: 'normal',
        color: theme.palette.text.disabled,
      },
      '& pre.ql-syntax': {
        ...theme.typography.body2,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[900],
      },
    },
  })
)

const StyledDiv = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}))

const Editor: FC<{
  autoFocus?: boolean
  children?: JSX.Element
  disabled?: boolean
  error?: boolean
  isForTodos?: boolean
  helperText?: string
  id?: string
  isDesktop?: boolean
  item?: ModelBase
  name?: string
  placeholder?: string
  sx?: object
  value?: string
  onChange: (newVal: string) => void
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
}> = ({
  children,
  disabled,
  error,
  isForTodos,
  helperText,
  id,
  item,
  sx,
  value,
  onBlur,
  ...rest
}): JSX.Element => {
  const [editorId] = useState(
    () => id || `wysiwyg-${(Math.random() + 1).toString(36).substring(2)}`
  )

  const windowHeight = useHeight()

  if (localStorage.getItem('qa-test')) {
    return (
      <textarea
        data-qa='wysiwyg-editor-emulator'
        id={editorId}
        value={value || ''}
        onChange={e => {
          if (typeof rest.onChange === 'function') {
            rest.onChange(e.target.value)
          }
        }}
      />
    )
  }

  const [maximized, toggleMaximized] = useState(false)
  const [showFormatter, setShowFormatter] = useState(true)
  const isDesktop = useResponsive('up', 'md')

  useEffect(() => {
    if (!isDesktop) {
      setShowFormatter(false)
    } else {
      setShowFormatter(true)
    }
  }, [isDesktop])

  const handleToggleMaximized = useCallback(() => {
    toggleMaximized(!maximized)
  }, [maximized])

  const handleToggleFormatter = useCallback(() => {
    setShowFormatter(!showFormatter)
  }, [showFormatter])

  return (
    <Fragment>
      <RootStyle
        onBlur={onBlur}
        ownerState={{ maximized, windowHeight }}
        sx={{
          ...(error && {
            border: theme => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
        id='evestech-editor-container'
      >
        <QuillToolbar
          id={`${editorId}-toolbar`}
          sx={showFormatter ? undefined : { display: 'none' }}
        />
        {/* ReactQuill displays the textarea  */}
        <QuillEditorTextarea
          id={editorId}
          disabled={disabled}
          value={value}
          {...rest}
        />
        {/* displays links attached to the bottom of textarea  */}
        {item && <TextFieldLinkAttachments item={item} />}
        {/* Holds all the buttons at the bottom of the editor, the Send and Cancel button are passed in as children */}
        <Box
          sx={{
            pr: 2,
            mb: 2,
            mt: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <StyledDiv>
            {/* toggles visibility of the formatter on the top of the textarea */}
            <Tooltip
              title={showFormatter ? 'Hide formatter' : 'Show Formatter'}
            >
              <IconButton size='small' onClick={handleToggleFormatter}>
                <GetWrappedIcon name='textFormat' />
              </IconButton>
            </Tooltip>
            {/* the attachement icon seen as paperclip attached items will appear in TextFieldLinkAttachments */}
            <EditorAttachmentModal isForTodos={isForTodos} item={item} />
            {/* maximizes and minimizes the text area */}
            <Tooltip title={maximized ? 'Minimize' : 'Expand'}>
              <IconButton size='small' onClick={handleToggleMaximized}>
                <GetWrappedIcon name={maximized ? 'minimize' : 'expand'} />
              </IconButton>
            </Tooltip>
          </StyledDiv>
          <Stack direction='row'>{children}</Stack>
        </Box>
      </RootStyle>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </Fragment>
  )
}

Editor.defaultProps = {
  autoFocus: false,
  children: null,
  disabled: false,
  error: false,
  isForTodos: false,
  helperText: undefined,
  id: undefined,
  isDesktop: false,
  item: undefined,
  name: undefined,
  onBlur: undefined,
  placeholder: undefined,
  sx: {},
  value: undefined,
}

export default Editor
