import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx'

import { generateID } from '../lib/utils'

import RootStore from '../stores/RootStore'

import ModelBase from './ModelBase'

import {
  RelationshipManagementAssignment,
  RelationshipManagementProps,
  RelationshipManagementRole,
} from '../types'

export default class RelationshipManagement extends ModelBase {
  constructor(rootStore: RootStore, data: RelationshipManagementProps) {
    super(rootStore)
    makeObservable(this, {
      _id: observable,
      assignments: observable,
      org_id: observable,
      roles: observable,
      handleDefaultUserRoles: action,
      assigneeOptions: action,
      addAssigneeToList: action,
      removeAssigneeFromList: action,
      getUserDefaultRole: action,
      save: action,
      roleOptions: computed,
      visibileRoleOptions: computed,
      data: override,
      currentOrgAssignment: computed,
      assignedContactRoles: computed,
    })

    this.loadData(data)
  }

  _id = ''

  assignments: IObservableArray<RelationshipManagementAssignment> =
    observable.array([])

  org_id = ''

  roles: IObservableArray<RelationshipManagementRole> = observable.array([])

  loadData = (data?: RelationshipManagementProps) => {
    if (data) {
      this._id = data._id || this._id
      this.assignments.replace(data.assignments || [])
      this.org_id = data.org_id || this.org_id
      this.roles.replace(data.roles || [])
    } else if (this.rootStore.orgStore.currentOrg.isHost) {
      this.org_id = this.rootStore.orgStore.currentOrg._id
    }
  }

  assigneeOptions(
    orgId: string
  ): { value: string; key: string; label: string }[] {
    const orgAssignment = this.assignments.find(
      assigned => assigned.org_id === orgId
    )
    return this.rootStore.orgStore.currentOrg.members.length > 0
      ? this.rootStore.orgStore.currentOrg.members
          .filter(
            member =>
              member.orgRole !== 'disabled' &&
              (!orgAssignment ||
                !orgAssignment.users.some(user => user._id === member._id))
          )
          .map(member => ({
            value: member._id,
            key: member._id,
            label: `${member.fname} ${member.lname}`,
          }))
      : []
  }

  addAssigneeToList(orgId: string, id: string) {
    const userRoles: string[] = []

    this.roles.forEach(role => {
      if (id && role.defaultFor && role.defaultFor.includes(id)) {
        userRoles.push(role.id)
      }
    })

    let orgAssignment = this.assignments.find(
      assigned => assigned.org_id === orgId
    )

    if (!orgAssignment) {
      orgAssignment = {
        org_id: orgId,
        users: [
          {
            _id: id,
            roles: userRoles,
          },
        ],
      }
      this.assignments.push(orgAssignment)
    }

    if (!orgAssignment.users.some(user => user._id === id)) {
      orgAssignment.users.push({
        _id: id,
        roles: userRoles,
      })
    }
  }

  removeAssigneeFromList(orgId: string, id: string) {
    const orgAssignment = this.assignments.find(
      assigned => assigned.org_id === orgId
    )

    if (orgAssignment) {
      orgAssignment.users = orgAssignment.users.filter(
        users => users._id !== id
      )
    }
  }

  handleDefaultUserRoles(userId: string, roles: string[]) {
    if (roles && roles.length > 0) {
      this.roles.replace(
        this.roles.map(currentRole => {
          if (
            roles.includes(currentRole.id) &&
            !currentRole.defaultFor.includes(userId)
          ) {
            currentRole.defaultFor.push(userId)
          }

          return currentRole
        })
      )
    }

    this.save(false).catch()
  }

  getUserDefaultRole = (userId: string) => {
    if (userId === 'user') {
      return []
    }
    const userRoles: string[] = []

    this.roles.forEach(role => {
      if (userId && role.defaultFor && role.defaultFor.includes(userId)) {
        userRoles.push(role.id)
      }
    })
    return userRoles
  }

  handleRemoveRole(role: RelationshipManagementRole) {
    this.roles.remove(role)
    this.assignments.replace(
      this.assignments.map(assignment => {
        assignment.users = assignment.users.map(user => {
          user.roles = user.roles.filter(userRole => userRole !== role.id)

          return user
        })

        return assignment
      })
    )

    this.rootStore.orgStore.currentOrg.contactManagement.removeRelationshipRole(
      role
    )
  }

  save = async (notify = true) => {
    if (this.rootStore.orgStore.currentOrg.isHost) {
      try {
        const { data } =
          await this.rootStore.client.relationshipManagement.save(this.data)

        this.loadData(data)

        this.rootStore.orgStore.currentOrg.contactManagement.save(false)

        if (notify) {
          this.rootStore.addNotificationItem({
            message: 'Successfully updated Relationship Management',
            success: true,
          })
        }
      } catch (e) {
        if (notify) {
          this.rootStore.addNotificationItem({
            error: true,
            message: `Failed to update Relationship Management. ${e.message}.`,
          })
        }
      }
    }
  }

  get currentOrgAssignment() {
    return this.assignments.find(
      assigned => assigned.org_id === this.rootStore.orgStore.currentOrg._id
    )
  }

  get assignedContactRoles() {
    const { contactManagement, members } = this.rootStore.orgStore.currentOrg
    return this.currentOrgAssignment
      ? contactManagement.contactRoles
          .filter(
            ({ id }) =>
              this.roles.some(rmRole => rmRole.id === id) &&
              this.currentOrgAssignment.users.filter(user =>
                user.roles.includes(id)
              )
          )
          .filter(role =>
            this.currentOrgAssignment.users.some(
              user =>
                user.roles.includes(role.id) &&
                members.some(mem => mem._id === user._id)
            )
          )
          .map(({ id }) => this.roles.find(rmRole => rmRole.id === id))
      : []
  }

  get visibileRoleOptions() {
    return this.roles
      .filter(roleOpt => !roleOpt.isHidden)
      .map(roleOpt => ({
        value: roleOpt.id,
        key: roleOpt.id,
        label: roleOpt.name,
      }))
  }

  get roleOptions() {
    return this.roles.map(roleOpt => ({
      value: roleOpt.id,
      key: roleOpt.id,
      label: roleOpt.name,
    }))
  }

  get data(): RelationshipManagementProps {
    if (!this.org_id && this.rootStore.orgStore.currentOrg.isHost) {
      this.org_id = this.rootStore.orgStore.currentOrg._id
    }
    const data = {
      _id: toJS(this._id),
      assignments: this.assignments.toJSON(),
      roles: this.roles.toJSON().map(role => {
        if (role.id.length === 0) {
          role.id = generateID()
        }

        return role
      }),
      org_id: toJS(this.org_id),
    }

    if (!data._id) {
      delete data._id
    }

    return data
  }
}
