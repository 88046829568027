import React from 'react'
import { styled } from '@mui/material/styles'
import { Paper, InputBase } from '@mui/material'

import { GetWrappedIcon } from '../../components/Icons'

const StyledPaper = styled(Paper)(() => ({
  p: '2px 4px',
  display: 'flex',
  alignItems: 'center',
}))

const Search = styled('div')(() => ({
  position: 'relative',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1rem + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}))

// ----------------------------------------------------------------------

const SearchInput = props => (
  <StyledPaper variant='outlined'>
    <Search>
      <SearchIconWrapper>
        <GetWrappedIcon name='search' color='disabled' />
      </SearchIconWrapper>
      <StyledInputBase
        inputProps={{ 'aria-label': 'search' }}
        placeholder='Search...'
        type='search'
        {...props}
      />
    </Search>
  </StyledPaper>
)

export default SearchInput
