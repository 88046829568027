import { observable, action, computed, makeObservable } from 'mobx'
import { createViewModel } from 'mobx-utils'

import { generateID } from '../lib/utils'
import RootStore from '../stores/RootStore'
import MemberRecord from './MemberRecord'
import MemberRecordProperty from './MemberRecordProperty'
import MemberRecordsRecordProperty from './MemberRecordsRecordProperty'

export default class MemberRecords {
  constructor(
    rootStore: RootStore,
    memberRecords: {
      org_id?: string | undefined
      properties?: MemberRecordsRecordProperty[] | undefined
      records?: { properties: MemberRecordProperty[] }[] | undefined
    } = {
      org_id: undefined,
      properties: [],
    }
  ) {
    this.rootStore = rootStore
    makeObservable(this, {
      org_id: observable,
      properties: observable,
      records: observable,
      addNewMemberRecord: action,
      getMemberRecord: action,
      addNewProperty: action,
      deleteProperty: action,
      savingMemberRecords: observable,
      propertiesView: computed,
      save: action,
    })

    this.org_id = memberRecords.org_id
    this.properties =
      memberRecords.properties && Array.isArray(memberRecords.properties)
        ? memberRecords.properties.map(
            prop => new MemberRecordsRecordProperty(prop)
          )
        : []
    this.setRecords(memberRecords)
  }

  org_id: string | undefined = ''

  properties: MemberRecordsRecordProperty[] | undefined = []

  records: MemberRecord[] = []

  setRecords(memberRecords: {
    properties?: MemberRecordsRecordProperty[]
    records?: {
      data?: { properties: MemberRecordProperty[] }
      properties?: MemberRecordProperty[]
    }[]
  }) {
    this.records =
      memberRecords.records && Array.isArray(memberRecords.records)
        ? memberRecords.records.map(mr => {
            const mrData = mr.data || mr
            if (!mrData.properties) mrData.properties = []
            memberRecords.properties.forEach(mrp => {
              if (!mrData.properties.find(rp => mrp.id === rp.id)) {
                mrData.properties.push({ id: mrp.id, value: '' })
              }
            })
            return new MemberRecord(mrData)
          })
        : []
  }

  addNewMemberRecord = (id: string) => {
    this.records.push(new MemberRecord({ id }))
    return this.records.find(r => r.id === id)
  }

  getMemberRecord = (id: string) => {
    return this.records.find(r => r.id === id) || this.addNewMemberRecord(id)
  }

  addNewProperty = (label = '', visible = true, memberCanEdit = false) => {
    this.properties.push(
      new MemberRecordsRecordProperty({
        id: generateID(),
        label,
        visible,
        memberCanEdit,
      })
    )
    this.setRecords(this)
  }

  deleteProperty = (propToDelete: MemberRecordsRecordProperty) => {
    this.properties = this.properties.filter(
      prop => prop.id !== propToDelete.id
    )
    this.records = this.records.map(record => {
      record.properties = record.properties.filter(
        prop => prop.id !== propToDelete.id
      )
      return record
    })
  }

  savingMemberRecords = false

  get propertiesView() {
    return this.properties.map(prop => createViewModel(prop))
  }

  save = async () => {
    this.savingMemberRecords = true

    // Purge empty fields
    this.properties = this.properties.filter(p => p.label && p.label.length > 0)

    try {
      const res = await this.rootStore.client.org.saveMemberRecords({
        org_id: this.org_id,
        properties: this.properties.map(p => p.data),
        records: this.records.map(r => r.data),
      })
      this.setRecords(res.data)
      this.savingMemberRecords = false
    } catch (e) {
      this.savingMemberRecords = false
      throw e
    }
  }
}
