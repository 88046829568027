import React, { useState, FC } from 'react'
import { useRouteError } from 'react-router-dom'
import {
  Alert,
  Box,
  Typography,
  CardContent,
  Card,
  Button,
  CardMedia,
  Container,
} from '@mui/material'

import ErrorImg from '../assets/problem_error_warning_alert.svg'
import { useLog } from '../lib/log'

const log = useLog()

const ErrorPage: FC = (): React.ReactElement | null => {
  const error = useRouteError()
  const [showErrorStack, setShowErrorStack] = useState(false)

  if (
    error?.message &&
    /^Failed to fetch dynamically imported module:.*$/.test(error?.message)
  ) {
    location.reload()
    return null
  }

  log.code('ptf301', { error: error?.message })

  if (Intercom && process.env.NODE_ENV !== 'development') {
    Intercom('show')
  }

  return (
    <Container
      maxWidth='md'
      sx={{ height: '100%', alignItems: 'center', display: 'flex' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Card sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <CardMedia
              component='img'
              sx={{ width: 240, display: 'flex', flexDirection: 'column' }}
              image={ErrorImg}
              alt='Error image'
            />
            <CardContent sx={{ flex: '.3 0 auto', pl: 7 }}>
              <Typography component='div' variant='h3'>
                Oops! Something went wrong.
              </Typography>
              <Typography
                variant='subtitle1'
                color='text.disabled'
                sx={{ mt: 2 }}
              >
                We were unable to process your request. Please try again,
                <br />
                if the problem persists please contact us.
              </Typography>

              <Box sx={{ mt: 4, alignSelf: 'center' }}>
                <Button variant='contained' onClick={() => location.reload()}>
                  Try Again
                </Button>
              </Box>
            </CardContent>
          </Box>
        </Card>
        {process.env.NODE_ENV !== 'production' && (
          <Box sx={{ mt: 3 }}>
            {!showErrorStack && (
              <Button
                onClick={() => {
                  setShowErrorStack(true)
                }}
                role='presentation'
              >
                Show error details
              </Button>
            )}
            {showErrorStack && (
              <Alert severity='warning'>
                <pre
                  style={{
                    border: '1px solid #ccc',
                    padding: '.3em',
                    overflow: 'scroll',
                    height: '20em',
                  }}
                >
                  {error.stack}
                </pre>
              </Alert>
            )}
          </Box>
        )}
      </Box>
    </Container>
  )
}

export default ErrorPage
