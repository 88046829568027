import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { observer } from 'mobx-react'
import { ViewModel } from 'mobx-utils'
import PropTypes, { InferProps } from 'prop-types'

import { GetWrappedIcon } from '../Icons'

import { sortArrayAlpha } from '../../utils/arrays'
import { useRootStore } from '../../services/RootStoreContext'

import ModelBase from '../../Model/ModelBase'

const PermissionsIconPopupContent = ({
  item,
}: InferProps<typeof PermissionsIconPopupContent.propTypes>) => {
  const { orgStore } = useRootStore()
  const theme = useTheme()
  const { currentOrg } = orgStore

  if (!currentOrg || !item) {
    return null
  }

  const hasGroupsPermissions = item.groups && item.groups.length > 0
  const hasUsersPermissions = item.users && item.users.length > 0
  const hasInheritedGroupPermissions =
    item.inheritedGroups && item.inheritedGroups.length > 0
  const hasInheritedUserPermissions =
    item.inheritedUsers && item.inheritedUsers.length > 0
  const itemTitle = (item ? item.displayName : 'item').toLowerCase()

  const commonIcon = {
    height: '1rem',
    width: '1rem',
  }

  if (
    !hasGroupsPermissions &&
    !hasUsersPermissions &&
    !hasInheritedGroupPermissions &&
    !hasInheritedUserPermissions
  ) {
    return (
      <Typography key={`${item.getNotifyId()}-pipc-all-members`}>
        This {itemTitle} is available to all members
      </Typography>
    )
  }

  const list = [
    <Typography key={`${item.getNotifyId()}-pipc-list-header`}>
      This {itemTitle} can be accessed by:
    </Typography>,
  ]
  const { groups, hostGroups, members } = currentOrg
  const allGroups = [...groups, ...hostGroups]
  const inactiveGroups = allGroups.filter(g => g.isDeleted)
  const inactiveMembers = members.filter(
    m => m.orgRole === 'disabled' || m.isDeleted || m.isExpired
  )

  const addGroupsToList = (groupArray, allowInactive = false) => {
    groupArray
      .map(group => {
        const orgGroup = allGroups.find(og => og.id === group)

        if (orgGroup) {
          return orgGroup
        }

        if (group === 'admins') {
          return { name: 'Administrators', id: 'admins' }
        }

        if (currentOrg.isHostWithGlobalGroups) {
          return currentOrg.config.globalGroups.find(g => g.id === group)
        }

        return false
      })
      .filter(Boolean)
      .sort((a, b) => sortArrayAlpha(a, b, 'name'))
      .forEach(orgGroup => {
        if (orgGroup) {
          if (
            !allowInactive &&
            inactiveGroups.some(g => g.id === orgGroup.id)
          ) {
            return
          }

          if (
            allowInactive &&
            !inactiveGroups.some(g => g.id === orgGroup.id)
          ) {
            return
          }

          if (orgGroup.isDeleted) {
            list.push(
              <Typography key={`${item.getNotifyId()}-${orgGroup.id}`}>
                <GetWrappedIcon
                  {...commonIcon}
                  color={theme.palette.error.main}
                  name={orgGroup.isHostGroup ? 'star' : 'group'}
                />
                &nbsp;
                {orgGroup.name} - Removed
              </Typography>
            )
          } else {
            list.push(
              <Typography key={`${item.getNotifyId()}-${orgGroup.id}`}>
                <GetWrappedIcon
                  {...commonIcon}
                  name={orgGroup.isHostGroup ? 'star' : 'group'}
                />
                &nbsp;
                {orgGroup.name}
              </Typography>
            )
          }
        } else if (orgGroup.id === 'admins' && !allowInactive) {
          list.push(
            <Typography key={`${item.getNotifyId()}-${orgGroup.id}`}>
              <GetWrappedIcon {...commonIcon} name='group' />
              &nbsp;Administators
            </Typography>
          )
        } else if (currentOrg.isHostWithGlobalGroups) {
          const globalGroup = currentOrg.config.globalGroups.find(
            g => g.id === orgGroup.id
          )
          if (globalGroup) {
            list.push(
              <Typography key={`${item.getNotifyId()}-${orgGroup.id}`}>
                <GetWrappedIcon {...commonIcon} name='group' />
                &nbsp;{globalGroup.name} (Global)
              </Typography>
            )
          }
        }
      })
  }

  const addUsersToList = (userArray, allowInactive) => {
    userArray
      .map(user => members.find(m => m._id.toString() === user.toString()))
      .filter(Boolean)
      .sort((a, b) => sortArrayAlpha(a, b, 'fullName'))
      .forEach(member => {
        const isInactive = inactiveMembers.some(m => m._id === member._id)

        if ((!allowInactive && isInactive) || (allowInactive && !isInactive)) {
          return
        }

        if (isInactive) {
          const verb = member.isDeleted
            ? 'Removed'
            : member.isExpired
            ? 'Membership Expired'
            : 'Disabled'
          list.push(
            <Typography key={`${item.getNotifyId()}-${member._id}`}>
              <GetWrappedIcon
                {...commonIcon}
                name='user'
                color={theme.palette.error.main}
              />
              &nbsp;{member.fullName} - {verb}
            </Typography>
          )
        } else {
          list.push(
            <Typography key={`${item.getNotifyId()}-${member._id}`}>
              <GetWrappedIcon {...commonIcon} name='user' />
              &nbsp;{member.fullName}
            </Typography>
          )
        }
      })
  }

  // Only add the inherited permissions if item level does not have their own
  //
  if (!hasGroupsPermissions && !hasUsersPermissions) {
    list[0] = (
      <Typography key={`${item.getNotifyId()}-inherited-header`}>
        This {itemTitle} has inherited permissions and can be accessed by:
      </Typography>
    )
  }

  const groupArrayToAdd = hasGroupsPermissions
    ? item.groups
    : hasInheritedGroupPermissions && !hasUsersPermissions
    ? item.inheritedGroups
    : []
  const userArrayToAdd = hasUsersPermissions
    ? item.users
    : hasInheritedUserPermissions && !hasGroupsPermissions
    ? item.inheritedUsers
    : []

  // First add the active folks
  addGroupsToList(groupArrayToAdd, false)
  addUsersToList(userArrayToAdd, false)

  // Now inactive if there are any
  const haveInactivesToAdd =
    (inactiveGroups.length > 0 &&
      inactiveGroups.some(iaG => groupArrayToAdd.includes(iaG.id))) ||
    (inactiveMembers.length > 0 &&
      inactiveMembers.some(iaG => userArrayToAdd.includes(iaG.id)))
  if (haveInactivesToAdd) {
    list.push(<br key={`${item.getNotifyId()}-inactive-break`} />)
    addGroupsToList(groupArrayToAdd, true)
    addUsersToList(userArrayToAdd, true)
  }

  return list
}

PermissionsIconPopupContent.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.instanceOf(ModelBase),
    PropTypes.instanceOf(ViewModel),
  ]).isRequired,
}

export default observer(PermissionsIconPopupContent)
