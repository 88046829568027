import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  toJS,
  reaction,
} from 'mobx'
import moment from 'moment'

import ModelBase from './ModelBase'
import Organization from './Organization'
import TodoItem from '../Model/TodoItem'

import { reorderArray } from '../utils/arrays'
import { useLog } from '../lib/log'

import RootStore from '../stores/RootStore'

import { TodoProps } from '../types'

const log = useLog()

const toDoProperties = [
  '_id',
  'author',
  'completedAt',
  'contacts',
  'archived',
  'items',
  'name',
  'org_id',
  'chainItems',
  'readBy',
]

export default class Todo extends ModelBase {
  modelCollection = 'todos'

  org: Organization | undefined = undefined

  childOrg: Organization | undefined = undefined

  constructor(
    rootStore: RootStore,
    toDo: TodoProps = {
      _id: undefined,
      contacts: undefined,
      createdAt: Date.now(),
      name: undefined,
      org_id: undefined,
      templateId: undefined,
      updatedAt: Date.now(),
    },
    org: Organization | undefined = undefined
  ) {
    super(rootStore)
    makeObservable(this, {
      _id: observable,
      archived: observable,
      author: observable,
      chainItems: observable,
      completedAt: observable,
      contacts: observable,
      items: observable,
      name: observable,
      org_id: observable,
      org: observable,
      readBy: observable,
      templateId: observable,
      addItem: action,
      removeItem: action,
      isComplete: computed,
      someComplete: computed,
      data: override,
      orgName: computed,
      canSave: computed,
      remove: action,
      markComplete: action,
      toggleArchived: action,
      save: action,
      status: computed,
      completePercentage: computed,
      getUserCompletePercentage: action,
      userHasAssignedItem: action,
      nudgeAssignees: action,
      contactMembers: computed,
      getUserStatus: action,
      getCompleteColor: action,
      getMyStatusColor: action,
      getCanEdit: computed,
      errorMsg: observable,
      handleSetOrg: action,
      groupSelection: computed,
      onDragEnd: action,
      validate: action,
      setOrgById: action,
      latestDueItem: computed,
      handleStoreUpdate: action,
      isIncompleteByCurrentUser: computed,
    })

    toDoProperties.forEach(prop => {
      if (toDo[prop]) {
        if (prop === 'items') {
          this.items = toDo[prop].map(
            item => new TodoItem(rootStore, item, org)
          )
        } else {
          this[prop] = toDo[prop]
        }
      }
    })

    this.createdAt = toDo.createdAt || Date.now()
    this.templateId = toDo.templateId
    this.updatedAt = toDo.updatedAt
    reaction(() => this.isComplete, this.handleStoreUpdate)
  }

  _id: string | undefined = undefined

  archived = false

  author = ''

  chainItems = false

  createdAt = 0

  completedAt = 0

  contacts: { groups: string[]; users: string[] } = { groups: [], users: [] }

  items: TodoItem[] = []

  name = ''

  org_id: string | undefined = undefined

  readBy: string[] = []

  templateId: string | undefined = undefined

  updatedAt = 0

  errorMsg: string | undefined = undefined

  setOrgById = async (orgId = this.org_id) => {
    if (orgId) {
      if (!this.org || this.org._id !== orgId) {
        this.org_id = orgId
        await this.handleSetOrg()
      }
    }
  }

  handleStoreUpdate = async () => {
    this.rootStore.todoStore.updatedOn = moment().valueOf()
  }

  getNotifyId = (): string => this._id

  addItem(data = {}) {
    const newItem = new TodoItem(
      this.rootStore,
      {
        links: [],
        groups: [],
        users: [],
        author: this.rootStore?.userStore?._id,
        ...data,
      },
      this.org
    )
    this.items.push(newItem)
  }

  onDragEnd = (result: {
    destination: { index: number } | undefined
    source: { index: number } | undefined
    type: string
  }) => {
    if (
      !result.destination ||
      result.type !== 'todo-item' ||
      result.source.index === result.destination.index
    ) {
      return
    }

    this.items = reorderArray(
      this.items,
      result.source.index,
      result.destination.index
    )
  }

  removeItem(id: string) {
    if (this.items && this.items.length > 0) {
      this.items = this.items.filter(item => item.id !== id)
    }
  }

  get status(): string {
    return this.isComplete
      ? 'Completed'
      : this.someComplete
      ? 'Partially Complete'
      : 'Incomplete'
  }

  getUserStatus(userId: string): string {
    return !this.userHasAssignedItem(userId)
      ? 'No Action Required'
      : this.getUserCompletePercentage(userId) === 0
      ? 'Not Started'
      : this.getUserCompletePercentage(userId) === 100
      ? 'Completed'
      : 'Partially Complete'
  }

  getCompleteColor = (status: string): string => {
    if (status === 'Partially Complete') {
      return 'warning'
    }

    if (status === 'Completed') {
      return 'success'
    }

    return 'error'
  }

  getMyStatusColor = (status: string) => {
    if (status === 'No Action Required') {
      return 'info'
    }

    if (status === 'Not Started') {
      return 'default'
    }

    if (status === 'Completed') {
      return 'success'
    }

    return 'warning'
  }

  get isComplete(): boolean {
    return (
      this.items &&
      this.items.length > 0 &&
      this.items.every(item => item.isComplete)
    )
  }

  get isIncompleteByCurrentUser(): boolean {
    const percentageCount = this.getUserCompletePercentage(
      this.rootStore?.userStore?._id
    )
    const userHasAssignedItem = this.userHasAssignedItem(
      this.rootStore?.userStore?._id
    )
    if (userHasAssignedItem && percentageCount !== 100) {
      return true
    }
    return false
  }

  get someComplete(): boolean {
    return (
      this.items &&
      this.items.length > 0 &&
      this.items.some(item => item.someComplete)
    )
  }

  get data() {
    return toDoProperties.reduce(
      (acc, prop) => {
        if (prop === 'items') {
          acc.items = this.items.map(item => item.data)
        } else {
          acc[prop] = toJS(this[prop])
        }
        return acc
      },
      {
        _id: undefined,
        contacts: { groups: [], users: [] },
        createdAt: undefined,
        items: undefined,
        name: undefined,
        org_id: undefined,
        templateId: undefined,
        updatedAt: undefined,
      }
    )
  }

  get displayName(): string {
    return 'To-do'
  }

  get orgName(): string {
    return this.org
      ? this.org.legal_business_name
      : this.rootStore.orgStore.availableOrgs.find(
          org => org.value === this.org_id
        )?.label || ''
  }

  get canSave(): boolean {
    return (
      Boolean(this.name) &&
      this.items &&
      this.items.length > 0 &&
      this.items.every(item => item.canSave)
    )
  }

  get getCanEdit(): boolean {
    if (
      this.rootStore.orgStore &&
      this.rootStore.orgStore.currentOrg &&
      this.rootStore.userStore &&
      this.rootStore.userStore._id
    ) {
      return this.rootStore.orgStore.currentOrg.isHost
    }
    return false
  }

  userHasAssignedItem = (userId: string): boolean => {
    return this.items.some(item => item.userIsAssignee(userId))
  }

  get completePercentage(): number {
    const [allCompletedItems, allAssignedItems] = this.items.reduce(
      (previousValue, item) => {
        const completedCount =
          previousValue[0] +
          (!item.requireAll && item.completed.length > 1
            ? 1
            : item.completed.length)
        const assigneeCount =
          previousValue[1] + (item.requireAll ? item.allAssinees.length : 1)
        return [completedCount, assigneeCount]
      },
      [0, 0]
    )
    const completedPercentage = (allCompletedItems / allAssignedItems) * 100
    return Math.round(completedPercentage)
  }

  get groupSelection() {
    if (this.org) {
      return this.org.hostGroups.filter(g => g.isVisibleToMemberOrgs)
    }

    return this.rootStore.orgStore.currentOrg.groups.filter(
      g => g.isVisibleToMemberOrgs
    )
  }

  get contactMembers() {
    if (this.org) {
      const hasGroups = this.contacts.groups && this.contacts.groups.length > 0

      let allMembersInGroup: string[] = []
      if (hasGroups) {
        this.contacts.groups.forEach(groupId => {
          // check to see if the assigned group is a client group if not check to see if is a host group
          let foundGroup = this.org.groups.find(g => g.id === groupId)
          if (!foundGroup) {
            foundGroup = this.org.hostGroups.find(
              host_g => host_g.id === groupId
            )
          }
          allMembersInGroup = allMembersInGroup.concat(
            foundGroup.members.slice()
          )
        })
      }

      const uniqueIds: string[] = [
        ...new Set([...allMembersInGroup, ...this.contacts.users]),
      ]

      return this.org.members
        .filter(m => uniqueIds.includes(m._id))
        .map(user => ({
          _id: user._id,
          name: `${user.fname} ${user.lname}`,
        }))
    }
    return []
  }

  getUserCompletePercentage = (userId: string): number => {
    // how many items have been assined to the user

    const completedObj = this.items
      .filter(item =>
        item.allAssinees.some(assignee => assignee._id === userId)
      )
      .map(item => {
        if (
          item.allAssinees.some(assignee => assignee._id === userId) &&
          (item.checkUserComplete(userId) ||
            (item.isComplete && !item.requireAll))
        ) {
          return 'complete'
        }
        return 'incomplete'
      })

    const completePercentage = completedObj.reduce(
      (previousValue, currentValue) => {
        if (currentValue === 'complete') {
          return previousValue + (1 / completedObj.length) * 100
        }
        return previousValue
      },
      0
    )

    return Math.round(completePercentage)
  }

  remove = async (todos: (typeof this)[]) => {
    if (this._id && this.org_id) {
      try {
        await this.client.todos.remove(this.org_id, this._id)
        log.code('tds002')
        return todos.filter(todo => todo._id !== this._id)
      } catch (e) {
        log.code('tds302', { error: e })
        throw e
      }
    }
    return todos
  }

  markComplete = async () => {
    try {
      this.completedAt = Date.now()
      await this.save()
    } catch (e) {
      log.code('tds303', { error: e })
      throw e
    }
  }

  markIncomplete = async () => {
    try {
      this.completedAt = 0
      await this.save()
    } catch (e) {
      log.code('tds303', { error: e })
      throw e
    }
  }

  toggleArchived = async () => {
    this.archived = !this.archived
    await this.save()
  }

  nudgeAssignees = async (toDoId: string, userIds: string[]) => {
    await this.client.todos.nudgeAssignees(this.org_id, toDoId, {
      assignees: userIds,
    })
  }

  handleSetOrg = async () => {
    if (this.org_id && (!this.org || this.org_id !== this.org._id)) {
      this.org = await this.rootStore.orgStore.getOrgById(this.org_id)

      if (this.org) {
        if (this.items && this.items.length > 0) {
          this.items.forEach(item => item.setOrg(this.org))
        }
      }
    }
  }

  validate = () => {
    const hasName = this.name && this.name.length > 0
    const hasOrg = this.org_id && this.org_id.length > 0
    const hasContacts =
      this.contacts &&
      (this.contacts.groups.length > 0 || this.contacts.users.length > 0)
    const hasItems = this.items && this.items.length > 0
    const itemsHaveName =
      hasItems && this.items.every(item => item.name.length > 0)

    if (!hasName || !hasOrg || !hasContacts || !hasItems || !itemsHaveName) {
      this.errorMsg = hasItems
        ? itemsHaveName
          ? hasName
            ? hasOrg
              ? 'You must select Contact(s)/Owners(s)'
              : 'You must select a Client'
            : 'You must supply a To-do name'
          : 'You must supply a name for every To-do Item'
        : 'You must have at least one To-Do Item'
      throw new Error(this.errorMsg)
    }

    return true
  }

  get latestDueItem(): number {
    const expiringItems: number[] = []
    this.items.forEach(item => {
      if (!item.isComplete && item.expiresAt !== 0) {
        expiringItems.push(item.expiresAt)
      }
    })
    if (expiringItems.length > 0) {
      expiringItems.sort((a, b) => {
        return a - b
      })
      return expiringItems[0]
    }
    return 0
  }

  save = async () => {
    const reqData = this.data

    if (!reqData._id) {
      delete reqData._id
    }

    try {
      const { data } = await this.client.todos.save(reqData)

      if (!this._id) {
        this._id = data._id
      }

      this.rootStore.todoStore.updateTodos(this)
      log.code('tds001')
    } catch (e) {
      log.code('tds301', { error: e })
      throw e
    }
  }
}
