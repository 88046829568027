import { observable, computed, toJS, makeObservable } from 'mobx'
import moment from 'moment'

import { generateID } from '../lib/utils'

const campaignMessageProps = [
  'id',
  'title',
  'sendAt',
  'sendTo',
  'message',
  'recipients',
]

export default class CampaignMessage {
  constructor(message: {
    id?: string
    title?: string
    sendAt?: number
    sendTo?: 'all' | 'not-seen' | 'not-visited'
    message?: string
    recipients?: object[]
  }) {
    makeObservable(this, {
      id: observable,
      title: observable,
      sendAt: observable,
      sendTo: observable,
      message: observable,
      recipients: observable,
      isValid: computed,
      data: computed,
    })
    if (!message.id) {
      message.id = generateID()
    }
    campaignMessageProps.forEach(prop => {
      if (typeof message[prop] !== 'undefined') {
        this[prop] = message[prop]
      }
    })
  }

  id = generateID()

  title = ''

  sendAt: number | undefined = undefined

  sendTo: 'all' | 'not-seen' | 'not-visited' = 'all'

  message = ''

  recipients: object[] = []

  get isValid() {
    const validProps = ['title', 'sendTo', 'message'].filter(
      prop => this[prop] && this[prop].length > 0
    )
    return Boolean(validProps && validProps.length === 3)
  }

  get data() {
    return campaignMessageProps.reduce((acc, prop) => {
      acc[prop] =
        prop === 'sendAt' ? +moment(this[prop]).valueOf() : toJS(this[prop])
      return acc
    }, {})
  }
}
