import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
} from 'mobx'
import moment from 'moment'

import InvestmentBillingProfile from '../Model/Investments/BillingProfile'
import InvestmentFeeSchedule from '../Model/Investments/FeeSchedule'
import RootStore from './RootStore'

import { handleSocketListener } from '../lib/socket'
import { useLog } from '../lib/log'
import { getMostRecentBusinessDay } from '../lib/time'

import {
  InvestmentFeeScheduleProps,
  FirmAllocation,
  InvestmentBillingProfileProps,
  SelectOptionType,
  EVestechInvoice,
} from '../types'

const log = useLog()

export default class InvestmentBillingStore {
  billingProfiles: IObservableArray<InvestmentBillingProfile> =
    observable.array([])

  feeSchedules: IObservableArray<InvestmentFeeSchedule> = observable.array([])

  firmAllocations: IObservableArray<FirmAllocation> = observable.array([])

  defaultFeeScheduleValues: InvestmentFeeScheduleProps = {
    _id: undefined,
    balanceType: 'ending',
    creator: '',
    deletedAt: 0,
    flatFee: {
      amount: 0,
      feeType: 'flat',
      createdAt: 0,
    },
    friendlyName: '',
    minimumFee: {
      amount: 0,
      feeType: 'flat',
      createdAt: 0,
    },
    name: '',
    org_id: undefined,
    priorPeriodCashFlows: 'never',
    tiers: [],
    type: 'inAdvance',
    updator: '',
  }

  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore

    makeObservable(this, {
      getData: action,
      getBillingProfile: action,
      getFeeSchedule: action,
      billingProfiles: observable,
      feeSchedules: observable,
      firmAllocations: observable,
      securityClassOptions: computed,
      securityOptions: computed,
    })

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
    reaction(() => this.feeSchedules, this.handleSocket)
  }

  get securityClassOptions(): SelectOptionType[] {
    return this.firmAllocations.reduce((allOptions, orgAllocation) => {
      orgAllocation.data.forEach(item => {
        if (!allOptions.some(option => option.value === item.type)) {
          allOptions.push({
            label: item.type,
            key: item.type,
            value: item.type,
          })
        }
      })
      return allOptions
    }, [])
  }

  get securityOptions(): SelectOptionType[] {
    return this.firmAllocations.reduce((allOptions, orgAllocation) => {
      orgAllocation.data.forEach(item => {
        if (!allOptions.some(option => option.value === item.securityId)) {
          allOptions.push({
            label: item.description,
            key: item.securityId,
            value: item.securityId,
          })
        }
      })
      return allOptions
    }, [])
  }

  getData = async () => {
    if (
      this.rootStore.orgStore?.currentOrg?.isHost &&
      this.rootStore.orgStore?.currentOrg?.hideInvestmentBilling === false
    ) {
      //
      try {
        const {
          data: { data: feeSchedulesData },
        } = await this.rootStore.client.investmentBilling.getFeeSchedules(
          this.rootStore.orgStore.currentOrg._id
        )
        this.loadFeeSchedules(feeSchedulesData)

        try {
          const {
            data: { data: billingProfilesData },
          } = await this.rootStore.client.investmentBilling.getBillingProfiles(
            this.rootStore.orgStore.currentOrg._id
          )
          this.loadBillingProfiles(billingProfilesData)
        } catch (e) {
          log.error(e)
        }

        this.getFirmAllocations()
      } catch (e) {
        log.error(e)
      }
    }
  }

  loadBillingProfiles(
    data: (InvestmentBillingProfile | InvestmentBillingProfileProps)[]
  ) {
    this.billingProfiles = observable.array(
      data
        .sort((a, b) => b.updatedAt - a.updatedAt)
        .map(profile =>
          profile instanceof InvestmentBillingProfile
            ? profile
            : new InvestmentBillingProfile(this.rootStore, profile)
        )
    )
  }

  getBillingProfile(id?: string): InvestmentBillingProfile {
    if (id) {
      const model = this.billingProfiles.find(
        (profile: InvestmentBillingProfile) => profile._id === id
      )

      if (model) {
        return model
      }
    }

    return new InvestmentBillingProfile(this.rootStore, {})
  }

  loadFeeSchedules(
    data: (InvestmentFeeSchedule | InvestmentFeeScheduleProps)[]
  ) {
    this.feeSchedules = observable.array(
      data
        .sort((a, b) => b.updatedAt - a.updatedAt)
        .map(profile =>
          profile instanceof InvestmentFeeSchedule
            ? profile
            : new InvestmentFeeSchedule(this.rootStore, profile)
        )
    )
  }

  getFeeSchedule(id?: string): InvestmentFeeSchedule {
    if (id) {
      const model = this.feeSchedules.find(
        (profile: InvestmentFeeSchedule) => profile._id === id
      )

      if (model) {
        return model
      }
    }

    return new InvestmentFeeSchedule(this.rootStore, {
      ...this.defaultFeeScheduleValues,
      creator: this.rootStore.userStore._id,
      org_id: this.rootStore.orgStore.currentOrg._id,
      updator: this.rootStore.userStore._id,
    })
  }

  getFirmAllocations = async () => {
    if (this.rootStore.orgStore.currentOrg.isHost) {
      try {
        const res =
          await this.rootStore.client.org.getInvestmentFirmAccountAllocationsByClient(
            this.rootStore.orgStore.currentOrg._id,
            {
              asOfDate: getMostRecentBusinessDay(
                moment(new Date()).subtract(1, 'day')
              ).format('YYYY-MM-DD'),
              page: 1,
              perPage: 1000,
            }
          )

        this.firmAllocations = res?.data?.data || []
      } catch (e) {
        //
      }
    }
  }

  generateInvoices = async (
    startDate: Date,
    endDate: Date,
    billableStartDate: Date = undefined,
    billableEndDate: Date = undefined,
    orgIds: string[] = []
  ): Promise<boolean> => {
    if (
      this.rootStore.orgStore?.currentOrg?.isHost &&
      this.rootStore.orgStore?.currentOrg?.hideInvestmentBilling === false
    ) {
      try {
        const startDateFormatted = moment(startDate).format('YYYY-MM-DD')
        const endDateFormatted = moment(endDate).format('YYYY-MM-DD')

        await this.rootStore.client.investmentBilling.generateInvoices(
          this.rootStore.orgStore.currentOrg._id,
          {
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            billingStartDate: billableStartDate
              ? moment(billableStartDate).format('YYYY-MM-DD')
              : startDateFormatted,
            billingEndDate: billableEndDate
              ? moment(billableEndDate).format('YYYY-MM-DD')
              : endDateFormatted,
            orgIds,
          }
        )

        return true
      } catch (e) {
        return false
      }
    }

    return false
  }

  getClientsWithoutBillingProfiles = async () => {
    if (
      this.rootStore.orgStore?.currentOrg?.isHost &&
      this.rootStore.orgStore?.currentOrg?.hideInvestmentBilling === false
    ) {
      const { data } =
        await this.rootStore.client.investmentBilling.getClientsWithoutBillingProfiles(
          this.rootStore.orgStore.currentOrg._id
        )
      return data
    }

    return Promise.reject(new Error('Unauthorized'))
  }

  approveInvoice = async (invoice: EVestechInvoice) => {
    if (
      this.rootStore.orgStore?.currentOrg?.isHost &&
      this.rootStore.orgStore?.currentOrg?.hideInvestmentBilling === false
    ) {
      return this.rootStore.client.investmentBilling.approveInvoice(
        invoice.org_id,
        invoice._id
      )
    }

    return Promise.reject(new Error('Unauthorized'))
  }

  unapproveInvoice = async (invoice: EVestechInvoice) => {
    if (
      this.rootStore.orgStore?.currentOrg?.isHost &&
      this.rootStore.orgStore?.currentOrg?.hideInvestmentBilling === false
    ) {
      return this.rootStore.client.investmentBilling.unapproveInvoice(
        invoice.org_id,
        invoice._id
      )
    }

    return Promise.reject(new Error('Unauthorized'))
  }

  approveInvoices = async (invoiceIds: (string | number)[]) => {
    if (
      this.rootStore.orgStore?.currentOrg?.isHost &&
      this.rootStore.orgStore?.currentOrg?.hideInvestmentBilling === false
    ) {
      return this.rootStore.client.investmentBilling.approveInvoices(
        this.rootStore.orgStore.currentOrg._id,
        { invoiceIds }
      )
    }

    return Promise.reject(new Error('Unauthorized'))
  }

  deleteInvoice = async (invoice: EVestechInvoice, reprocess = false) => {
    if (
      this.rootStore.orgStore?.currentOrg?.isHost &&
      this.rootStore.orgStore?.currentOrg?.hideInvestmentBilling === false &&
      invoice._id
    ) {
      await this.rootStore.client.investmentBilling.deleteInvoice(
        invoice.org_id,
        invoice._id
      )

      if (reprocess) {
        await this.generateInvoices(
          moment(invoice.startDate, 'YYYY-MM-DD').toDate(),
          moment(invoice.endDate, 'YYYY-MM-DD').toDate(),
          invoice.billingStartDate
            ? moment(invoice.billingStartDate, 'YYYY-MM-DD').toDate()
            : undefined,
          invoice.billingEndDate
            ? moment(invoice.billingEndDate, 'YYYY-MM-DD').toDate()
            : undefined,
          [invoice.org_id]
        )
      }

      return Promise.resolve(true)
    }

    return Promise.reject(new Error('Unauthorized'))
  }

  deleteInvoices = async (invoiceIds: (string | number)[]) => {
    if (
      this.rootStore.orgStore?.currentOrg?.isHost &&
      this.rootStore.orgStore?.currentOrg?.hideInvestmentBilling === false
    ) {
      return this.rootStore.client.investmentBilling.deleteInvoices(
        this.rootStore.orgStore.currentOrg._id,
        { invoiceIds }
      )
    }

    return Promise.reject(new Error('Unauthorized'))
  }

  handleSocket = () => {
    if (this.rootStore.userStore._id) {
      handleSocketListener(
        'investment_fee_schedules',
        InvestmentFeeSchedule,
        this.feeSchedules,
        this.rootStore
      )
      handleSocketListener(
        'investment_billing_profiles',
        InvestmentBillingProfile,
        this.billingProfiles,
        this.rootStore
      )
    }
  }
}
