import React, { FC, useState } from 'react'
import { Alert, LinearProgress, Paper } from '@mui/material'
import { observer } from 'mobx-react'

import DocumentUploaderDropContainer from './DocumentUploaderDropContainer'

import { useRootStore } from '../../../services/RootStoreContext'

import Documents from '../../../Model/Documents'

const DocumentUploader: FC<{
  currentPath: string
  documents: Documents
  isHostOrgDocuments?: boolean
}> = ({ currentPath, documents, isHostOrgDocuments }): JSX.Element => {
  const [isUploading, setIsUploading] = useState(false)
  const {
    orgStore: {
      currentOrg: { isImpersonating },
    },
    userStore: user,
  } = useRootStore()

  if (isHostOrgDocuments) {
    return null
  }

  const { uploadFiles, uploadError, uploadSizes, uploadProgress } = documents
  const handleUpload = async (e: {
    target: {
      files: { size: number; name: string; webkitRelativePath: string }[]
      value: string
    }
  }) => {
    if (isImpersonating) {
      return
    }

    setIsUploading(true)

    if (isUploading) {
      return
    }

    try {
      await uploadFiles(e.target.files, currentPath, user._id)
    } catch (err) {
      /* nothing */
    }
    setIsUploading(false)
    // Clear value to reset after upload
    e.target.value = ''
  }
  const getProgressSize = (): number => {
    let progressSize = 0

    if (uploadSizes) {
      Object.values(uploadSizes).forEach(s => {
        progressSize += Number(s)
      })
    }
    return progressSize
  }
  const getUploadSize = (): number => {
    let progressSize = 0

    if (uploadProgress) {
      Object.values(uploadProgress).forEach(s => {
        progressSize += Number(s)
      })
    }
    return progressSize
  }
  const progressSize = getProgressSize()
  const progressTotal = getUploadSize()
  const uploadPercent =
    progressSize > 0 || progressTotal > 0
      ? Math.ceil((progressTotal / progressSize) * 100)
      : 0
  return (
    <Paper
      sx={{
        padding: 1,
        textAlign: 'center',
      }}
    >
      <Paper>
        {+uploadPercent > 0 && (
          <LinearProgress variant='determinate' value={uploadPercent} />
        )}
        {uploadError && uploadError.message && (
          <Alert severity='error'>
            <div>{uploadError.message}</div>
            {uploadError.fileErrors.length > 0 && (
              <ul>
                {uploadError.fileErrors.map(f => (
                  <li key={f}>{f}</li>
                ))}
              </ul>
            )}
          </Alert>
        )}
      </Paper>
      <DocumentUploaderDropContainer
        handleUpload={handleUpload}
        isUploading={isUploading}
      />
    </Paper>
  )
}

DocumentUploader.defaultProps = {
  isHostOrgDocuments: false,
}

export default observer(DocumentUploader)
