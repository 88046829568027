/* eslint-disable import/prefer-default-export */

export const getIcon = name => {
  const split = name.split('.')
  const ext = /^\/.*/.test(name)
    ? 'folder'
    : split.length > 0
    ? split.pop().toLowerCase()
    : false
  switch (ext) {
    case 'pdf':
      return { icon: 'mdi:file-pdf-box', color: 'red' }
    case 'doc':
    case 'docx':
      return { icon: 'mdi:file-word', color: 'steelblue' }
    case 'xls':
    case 'xlsx':
      return { icon: 'mdi:file-excel-outline', color: 'forestgreen' }
    case 'ppt':
    case 'pptx':
      return { icon: 'mdi:file-powerpoint-outline', color: 'darkorange' }
    case 'webm':
    case 'mov':
    case 'mp4':
      return { icon: 'mdi:file-video-outline', color: 'darkcyan' }
    case 'jpg':
      return { icon: 'mdi:file-jpg-box', color: 'darkcyan' }
    case 'gif':
      return { icon: 'mdi:file-gif-box', color: 'darkcyan' }
    case 'svg':
      return { icon: 'mdi:file-outline', color: 'darkcyan' }
    case 'jpeg':
      return { icon: 'mdi:file-jpg-box', color: 'darkcyan' }
    case 'png':
      return { icon: 'mdi:file-png-box', color: 'darkcyan' }
    case 'wav':
    case 'mp3':
      return { icon: 'mdi:volume-medium', color: 'darkcyan' }
    case 'zip':
      return { icon: 'mdi:zip-box-outline', color: 'sandybrown' }
    case 'folder':
      return { icon: 'mdi:folder-open-outline', color: 'sandybrown' }
    case 'html': {
      return { icon: 'mdi:file-outline', color: 'steelblue' }
    }
    default:
      return { icon: 'mdi:file-outline', color: 'darkgray' }
  }
}
