// General functions that can be used anywhere
import { nanoid } from 'nanoid'
import { AnyObject } from '../types'

export const formatMoney = (amount: string | number, fixed = 0): string => {
  if (!amount) {
    amount = '0'
  }
  return parseFloat(
    (
      Math.round((parseFloat(amount.toString()) + Number.EPSILON) * 100) / 100
    ).toString()
  )
    .toFixed(fixed)
    .toLocaleString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const captilizeWord = (string: string): string => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const captilizeWords = (string: string): string => {
  if (typeof string !== 'string') return ''
  return string.split(' ').map(captilizeWord).join(' ')
}

export const toSnakeCase = (string: string): string =>
  string &&
  string
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('_')

export const toOrdinal = (num: string): string => {
  const actualNum = parseInt(num, 10)
  if (!actualNum) return ''
  const ordinal = ['th', 'st', 'nd', 'rd']
  const val = actualNum % 100
  return `${actualNum}${ordinal[(val - 20) % 10] || ordinal[val] || ordinal[0]}`
}

export const showNotification = (
  title: string,
  type: string,
  path: string,
  rootStore: {
    addNotificationItem: (arg0: {
      message: string
      success: boolean
      onClick: () => void
    }) => void
  }
): void => {
  try {
    if (!document.hidden && rootStore && rootStore.addNotificationItem) {
      rootStore.addNotificationItem({
        message: `${title}. Click here to view`,
        success: true,
        onClick: () => {
          location.hash = path
        },
      })
    } else if (Notification.permission === 'granted') {
      const payload = {
        actions: [{ action: 'goto', title: `Open ${type}` }],
        body: title,
        tag: title,
        data: {
          url: `${location.origin}/#/${path}`,
        },
        badge: 'favicon-32x32.png',
        icon: 'favicon-32x32.png',
        silent: false,
        requireInteraction: true,
      }

      if ('safari' in window) {
        const n = new Notification('eCIO', payload)
        n.onclick = () => {
          location.href = payload.data.url
          n.close()
        }
      } else {
        navigator.serviceWorker.ready.then(reg =>
          reg.showNotification('eCIO', payload)
        )
      }
    }
  } catch (e) {
    /* no-op, non-critical, most likely falls here when no browser support */
  }
}

export const validation = {
  // eslint-disable-next-line
  emailRegex: /^([\w\-\.\+])+\@([A-Za-z0-9\-\.])+\.([A-Za-z]{2,4})$/,
}

export const timeoutWithPauseResume = (
  fn: () => void,
  delay: number
): {
  cancel: () => void
  pause: () => void
  resume: () => void
  getTimeRemaining: () => void
} => {
  let complete = false
  let timeoutId: number
  let totalTimeRun = 0
  let startTime = new Date().getTime()
  let timeRemaining = delay

  const timeDiff = (date1: number) => new Date().getTime() - date1
  const cancel = () => {
    clearTimeout(timeoutId)
  }
  const pause = () => {
    clearTimeout(timeoutId)
    timeoutId = -1
    totalTimeRun = timeDiff(startTime)
    complete = totalTimeRun >= delay
    timeRemaining = delay - totalTimeRun
  }
  const getTimeRemaining = () => {
    if (timeoutId > 0) {
      totalTimeRun = timeDiff(startTime)
    }
    timeRemaining = delay - totalTimeRun
    return timeRemaining
  }
  const resume = () => {
    startTime = new Date().getTime()
    delay = timeRemaining
    timeoutId = complete ? -1 : +setTimeout(fn, delay)

    return timeRemaining
  }

  timeoutId = +setTimeout(fn, delay)

  return { cancel, pause, resume, getTimeRemaining }
}

export const chunkArray = (arr: unknown[], chunkSize: number) => {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

export function stringToColor(string: string) {
  if (!string) {
    return undefined
  }

  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.substr(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export const getNameInitials = (name: string): string => {
  if (typeof name !== 'string') {
    return ''
  }
  if (name.includes(' ')) {
    const nameSplit = name.split(' ')
    return `${nameSplit[0][0] || ''}${
      nameSplit[1] ? nameSplit[1][0] || '' : ''
    }`
  }

  return name[0]
}

// makes sure array of object is unique
export const unique = (array: [], propertyName: string) => {
  return array.filter(
    (e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i
  )
}

// works for array of string
export const areArraysTheSame = (arr1: string[], arr2: string[]): boolean =>
  arr1.length === arr2.length && arr2.every(arr2Item => arr1.includes(arr2Item))

export const clearCache = async () => {
  if ('caches' in window) {
    const cacheKeys = await window.caches.keys()

    await Promise.all(cacheKeys.map(key => window.caches.delete(key)))
  }
}

/** we dont want an id that starts with anything other than a letter so
 * so we genereate the id here instead
 * */

export const generateID = (): string => {
  return `i${nanoid()}`
}

// assign a a value to a property to an object
// by passing the object, path, and new value
export const set = (obj: AnyObject, path: string, value: unknown) => {
  function getPropertyName(key: string) {
    return key.split('[')[0]
  }

  function getNumberBetweenBrakets(key: string) {
    return Number(key.substring(key.indexOf('[') + 1, key.indexOf(']')))
  }

  let schema = obj
  const keysList = path.split('.')
  const len = keysList.length
  let i
  for (i = 0; i < len - 1; i += 1) {
    const key = keysList[i]
    // checking if key represents an array element e.g. users[0]
    if (key.includes('[')) {
      // getting propertyName 'users' form key 'users[0]'
      const propertyName = getPropertyName(key)
      let schemaProp = schema[propertyName]
      if (!schemaProp) {
        schemaProp = []
      }
      // schema['users'][getting index 0 from 'users[0]']
      if (!schemaProp[getNumberBetweenBrakets(key)]) {
        // if it doesn't exist create and initialise it
        schemaProp[getNumberBetweenBrakets(key)] = {}
        schema = schemaProp[getNumberBetweenBrakets(key)]
      } else {
        schema = schemaProp[getNumberBetweenBrakets(key)]
      }
    } else {
      if (!schema[key]) {
        schema[key] = {}
      }
      schema = schema[key]
    }
  } // loop ends
  // if last key is array element
  if (keysList[len - 1].includes('[')) {
    // getting propertyName 'users' form key 'users[0]'
    const propertyName = getPropertyName(keysList[len - 1])
    let schemaProp = schema[propertyName]

    if (!schemaProp) {
      schemaProp = []
    }
    // schema[users][0] = value;
    schemaProp[getNumberBetweenBrakets(keysList[len - 1])] = value
  } else {
    schema[keysList[len - 1]] = value
  }
  return true
}
export const base64regex =
  /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/

export const isBase64String = (stringToTest: string) =>
  base64regex.test(stringToTest)

export const downloadFileFromUrl = (src: string, fileName: string) => {
  fetch(src, {
    method: 'GET',
    headers: {
      'Content-Type': '*',
    },
  })
    .then(response => response.blob())
    .then(blob => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)

      // Append to html link element page
      document.body.appendChild(link)

      // Start download
      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)
    })
}

interface CompareObject {
  [key: string]: unknown
}

export const findMissingObjects = <T extends CompareObject>(
  arrayOne: T[],
  arrayTwo: T[],
  propertyOne: keyof T,
  propertyTwo: keyof T
): T[] => {
  const valuesInArrayOne: Set<unknown> = new Set(
    arrayOne.map(obj => obj[propertyOne])
  )
  const missingObjects: T[] = []

  // eslint-disable-next-line no-restricted-syntax
  for (const obj of arrayTwo) {
    if (!valuesInArrayOne.has(obj[propertyTwo])) {
      missingObjects.push(obj)
    }
  }

  return missingObjects
}
