import { alpha } from '@mui/material/styles'

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
}

const PRIMARY = {
  contrastText: '#fff',
  dark: '#15243C',
  light: '#4B5C77',
  main: '#1E3456',
}
const SECONDARY = {
  contrastText: '#fff',
  dark: '#30566D',
  light: '#6A95B0',
  main: '#457B9D',
}
const INFO = {
  main: '#1890FF',
  contrastText: '#fff',
}
const SUCCESS = {
  main: '#00AB55',
  dark: '#007B55',
  darker: '#005249',
  contrastText: '#fff',
}
const WARNING = {
  main: '#FFC107',
  contrastText: GREY[800],
}
const ERROR = {
  main: '#FF4842',
  contrastText: '#fff',
}

const palette = {
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  error: { ...ERROR },
  warning: { ...WARNING },
  info: { ...INFO },
  success: { ...SUCCESS },
  common: { black: '#000', white: '#fff', grey: GREY[0] },
  grey: GREY,
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  divider: GREY[500_24],
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  background: {
    paper: '#fff',
    default: '#f8f9fa',
  },
}

export default palette
