import React, { FC, Fragment } from 'react'
import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react'

import useFormContext from '../../../../../components/Form/FormContext'
import { useRootStore } from '../../../../../services/RootStoreContext'

const OrgToggles: FC = (): JSX.Element => {
  const { orgStore } = useRootStore()
  const { currentOrg } = orgStore

  const { setFieldValue, model: orgData } = useFormContext()

  const getInvestmentToggles = () => {
    if (!currentOrg.hideInvestments) {
      return (
        <Fragment>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControlLabel
              label='Show Investments'
              control={
                <Checkbox
                  checked={!orgData.hideInvestments}
                  onChange={() => {
                    setFieldValue('hideInvestments', !orgData.hidePreviewImage)
                  }}
                />
              }
            />
          </Grid>
          {!orgData.hideInvestments && (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControlLabel
                label='Show Invoicing'
                control={
                  <Checkbox
                    checked={
                      orgData.config && orgData.config.showInvestmentInvoicing
                    }
                    onChange={() => {
                      setFieldValue(
                        'config.showInvestmentInvoicing',
                        !orgData.config.showInvestmentInvoicing
                      )
                    }}
                  />
                }
              />
            </Grid>
          )}
        </Fragment>
      )
    }
    return null
  }

  return (
    <Container maxWidth='lg'>
      <Stack spacing={3}>
        <Box>
          <Divider>
            <Typography>Resources</Typography>
          </Divider>
          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControlLabel
                label='Show Analytics'
                control={
                  <Checkbox
                    checked={orgData.showAnalytics}
                    onClick={() => {
                      setFieldValue('showAnalytics', !orgData.showAnalytics)
                    }}
                  />
                }
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControlLabel
                label='Show Events'
                control={
                  <Checkbox
                    checked={!orgData.hideEvents}
                    onChange={() => {
                      setFieldValue('hideEvents', !orgData.hideEvents)
                    }}
                  />
                }
              />
            </Grid>
            {getInvestmentToggles()}
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControlLabel
                label='Show Messages'
                control={
                  <Checkbox
                    checked={!orgData.hideMessages}
                    onChange={() => {
                      setFieldValue('hideMessages', !orgData.hideMessages)
                    }}
                  />
                }
              />
            </Grid>
            {currentOrg.showOutreach && (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControlLabel
                  label='Show Outreach'
                  control={
                    <Checkbox
                      checked={orgData.showOutreach}
                      onChange={() => {
                        setFieldValue('showOutreach', !orgData.showOutreach)
                      }}
                    />
                  }
                />
              </Grid>
            )}
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControlLabel
                label='Show Polls'
                control={
                  <Checkbox
                    checked={!orgData.hidePolls}
                    onChange={() => {
                      setFieldValue('hidePolls', !orgData.hidePolls)
                    }}
                  />
                }
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControlLabel
                label='Show News &amp; Updates'
                control={
                  <Checkbox
                    checked={!orgData.hidePosts}
                    onChange={() => {
                      setFieldValue('hidePosts', !orgData.hidePosts)
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Divider>
            <Typography>Members</Typography>
          </Divider>
          <FormControlLabel
            label='Allow members to see each other'
            control={
              <Checkbox
                checked={!orgData.hideMembersByHost}
                onChange={() => {
                  setFieldValue('hideMembersByHost', !orgData.hideMembersByHost)
                }}
              />
            }
          />
        </Box>
      </Stack>
    </Container>
  )
}

export default observer(OrgToggles)
