import React, { FC } from 'react'
import { Input } from '@mui/material'
import { styled } from '@mui/material/styles'
import { observer } from 'mobx-react'

const InputStyle = styled(Input)(({ theme }) => ({
  padding: theme.spacing(0.5, 2),
  borderBottom: `solid 1px ${theme.palette.divider}`,
}))

const SubjectInput: FC<{ discussion: { topic: string; _id: string } }> = ({
  discussion,
}) => {
  return (
    <InputStyle
      autoFocus={!discussion?.topic}
      disableUnderline
      placeholder='Subject*'
      readOnly={Boolean(discussion?._id)}
      value={discussion?.topic || ''}
      onBlur={() => {
        if (discussion.topic && discussion.topic.trim) {
          discussion.topic = discussion.topic.trim()
        }
      }}
      onChange={({ target: { value } }) => {
        discussion.topic = value
      }}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          e.preventDefault()
        }
      }}
    />
  )
}

export default observer(SubjectInput)
