import {
  action,
  IObservableArray,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx'
import RootStore from '../stores/RootStore'

import CallToAction from './CallToAction'
import ModelBase from './ModelBase'

import {
  CallToActionProps,
  ContactManagementProps,
  RelationshipManagementRole,
} from '../types'

export default class ContactManagement extends ModelBase {
  constructor(rootStore: RootStore, data: ContactManagementProps) {
    super(rootStore)
    makeObservable(this, {
      callsToAction: observable,
      contactRoles: observable,
      org_id: observable,
      _id: observable,
      addCallToAction: action,
      addContactRole: action,
      handleContactRolesChange: action,
      removeCallToAction: action,
      removeContactRole: action,
      save: action,
      data: override,
    })

    this.loadData(data)
  }

  loadData = (data?: ContactManagementProps) => {
    if (data) {
      this._id = data._id || this._id
      this.org_id = data.org_id || this.org_id
      this.callsToAction.replace(
        data.callsToAction
          ? data.callsToAction.map(
              call => new CallToAction(this.rootStore, call)
            )
          : []
      )
      this.contactRoles.replace(data.contactRoles || [])
    }
  }

  _id = ''

  callsToAction: IObservableArray<CallToAction> = observable.array([])

  contactRoles: IObservableArray<{ id: string }> = observable.array([])

  org_id = ''

  addCallToAction = (contactAction: CallToActionProps) => {
    const defaultAction = {
      type: 'message',
      description: '',
      title: '',
    }
    this.callsToAction.push(
      new CallToAction(this.rootStore, { ...defaultAction, ...contactAction })
    )
  }

  handleContactRolesChange = (roles: string[]) => {
    const availableRoles: RelationshipManagementRole[] = this.rootStore.orgStore
      .currentOrg.relationshipManagement
      ? this.rootStore.orgStore.currentOrg.relationshipManagement.roles
      : []
    const contactRoles = availableRoles.filter(available =>
      roles.includes(available.id)
    )
    this.contactRoles.replace(contactRoles.map(({ id }) => ({ id })))
  }

  addContactRole = (role: { id: string }) => {
    this.contactRoles.push(role)
  }

  removeCallToAction = (orgAction: CallToAction) => {
    this.callsToAction.remove(orgAction)
  }

  removeContactRole = (role: { id: string }) => {
    this.contactRoles.remove(role)
  }

  removeRelationshipRole = (role: { id: string }) => {
    this.contactRoles.replace(this.contactRoles.filter(cR => cR.id !== role.id))
    this.callsToAction.forEach(cta => {
      cta.roles.replace(cta.roles.filter(ctaRole => ctaRole !== role.id))
    })
  }

  save = async (notify = true) => {
    if (this.rootStore.orgStore.currentOrg.isHost) {
      try {
        const { data } = await this.rootStore.client.contactManagement.save(
          this.data
        )

        this.loadData(data)

        if (notify) {
          this.rootStore.addNotificationItem({
            message: 'Successfully updated Contact Management',
            success: true,
          })
        }
      } catch (e) {
        if (notify) {
          this.rootStore.addNotificationItem({
            error: true,
            message: `Failed to update Contact Management. ${e.message}.`,
          })
        }

        throw e
      }
    }
  }

  get data() {
    const data = {
      _id: toJS(this._id),
      callsToAction: toJS(
        this.callsToAction.map(callToAction => callToAction.data)
      ),
      contactRoles: toJS(this.contactRoles),
      org_id: toJS(this.org_id),
    }

    if (!data._id) {
      delete data._id
    }

    return data
  }
}
