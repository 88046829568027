import APIResponseType from './APIResponseType'

export const getContent = (
  org_id: string,
  investments = false
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/content${investments ? '?investments=true' : ''}`,
  method: 'GET',
})

export const saveContent = (
  data: { org_id: string },
  id: string = undefined,
  sendNotification = false
): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/content${id ? `/${id}` : ''}${
    sendNotification ? '?notify=true' : ''
  }`,
  method: id ? 'PATCH' : 'POST',
  data,
})

export const toggleArchived = (
  orgId: string,
  contentId: string
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/content/${contentId}/archive`,
  method: 'PATCH',
})

export const getUploadUrl = (data: {
  org_id: string
  filename: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/file`,
  method: 'POST',
  data: { filename: data.filename },
})

export const videoExists = (data: {
  org_id: string
  key: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/file/${data.key}/exists`,
  method: 'GET',
})

export const getContentData = (
  data: {
    org_id: string
    file: string
    host_id?: string
  },
  query?: object
): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/file/${
    typeof data?.file === 'string' ? data.file.replace(/\s/g, '<space>') : ''
  }${data.host_id ? `/${data.host_id}` : ''}`,
  params: query,
  method: 'GET',
})

export const getMetadata = (data: {
  org_id: string
  url: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/content/metadata`,
  method: 'POST',
  data: { url: data.url },
})

export const deleteContent = (data: {
  org_id: string
  _id: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/content/${data._id}`,
  method: 'DELETE',
})

export const trackContent = (data: { org_id: string }): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/content/track`,
  method: 'POST',
  data,
})
