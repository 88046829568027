import React, { memo } from 'react'
import { Box } from '@mui/material'
import PropTypes, { InferProps } from 'prop-types'

const RightSidePanel = ({
  children,
  hideScrollBar,
}: InferProps<typeof RightSidePanel.propTypes>) =>
  children ? (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        overflowX: hideScrollBar ? 'chip' : 'inherit',
      }}
    >
      {children}
    </Box>
  ) : null

RightSidePanel.defaultProps = {
  children: undefined,
  hideScrollBar: false,
}

RightSidePanel.propTypes = {
  children: PropTypes.node,
  hideScrollBar: PropTypes.bool,
}

export default memo(RightSidePanel)
