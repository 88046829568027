import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx'
import * as yup from 'yup'

import ModelBase from '../ModelBase'

import { useLog } from '../../lib/log'

import RootStore from '../../stores/RootStore'

import {
  AssetOverrideProps,
  InvestmentFeeScheduleProps,
  TierFeeType,
  SelectOptionType,
} from '../../types'
import { getFeeDescription, formatMoney } from '../../lib/investmentBilling'
import TierFee from './TierFee'
import FeeBase from './FeeBase'

const log = useLog()

export default class FeeSchedule extends ModelBase {
  modelCollection = 'investment_fee_schedules'

  constructor(rootStore: RootStore, profile: InvestmentFeeScheduleProps) {
    super(rootStore)
    makeObservable(this, {
      save: action,
      data: override,
      displayName: override,
      _id: observable,
      assetOverrides: observable,
      balanceType: observable,
      creator: observable,
      flatFee: observable,
      friendlyName: observable,
      minimumFee: observable,
      name: observable,
      org_id: observable,
      priorPeriodCashFlows: observable,
      tiers: observable,
      type: observable,
      validationSchema: override,
      description: computed,
      tieredOrFlat: computed,
      hasAnyFlatFee: computed,
      hasFlatFee: computed,
      hasMinimumFee: computed,
    })

    this.setProps(profile)
  }

  setProps = (data: InvestmentFeeScheduleProps) => {
    this._id = data._id || undefined
    this.balanceType = data.balanceType
    this.createdAt = data.createdAt || Date.now()
    this.creator = data.creator || this.rootStore.userStore._id
    this.flatFee = new FeeBase(data.flatFee)
    this.friendlyName = data.friendlyName || ''
    this.minimumFee = new FeeBase(data.minimumFee)
    this.name = data.name || ''
    this.org_id = data.org_id || this.rootStore.orgStore.currentOrg._id
    this.priorPeriodCashFlows = data.priorPeriodCashFlows
    this.tiers = observable.array(
      data.tiers ? data.tiers.map(tier => new TierFee(tier)) : []
    )
    this.type = data.type
    this.updatedAt = data.updatedAt || Date.now()
    this.updator = data.updator || this.rootStore.userStore._id
  }

  _id: string | undefined = undefined

  createdAt = Date.now()

  deletedAt = 0

  updatedAt = Date.now()

  assetOverrides: IObservableArray<AssetOverrideProps> = observable.array([])

  balanceType: 'ending' | 'average' = 'ending'

  creator: string = undefined

  flatFee: FeeBase = undefined

  friendlyName = ''

  minimumFee: FeeBase = undefined

  name = ''

  org_id = ''

  priorPeriodCashFlows: 'always' | 'never' | 'notWithMinimumFee' = 'never'

  tiers: IObservableArray<TierFee> = observable.array([]) // split up to own model

  type: 'arrears' | 'inAdvance' = 'inAdvance'

  updator = ''

  addTier = () => {
    this.tiers.push(
      new TierFee({
        amount: 0,
        createdAt: Date.now(),
        feeType: 'percent',
        maximumBalance: 0,
        minimumBalance: 0,
      })
    )

    return this
  }

  get tieredOrFlat(): 'Tiered' | 'Flat Dollar' | 'Flat Percent' {
    return this.tiers.length > 0
      ? 'Tiered'
      : `Flat ${this.flatFee.feeType === 'percent' ? 'Percent' : 'Dollar'}`
  }

  get hasMinimumFee(): boolean {
    return this?.minimumFee?.amount > 0
  }

  get hasFlatFee(): boolean {
    return this?.flatFee?.amount > 0
  }

  get isFlatDollar(): boolean {
    return this.hasFlatFee && this.flatFee.feeType === 'flat'
  }

  get hasAnyFlatFee(): boolean {
    return this.hasMinimumFee || this.hasFlatFee
  }

  get id(): string {
    return this._id
  }

  get typeSelectOptions(): SelectOptionType[] {
    return [
      {
        key: 'arrears',
        label: 'Arrears',
        value: 'arrears',
      },
      {
        key: 'inAdvance',
        label: 'In Advance',
        value: 'inAdvance',
      },
    ]
  }

  get feeTypeSelectOptions(): SelectOptionType[] {
    return this.flatFee.feeTypeSelectOptions
  }

  get balanceTypeSelectOptions(): SelectOptionType[] {
    return [
      {
        key: 'ending',
        label: 'Ending Balance',
        value: 'ending',
      },
      {
        description: 'This is not yet implemented',
        disabled: true,
        key: 'average',
        label: 'Average Daily Balance',
        value: 'average',
      },
    ]
  }

  get priorPeriodCashFlowsSelectOptions(): SelectOptionType[] {
    return [
      {
        key: 'always',
        label: 'Always',
        value: 'always',
      },
      {
        key: 'never',
        label: 'Never',
        value: 'never',
      },
      {
        key: 'notWithMinimumFee',
        label: 'Not if Minimum Fee is applied',
        value: 'notWithMinimumFee',
      },
    ]
  }

  get displayName(): string {
    return 'Investment Fee Schedule'
  }

  get data(): InvestmentFeeScheduleProps {
    const flatFeeData = this.flatFee.data

    return {
      _id: toJS(this._id),
      balanceType: toJS(this.balanceType),
      creator: toJS(this.creator),
      deletedAt: toJS(this.deletedAt),
      flatFee: flatFeeData,
      friendlyName: toJS(this.friendlyName),
      minimumFee: this.minimumFee.data,
      name: toJS(this.name),
      org_id: toJS(this.org_id),
      priorPeriodCashFlows:
        this.type === 'inAdvance' ? toJS(this.priorPeriodCashFlows) : 'never',
      tiers: flatFeeData.amount > 0 ? [] : this.tiers.map(tier => tier.data),
      type: toJS(this.type),
      updator: toJS(this.updator),
    }
  }

  get validationSchema(): yup.Schema<object> {
    const feeValidationObject = {
      amount: yup.number(),
      feeType: yup
        .string()
        .oneOf(['flat', 'percent'], 'This field is required'),
    }
    const feeValidation = yup.object(feeValidationObject)

    return yup.object().shape({
      assetOverrides: yup.array(
        yup.object({
          ...feeValidationObject,
          securityId: yup.string(),
          symbol: yup.string().required(),
        })
      ),
      balanceType: yup
        .string()
        .oneOf(['ending', 'average'], 'This field is required')
        .required(),
      flatFee: feeValidation,
      friendlyName: yup.string().required('Display Name is required'),
      minimumFee: feeValidation,
      name: yup.string().required(),
      org_id: yup.string().required(),
      priorPeriodCashFlows: yup
        .string()
        .oneOf(
          ['always', 'never', 'notWithMinimumFee'],
          'This field is required'
        )
        .required(),
      tiers: yup.array(
        yup.object({
          ...feeValidationObject,
          maximumBalance: yup.number().required(),
          minimumBalance: yup.number().required(),
        })
      ),
      type: yup
        .string()
        .oneOf(['arrears', 'inAdvance'], 'This field is required')
        .required(),
    })
  }

  getTierDescription = (tier: TierFeeType) =>
    `$${formatMoney(tier.minimumBalance)} - $${formatMoney(
      tier.maximumBalance
    )}: ${getFeeDescription(tier)}`

  get description(): string | string[] {
    return this.tiers.length > 0
      ? this.tiers.map(this.getTierDescription)
      : getFeeDescription(this.flatFee)
  }

  save = async () => {
    try {
      const { data } =
        await this.rootStore.client.investmentBilling.saveFeeSchedule(this.data)

      this.setProps(data)

      if (
        this.rootStore.investmentBillingStore.feeSchedules.some(
          schedule => schedule._id === this._id
        )
      ) {
        this.rootStore.investmentBillingStore.feeSchedules.replace(
          this.rootStore.investmentBillingStore.feeSchedules.map(schedule => {
            if (schedule._id === this._id) {
              return this
            }

            return schedule
          })
        )
      } else {
        this.rootStore.investmentBillingStore.feeSchedules.push(this)
      }
    } catch (e) {
      log.error({ errorMessage: e.message, errorStack: e.stack })
      throw e
    }
  }
}
