import React, { FC, useCallback, useMemo } from 'react'
import { Alert, Button, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { IViewModel } from 'mobx-utils'

import {
  ClientTagPermissionFilter,
  OrganizationGroupUserSelect,
} from '../../Form'
import ModalDialog from '../../ModalDialog/ModalDialog'
import GlobalContentFolder from '../../Actions/GlobalContentFolder'

import { useRootStore } from '../../../services/RootStoreContext'

import Folder from '../../../Model/Folder'

const HostInheritOption: FC<{
  isFromList: boolean
  node: IViewModel<Folder>
}> = ({ isFromList, node }): JSX.Element => {
  const {
    addNotificationItem,
    documentStore: { documents },
    orgStore: {
      currentOrg: {
        config: { globalGroups },
        isHost,
        isOrgAdmin,
      },
    },
  } = useRootStore()

  if (
    !isHost ||
    !isOrgAdmin ||
    !isFromList ||
    node.type !== 'folder' ||
    node.path.split('/').length > 2
  ) {
    return null
  }

  const modalContent = useMemo(
    () => (
      <Stack spacing={2}>
        <Typography>{node.name}</Typography>
        <Alert severity={node.isInherited ? 'success' : 'warning'}>
          Status: {node.isInherited ? '' : 'Not '}Currently a Global Content
          Folder
        </Alert>
        <ClientTagPermissionFilter
          item={node}
          onChange={(newTags: string[]) => {
            node.isInherited = true
            node.permissionTags.replace(newTags)
          }}
          helperText='Limit folder to clients that have any of the selected tag(s)'
          sx={{ mt: 3 }}
        />
        <OrganizationGroupUserSelect
          allowSave={false}
          disableAllOrgMembers
          groups={globalGroups}
          groupsOnChange={newVals => {
            node.isInherited = true
            node.groups.replace(newVals)
          }}
          groupsState={node.groups.toJSON()}
          hideMembers
          item={node}
          label='Global Groups'
          members={[]}
          permissionsName='Global Group'
          usersOnChange={() => {
            return true
          }}
          usersState={[]}
        />
      </Stack>
    ),
    [node.data]
  )

  const additonalActions = useMemo(
    () =>
      node.isInherited ? (
        <Button
          color='error'
          onClick={() => {
            const initialGroups = node.groups || []
            const initialTags = node.permissionTags || []

            node.isInherited = false
            node.groups = []
            node.users = []
            node.permissionTags = []

            node.submit()

            documents
              .save()
              .then(() => {
                addNotificationItem({
                  message: `Successfully made ${node.name} unavailable.`,
                  success: true,
                })
              })
              .catch(() => {
                node.isInherited = !node.isInherited
                node.groups = initialGroups
                node.permissionTags = initialTags

                node.submit()
                addNotificationItem({
                  error: true,
                  message: `Failed to make ${node.name} unavailable.`,
                })
              })
          }}
          variant='contained'
        >
          Remove as Global Content Folder
        </Button>
      ) : null,
    [node.data]
  )

  const onConfirm = useCallback(() => {
    const initialUsers = node.users || []
    const initialGroups = node.groups || []
    node.isInherited = true
    const hasGroups = node.groups.length > 0

    node.submit()

    node
      .toggleGlobalFolderContent()
      .then(() => {
        addNotificationItem({
          message: `Successfully made ${node.name} ${
            !node.isInherited ? 'un' : ''
          }available to ${
            hasGroups ? 'selected Global Groups' : 'all members of all clients'
          }.`,
          success: true,
        })
      })
      .catch(err => {
        node.isInherited = !node.isInherited
        node.users = initialUsers
        node.groups = initialGroups

        node.submit()
        addNotificationItem(
          {
            error: true,
            message:
              err?.response?.data ||
              `Failed to make ${node.name} ${
                node.isInherited ? 'un' : ''
              }available to ${
                hasGroups
                  ? 'selected Global Groups'
                  : 'all members of all clients'
              }.`,
          },
          10000 // increase timeout a little so they can get the clients that are causing the problem
        )
      })
  }, [node.data])

  return (
    <ModalDialog
      additionalActions={additonalActions}
      confirmActionText='Save as Global Content Folder'
      onConfirm={onConfirm}
      header='Global Content Folder Details'
      content={modalContent}
      trigger={<GlobalContentFolder data-qa='share-folder-client-action' />}
    />
  )
}

export default observer(HostInheritOption)
