import APIResponseType from './APIResponseType'

export const getTags = (org_id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/tags`,
  method: 'GET',
})

export const saveTag = (data: {
  _id?: string | undefined
  org_id: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/tags${data._id ? `/${data._id}` : ''}`,
  method: data._id ? 'PATCH' : 'POST',
  data,
})

export const process = (org_id: string, _id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/tags/${_id}/process`,
  method: 'POST',
})

export const remove = (org_id: string, _id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/tags/${_id}`,
  method: 'DELETE',
})
