export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: theme.palette.grey[800],
        },
        tooltip: {
          ...theme.typography.body2,
          backgroundColor: theme.palette.grey[800],
        },
      },
      defaultProps: {
        placement: 'bottom-start',
      },
    },
  }
}
