/* eslint-disable react/require-default-props */
import React, { ForwardRefRenderFunction, forwardRef, ReactNode } from 'react'
import { alpha, styled, SxProps, useTheme } from '@mui/material'

const ScrollbarStyled = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  }
})

const Scrollbar: ForwardRefRenderFunction<
  HTMLDivElement,
  {
    children: ReactNode
    sx?: SxProps
    alwaysShowScroll?: boolean
  }
> = ({ alwaysShowScroll, children, sx, ...other }, ref): JSX.Element => {
  const theme = useTheme()
  const overflowYAlwaysShowScroll = navigator.userAgent.includes('Firefox')
    ? 'auto'
    : 'overlay'
  return (
    <ScrollbarStyled
      sx={
        alwaysShowScroll
          ? {
              ...sx,
              ...{
                overflowY: 'hidden',
                '&:hover': {
                  overflowY: overflowYAlwaysShowScroll,
                  '&::-webkit-scrollbar': {
                    width: theme.spacing(0.875),
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: theme.spacing(1),
                    backgroundColor: alpha(theme.palette.grey[900], 0.6),
                  },
                },
              },
            }
          : sx
      }
      ref={ref}
      {...other}
    >
      {children}
    </ScrollbarStyled>
  )
}

export default forwardRef(Scrollbar)
