import React, { FC } from 'react'
import { Alert, Typography } from '@mui/material'
import { observer } from 'mobx-react'

import { useRootStore } from '../../services/RootStoreContext'

import ModelBase from '../../Model/ModelBase'

import { captilizeWord } from '../../lib/utils'

const PermissionsWarningMessage: FC<{
  groupsValue?: string[]
  item: ModelBase
  usersValue?: string[]
}> = ({ groupsValue, item, usersValue }): JSX.Element => {
  const {
    orgStore: { currentOrg },
    userStore: user,
  } = useRootStore()

  if (!item) {
    return null
  }

  const { isOrgAdmin } = currentOrg
  const currentUserId = user._id.toString()
  const isEvent = item.modelCollection === 'events'
  const isDocument = item.modelCollection === 'documents'
  const isMessage = item.modelCollection === 'discussions'
  const isPoll = item.modelCollection === 'polls'
  const isPost = item.modelCollection === 'content'
  const shouldReturn =
    isEvent || isPoll || isPost || isDocument || isMessage
      ? (usersValue && usersValue.length > 0) ||
        (groupsValue && groupsValue.length > 0)
      : false

  if (!shouldReturn) {
    return null
  }

  let content = null
  const displayName = captilizeWord(item.displayName)
  const isInUsers = usersValue.includes(currentUserId)
  const isInGroups = groupsValue.some(gS =>
    currentOrg.groups.some(
      g =>
        (g.id === gS && g.members.includes(currentUserId)) ||
        (gS === 'admins' && isOrgAdmin)
    )
  )

  if (!isInGroups && !isInUsers) {
    const verb = isEvent
      ? 'attending'
      : isPoll
      ? 'voting on'
      : isDocument || isMessage
      ? ''
      : false
    if (verb !== false) {
      content =
        isDocument || isMessage
          ? `You also have access to view this ${displayName}.`
          : `You are not ${verb} this ${displayName}. You will still have access to view the ${displayName}.`
    }
  }

  if (
    isInUsers &&
    usersValue.length === 1 &&
    groupsValue.length === 0 &&
    isPost
  ) {
    content = `You are the only one that is able to view this ${displayName}.`
  }

  if (content) {
    return (
      <Alert
        severity={isDocument || isMessage ? 'info' : 'warning'}
        sx={{ mb: 2 }}
      >
        <Typography>{content}</Typography>
      </Alert>
    )
  }
  return null
}

PermissionsWarningMessage.defaultProps = {
  groupsValue: [],
  usersValue: [],
}

export default observer(PermissionsWarningMessage)
