import React, { ChangeEvent, FC, useMemo } from 'react'
import { observer } from 'mobx-react'

import MultiSelect from './MultiSelect'
import Select from './Select'
import { SelectOptionType } from './index'

import usStates from '../../lib/usStates'

const StateSelect: FC<{
  multiple?: boolean
  onChange: (
    event: ChangeEvent<HTMLSelectElement>,
    option: SelectOptionType | string[]
  ) => void
  value: string | string[]
}> = (props): JSX.Element => {
  const { multiple } = props
  const options = useMemo(() => usStates, [])

  return multiple ? (
    <MultiSelect label='State' {...props} options={options} />
  ) : (
    <Select label='State' {...props} options={options} />
  )
}

StateSelect.defaultProps = {
  multiple: false,
}

export default observer(StateSelect)
