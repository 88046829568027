import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  toJS,
} from 'mobx'

import { LinkProps } from '../types'

const linkProps = ['id', 'type', 'title', 'url', 'path']

export default class Link {
  org_id: string | undefined = undefined

  constructor(
    link: LinkProps = {
      id: undefined,
      org_id: undefined,
      path: undefined,
      type: undefined,
      url: undefined,
    },
    org_id: string | undefined = undefined
  ) {
    makeObservable(this, {
      type: observable,
      title: observable,
      url: observable,
      user_id: observable,
      id: observable,
      fetchingMeta: observable,
      path: observable,
      hasEnoughData: computed,
      data: computed,
      getLinkMetadata: action,
    })

    linkProps.forEach(prop => {
      this[prop] = link[prop]
    })
    this.org_id = link.org_id || org_id
    reaction(() => this.url, this.getLinkMetadata)
  }

  type: string | undefined = undefined

  title: string | undefined = undefined

  url: string | undefined = undefined

  path: string | undefined = undefined

  user_id: string | undefined = undefined

  id: string | undefined = undefined

  fetchingMeta = false

  get hasEnoughData() {
    if (this.type === 'url') {
      try {
        // try to create the url if it fails return false
        new URL(this.url)
        return this.title && this.title.length > 0
      } catch (e) {
        return false
      }
    }
    return Boolean(this.type && this.title && this.id)
  }

  get data() {
    const data = linkProps.reduce(
      (acc, prop) => {
        acc[prop] = toJS(this[prop])
        return acc
      },
      { org_id: undefined }
    )
    if (this.type === 'document' && this.org_id) {
      data.org_id = this.org_id
    }

    return data
  }

  getLinkMetadata = async () => {
    if (this.type === 'url' && (!this.title || this.title.length === 0)) {
      this.fetchingMeta = true
      try {
        /**
         * By calling new URL, an exception will be thrown if it is an invalid URL
         * That way we do not make the API call until we have all the pieces
         */
        const url = new URL(this.url)
        const { data } = await this.client.content.getMetadata({
          org_id: this.org_id,
          url: url.href,
        })
        if (
          (this.title && this.title.length > 0) ||
          !data.title ||
          data.title.length === 0
        ) {
          this.fetchingMeta = false
          return
        }
        this.title = data.title
      } catch (e) {
        /* swallow */
      }
      this.fetchingMeta = false
    }
  }
}
