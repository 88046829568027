import React, { FC, memo, useState, useMemo } from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import Animation from '../../components/Animation/Animation'
import MainPanelCard from './MainPanelCard'
import LeftSidePanel from './LeftSidePanel'
import RightSidePanel from './RightSidePanel'
import useResponsive from '../../hooks/useResponsive'
import { TwoPanelSideNavContext } from '../../services/TwoPanelSideNavContext'

const TwoPanelView: FC<{
  left: JSX.Element | JSX.Element[]
  right?: JSX.Element | JSX.Element[]
  variant?: 'elevation' | 'outlined'
  hideScrollBar?: boolean
}> = ({ hideScrollBar, left, right, variant }): JSX.Element => {
  const isDesktop = useResponsive('up', 'lg')
  const [openSidebar, setOpenSidebar] = useState(false)
  const params = useParams()
  const { id } = params

  const contextValue = useMemo(() => {
    return { openSidebar, setOpenSidebar }
  }, [openSidebar])

  const getRightPanelContent = () => {
    if (right) {
      return right
    }

    if (!isDesktop) {
      return null
    }

    return (
      <Box sx={{ alignSelf: 'center', justifySelf: 'center', width: '100%' }}>
        <Animation
          width='5rem'
          height='5rem'
          name='list'
          text='Select an item from the list...'
        />
      </Box>
    )
  }

  return (
    <MainPanelCard variant={variant}>
      <TwoPanelSideNavContext.Provider value={contextValue}>
        <LeftSidePanel>{left}</LeftSidePanel>
        {!isDesktop && !id ? null : (
          <RightSidePanel hideScrollBar={hideScrollBar}>
            {getRightPanelContent()}
          </RightSidePanel>
        )}
      </TwoPanelSideNavContext.Provider>
    </MainPanelCard>
  )
}

TwoPanelView.defaultProps = {
  hideScrollBar: false,
  right: undefined,
  variant: 'outlined',
}

export default memo(TwoPanelView)
