import { IObservableArray } from 'mobx'
import { Order } from '../components/Table/SortableDataTable/types'

/* eslint-disable no-unused-vars */
export function sortArrayAlpha<T>(
  a: T,
  b: T,
  key?: keyof T,
  ascending = true
): number {
  const hasValidKey = typeof key === 'string'
  const aNameUpper =
    hasValidKey && a && a[key]
      ? a[key].toString().toUpperCase()
      : typeof a === 'string'
      ? a.toUpperCase()
      : ''
  const bNameUpper =
    hasValidKey && b && b[key]
      ? b[key].toString().toUpperCase()
      : typeof b === 'string'
      ? b.toUpperCase()
      : ''

  if (aNameUpper > bNameUpper) {
    return ascending ? 1 : -1
  }
  if (aNameUpper < bNameUpper) {
    return ascending ? -1 : 1
  }

  return 0
}

export function reorderArray(
  array: unknown[] | IObservableArray<object>,
  startIndex = 0,
  endIndex = 0
) {
  const result = Array.from(array || [])
  const moveItem = result[startIndex]
  result.splice(startIndex, 1)
  result.splice(endIndex, 0, moveItem)
  return result
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: string | number | boolean | undefined },
  b: { [key in Key]: string | number | boolean | undefined }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
