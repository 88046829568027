import APIResponseType from './APIResponseType'

export const remove = (orgId: string, templateId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/templates/${templateId}`,
  method: 'DELETE',
})

export const get = (orgId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/templates`,
  method: 'GET',
})

export const save = (data: {
  org_id: string
  _id?: string
  payload: { _id: undefined }
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/templates${data._id ? `/${data._id}` : ''}`,
  method: data._id ? 'PATCH' : 'POST',
  data,
})
