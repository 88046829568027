import { computed, makeObservable, observable, toJS } from 'mobx'

import { FeeType, SelectOptionType } from '../../types'

export default class FeeBase {
  amount = 0

  feeType: 'percent' | 'flat' = 'percent'

  createdAt = Date.now()

  constructor(fee?: FeeType) {
    makeObservable(this, {
      data: computed,
      amount: observable,
      feeType: observable,
      createdAt: observable,
    })

    this.amount = fee?.amount || 0
    this.feeType = fee?.feeType || 'percent'
    this.createdAt = fee?.createdAt || Date.now()
  }

  get feeTypeSelectOptions(): SelectOptionType[] {
    return [
      {
        key: 'flat',
        label: 'Annual Flat Dollar Amount',
        value: 'flat',
      },
      {
        key: 'percent',
        label: 'Annual Percentage',
        value: 'percent',
      },
    ]
  }

  get data(): FeeType {
    return {
      amount: +(toJS(this.amount) || 0),
      feeType: toJS(this.feeType),
      createdAt: toJS(this.createdAt),
    }
  }
}
