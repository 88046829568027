import React, { FC, useCallback, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { formats, redoChange, undoChange } from './QuillToolbar'

const Delta = Quill.import('delta')

const QuillEditorTextarea: FC<{
  autoFocus?: boolean
  disabled?: boolean
  id: string
}> = ({ disabled, id, ...rest }): JSX.Element => {
  const collapseToPlainTextMatcher = useCallback(
    (_node, delta) =>
      delta.compose(
        new Delta().retain(delta.length(), {
          color: false,
          background: false,
        })
      ),
    []
  )
  const ref = useRef()

  useEffect(() => {
    if (rest.autoFocus && ref?.current?.focus) {
      ref.current.focus()
    }
  }, [ref])
  return (
    <ReactQuill
      {...rest}
      ref={ref}
      formats={formats}
      id={id}
      modules={{
        clipboard: {
          matchers: [[Node.ELEMENT_NODE, collapseToPlainTextMatcher]],
        },
        history: {
          delay: 500,
          maxStack: 100,
          userOnly: true,
        },
        syntax: false,
        toolbar: {
          container: `#${id}-toolbar`,
          handlers: {
            undo: undoChange,
            redo: redoChange,
          },
        },
      }}
      preserveWhitespace
      readOnly={disabled}
    />
  )
}

QuillEditorTextarea.defaultProps = {
  autoFocus: false,
  disabled: false,
}

export default observer(QuillEditorTextarea)
