import React, { FC, Fragment, MouseEvent, useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { observer } from 'mobx-react'

import ModalDialog from '../ModalDialog/ModalDialog'
import ModelBase from '../../Model/ModelBase'

import NotificationLogs from './NotificationLogs'
import NotificationRecipients from './NotificationRecipients'

import { useRootStore } from '../../services/RootStoreContext'

const ConfirmNotificationOnSave: FC<{
  item: ModelBase
  onConfirm: (shouldNotify: boolean) => void
  onClose?: (event: MouseEvent | string) => void
  children: JSX.Element
  disabled?: boolean
  isSendOnly?: boolean
  usersState?: string[]
  groupsState?: string[]
  open?: boolean
}> = ({
  item,
  onConfirm,
  children,
  disabled,
  isSendOnly,
  usersState,
  groupsState,
  onClose,
  open,
}): JSX.Element => {
  const {
    orgStore: { currentOrg },
  } = useRootStore()
  const [notificationLogs, setNotificationLogs] = useState([])
  const [isSaving, setIsSaving] = useState(false)

  const users = usersState || item.users || []
  const groups = groupsState || item.groups || []
  const { displayName } = item

  const onConfirmClick = async (sendNotification: boolean) => {
    setIsSaving(true)

    try {
      onConfirm(sendNotification)

      if (onClose) {
        onClose('success')
      }

      setIsSaving(false)
    } catch (e) {
      setIsSaving(false)
      throw e // Just throw back
    }
  }
  // Load notification logs
  useEffect(() => {
    if (item && item._id) {
      item.client.notifications
        .getNotificationLogs(item._id)
        .then(res => {
          setNotificationLogs(res.data)
        })
        .catch(() => true)
    }
  }, [item])

  if (disabled) {
    return children
  }

  if (!children) {
    return null
  }

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      additionalActions={
        isSendOnly ? null : (
          <Button
            disabled={isSaving}
            onClick={() => onConfirmClick(false)}
            variant='outlined'
          >
            Save & Notify Me
          </Button>
        )
      }
      confirmActionText={
        isSendOnly ? 'Send Notification' : 'Save & Notify Recipients'
      }
      content={
        <Fragment>
          <NotificationRecipients
            author={item.author || item.creator}
            currentOrg={currentOrg}
            itemTypeName={displayName}
            users={users}
            groups={groups}
          />
          {notificationLogs && notificationLogs.length > 0 && (
            <Box mt={2}>
              <NotificationLogs
                logs={notificationLogs}
                members={currentOrg.members}
              />
            </Box>
          )}
        </Fragment>
      }
      header={`Send ${displayName} Notification Email`}
      onConfirm={() => onConfirmClick(true)}
      maxWidth='md'
      trigger={children}
    />
  )
}

ConfirmNotificationOnSave.defaultProps = {
  disabled: false,
  groupsState: undefined,
  isSendOnly: false,
  usersState: undefined,
  open: undefined,
  onClose: undefined,
}

export default observer(ConfirmNotificationOnSave)
