import { ExternalCRMEvent, UID } from '../types'

export default class CRMEvent {
  constructor(
    event: ExternalCRMEvent = {
      date: '',
      dateTime: '',
      description: '',
      isOwner: false,
      location: '',
      org_id: '',
      title: '',
      uid: '',
      url: '',
    }
  ) {
    Object.assign(this, event)
  }

  date: string

  dateTime: string

  description = ''

  isOwner = false

  location = ''

  org_id = ''

  title: string

  uid: UID

  url = ''
}
