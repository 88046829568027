import APIResponseType from './APIResponseType'

export const authenticate = (data: object): APIResponseType => ({
  url: '/auth/v1/authenticate',
  method: 'POST',
  data,
})

export const authenticateWithGoogle = (data: object): APIResponseType => ({
  url: '/auth/v1/authenticate/google',
  method: 'POST',
  data,
})

export const authenticateWithApple = (data: object): APIResponseType => ({
  url: '/auth/v1/authenticate/apple',
  method: 'POST',
  data,
})

export const decodeAppleToken = (data: object): APIResponseType => ({
  url: '/auth/v1/apple/decode',
  method: 'POST',
  data,
})

export const sendAutoLoginLink = (data: object): APIResponseType => ({
  url: '/auth/v1/user/autologin',
  method: 'POST',
  data,
})

export const getUserFromAutoLoginToken = (data: object): APIResponseType => ({
  url: '/auth/v1/user/autologin',
  method: 'GET',
  params: data,
})

export const signup = (data: object): APIResponseType => ({
  url: '/auth/v1/user',
  method: 'POST',
  data,
})

export const reissueToken = (): APIResponseType => ({
  url: '/auth/v1/reissue-token',
  method: 'GET',
})

export const update = (data: { _id: string }): APIResponseType => {
  // Strip out id to use in the route
  const id = data._id
  delete data._id
  return { url: `/auth/v1/user/${id}`, method: 'PATCH', data }
}

export const adminResetMemberCredentials = (
  memberId: string,
  data: {
    emails: string[]
    password: string
  }
): APIResponseType => ({
  url: `/auth/v1/user/${memberId}/admin-reset-member-credentials`,
  method: 'PATCH',
  data,
})

export const requestResetToken = (data: object): APIResponseType => ({
  url: '/auth/v1/account-recovery',
  method: 'POST',
  data,
})

export const resetPasswordFromToken = (data: {
  token: string
  password?: string
}): APIResponseType => ({
  url: `/auth/v1/account-recovery/${data.token}`,
  method: 'POST',
  data,
})

export const verifyRecoveryToken = (token: string): APIResponseType => ({
  url: `/auth/v1/account-recovery/${token}`,
  method: 'GET',
})

export const getUser = (id: string): APIResponseType => ({
  url: `/auth/v1/user/${id}`,
  method: 'GET',
})

export const inviteUsers = (data: object): APIResponseType => ({
  url: '/auth/v1/invite',
  method: 'POST',
  data,
})

export const emailCheck = (data: { email: string }): APIResponseType => ({
  url: `/auth/v1/user/email-check?email=${encodeURIComponent(data.email)}`,
  method: 'GET',
})

export const deleteUser = (data: { _id: string }): APIResponseType => ({
  url: `/auth/v1/user/${data._id}`,
  method: 'DELETE',
})

export const getIpData = (): APIResponseType => ({
  url: '/auth/v1/ipdata',
  method: 'GET',
})

export const getVersion = (): APIResponseType => ({
  url: `${location.protocol === 'https:' ? 'https://' : ''}${
    location.hostname
  }/version.txt`,
  method: 'GET',
})

export const getDevices = (id: string): APIResponseType => ({
  url: `/auth/v1/user/${id}/devices`,
  method: 'GET',
})
