import React, { FC, memo } from 'react'
import { IconButton, IconButtonProps } from '@mui/material'

import { GetWrappedIcon } from '../Icons'

const CancelButton: FC<IconButtonProps> = (props): JSX.Element => (
  <IconButton {...props}>
    <GetWrappedIcon name='close' />
  </IconButton>
)

export default memo(CancelButton)
