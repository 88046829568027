import {
  action,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
} from 'mobx'

import RootStore from './RootStore'
import Template from '../Model/Template'

import { handleSocketListener } from '../lib/socket'
import StoreResourceBase from './StoreResourceBase'

export default class TemplateStore extends StoreResourceBase {
  templates: IObservableArray<Template> = observable.array([])

  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      loadData: action,
      templates: observable,
      getTemplates: action,
      getModel: action,
      remove: action,
    })

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
    reaction(() => this.templates, this.handleSocket)
  }

  loadData(data: Template[]) {
    this.templates = observable.array(
      data.map((template: Template) => new Template(this.rootStore, template))
    )
  }

  getTemplates = async (org = this.rootStore.orgStore.currentOrg) => {
    if (org && org._id) {
      try {
        const { data } = await this.rootStore.client.templates.get(org._id)
        this.loadData(data)
        this.loadedResource = true
      } catch (e) {
        this.templates = observable.array([])
        this.loadedResource = true
      }
    }
  }

  getModel = (data?: { _id: string; type?: string }) => {
    const org_id = this.rootStore?.orgStore?.currentOrg?._id || undefined

    if (data) {
      if (data._id) {
        const template = this.templates.find(temp => temp._id === data._id)

        if (template) {
          return template
        }
      }

      return new Template(this.rootStore, {
        ...data,
        org_id,
      })
    }

    return new Template(this.rootStore, {
      org_id,
    })
  }

  updateTemplates(template: Template) {
    if (
      this.templates &&
      !this.templates.find(temp => temp._id === template._id)
    ) {
      this.templates.push(template)
    } else {
      this.templates[this.templates.findIndex(t => t._id === template._id)] =
        template
    }
  }

  remove = async (tempId: string, displayNotification = true) => {
    try {
      if (tempId) {
        const { data } = await this.rootStore.client.templates.remove(
          this.rootStore.orgStore.currentOrg._id,
          tempId
        )
        if (data.message === 'OK') {
          this.templates.replace(
            this.templates.filter(template => template._id !== tempId)
          )
          if (displayNotification) {
            this.rootStore.addNotificationItem({
              message: 'Template was succesfully removed',
              success: true,
            })
          }
        }
      }
      return this.templates
    } catch (error) {
      if (displayNotification) {
        this.rootStore.addNotificationItem({
          error: true,
          message: error.message,
        })
      }

      throw new Error(error)
    }
  }

  handleSocket = () => {
    if (this.rootStore.userStore._id) {
      handleSocketListener(
        'templates',
        Template,
        this.templates,
        this.rootStore
      )
    }
  }
}
