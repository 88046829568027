import React, { Fragment } from 'react'
import { Grid } from '@mui/material'

import { useRootStore } from '../../../services/RootStoreContext'
import ActionCard from './ActionCard'
import CallToAction from '../../../Model/CallToAction'

const CallsToAction = (): JSX.Element => {
  const { orgStore } = useRootStore()
  const {
    currentOrg: { contactManagement, relationshipManagement },
  } = orgStore

  if (!contactManagement) {
    return null
  }

  const { callsToAction } = contactManagement

  const actionsToShow = relationshipManagement.currentOrgAssignment
    ? callsToAction.filter(action =>
        action.roles.some(role =>
          relationshipManagement.currentOrgAssignment.users.some(user =>
            user.roles.includes(role)
          )
        )
      )
    : []

  if (actionsToShow.length === 0) {
    return null
  }

  return (
    <Fragment>
      {actionsToShow.map((action: CallToAction) => (
        <Grid item xs={12} md={6} lg={4} key={action.id}>
          <ActionCard action={action} />
        </Grid>
      ))}
    </Fragment>
  )
}

export default CallsToAction
