import React, { FC, Fragment } from 'react'
import { Avatar, SxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

import UserAvatar from '../UserAvatar'
import OrgMember from '../../Model/OrgMember'

export const AvatarRootStyle = styled('div')(({ theme, ownerState }) => ({
  width:
    ownerState.circleSize === 'lg'
      ? 144
      : ownerState.circleSize === 'md'
      ? 88
      : 68,
  height:
    ownerState.circleSize === 'lg'
      ? 144
      : ownerState.circleSize === 'md'
      ? 88
      : 68,
  margin: 'auto',
  borderRadius: '50%',
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.divider}`,
}))

export const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9,
    },
  },
})

const HaloAvatar: FC<{
  caption?: JSX.Element
  circleSize?: 'sm' | 'md' | 'lg'
  image?: string
  member?:
    | string
    | {
        fname: string
        lname: string
        photo: string
        name: string
        label?: string
      }
    | OrgMember
  sx?: SxProps
}> = ({ caption, circleSize, image, member, sx }): JSX.Element => {
  return (
    <Fragment>
      <AvatarRootStyle sx={sx} ownerState={{ circleSize }}>
        <DropZoneStyle>
          {image ? (
            <Avatar src={image} />
          ) : member ? (
            <UserAvatar user={member} sx={{ width: 144, height: 144 }} />
          ) : (
            <Avatar sx={{ width: 144, height: 144 }}>New Photo...</Avatar>
          )}
        </DropZoneStyle>
      </AvatarRootStyle>
      {caption}
    </Fragment>
  )
}

HaloAvatar.defaultProps = {
  caption: undefined,
  circleSize: 'lg',
  image: undefined,
  member: undefined,
  sx: undefined,
}

export default HaloAvatar
