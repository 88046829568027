import React, {
  FC,
  Fragment,
  HTMLAttributes,
  MouseEvent,
  TouchEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { styled } from '@mui/material/styles'
import {
  AutocompleteRenderOptionState,
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  InputBase,
  Paper,
  Popover,
  useTheme,
} from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import { observer } from 'mobx-react'

import { GetWrappedIcon } from '../Icons'
import { SaveButton } from '../Buttons'
import { generateID } from '../../lib/utils'
import { SelectOptionType } from './types'

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    marginBottom: theme.spacing(1),
    color: 'inherit',
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 360,
    },
    borderRadius: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    marginBottom: theme.spacing(1.5),
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'center',
      padding: theme.spacing(1.5),
      borderBottom: `1px solid ${theme.palette.divider}`,

      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}))

const StyledPopover = styled(Popover)(({ theme }) => ({
  width: 402,
  [theme.breakpoints.down('md')]: {
    width: 362,
  },
}))

const StyledInput = styled(InputBase)(({ theme }) => ({
  width: '360px',
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontSize: 14,
  },
}))

type ListMultiSelectType = {
  allowCheckAll?: boolean
  allowSave?: boolean
  disabled?: boolean
  disableTrigger?: boolean
  getHeader?: () => JSX.Element | null
  hideSave?: boolean
  options: SelectOptionType[]
  onChange: (
    event: React.ChangeEvent<HTMLElement> | undefined,
    newValue: SelectOptionType[],
    reason: string
  ) => void
  onClose?: (fromSave: boolean | undefined) => void
  popOverPlaceholder?: string
  renderOption: (
    props: HTMLAttributes<HTMLLIElement>,
    option: SelectOptionType,
    state: AutocompleteRenderOptionState
  ) => JSX.Element
  trigger?: JSX.Element | null | undefined
  value: SelectOptionType[]
  roles?: string[]
}

const ListMultiSelect: FC<ListMultiSelectType> = ({
  allowCheckAll,
  allowSave,
  disabled,
  disableTrigger,
  getHeader,
  hideSave,
  options,
  onChange,
  onClose,
  popOverPlaceholder,
  renderOption,
  trigger,
  value,
  roles,
  ...rest
}): JSX.Element => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | EventTarget>(null)
  const [tempId] = useState<string>(generateID())
  const open = Boolean(anchorEl)

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>) => {
      if (!disableTrigger) {
        setAnchorEl(event.target)
      }
    },
    []
  )

  const resetAnchor = useCallback(() => {
    if (anchorEl && anchorEl.focus) {
      anchorEl.focus()
    }

    setAnchorEl(null)
  }, [])

  const handleSaveClick = () => {
    if (onClose) {
      onClose(true)
    }
    resetAnchor()
  }

  const handleClose = useCallback(
    (e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>) => {
      if (e?.target?.name !== `${tempId}-input`) {
        if (onClose) {
          onClose(false)
        }
        resetAnchor()
      }
    },
    []
  )
  const actualTrigger =
    trigger && React.isValidElement(trigger) ? (
      typeof trigger === 'string' ? (
        <Button onClick={handleClick}>{trigger}</Button>
      ) : (
        React.cloneElement(trigger, {
          onClick: (
            event: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>
          ) => {
            if (trigger.props && trigger.props.onClick) {
              trigger.props.onClick(event)
            }
            handleClick(event)
          },
        })
      )
    ) : null

  const handleSelectAll = useCallback(() => {
    onChange(
      undefined,
      options.filter(
        (o: SelectOptionType) => !o.disabled || roles.includes(o.value)
      ),
      ''
    )
  }, [options])

  const handleDeselectAll = useCallback(() => {
    onChange(
      undefined,
      options.filter((o: SelectOptionType) => roles.includes(o.value)),
      ''
    )
  }, [options])

  const getTrigger = useCallback(() => {
    if (actualTrigger) {
      return actualTrigger
    }
    return null
  }, [trigger])

  const getHeaderNode = () => {
    if (getHeader) {
      return <Box p={0.5}>{getHeader()}</Box>
    }
    return null
  }

  const saveButtonSx = useMemo(() => {
    return { ml: 'auto' }
  }, [])

  const getButtons = () => {
    const saveButtonOnClick = allowSave ? handleSaveClick : handleClose

    if (allowCheckAll) {
      return (
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            padding: '8px 10px',
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Button
              variant='outlined'
              sx={{ mr: 1 }}
              onClick={handleSelectAll}
              disabled={value.length === options.length}
            >
              Select all
            </Button>
            <Button
              onClick={handleDeselectAll}
              variant='outlined'
              disabled={value.length === 0}
            >
              Deselect all
            </Button>
          </Box>
          {!hideSave && (
            <SaveButton onClick={saveButtonOnClick} sx={saveButtonSx} />
          )}
        </Box>
      )
    }

    if (hideSave) {
      return null
    }

    return (
      <Box
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          padding: '8px 10px',
          fontWeight: 600,
        }}
      >
        <SaveButton onClick={saveButtonOnClick} sx={saveButtonSx} />
      </Box>
    )
  }

  const autoComplete = (
    <Autocomplete
      {...rest}
      open
      multiple
      disabled={disabled}
      onClose={handleClose}
      value={value}
      onChange={onChange}
      isOptionEqualToValue={(option, currentValue) =>
        option && currentValue && option.value === currentValue.value
      }
      groupBy={opt => opt.icon}
      disableCloseOnSelect
      PopperComponent={StyledAutocompletePopper}
      renderTags={() => null}
      noOptionsText='No Options'
      renderOption={renderOption}
      options={options}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <Fragment>
          <Paper
            sx={{
              mr: 1,
              ml: 1,
              mt: 1,
              mb: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}
            variant='outlined'
          >
            <StyledInput
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              name={`${tempId}-input`}
              autoFocus
              placeholder={popOverPlaceholder}
            />
            <IconButton sx={{ p: 1 }} aria-label='search'>
              <GetWrappedIcon name='search' />
            </IconButton>
          </Paper>
          {getHeaderNode()}
          {getButtons()}
        </Fragment>
      )}
    />
  )

  return actualTrigger ? (
    <Fragment>
      <Box>{getTrigger()}</Box>
      {(anchorEl || open) && (
        <StyledPopover
          id={tempId}
          open={open}
          anchorEl={anchorEl}
          onClick={(e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>) => {
            if (Array.from(e?.target?.classList).includes('MuiBackdrop-root')) {
              handleClose(e)
            }
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <div>{autoComplete}</div>
          </ClickAwayListener>
        </StyledPopover>
      )}
    </Fragment>
  ) : (
    autoComplete
  )
}

ListMultiSelect.defaultProps = {
  allowCheckAll: false,
  allowSave: false,
  disabled: false,
  disableTrigger: false,
  getHeader: () => null,
  hideSave: false,
  onClose: undefined,
  popOverPlaceholder: undefined,
  trigger: undefined,
  roles: [],
}

export default observer(ListMultiSelect)
