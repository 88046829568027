import React, { useRef, FC, memo, MutableRefObject, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
  GridApiCommon,
  GridEventLookup,
  GridFooter,
  useGridApiEventHandler,
} from '@mui/x-data-grid-premium'

const StyledGridFooter = styled(GridFooter)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  '& .MuiDataGrid-cell': {
    border: 'none',
    '& .MuiTypography-body1, & .MuiDataGrid-treeDataGroupingCell': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  '.MuiDataGrid-main': {
    borderBottomLeftRadius: 'unset',
    borderBottomRightRadius: 'unset',
  },
}))

const SummaryFooter: FC<{
  apiRef: MutableRefObject<GridApiCommon>
  rowId: string
}> = ({ apiRef, rowId }): JSX.Element => {
  const footerRef = useRef(null)
  const handleSetSummaryRow = () => {
    if (apiRef?.current) {
      setTimeout(() => {
        if (apiRef?.current) {
          const rowElement = apiRef.current.getRowElement(rowId)

          if (rowElement && footerRef.current) {
            rowElement.style.display = 'inherit'
            const newElement = rowElement.cloneNode(true)
            rowElement.style.display = 'none'
            footerRef.current.replaceChildren(newElement)
          }
        }
      }, 1)
    }
  }
  const eventTypesToHandle: (keyof GridEventLookup)[] = [
    'columnsChange',
    'columnOrderChange',
    'columnResizeStop',
    'columnVisibilityModelChange',
    'rowsScrollEnd',
    'sortModelChange',
  ]
  eventTypesToHandle.forEach(eventType =>
    useGridApiEventHandler(apiRef, eventType, handleSetSummaryRow)
  )
  useEffect(() => {
    if (apiRef && apiRef.current) {
      handleSetSummaryRow()
    }
  }, [apiRef && apiRef.current && apiRef.current.getRowElement(rowId)])

  return <StyledGridFooter ref={footerRef} />
}

export default memo(SummaryFooter)
