/* eslint-disable react/default-props-match-prop-types */
import React, { ForwardedRef } from 'react'
import { Icon, IconProps } from '@iconify/react'
import { useTheme } from '@mui/material/styles'
import AndroidLogoIcon from './Logos/Android'
import AppleLogoIcon from './Logos/Apple'
import FirefoxLogoIcon from './Logos/Firefox'
import GoogleLogoIcon from './Logos/Google'
import GoogleChromeLogoIcon from './Logos/GoogleChrome'
import MicrosoftLogoIcon from './Logos/Microsoft'
import MicrosoftEdgeLogoIcon from './Logos/MicrosoftEdge'
import MicrosoftOutlookLogoIcon from './Logos/MicrosoftOutlook'
import OrganizationIcon from './OrganizationIcon'
import SafariLogoIcon from './Logos/Safari'
import YahooLogoIcon from './Logos/Yahoo'

const categoryIcons = {
  analytics: { icon: 'mdi:google-analytics' },
  clients: { icon: 'mdi:account-multiple' },
  comment: { icon: 'mdi:chat' },
  comments: { icon: 'mdi:chat' },
  content: { icon: 'mdi:newspaper' },
  create: { icon: 'mdi:plus' },
  discussion: { icon: 'mdi:chat' },
  discussions: { icon: 'mdi:chat' },
  documents: { icon: 'mdi:folder-open' },
  event: { icon: 'mdi:calendar' },
  events: { icon: 'mdi:calendar' },
  folder: { icon: 'mdi:folder' },
  group: { icon: 'mdi:account-multiple' },
  investments: { icon: 'fluent:arrow-trending-lines-20-filled' },
  link: { icon: 'mdi:open-in-new' },
  member: { icon: 'mdi:user' },
  message: { icon: 'mdi:chat' },
  messages: { icon: 'mdi:chat' },
  new: { icon: 'mdi:new-box' },
  orgForm: { icon: 'mdi:form-select' },
  orgForms: { icon: 'mdi:form-select' },
  form: { icon: 'mdi:form-select' },
  forms: { icon: 'mdi:form-select' },
  poll: { icon: 'mdi:checkbox-marked-outline' },
  polls: { icon: 'mdi:checkbox-marked-outline' },
  post: { icon: 'mdi:newspaper' },
  posts: { icon: 'mdi:newspaper' },
  stars: { icon: 'mdi:star' },
  tag: { icon: 'mdi:tag-outline' },
  tags: { icon: 'mdi:tag' },
  template: { icon: 'mdi:clipboard' },
  todos: { icon: 'mdi:format-list-checks' },
  users: { icon: 'mdi:account' },
  organization: OrganizationIcon,
  outreach: { icon: 'mdi:bullhorn' },
}

const GetWrappedIcon = React.forwardRef(
  (
    {
      name,
      icon,
      ...rest
    }: Omit<IconProps, 'icon'> & { name?: string; icon?: string },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    const theme = useTheme()
    const iconsByName = {
      accountKey: { icon: 'mdi:account-key-outline' },
      actions: { icon: 'mdi:dots-vertical' },
      add: { icon: 'mdi:plus' },
      addClient: { icon: 'mdi:account-plus' },
      addContent: { icon: 'mdi:newspaper-plus' },
      addEvent: { icon: 'mdi:calendar-plus' },
      addFile: { icon: 'mdi:file-plus-outline' },
      addFolder: { icon: 'mdi:folder-plus-outline' },
      addGroup: { icon: 'mdi:account-multiple-plus' },
      addList: { icon: 'mdi:playlist-plus' },
      addMessage: { icon: 'mdi:chat-plus-outline' },
      addOrgForm: { icon: 'mdi:playlist-plus' },
      addPoll: { icon: 'mdi:checkbox-marked-circle-plus-outline' },
      addTemplate: { icon: 'mdi:clipboard-plus' },
      addUser: { icon: 'mdi:account-plus-outline' },
      addUserSendEmail: { icon: 'mdi:email-plus-outline' },
      assistance: { icon: 'mdi:user-tie' },
      agendaMinutes: { icon: 'mdi:book-open-outline' },
      attachment: { icon: 'simple-line-icons:paper-clip' },
      archive: { icon: 'mdi:archive-outline' },
      archiveNotification: { icon: 'mdi:package-down' },
      audio: { icon: 'mdi:volume-medium' },
      bank: { icon: 'mdi:bank-outline' },
      backArrow: { icon: 'mdi:arrow-left' },
      changeOrg: { icon: 'mdi:swap-horizontal' },
      check: { icon: 'mdi:check' },
      checkCircle: { icon: 'mdi:check_circle' },
      checkAll: { icon: 'mdi:check-all' },
      checkboxSelected: {
        icon: 'mdi:checkbox-marked-outline',
        color: theme.palette.success.main,
      },
      checkboxNotSelected: {
        icon: 'mdi:checkbox-blank-outline',
        color: theme.palette.action.disabled,
      },
      checkPlus: { icon: 'mdi:task-plus' },
      chevronDown: { icon: 'mdi:chevron-down' },
      chevronLeft: { icon: 'mdi:chevron-left' },
      chevronUp: { icon: 'mdi:chevron-up' },
      chevronRight: { icon: 'mdi:chevron-right' },
      clock: { icon: 'mdi:clock-outline' },
      close: { icon: 'mdi:close' },
      copy: { icon: 'mdi:content-copy' },
      complete: { icon: 'mdi:checkboxes-marked-circle-outline' },
      create: { icon: 'mdi:plus' },
      dashboard: { icon: 'mdi:view-dashboard' },
      directory: { icon: 'mdi:book-open-blank-variant' },
      delete: {
        icon: 'mdi:trash-can-outline',
      },
      deleteClock: { icon: 'mdi:delete-clock-outline' },
      disable: { icon: 'mdi:close' },
      doubleForwardArrows: { icon: 'eva:arrowhead-right-outline' },
      download: { icon: 'mdi:tray-arrow-down' },
      edit: { icon: 'mdi:square-edit-outline' },
      email: { icon: 'mdi:email-send-outline' },
      emailReceived: { icon: 'mdi:email-receive' },
      emoji: { icon: 'eva:smiling-face-fill' },
      enable: { icon: 'mdi:check' },
      eventsSubscribe: { icon: 'mdi:calendar-check' },
      expand: { icon: 'mdi:arrow-expand' },
      expiring: { icon: 'mdi:delete-clock-outline' },
      externalCRMEvent: { icon: 'mdi:calendar-account' },
      externalCRMTask: { icon: 'mdi:clipboard-account' },
      exportsFolder: { icon: 'mdi:folder-zip-outline' },
      externalProvider: { icon: 'mdi:tools' },
      eye: { icon: 'mdi:eye-outline' },
      eyeOff: { icon: 'mdi:eye-off-outline' },
      filter: { icon: 'mdi:filter' },
      folder: { icon: 'mdi:folder' },
      folderOpened: { icon: 'mdi:folder-open' },
      globalContentFolder: { icon: 'mdi:folder-account-outline' },
      globe: { icon: 'mdi:web' },
      group: { icon: 'mdi:account-group' },
      grab: { icon: 'mdi:drag' },
      help: { icon: 'mdi:help-circle-outline' },
      hostFolder: { icon: 'mdi:folder-star-outline' },
      impersonate: { icon: 'mdi:account-supervisor-circle-outline' },
      impersonating: { icon: 'mdi:account-supervisor-circle-outline' },
      inactiveClient: { icon: 'mdi:account-alert' },
      info: { icon: 'mdi:information' },
      'investment billing': { icon: 'mdi:receipt-text-outline' },
      investments: { icon: 'fluent:arrow-trending-lines-20-filled' },
      invoices: { icon: 'mdi:receipt' },
      key: { icon: 'mdi:key' },
      list: { icon: 'mdi:menu' },
      lock: { icon: 'mdi:lock', color: theme.palette.warning.dark },
      lockedChain: { icon: 'mdi:block-chain' },
      login: { icon: 'mdi:login' },
      logs: { icon: 'mdi:format-list-bulleted' },
      logout: { icon: 'mdi:logout' },
      mapMarker: { icon: 'mdi:map-marker-radius-outline' },
      markRead: { icon: 'mdi:email-open' },
      markUnread: { icon: 'mdi:email' },
      markReadMultiple: { icon: 'mdi:email-open-multiple' },
      markUnreadMultiple: { icon: 'mdi:email-multiple' },
      maximize: { icon: 'mdi:arrow-expand' },
      menu: { icon: 'mdi:menu' },
      minimize: { icon: 'mdi:window-minimize' },
      'news-updates': { icon: 'mdi:newspaper' },
      'news & updates': { icon: 'mdi:newspaper' },
      'news &amp; updates': { icon: 'mdi:newspaper' },
      noPermissions: { icon: 'mdi:unlock', color: theme.palette.success.dark },
      notifications: { icon: 'mdi:bell-ring' },
      notificationsOff: { icon: 'mdi:bell-off' },
      notify: { icon: 'mdi:bell-badge' },
      openInNew: { icon: 'mdi:open-in-new' },
      pause: { icon: 'mdi:pause-circle-outline' },
      pending: {
        icon: 'mdi:clock-alert-outline',
        color: theme.palette.info.main,
      },
      permission: { icon: 'mdi:lock' },
      phone: { icon: 'mdi:telephone-in-talk' },
      pictureAsPdf: { icon: 'mdi:file-pdf-box' },
      pin: { icon: 'mdi:pin' },
      polls: { icon: 'mdi:checkbox-marked-outline' },
      readBy: { icon: 'mdi:read' },
      record: { icon: 'mdi:record-rec' },
      restore: { icon: 'mdi:restore' },
      replace: { icon: 'mdi:restore' },
      search: { icon: 'mdi:magnify' },
      send: { icon: 'mdi:send' },
      settings: { icon: 'mdi:cog' },
      share: { icon: 'mdi:share' },
      sortAlphaAscending: { icon: 'mdi:sort-alphabetical-ascending' },
      sortAlphaDescending: { icon: 'mdi:sort-alphabetical-descending' },
      sortTimeAscending: { icon: 'mdi:sort-calendar-ascending' },
      sortTimeDescending: { icon: 'mdi:sort-calendar-descending' },
      star: { icon: 'mdi:star' },
      square: { icon: 'mdi:square' },
      subtract: { icon: 'mdi:minus' },
      subtask: { icon: 'mdi:file-tree', color: theme.palette.success.main },
      sync: { icon: 'mdi:sync' },
      systemFolder: { icon: 'mdi:folder-cog-outline' },
      tag: { icon: 'mdi:tag-outline' },
      textFormat: { icon: 'mdi:format-letter-case' },
      todo: { icon: 'mdi:format-list-checks' },
      'to-dos': { icon: 'mdi:format-list-checks' },
      unarchiveNotification: { icon: 'mdi:package-up' },
      unlock: { icon: 'mdi:lock-open', color: theme.palette.success.dark },
      unpin: { icon: 'mdi:pin-off' },
      upload: { icon: 'mdi:cloud-upload-outline' },
      uploadFolder: { icon: 'mdi:folder-upload-outline' },
      uploadProgress: { icon: 'mdi:progress-upload' },
      user: { icon: 'mdi:account' },
      video: { icon: 'mdi:video-outline' },
      viewCount: { icon: 'ant-design:fund-view-filled' },
      warning: { icon: 'mdi:alert' },
      zoomIn: { icon: 'mdi:magnify-plus-outline' },
      ZoomOut: { icon: 'mdi:magnify-minus-outline' },
    }

    if (name) {
      if (categoryIcons[name]) {
        if (name === 'organization') {
          return <OrganizationIcon {...rest} ref={ref} />
        }

        return <Icon {...rest} {...categoryIcons[name]} ref={ref} />
      }

      if (iconsByName[name]) {
        return <Icon {...rest} {...iconsByName[name]} ref={ref} />
      }
    }

    if (icon) {
      const variantIcon = () => <Icon icon={icon} {...rest} />

      if (variantIcon) {
        return variantIcon()
      }
    }

    return <Icon {...rest} {...iconsByName.help} ref={ref} />
  }
)

GetWrappedIcon.defaultProps = {
  width: '24px',
  height: '24px',
  name: undefined,
  icon: undefined,
}

export {
  AndroidLogoIcon,
  AppleLogoIcon,
  FirefoxLogoIcon,
  GetWrappedIcon,
  GoogleLogoIcon,
  GoogleChromeLogoIcon,
  MicrosoftLogoIcon,
  MicrosoftEdgeLogoIcon,
  MicrosoftOutlookLogoIcon,
  OrganizationIcon,
  SafariLogoIcon,
  YahooLogoIcon,
}
