import React, { FC, useMemo } from 'react'
import { Alert, Container, Grid } from '@mui/material'
import { observer } from 'mobx-react'
import { observable } from 'mobx'

import { useRootStore } from '../../services/RootStoreContext'
import Checkbox from './Checkbox'
import { PerformanceReportingReportsOptions } from '../../types'
import useFormContext from './FormContext'
import Organization from '../../Model/Organization'

const InvestmentReportsList: FC<{ org?: Organization }> = ({
  org,
}): JSX.Element => {
  const { orgStore } = useRootStore()
  const { setFieldValue } = useFormContext()
  const { currentOrg } = orgStore
  const { isOrgAdmin } = currentOrg
  const orgToUpdate = org || currentOrg
  const { hideInvestments, isHost } = orgToUpdate
  const canChangeReports = hideInvestments
    ? false
    : isHost
    ? isOrgAdmin
    : orgToUpdate.host === currentOrg._id

  const { config } = orgToUpdate
  const { performanceReporting } = config
  const { reports } = performanceReporting

  const selectOptions = useMemo(() => {
    const labels = Object.values(PerformanceReportingReportsOptions)
    const selectValues = Object.keys(PerformanceReportingReportsOptions)
    return labels.map((label, idx) => ({
      label,
      key: label,
      value: selectValues[idx],
    }))
  }, [])

  const hostDisclaimer = useMemo(
    () =>
      isHost ? (
        <Alert severity='warning' sx={{ marginBottom: 2 }}>
          This will update the Investment Reports for all of your clients that
          do not have custom reports already configured
        </Alert>
      ) : null,
    [isHost]
  )

  if (!canChangeReports) {
    return null
  }

  return (
    <Container maxWidth='lg'>
      {hostDisclaimer}
      <Grid container spacing={2}>
        {selectOptions.map(option => {
          const { key, label, value } = option
          return (
            <Grid item key={`${key}-grid-item`} sm={12} md={4}>
              <Checkbox
                key={`${key}-input`}
                label={label}
                value={value}
                checked={reports.includes(value)}
                onChange={() => {
                  const existingIndex =
                    orgToUpdate.config.performanceReporting.reports.findIndex(
                      report => report === value
                    )
                  const reportsCopy = [
                    ...orgToUpdate.config.performanceReporting.reports,
                  ]
                  if (existingIndex !== -1) {
                    setFieldValue(
                      'config.performanceReporting.reports',
                      observable.array(
                        reportsCopy
                          .filter((e, index) => index !== existingIndex)
                          .sort()
                      )
                    )
                  } else {
                    setFieldValue(
                      'config.performanceReporting.reports',
                      observable.array([...reportsCopy, value].sort())
                    )
                  }
                }}
              />
            </Grid>
          )
        })}
      </Grid>
    </Container>
  )
}

InvestmentReportsList.defaultProps = {
  org: undefined,
}

export default observer(InvestmentReportsList)
