import React, { FC } from 'react'
import { Box, Divider, Stack } from '@mui/material'
import { observer } from 'mobx-react'

import { OrganizationGroupUserSelect } from '../../../components/Form'
import DiscussionComposerContainer from './DiscussionComposerContainer'
import SubjectInput from './SubjectInput'

import Discussion from '../../../Model/Discussion'
import DiscussionMessage from '../../../Model/DiscussionMessage'

import { useRootStore } from '../../../services/RootStoreContext'

const CreateDiscussionPanel: FC<{
  discussion: Discussion
  message: DiscussionMessage
  roles?: string[]
}> = ({ discussion, message, roles }): JSX.Element => {
  const { orgStore } = useRootStore()
  const { currentOrg } = orgStore

  if (!discussion) {
    return null
  }

  const panelContent = () => (
    <Stack>
      <Box sx={{ mb: 1 }}>
        <OrganizationGroupUserSelect
          includeHostGroups
          item={discussion}
          groups={currentOrg ? currentOrg.groups : []}
          members={currentOrg ? currentOrg.members : []}
          roles={roles}
        />
      </Box>
      <Divider />
      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Stack sx={{ flexGrow: 1 }}>
          <SubjectInput discussion={discussion} />
          {message && (
            <DiscussionComposerContainer
              message={message}
              discussion={discussion}
              error={Boolean(message.messageSaveError)}
              isNew
            />
          )}
        </Stack>
      </Box>
    </Stack>
  )

  return panelContent()
}

CreateDiscussionPanel.defaultProps = {
  roles: [],
}

export default observer(CreateDiscussionPanel)
