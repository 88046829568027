import React, { FC, memo } from 'react'
import { Box, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import OrganizationIcon from '../Icons/OrganizationIcon'

const LoadingIndicator: FC<{ text?: JSX.Element | string }> = ({ text }) => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    }}
  >
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
      }}
    >
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
          }}
          size={50}
          thickness={4}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 0.7,
          }}
        >
          <OrganizationIcon />
        </Box>
      </Box>
      <Typography variant='h6' color='primary' sx={{ mt: 2 }}>
        {text}
      </Typography>
    </Box>
  </Box>
)

LoadingIndicator.defaultProps = {
  text: 'Loading...',
}

export default memo(LoadingIndicator)
