const findNode = (tree, query) => {
  const results = []
  const loop = parent =>
    parent.forEach(item => {
      if (query(item)) {
        results.push(item)
      }
      if (item.children) {
        loop(item.children)
      }
    })
  loop(tree)
  return results
}

const addNode = (tree, parentQuery, node) => {
  // Push to root
  if (!parentQuery) {
    return [...tree, node]
  }

  // Push to node
  const loop = parent =>
    parent.reduce((acc, item) => {
      if (parentQuery(item)) {
        if (item.children) {
          item.children.push(node)
        } else {
          item.children = [node]
        }
      }
      if (item.children) {
        item.children = loop(item.children)
      }
      acc.push(item)
      return acc
    }, [])
  return loop(tree)
}

const updateNode = (tree, query, data) => {
  const loop = parent =>
    parent.reduce((acc, item) => {
      if (!query(item)) {
        acc.push(item) // No-match, return original
      } else {
        acc.push({ ...item, ...data })
      }

      // Recurse if has children
      if (item.children) {
        item.children = loop(item.children)
      }
      return acc
    }, [])
  return loop(tree)
}

const removeNode = (tree, query) => {
  const loop = parent =>
    parent.reduce((acc, item) => {
      if (query(item)) {
        return acc
      }
      acc.push(item) // No-match, return original

      // Recurse if has children
      if (item.children) {
        item.children = loop(item.children)
      }
      return acc
    }, [])
  return loop(tree)
}

const traverse = tree => {
  return {
    find: findNode.bind(null, tree),
    add: addNode.bind(null, tree),
    update: updateNode.bind(null, tree),
    remove: removeNode.bind(null, tree),
  }
}

export default traverse
