import APIResponseType from './APIResponseType'

export const createLog = (
  org_id: string,
  type: string,
  typeid?: string,
  data?: object,
  token?: string
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/audit-logs/${type}/${typeid}`,
  method: 'POST',
  headers: {
    Authorization: `Bearer ${token || localStorage.getItem('jwt')}`,
  },
  data,
})

export const getOrgLogs = (org_id: string, data: object): APIResponseType => ({
  url: `/orgs/v1/${org_id}/audit-logs${data ? '-paginated' : ''}`,
  method: data ? 'POST' : 'GET',
  data,
})

export const getItemLogs = (
  org_id: string,
  item_id: string
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/audit-logs/${item_id}`,
  method: 'GET',
})
