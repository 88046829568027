import React, { forwardRef, ForwardRefRenderFunction, useMemo } from 'react'
import { ViewModel } from 'mobx-utils'

import ManageNodePermissionsForm from './ManageNodePermissionsForm'
import NodePermissionsTrigger from './NodePermissionsTrigger'

import { useRootStore } from '../../../../services/RootStoreContext'

import File from '../../../../Model/File'
import Folder from '../../../../Model/Folder'
import Documents from '../../../../Model/Documents'

const ManageNodePermissions: ForwardRefRenderFunction<
  HTMLElement,
  {
    disablePermissionsEdit: boolean
    documents: Documents
    hideText: boolean
    node: ViewModel<File | Folder>
  }
> = (props, ref): JSX.Element => {
  const { hideText, disablePermissionsEdit, node } = props
  const { orgStore, userStore: user } = useRootStore()
  const { currentOrg } = orgStore
  const { isOrgContributor, isOrgAdmin } = currentOrg
  const canEditPermissions =
    !disablePermissionsEdit &&
    (isOrgAdmin ||
      (isOrgContributor &&
        node.creator &&
        node.creator.toString() === user._id.toString()))
  const trigger = useMemo(() => {
    return (
      <span key={`${node.permalink}-node-permissions-trigger`}>
        <NodePermissionsTrigger hideText={hideText} node={node} />
      </span>
    )
  }, [node])
  return canEditPermissions ? (
    <ManageNodePermissionsForm {...props} trigger={trigger} innerRef={ref} />
  ) : (
    <NodePermissionsTrigger hideText={hideText} ref={ref} node={node} />
  )
}

export default forwardRef(ManageNodePermissions)
