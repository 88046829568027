import { observable, computed, toJS, makeObservable } from 'mobx'

export default class ContentData {
  constructor(
    contentData: {
      id: string
      image: string
      path: string
      url: string
    } = {
      id: '',
      image: '',
      path: '',
      url: '',
    }
  ) {
    makeObservable(this, {
      image: observable,
      id: observable,
      path: observable,
      url: observable,
      data: computed,
    })

    this.id = contentData.id || ''
    this.image = contentData.image || ''
    this.path = contentData.path || ''
    this.url = contentData.url || ''
  }

  id = ''

  image = ''

  path = ''

  url = ''

  get data() {
    return {
      id: toJS(this.id),
      image: toJS(this.image),
      path: toJS(this.path),
      url: toJS(this.url),
    }
  }
}
