import { observable, computed, toJS, makeObservable } from 'mobx'
import { createViewModel } from 'mobx-utils'

import MemberRecordProperty from './MemberRecordProperty'

export default class MemberRecord {
  constructor(record: { id?: string; properties?: MemberRecordProperty[] }) {
    makeObservable(this, {
      id: observable,
      properties: observable,
      data: computed,
      propertiesView: computed,
    })

    this.id = record.id || undefined
    this.properties = record.properties
      ? record.properties.map(prop => new MemberRecordProperty(prop))
      : []
  }

  id: string | undefined = undefined

  properties: MemberRecordProperty[] = []

  get data() {
    return toJS({
      id: this.id,
      properties: this.properties.map(prop => prop.data),
    })
  }

  get propertiesView() {
    return this.properties.map(prop => createViewModel(prop))
  }
}
