/* eslint-disable import/prefer-default-export */
import APIResponseType from './APIResponseType'

export const getReport = (
  org_id: string,
  type: string,
  by_host = false,
  days = 14
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/analytics/${type}?by_host=${by_host}&days=${days}`,
  method: 'GET',
})

export const getInactiveClients = (
  org_id: string,
  days = 90
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/analytics/inactive-clients?days=${days}`,
  method: 'GET',
})

export const getActiveClients = (
  org_id: string,
  days = 90,
  limit = 10
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/analytics/active-clients?days=${days}&limit=${limit}`,
  method: 'GET',
})

export const getGlobalItemClientViews = (
  org_id: string,
  itemid: string
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/analytics/global/${itemid}`,
  method: 'GET',
})
