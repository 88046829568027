import { makeObservable, observable, override, toJS } from 'mobx'

import { generateID } from '../lib/utils'
import RootStore from '../stores/RootStore'
import ModelBase from './ModelBase'

const contactListProps = ['id', 'name']

export default class ContactList extends ModelBase {
  constructor(rootStore: RootStore, list: { id: string; name: string }) {
    super(rootStore)
    makeObservable(this, {
      id: observable,
      name: observable,
      data: override,
    })

    contactListProps.forEach(prop => {
      this[prop] = list[prop]
    })
    this.id = this.id || generateID()
  }

  id = generateID()

  name = ''

  get data() {
    return contactListProps.reduce((acc: { [key: string]: unknown }, prop) => {
      acc[prop] = toJS(this[prop])
      return acc
    }, {})
  }
}
