import React, { FC } from 'react'
import {
  Link,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react'
import moment from 'moment'

import { GetWrappedIcon } from '../Icons'

import { useRootStore } from '../../services/RootStoreContext'

const NotificationLogs: FC<{
  logs?: {
    by: string
    at: number
  }[]
  members?: {
    _id: string
    fname: string
    lname: string
  }[]
}> = ({ logs, members }): JSX.Element => {
  const {
    userStore: { _id: currentUserId },
  } = useRootStore()

  const getMemberName = (id: string) => {
    if (id === currentUserId) {
      return 'you'
    }

    const member = members.find(m => m._id === id)
    return member ? `${member.fname} ${member.lname}` : 'system automation'
  }

  const lastNotification =
    logs && logs.length > 0
      ? {
          by: getMemberName(logs[logs.length - 1].by),
          at: moment(logs[logs.length - 1].at).format('LLL'),
        }
      : false

  if (!lastNotification) {
    return null
  }

  return (
    <Tooltip
      className='notification-logs'
      title={
        <List>
          {logs
            .filter((i, idx) => idx >= logs.length - 10) // Only show up to 10 of these
            .map(log => (
              <ListItem key={log.at}>
                <ListItemText>
                  {moment(log.at).format('LLL')} by {getMemberName(log.by)}
                </ListItemText>
              </ListItem>
            ))}
        </List>
      }
    >
      <Link underline='none'>
        <Typography>
          <GetWrappedIcon name='clock' /> Last notification sent at{' '}
          {lastNotification.at} by {lastNotification.by}
        </Typography>
      </Link>
    </Tooltip>
  )
}

NotificationLogs.defaultProps = {
  logs: [],
  members: [],
}

export default observer(NotificationLogs)
