import React, { FC, useEffect, useState } from 'react'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import moment from 'moment'
import { observer } from 'mobx-react'

import { GetWrappedIcon } from '../../Icons'

import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog'
import ModalDialog from '../../ModalDialog/ModalDialog'

import Documents from '../../../Model/Documents'
import File from '../../../Model/File'
import Folder from '../../../Model/Folder'

import { useRootStore } from '../../../services/RootStoreContext'

const BulkActions: FC<{ documents: Documents }> = ({
  documents,
}): JSX.Element => {
  const { addNotificationItem, userStore: user } = useRootStore()
  const [exportFileName, setExportFileName] = useState('')
  const [isLoadingMoveMenu, toggleIsLoadingMoveMenu] = useState(false)
  const [selectedMoveNode, setSelectedMoveNode] = useState<Documents | Folder>(
    documents
  )
  useEffect(() => {
    if (documents) {
      documents.bulkSelectedList = []
    }

    return () => {
      if (documents) {
        documents.bulkSelectedList = []
      }
    }
  }, [])

  if (
    !documents ||
    !documents.currentDirectory ||
    documents?.currentDirectory?.isHostFolder
  ) {
    return null
  }

  const { currentDirectory } = documents

  const bulkSelectedLength =
    documents && documents.bulkSelectedList
      ? documents.bulkSelectedList.length
      : 0

  const getExportItemContent = () => {
    if (bulkSelectedLength === 0) {
      return 'You have not selected anything'
    }

    let folders = 0
    let files = 0
    const updateCounts = (item: {
      name: string
      type: 'folder' | 'file'
      contents?: (File | Folder)[]
    }) => {
      if (item.type === 'folder' && item.name !== 'Exports') {
        folders += 1
        if (item.contents && item.contents.length > 0) {
          item.contents.forEach(updateCounts)
        }
      } else if (item.type === 'file') {
        files += 1
      }
    }

    documents.bulkSelectedList.forEach(updateCounts)

    const folderContent =
      folders > 0
        ? `a total of ${folders} folder${
            folders > 1 ? 's containing ' : ' containing '
          }`
        : ''
    const fileContent =
      files > 0 ? `a total of ${files} file${files > 1 ? 's' : ''}` : ''

    return `Export ${folderContent}${fileContent}.`
  }
  const getTreeItems = () => {
    if (documents) {
      const getItems = (treeNode: { contents?: (File | Folder)[] }) => {
        if (treeNode.contents) {
          return treeNode.contents
            .filter((c: File | Folder) => c.type === 'folder')
            .map((folder: Folder) => {
              const disabled = documents.bulkSelectedList.some(
                selected =>
                  selected.type === 'folder' &&
                  (selected.path === folder.path || folder.isHostFolder)
              )
              const onClick = () => {
                if (!disabled) {
                  setSelectedMoveNode(folder)
                }
              }
              return folder.contents.some(
                (c: File | Folder) => c.type === 'folder'
              ) ? (
                <TreeItem
                  disabled={disabled}
                  label={folder.name}
                  key={folder.path}
                  itemId={folder.path}
                  onClick={onClick}
                >
                  {getItems(folder)}
                </TreeItem>
              ) : (
                <TreeItem
                  disabled={disabled}
                  label={folder.name}
                  key={folder.path}
                  itemId={folder.path}
                  onClick={onClick}
                />
              )
            })
        }
        return null
      }
      return getItems(documents)
    }

    return null
  }

  return (
    <Box pl={2}>
      <ModalDialog
        content={
          <Box>
            {isLoadingMoveMenu && <div className='loader'>Loading...</div>}
            {Boolean(selectedMoveNode.path) &&
              selectedMoveNode.path !== '/' && (
                <Typography>{selectedMoveNode.path}</Typography>
              )}
            <SimpleTreeView
              slots={{
                collapseIcon: GetWrappedIcon,
                expandIcon: GetWrappedIcon,
              }}
              slotProps={{
                collapseIcon: { name: 'chevronDown' },
                expandIcon: { name: 'chevronRight' },
              }}
            >
              <TreeItem
                label='Documents'
                itemId='home'
                onClick={() => {
                  setSelectedMoveNode(documents)
                }}
              >
                {getTreeItems()}
              </TreeItem>
            </SimpleTreeView>
          </Box>
        }
        header={`Move ${bulkSelectedLength} ${
          bulkSelectedLength === 1 ? 'item' : 'items'
        }?`}
        onClose={() => setSelectedMoveNode(documents)}
        onConfirm={async () => {
          await documents.bulkMoveSelected(selectedMoveNode)
          setSelectedMoveNode(documents)
          addNotificationItem({
            message: `Successfully moved ${bulkSelectedLength} item${
              bulkSelectedLength === 1 ? '' : 's'
            } to ${selectedMoveNode.name}`,
            success: true,
          })
        }}
        trigger={
          <Button
            disabled={bulkSelectedLength === 0}
            onClick={() => {
              setSelectedMoveNode(documents)
              toggleIsLoadingMoveMenu(true)
              setTimeout(() => {
                toggleIsLoadingMoveMenu(false)
              }, 500)
            }}
          >
            Move {bulkSelectedLength}
            {bulkSelectedLength === 1 ? ' Item' : ' Items'}
          </Button>
        }
      />
      <ConfirmDialog
        className='bulk-actions-delete-confirmation'
        size='mini'
        confirmActionText='DELETE'
        header={`Delete ${bulkSelectedLength} Item${
          bulkSelectedLength === 1 ? '' : 's'
        }?`}
        content={`Are you sure you wish to delete the ${bulkSelectedLength} selected item${
          bulkSelectedLength === 1 ? '' : 's'
        }? This action cannot be undone.`}
        onConfirm={async () => {
          await documents.bulkDeleteSelected(user)
          addNotificationItem({
            message: `Successfully deleted ${bulkSelectedLength} item${
              bulkSelectedLength === 1 ? '' : 's'
            }`,
            success: true,
          })
        }}
        trigger={<Button disabled={bulkSelectedLength === 0}>Delete</Button>}
      />
      <ConfirmDialog
        header='Export folders and files'
        confirmActionText='Export'
        onConfirm={async () => {
          await documents.bulkExportSelected(exportFileName)
          setExportFileName('')
          addNotificationItem({
            message:
              'Successfully queued export. We will notify you once the export is complete.',
            success: true,
          })
        }}
        content={
          <Stack spacing={2}>
            <Typography>{getExportItemContent()}</Typography>
            <Typography>
              You will receive a confirmation email once the export is complete.
            </Typography>
            <TextField
              label='File name'
              value={exportFileName}
              onChange={({ target }) => {
                const { value } = target
                setExportFileName(value)
              }}
              placeholder={`export-${moment().format(
                'YYYY-MM-DD-HH-mm-ss'
              )}.zip`}
              data-qa='export-filename-field'
            />
          </Stack>
        }
        trigger={
          <Button
            disabled={
              bulkSelectedLength === 0 ||
              (currentDirectory && currentDirectory.name === 'Exports')
            }
          >
            Export
          </Button>
        }
      />
    </Box>
  )
}

export default observer(BulkActions)
