import { TagItemProps } from '../types'

export default class TagItem {
  constructor(
    item: TagItemProps = {
      uid: undefined,
      collection: undefined,
    }
  ) {
    Object.assign(this, item)
  }

  uid: string

  collection: string
}
