import React, { FC, useEffect } from 'react'
import { Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react'

import Accordion from '../../../../../components/Accordion/Accordion'
import {
  OrganizationGroupUserSelect,
  useFormContext,
} from '../../../../../components/Form'
import { useRootStore } from '../../../../../services/RootStoreContext'
import Organization from '../../../../../Model/Organization'
import OrgGroup from '../../../../../Model/OrgGroup'

const OrgGlobalGroups: FC<{ hostOrg: Organization }> = ({
  hostOrg,
}): JSX.Element => {
  const rootStore = useRootStore()
  const { userStore } = rootStore
  const { setFieldTouched, model: orgData } = useFormContext()

  if (
    !hostOrg.isHost ||
    !hostOrg.config.globalGroups ||
    hostOrg.config.globalGroups.length === 0 ||
    !orgData
  ) {
    return null
  }

  useEffect(() => {
    if (!orgData.members || orgData.members.length === 0) {
      orgData.getMembers()
    }
  }, [])

  const getGlobalGroupsSection = () =>
    hostOrg.config.globalGroups.map(
      (group: { id: string; name: string; members: string[] }) => {
        const orgGroup = orgData.groups.find(
          (g: { id: string }) => g.id === group.id
        )
        return (
          <Accordion
            details={
              <OrganizationGroupUserSelect
                displayUserChips
                hideAdminGroup
                user={userStore}
                org={orgData}
                members={
                  orgData.members
                    ? orgData.members.filter(m => !m.isHostAdmin)
                    : []
                }
                groups={[]}
                groupsState={[]}
                groupsOnChange={() => true}
                usersState={orgGroup ? orgGroup.members : []}
                usersOnChange={(values: string[]) => {
                  if (!orgGroup) {
                    orgData.groups.push(
                      new OrgGroup(rootStore, {
                        id: group.id,
                        name: group.name,
                        members: values,
                        isGlobalGroup: true,
                        isHostGroup: undefined,
                      })
                    )
                    setFieldTouched('groups', true)
                  } else {
                    orgGroup.members = values
                    setFieldTouched('members', true)
                  }
                }}
                label='Members'
              />
            }
            key={group.id}
            summary={<Typography>{group.name}</Typography>}
          />
        )
      }
    )

  return <Stack>{getGlobalGroupsSection()}</Stack>
}

export default observer(OrgGlobalGroups)
