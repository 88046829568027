import { useState, useEffect, useCallback } from 'react'

const useHeight = (calcHeight = 250) => {
  const getHeight = useCallback((windowHeight: number): string => {
    return `${windowHeight - calcHeight}px`
  }, [])

  const [windowHeight, setWindowHeight] = useState<string>(
    getHeight(window.innerHeight)
  )

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(getHeight(window.innerHeight))
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  return windowHeight
}

export default useHeight
