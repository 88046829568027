import {
  observable,
  computed,
  toJS,
  action,
  makeObservable,
  IObservableArray,
} from 'mobx'
import { generateID } from '../lib/utils'

import {
  FieldOption,
  FormField,
  Mask,
  PDFFieldType,
  PortalField,
} from '../types'

export default class OrganizationFormField {
  constructor(formField?: FormField) {
    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      options: observable,
      type: observable,
      formatMask: observable,
      pdfFieldType: observable,
      optional: observable,
      pageIndex: observable,
      addOption: action,
      removeOption: action,
      isSelect: computed,
      isMultiSelect: computed,
      isInvalid: computed,
      data: computed,
      pdfFieldTypeOptions: computed,
      individualIndex: observable,
      formData: computed,
    })

    if (formField) {
      this.id = formField.id
      this.name = formField.name || this.name
      this.description = formField.description || this.description
      this.options = observable.array(
        (formField.options || this.options).map(opt => ({
          ...opt,
          label: opt.label || opt.text,
        }))
      )
      this.type = formField.type || this.type
      this.formatMask = formField.formatMask || this.formatMask
      this.optional = formField.optional || this.optional
      this.pdfFieldType = formField.pdfFieldType || this.pdfFieldType
      this.individualIndex = formField.individualIndex || this.individualIndex
    } else {
      this.id = generateID()
    }
  }

  // first half indicates the group id
  id = ''

  name: string | undefined = undefined

  description: string | undefined = undefined

  options: IObservableArray<FieldOption> = observable.array([])

  type: PortalField = 'string'

  formatMask: Mask = 'string'

  optional = false

  pageIndex = 0 // Display only prop

  pdfFieldType: PDFFieldType = 'PDFTextField'

  individualIndex: number | undefined = 0

  addOption = () => {
    const id = generateID()
    this.options.push({
      label: '',
      value: id,
      key: id,
    })
  }

  removeOption = (key: string) => {
    this.options.replace(this.options.filter(opt => opt.key !== key))
  }

  get isSelect(): boolean {
    return this.type === 'enum'
  }

  get isMultiSelect(): boolean {
    return this.type === 'enum_multi'
  }

  get pdfFieldTypeOptions(): {
    value: PDFFieldType
    key: string
    label: string
  }[] {
    const getPdfTypeOptions = (type: string): PDFFieldType[] => {
      switch (type) {
        case 'bool':
          return ['PDFDropdown', 'PDFRadioGroup', 'PDFCheckBox']
        case 'enum':
          return ['PDFDropdown', 'PDFRadioGroup']
        case 'enum_multi':
          return ['PDFDropdown', 'PDFCheckBox']
        default:
          return []
      }
    }

    const getOptionObject: {
      [K in PDFFieldType]: { value: string; key: string; label: string }
    } = {
      PDFCheckBox: {
        value: 'PDFCheckBox',
        key: 'PDFCheckBox',
        label: 'Checkbox',
      },
      PDFDropdown: {
        value: 'PDFDropdown',
        key: 'PDFDropdown',
        label: 'Dropdown',
      },
      PDFRadioGroup: {
        value: 'PDFRadioGroup',
        key: 'PDFRadioGroup',
        label: 'Radio',
      },
      PDFTextField: {
        value: 'PDFTextField',
        key: 'PDFTextField',
        label: 'Text',
      },
    }

    return getPdfTypeOptions(this.type).reduce(
      (previousValue, currentValue) => [
        ...previousValue,
        getOptionObject[currentValue],
      ],
      []
    )
  }

  get isInvalid(): boolean | string[] {
    const errors = []
    if (!this.name) errors.push('Field name is required')
    if (
      ['enum', 'enum_multi'].includes(this.type) &&
      (this.options.length < 2 ||
        this.options.some(opt => opt.label && opt.label.length === 0))
    )
      errors.push('Must provide at least 2 valid options')
    return errors.length > 0 ? errors : false
  }

  get formData() {
    return {
      name: toJS(this.name),
      description: toJS(this.description),
      options: this.options
        .filter(option => option && option.value)
        .map(option => toJS(option)),
      type: toJS(this.type),
    }
  }

  get data(): FormField {
    return {
      id: toJS(this.id),
      name: toJS(this.name),
      description: toJS(this.description),
      options: this.options
        .filter(option => option && option.value)
        .map(option => toJS(option)),
      type: toJS(this.type),
      formatMask: toJS(this.formatMask),
      optional: toJS(this.optional),
      pdfFieldType: toJS(this.pdfFieldType),
      individualIndex: toJS(this.individualIndex),
    }
  }
}
