import React, { FC, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { Outlet } from 'react-router-dom'

import { ClientsMissingBillingProfileContext } from './Services/ClientsMissingBillingProfileContext'

import { useRootStore } from '../../services/RootStoreContext'

import { OrgProps } from '../../types'

const InvestmentBilling: FC = (): JSX.Element => {
  const {
    orgStore: {
      currentOrg: { showInvestmentBilling },
    },
    investmentBillingStore: {
      billingProfiles,
      getClientsWithoutBillingProfiles,
    },
  } = useRootStore()
  const [clientsMissingBillingProfile, setClientsMissingBillingProfiles] =
    useState<OrgProps[]>([])
  const [missingBillingProfilesAlertOpen, setMissingBillingProfilesAlertOpen] =
    useState(false)
  useEffect(() => {
    if (showInvestmentBilling) {
      getClientsWithoutBillingProfiles()
        .then(data => {
          setClientsMissingBillingProfiles(data)
          setMissingBillingProfilesAlertOpen(data.length > 0)
        })
        .catch(() => setClientsMissingBillingProfiles([]))
    }
  }, [billingProfiles.toJSON().length])
  const contextValue = useMemo(
    () => ({
      clientsMissingBillingProfile,
      missingBillingProfilesAlertOpen,
      setMissingBillingProfilesAlertOpen,
    }),
    [clientsMissingBillingProfile, missingBillingProfilesAlertOpen]
  )

  return (
    <ClientsMissingBillingProfileContext.Provider value={contextValue}>
      <Outlet />
    </ClientsMissingBillingProfileContext.Provider>
  )
}

export default observer(InvestmentBilling)
