import React, { FC, Fragment } from 'react'
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { ViewModel } from 'mobx-utils'

import { GetWrappedIcon } from '../../../components/Icons'

import DeleteNode from './DeleteNode'
import HostInheritedAction from './HostInheritedAction'
import ManageNodePermissions from './ManageNodePermissions/ManageNodePermissions'
import RenameNode from './RenameNode'
import ReplaceNode from './ReplaceNode'

import { useRootStore } from '../../../services/RootStoreContext'

import Documents from '../../../Model/Documents'
import File from '../../../Model/File'
import Folder from '../../../Model/Folder'

const DocumentActions: FC<{
  documents: Documents
  isFromList?: boolean
  node: ViewModel<File | Folder>
}> = ({ documents, isFromList, node }): JSX.Element => {
  const {
    orgStore: { currentOrg },
    userStore: user,
  } = useRootStore()
  const { _id: currentOrgId, isHostUser, isOrgAdmin } = currentOrg

  if (!node || !documents || !isOrgAdmin || node.org_id !== currentOrgId) {
    return null
  }

  const isExportsFolder = node.type === 'folder' && node.name === 'Exports'
  const canRenameDelete =
    !isExportsFolder && node.isGlobalFolder ? isHostUser : isOrgAdmin

  return (
    <Fragment>
      {isOrgAdmin && isFromList && !node.isInherited && (
        <ManageNodePermissions
          disablePermissionsEdit={false}
          hideText={false}
          node={node}
          documents={documents}
        />
      )}
      <HostInheritedAction isFromList={isFromList} node={node} />
      {canRenameDelete && (
        <Fragment>
          <RenameNode node={node} documents={documents} />
          <DeleteNode
            node={node}
            deleteNode={documents.deleteNode}
            isFromList={isFromList}
            user={user}
          />
        </Fragment>
      )}
      {node.type === 'file' && (
        <Fragment>
          <ReplaceNode node={node} documents={documents} />
          <MenuItem
            onClick={() => {
              node.disableComments = !node.disableComments
              node.submit()
              documents.save()
            }}
            role='presentation'
          >
            <ListItemIcon>
              <GetWrappedIcon name='comment' />
            </ListItemIcon>
            <ListItemText>
              {node.disableComments ? 'Enable' : 'Disable'} Comments
            </ListItemText>
          </MenuItem>
        </Fragment>
      )}
    </Fragment>
  )
}

DocumentActions.defaultProps = {
  isFromList: false,
}

export default DocumentActions
