import { action, makeObservable, observable, override, reaction } from 'mobx'

import ModelBase from '../Model/ModelBase'
import StarItem from '../Model/StarItem'
import RootStore from './RootStore'

import { StarItemProps } from '../types'

import traverse from '../lib/traverse'
import { getSocket } from '../lib/socket'

export default class StarStore extends ModelBase {
  constructor(
    rootStore: RootStore,
    stars: { org_id: string; items?: StarItem[] } = {
      org_id: undefined,
      items: [],
    }
  ) {
    super(rootStore)
    makeObservable(this, {
      org_id: observable,
      items: observable,
      updated: observable,
      data: override,
      addItem: action,
      removeItem: action,
      isSavingStars: observable,
      errorSavingStars: observable,
      save: action,
      loadData: action,
      resetStore: action,
      loadedResource: observable,
    })

    this.org_id = stars.org_id
    this.items = stars.items.map(
      (item: StarItem) => new StarItem(this.rootStore, item)
    )
    this.updated = Date.now()

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
  }

  org_id: string | undefined = undefined

  items: StarItem[] = []

  updated = Date.now()

  modelCollection = 'stars'

  loadedResource = false

  get data() {
    return this.items.map(i => i.data)
  }

  loadData = (data: { items: StarItem[]; org_id: string }) => {
    if (data) {
      // here we load the actual stars
      this.items = data.items.map(
        (item: StarItem) => new StarItem(this.rootStore, item)
      )
      this.org_id = data.org_id || this.rootStore.orgStore.currentOrg._id
    }
    this.loadedResource = true
    return this
  }

  resetStore() {
    this.org_id = undefined
    this.items = []
  }

  addItem = async (item: StarItemProps) => {
    if (this.items.some(oldItem => oldItem.title === item.title)) {
      this.rootStore.addNotificationItem({
        error: true,
        message: `An item with this title already exists, please enter a new title`,
      })
    } else {
      this.items.push(new StarItem(this.rootStore, item))
      this.save(this.data)
    }
  }

  removeItem = async (uid: string) => {
    await this.save(
      traverse(this.data).remove((n: { uid: string }) => n.uid === uid)
    )
  }

  isSavingStars = false

  errorSavingStars = false

  save = async (items: StarItem[]) => {
    if (!this.org_id) {
      this.org_id = this.rootStore?.orgStore?.currentOrg?._id
    }

    this.isSavingStars = true
    this.errorSavingStars = false
    try {
      const { data } = await this.client.stars.saveStars({
        org_id: this.org_id,
        items,
      })
      // Map items back on model
      this.items = data.items.map(
        (item: StarItem) => new StarItem(this.rootStore, item)
      )
      this.updated = Date.now()
    } catch (e) {
      this.errorSavingStars = true
    }
    this.isSavingStars = false
  }

  getStars = async () => {
    if (this.rootStore.orgStore.currentOrg._id) {
      try {
        const { data } = await this.rootStore.client.stars.getStars(
          this.rootStore.orgStore.currentOrg._id
        )
        this.loadData(data)
      } catch (e) {
        this.loadedResource = true
      }
    }
  }

  handleSocket = () => {
    const socket = getSocket()
    socket.on('stars', this.getStars)
  }
}
