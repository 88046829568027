export default function Table(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
      },
    },
  }
}
