import { makeObservable, observable, override, computed } from 'mobx'
import * as yup from 'yup'

import RootStore from '../../stores/RootStore'

import { OrgProps } from '../../types'
import Organization from '../Organization'

export default class OrgProfileForm extends Organization {
  constructor(rootStore: RootStore, org: OrgProps) {
    super(rootStore, org)
    makeObservable(this, {
      validationSchema: override,
      quarterlyReportDate: observable,
      relationshipManagementAssignments: observable,
      hasRelationshipAssignmentUpdated: computed,
      users: override,
    })

    this.initialRelationshipManagementAssignments = this.getAssignmentString()
    this.relationshipManagementAssignments =
      this.initialRelationshipManagementAssignments

    this.users = org.users

    if (
      !this.quarterlyInvestmentReportsMetaData ||
      this.quarterlyInvestmentReportsMetaData.length === 0
    ) {
      this.quarterlyInvestmentReportsMetaData.push({
        commentary: '<p><br></p>',
        disclaimer: '<p><br></p>',
        quarterEnding: this.quarterlyReportDate,
      })
    }
  }

  getAssignmentString() {
    if (this.host) {
      // find host org
      const hostOrg = this.rootStore.orgStore.orgs.find(
        org => org._id === this.host
      )

      if (hostOrg) {
        const orgAssignment = hostOrg?.relationshipManagement?.assignments.find(
          assignment => assignment.org_id === this._id
        )

        if (orgAssignment && orgAssignment.users) {
          return JSON.stringify(orgAssignment.users)
        }
      }
    }

    return ''
  }

  quarterlyReportDate = 1

  relationshipManagementAssignments = ''

  initialRelationshipManagementAssignments = ''

  get validationSchema() {
    return yup.object().shape({
      legal_business_name: yup.string().required('Name is a required field'),
      phone: yup.string().matches(/^[0-9]\d{9}$/, {
        message: 'Please enter valid number. ex:4679876535',
        excludeEmptyString: false,
      }),
      address: yup.object().shape({
        address1: yup.string().required('Address is a required field'),
        city: yup.string().required('City is a required field'),
        state: yup.string().required('State is a required field'),
        zip: yup.string().matches(/^[0-9]\d{4}$/, {
          message: 'Please enter valid zipcode. ex:55555',
          excludeEmptyString: false,
        }),
      }),
    })
  }

  get hasRelationshipAssignmentUpdated() {
    return (
      this.relationshipManagementAssignments !==
      this.initialRelationshipManagementAssignments
    )
  }
}
