import {
  ChangeEvent,
  FocusEvent,
  FocusEventHandler,
  FormEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  isObservableArray,
  isObservableMap,
  isObservableSet,
  observable,
  toJS,
} from 'mobx'
import { createViewModel } from 'mobx-utils'
import { ValidationError } from 'yup'

import { set } from '../../lib/utils'

import {
  HandleSubmitProps,
  FormModel,
  MobxFormBag,
  ResetFormProps,
} from './types'

import { SelectOptionType, StringObject } from '../../types'

/**
 * Convert yup error into an error object where the keys
 *  are the fields and the values are the errors for that field
 * @param {ValidationError} err The yup error to convert
 * @returns {StringObject} The error object
 */
export function yupErrorToErrorObject(err: ValidationError): StringObject {
  const object: StringObject = {}

  if (err?.inner?.length > 0) {
    err.inner.forEach(x => {
      if (x.path !== undefined) {
        const error = x.errors[0]
        object[x.path] = error

        // Convert to dot notation
        const dotted = x.path.replace('[', '.').replace(']', '')
        object[dotted] = error
      }
    })
  }

  return object
}

const useMobxForm = (
  originalModel: FormModel,
  handleSubmit: (submitProps: HandleSubmitProps) => void
): MobxFormBag => {
  const [currentView, setCurrentView] = useState(createViewModel(originalModel))
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [status, setStatus] = useState<{ [key: string]: string }>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [touchedArray, setTouchedArray] = useState<string[]>([])
  const shouldNotifyRef = useRef(false)

  const setShouldNotify = (newVal: boolean) => {
    if (typeof shouldNotifyRef?.current !== 'undefined') {
      shouldNotifyRef.current = newVal
    }
  }

  const isValid = Object.keys(errors).length === 0
  const dataString = JSON.stringify(currentView.formData)

  useEffect(() => {
    let madeSomeChanges = false

    Object.keys(currentView).forEach((key: keyof FormModel) => {
      const val = originalModel[key]

      if (isObservableArray(val)) {
        currentView[key] = observable.array(val.toJSON())
        madeSomeChanges = true
      } else if (isObservableMap(val)) {
        currentView[key] = observable.map(val.toJSON())
        madeSomeChanges = true
      } else if (isObservableSet(val)) {
        currentView[key] = observable.set(toJS(val))
        madeSomeChanges = true
      }
    })

    if (madeSomeChanges) {
      currentView.submit()
    }
  }, [originalModel])

  // make a copy of the original model because we can't keep it unmodified
  const [startString, setStartString] = useState(
    JSON.stringify(currentView.model.formData)
  )

  const isTrulyDirty = dataString !== startString

  const submitDisabled = isSubmitting || !isValid || (!isTrulyDirty && isValid)

  const validateFields = async (): Promise<boolean> => {
    const { validationSchema, formData } = currentView

    return validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({})

        return true
      })
      .catch(err => {
        setErrors(yupErrorToErrorObject(err))

        return false
      })
  }

  const setFieldTouched = (path: string) => {
    setTouchedArray([...new Set([...touchedArray, path])])
  }

  const setFieldValue = (path: string, value: unknown) => {
    if (Object.keys(status).length > 0) {
      setStatus({})
    }
    set(currentView, path, value)
    validateFields()
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    option?: SelectOptionType
  ) => {
    setFieldValue(e.target.name, e.target.value || option?.value)
  }

  const handleBlur: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
    if (typeof e === 'string') {
      setFieldTouched(e)
    } else {
      setFieldTouched(e.target.name)
    }

    validateFields()
  }

  const isFieldDirty = (path: keyof FormModel): boolean =>
    currentView.isPropertyDirty(path)

  const isFieldTouched = (path: string): boolean => touchedArray.includes(path)

  const getError = (name: string) => {
    if (errors && errors[name]) {
      return errors[name]
    }

    return ''
  }

  const handleReset = (e: FormEvent<HTMLFormElement> | undefined) => {
    if (e) {
      e.preventDefault()
    }

    currentView.reset()
    setErrors({})
    setIsSubmitting(false)
    setShouldNotify(false)
    setTouchedArray([])
    setStatus({})
    setStartString(JSON.stringify(currentView.model.formData))
  }

  /**
   * resets the viewModel completely
   * gives you the ability to decide how to reset the form
   * @param {FormModel} newViewModel accepts a new model to update the viewModel
   * @returns {ResetFormProps} allows you to manipulate form after reset
   */
  const resetForm = (newViewModel?: FormModel | undefined): ResetFormProps => {
    handleReset(null)

    if (newViewModel) {
      setCurrentView(createViewModel(originalModel))
    }
    return {
      model: currentView,
      state: {
        isSubmitting,
        shouldNotify: shouldNotifyRef?.current || false,
        errors,
        isValid,
        submitDisabled,
        status,
        isTrulyDirty,
      },
      actions: {
        setIsSubmitting,
        setShouldNotify,
        validateFields,
        setFieldValue,
        setFieldTouched,
        setStatus,
      },
    }
  }

  const onSubmit = (
    e: FormEvent<HTMLFormElement> | undefined
  ): Promise<void> => {
    if (e) {
      e.preventDefault()
    }

    return validateFields().then(valid => {
      handleSubmit({
        model: currentView,
        state: {
          isSubmitting,
          shouldNotify: shouldNotifyRef?.current || false,
          errors,
          isValid: valid,
          submitDisabled,
          status,
          isTrulyDirty,
          isFieldDirty,
        },
        actions: {
          setStatus,
          setIsSubmitting,
          setShouldNotify,
          resetForm,
          validateFields,
          setFieldValue,
          setFieldTouched,
        },
      })
    })
  }

  return useMemo(
    () => ({
      handleChange,
      handleBlur,
      setFieldValue,
      getError,
      model: currentView,
      setIsSubmitting,
      isSubmitting,
      shouldNotify: shouldNotifyRef?.current || false,
      setShouldNotify,
      isValid,
      submitDisabled,
      setFieldTouched,
      isFieldTouched,
      isFieldDirty,
      setTouchedArray,
      validateFields,
      handleReset,
      onSubmit,
      status,
      setStatus,
      resetForm,
      errors,
      setErrors,
      isTrulyDirty,
    }),
    [
      currentView,
      JSON.stringify(errors),
      isSubmitting,
      isTrulyDirty,
      isValid,
      submitDisabled,
      JSON.stringify(touchedArray),
    ]
  )
}

export default useMobxForm
