import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'

import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog'
import DeleteAction from '../../Actions/DeleteAction'

import File from '../../../Model/File'
import Folder from '../../../Model/Folder'

const DeleteNode: FC<{
  deleteNode: (node: File | Folder) => Promise<string>
  isFromList: boolean
  node: File | Folder
}> = ({ deleteNode, isFromList, node }): JSX.Element => {
  const navigate = useNavigate()

  if (node.isGlobalFolder) {
    return null
  }

  return (
    <ConfirmDialog
      onConfirm={async () => {
        await deleteNode(node)

        if (!isFromList) {
          navigate(-1)
        }
      }}
      onCancel={() => true}
      header={`Delete ${node.type === 'file' ? 'File' : 'Folder & Contents'}?`}
      content={`Are you sure you want to delete '${node.name.replace(
        '.html',
        ''
      )}'${node.type === 'folder' ? ' and its contents' : ''}?`}
      trigger={<DeleteAction data-qa='delete-node-action' />}
    />
  )
}

export default observer(DeleteNode)
