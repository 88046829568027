import React, { forwardRef } from 'react'
import useFormContext from './FormContext'

const Form = forwardRef<
  HTMLFormElement,
  React.ComponentPropsWithoutRef<'form'>
>(
  (
    props: Pick<
      React.FormHTMLAttributes<HTMLFormElement>,
      Exclude<
        keyof React.FormHTMLAttributes<HTMLFormElement>,
        'onReset' | 'onSubmit'
      >
    >,
    ref
  ) => {
    const { onSubmit, handleReset } = useFormContext()

    // We default the action to "#" in case the preventDefault fails (just updates the URL hash)
    return (
      <form
        onSubmit={onSubmit}
        ref={ref}
        onReset={handleReset}
        action='#'
        {...props}
      />
    )
  }
)

Form.displayName = 'Form'

export default Form
