import APIResponseType from './APIResponseType'

export const get = (data: { org_id: string }): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/contacts`,
  method: 'GET',
})

export const save = (data: { org_id: string }): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/contacts`,
  method: 'PATCH',
  data,
})
