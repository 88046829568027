import APIResponseType from './APIResponseType'

export const getCampaigns = (data: { org_id: string }): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/campaigns`,
  method: 'GET',
})

export const saveCampaign = (data: {
  org_id: string
  _id?: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/campaigns/${data._id ? data._id : ''}`,
  method: data._id ? 'PATCH' : 'POST',
  data,
})

export const deleteCampaign = (
  org_id: string,
  campaignId: string
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/campaigns/${campaignId}`,
  method: 'DELETE',
})

export const getCampaignPublicData = (
  org_id: string,
  campaignId: string
): APIResponseType => ({
  url: `/orgs/v1/${org_id}/campaigns/${campaignId}`,
  method: 'GET',
})

export const trackCampaignView = (data: {
  org_id: string
  campaignId: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/campaigns/${data.campaignId}/track`,
  method: 'PATCH',
  data,
})

export const getAttachmentURLs = (data: {
  org_id: string
  campaignId: string
  key: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/campaigns/${data.campaignId}/attachment?key=${data.key}`,
  method: 'GET',
})

export const saveContactResponse = (data: {
  org_id: string
  campaignId: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/campaigns/${data.campaignId}/response`,
  method: 'PATCH',
  data,
})
