import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { Icon } from '@iconify/react'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { observer } from 'mobx-react'
import { IObservableArray } from 'mobx'

import OverflowPopup from '../OverflowPopup/OverflowPopup'
import SelectOrgResource from '../SelectOrgResource'
import { GetWrappedIcon } from '../Icons'

import Link from '../../Model/Link'
import ModelBase from '../../Model/ModelBase'

import { getIcon } from '../../lib/fileIcons'

const storeMap: { [propType: string]: string } = {
  content: 'content',
  events: 'event',
  polls: 'poll',
  todos: 'todos',
  messages: 'discussion',
  documents: 'document',
  'organization-forms': 'form',
}

const EditorAttachmentItems: FC<{
  isForTodos?: boolean
  link: Link
  item: {
    links: IObservableArray<Link>
    getNotifyId?: () => string
    _id?: string
    org_id?: string
  }
  idx: number | undefined
}> = ({ isForTodos, link, item, idx }): JSX.Element | null => {
  const [doneEditing, setDoneEditing] = useState(link.hasEnoughData)

  const getLinkIcon = () => {
    if (link.type) {
      if (link.type === 'document') {
        const iconData = getIcon(link.id)
        return (
          <Icon
            icon={iconData.icon}
            style={{ color: iconData.color || 'grey' }}
          />
        )
      }

      if (link.type === 'url') {
        return <GetWrappedIcon name='link' />
      }

      return <GetWrappedIcon name={link.type} />
    }

    return null
  }

  const getSelectOptionIcon = useCallback(() => {
    if (!link.type || link.type === 'url') {
      return null
    }

    return getLinkIcon()
  }, [link?.type])

  const handleUrlChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event
      link.url = value
    },
    [link?.url]
  )

  const handleUrlTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event
      link.title = value
    },
    [link?.title]
  )

  // link item will be displayed if the link has enough data or the user is done editing
  if (link.type === 'url' ? doneEditing : link.hasEnoughData) {
    return (
      <Box>
        <Stack alignItems='center' direction='row' spacing={3}>
          <Stack alignItems='center' direction='row' spacing={1}>
            {getLinkIcon()}&nbsp;
            <OverflowPopup content={link.title} fullWidth />
          </Stack>
          <IconButton
            data-qa='link-remove'
            onClick={() => {
              item.links.replace(
                item.links.filter((lnk, index) => index !== idx)
              )
            }}
          >
            <GetWrappedIcon name='delete' />
          </IconButton>
        </Stack>
      </Box>
    )
  }

  return (
    <Box>
      <SelectOrgResource
        getSelectOptionIcon={getSelectOptionIcon}
        isForTodos={isForTodos}
        item={item}
        onTabChange={() => {
          link.type = undefined
          link.url = undefined
          link.title = undefined
          link.id = undefined
        }}
        onSelect={(obj: ModelBase & { path?: string }) => {
          link.type = storeMap[obj.modelCollection]
          link.title = obj.listTitle
          link.id = obj.getNotifyId()

          if (link.type === 'document') {
            link.org_id = obj.org_id
            link.path = obj.path
          }
        }}
        onUrlBlur={() => {
          if (link.url && link.url.length > 0 && !link.url.startsWith('http')) {
            link.url = `https://${link.url}`
          }
        }}
        onUrlChange={handleUrlChange}
        onUrlTitleChange={handleUrlTitleChange}
        urlSaveButton={
          <Button
            sx={{ mt: 2 }}
            variant='contained'
            color='success'
            disabled={
              !link.url ||
              link.url.length === 0 ||
              !link.title ||
              link.title.length === 0
            }
            onClick={() => {
              link.type = 'url'
              setDoneEditing(true)
            }}
          >
            Create Link
          </Button>
        }
      />
    </Box>
  )
}

EditorAttachmentItems.defaultProps = {
  isForTodos: false,
}

export default observer(EditorAttachmentItems)
