import React, { FC, useState } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import {
  DatePicker as MUIDatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers-pro'
import { observer } from 'mobx-react'
import moment from 'moment'

const DatePicker: FC<
  Omit<DatePickerProps<Date>, 'renderInput'> & {
    dataQa?: string
    disabled?: boolean
    error?: boolean
    helperText?: string
    label?: string | JSX.Element | null
    onChange: (newVal: Date) => void
    required?: boolean
    value: Date
  }
> = ({
  dataQa,
  disabled,
  error,
  helperText,
  label,
  required,
  onChange,
  value,
  ...rest
}): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUIDatePicker
        {...rest}
        data-qa={dataQa}
        disabled={disabled}
        label={label}
        value={new Date(value || moment().valueOf())}
        onChange={onChange}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        slotProps={{
          textField: {
            error,
            helperText,
            onClick: () => setOpen(true),
            required,
          },
        }}
      />
    </LocalizationProvider>
  )
}

DatePicker.defaultProps = {
  dataQa: undefined,
  disabled: false,
  error: false,
  helperText: undefined,
  label: 'Date',
  required: true,
}

export default observer(DatePicker)
