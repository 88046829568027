import { makeObservable, observable, override } from 'mobx'

import ModelBase from './ModelBase'

import { generateID } from '../lib/utils'

import RootStore from '../stores/RootStore'

import { StarItemProps } from '../types'

const starItemProperties = ['uid', 'type', 'title', 'key', 'url', 'parentKey']

export default class StarItem extends ModelBase {
  uid: string | undefined = undefined

  key = generateID()

  parentKey: string | undefined = undefined

  type = ''

  url = ''

  title = ''

  children: this[] | undefined = undefined

  constructor(
    rootStore: RootStore,
    item: StarItemProps,
    parentKey: string | undefined = undefined
  ) {
    super(rootStore)
    makeObservable(this, {
      uid: observable,
      key: observable,
      parentKey: observable,
      type: observable,
      url: observable,
      title: observable,
      children: observable,
      data: override,
    })

    starItemProperties.forEach(prop => {
      this[prop] = item[prop]
    })
    this.key = item.key || generateID() // ensure unique key
    this.parentKey = parentKey
    this.children =
      item.children && item.children.length > 0
        ? item.children.map(
            child => new StarItem(this.rootStore, child, item.key || undefined)
          )
        : item.type === 'folder'
        ? []
        : undefined
  }

  get data() {
    const raw = JSON.parse(
      JSON.stringify(
        starItemProperties.reduce((acc, key) => {
          acc[key] = this[key]
          return acc
        }, {})
      )
    )

    if (this.type === 'folder') {
      raw.children = (this.children || []).map(c => ({
        ...c.data,
        parentKey: this.key,
      }))
      raw.allowDrop = true
    } else {
      raw.allowDrop = false
    }
    return raw
  }
}
