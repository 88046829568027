import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { ViewModel } from 'mobx-utils'

import Documents from '../Model/Documents'
import Folder from '../Model/Folder'
import File from '../Model/File'
import Link from '../Model/Link'

import RootStore from './RootStore'
import StoreResourceBase from './StoreResourceBase'

import { flattenFolders } from '../lib/documentsHelper'
import { getSocket } from '../lib/socket'
import { generateDocumentSlug } from '../lib/slug'
import { showNotification } from '../lib/utils'

export default class DocumentStore extends StoreResourceBase {
  documents: Documents | undefined = undefined

  hostOrgDocuments: Documents | undefined = undefined

  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      documents: observable,
      hostOrgDocuments: observable,
      getDocuments: action,
      getHostOrgDocuments: action,
      loadDocuments: action,
      loadHostOrgDocuments: action,
      flattenedDocuments: computed,
      handleDocumentRename: action,
      refreshDocuments: action,
      getDocumentLinkDownloadUrl: action,
      getDocumentLinkPreviewUrl: action,
    })

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
  }

  async loadDocuments(data: { org_id: string; contents: (Folder | File)[] }) {
    this.documents = new Documents(this.rootStore, data)

    /**
     * These next few lines is a little hack
     * to force refresh the document list if a user
     * is on the documents page when this is loaded
     */
    this.documents.saved = true

    this.documents.saved = await new Promise(resolve => {
      setTimeout(() => {
        resolve(false)
      }, 1000)
    })

    this.loadedResource = true
  }

  loadHostOrgDocuments(data: { org_id: string; contents: (Folder | File)[] }) {
    this.hostOrgDocuments = new Documents(this.rootStore, data)
  }

  getDocuments = async () => {
    if (
      this.rootStore.orgStore.currentOrg &&
      this.rootStore.orgStore.currentOrg._id
    ) {
      try {
        const { data } = await this.rootStore.client.documents.getDocuments(
          this.rootStore.orgStore.currentOrg._id
        )
        this.loadDocuments(data)
        return true
      } catch (e) {
        this.loadedResource = true
      }
    }
    return false
  }

  getHostOrgDocuments = async () => {
    this.hostOrgDocuments = undefined

    if (!this.rootStore.orgStore.currentOrg.isHostUser) {
      return
    }

    try {
      const res = await this.rootStore.client.documents.getDocuments(
        this.rootStore.orgStore.currentOrg.host
      )
      this.loadHostOrgDocuments(res.data)
    } catch (e) {
      this.hostOrgDocuments = undefined
    }
  }

  handleDocumentRename = async (
    node: Folder | File | ViewModel<Folder | File>,
    currentName: string,
    newName: string
  ) => {
    if (
      this.documents &&
      this.rootStore.orgStore.currentOrg &&
      this.rootStore.orgStore.currentOrg._id
    ) {
      await this.documents.save()
      const hasStars =
        this.rootStore.starStore &&
        this.rootStore.starStore.items &&
        this.rootStore.starStore.items.length > 0
      // Get old name for uid for folder
      //
      const uid =
        node.type === 'folder'
          ? node.path.replace(newName, currentName)
          : node.key
      const folderNeedsUpdate = stringToCheck =>
        /^\/.*/.test(stringToCheck) && stringToCheck.includes(currentName)
      if (hasStars) {
        const newItems = this.rootStore.starStore.items.map(item => {
          if (item.type === 'document') {
            if (item.uid === uid) {
              item.title = newName
            }
            if (node.type === 'folder' && folderNeedsUpdate(item.uid)) {
              const newUid = item.uid.replace(currentName, newName)
              item.uid = newUid
              item.url = `/documents/${generateDocumentSlug(
                this.rootStore.orgStore.currentOrg._id,
                {
                  path: newUid,
                }
              )}`
            }
          }
          return item.data
        })
        await this.rootStore.starStore.save(newItems)
      }
      const handleItemUpdate = item => {
        const hasDocumentLinks =
          item.links &&
          item.links.length > 0 &&
          item.links.some(link => link.type === 'document' && link.id === uid)
        if (hasDocumentLinks) {
          item.links = item.links.map(link => {
            if (link.type === 'document') {
              if (link.id === uid) {
                link.title = newName
              }
              if (node.type === 'folder' && folderNeedsUpdate(link.id)) {
                link.id = link.id.replace(currentName, newName)
              }
            }
            return link
          })
          if (item.save) item.save()
        }
      }
      const toBeChecked = [
        'content',
        'events',
        'polls',
        'discussions',
        'campaigns',
      ]

      const itemToStoreMap = new Map()
      itemToStoreMap.set('content', 'contentStore')
      itemToStoreMap.set('events', 'eventStore')
      itemToStoreMap.set('polls', 'pollStore')
      itemToStoreMap.set('discussions', 'discussionStore')
      itemToStoreMap.set('campaigns', 'campaignStore')

      toBeChecked.forEach(
        type =>
          this.rootStore[itemToStoreMap.get(type)][type] &&
          this.rootStore[itemToStoreMap.get(type)][type].forEach(
            handleItemUpdate
          )
      )
    }
    return true
  }

  refreshDocuments = async (item: unknown) => {
    const now = Date.now()
    await this.getDocuments()

    if (item) {
      this.flattenedDocuments.forEach(doc => {
        if (doc.creator && doc.creator !== this.rootStore.userStore._id) {
          const diffInSeconds = Math.abs(Math.round(doc.modified - now)) / 1000

          if (
            diffInSeconds < 3 &&
            !doc.viewedBy.includes(this.rootStore.userStore._id)
          ) {
            showNotification(
              `New Document Created`,
              'event',
              doc.permalink,
              this.rootStore
            )
          }
        }
      })
    }
  }

  getDocumentLinkDownloadUrl = async (link: Link) => {
    if (link.type === 'document' && link.id) {
      const { data } =
        await this.rootStore.client.documents.getDocumentDownload(
          {
            file: link.id,
            org_id: this.rootStore.orgStore.currentOrg._id,
            host_id:
              link.org_id === this.rootStore.orgStore.currentOrg.host
                ? this.rootStore.orgStore.currentOrg.host
                : undefined,
          },
          link.path
            ? {
                path: link.path,
              }
            : undefined
        )
      return data
    }
    return ''
  }

  getDocumentLinkPreviewUrl = async (link: Link) => {
    if (link.type === 'document' && link.id) {
      const { data } = await this.rootStore.client.documents.getDocumentPreview(
        {
          file: link.id,
          org_id: this.rootStore.orgStore.currentOrg._id,
          host_id:
            link.org_id === this.rootStore.orgStore.currentOrg.host
              ? this.rootStore.orgStore.currentOrg.host
              : undefined,
        }
      )
      return data
    }
    return ''
  }

  flattenDocs = (docs: Documents): File[] => {
    const flat: File[] = []
    const hasContent = docs && docs.contents && docs.contents.length > 0

    if (hasContent) {
      const recurseTree = (node, parentPath = '') => {
        return node.forEach(cur => {
          if (cur.type === 'folder') {
            recurseTree(cur.contents, `${parentPath}/${cur.name}`)
          } else {
            flat.push(cur)
          }
        })
      }
      recurseTree(docs.contents || [])
    }
    return flat
  }

  handleSocket = () => {
    const socket = getSocket()
    socket.on('documents', async message => {
      await this.getDocuments()
      const { data } = JSON.parse(message)
      const { fromExport } = data

      if (fromExport) {
        return showNotification(
          'Your document export is now available',
          'event',
          this.allFlattenedDocuments.find(doc => doc.key === data.key)
            ?.permalink,
          this.rootStore
        )
      }

      return true
    })
  }

  get flattenedDocuments() {
    return this.flattenDocs(this.documents)
  }

  get flattendHostFolders() {
    return this.hostOrgDocuments ? flattenFolders(this.hostOrgDocuments) : []
  }

  get flattendHostDocuments() {
    return this.hostOrgDocuments ? this.flattenDocs(this.hostOrgDocuments) : []
  }

  get allFlattenedDocuments() {
    return this.flattenedDocuments
  }

  get flattenedFolders() {
    return flattenFolders(this.documents)
  }

  get allFlattenedFolders() {
    return this.flattenedFolders
  }
}
