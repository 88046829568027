import {
  action,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
} from 'mobx'

import OrganizationForm from '../Model/OrganizationForm'
import OrganizationFormField from '../Model/OrganizationFormField'

import RootStore from './RootStore'
import StoreResourceBase from './StoreResourceBase'

import { OrgFormProps } from '../types'

import { handleSocketListener } from '../lib/socket'

export default class OrgFormStore extends StoreResourceBase {
  orgForms: IObservableArray<OrganizationForm> = observable.array([])

  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      loadData: action,
      orgForms: observable,
      getOrgForms: action,
      getModel: action,
    })

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
    reaction(() => this.orgForms, this.handleSocket)
  }

  loadData(data: OrgFormProps[]) {
    this.orgForms = observable.array(
      data.map(
        (orgForm: {
          createdAt: number
          fields: OrganizationFormField[] | undefined
          updatedAt: number
          templateId: string | undefined
          contacts?: { groups: []; users: [] } | undefined
        }) => new OrganizationForm(this.rootStore, orgForm)
      )
    )
  }

  getModel(data: OrgFormProps): OrganizationForm {
    if (data._id) {
      const model = this.orgForms.find(
        (orgForm: OrganizationForm) => orgForm._id === data._id
      )
      if (model) {
        return model
      }
    }
    return new OrganizationForm(this.rootStore, data)
  }

  getOrgForms = async (org = this.rootStore.orgStore.currentOrg) => {
    if (org && org._id) {
      try {
        const { data } = await this.rootStore.client.organizationForms.get(
          org._id
        )

        this.orgForms = observable.array(
          data.map(
            (form: {
              createdAt: number
              fields: OrganizationFormField[] | undefined
              updatedAt: number
              templateId: string | undefined
              contacts?: { groups: []; users: [] } | undefined
            }) => new OrganizationForm(this.rootStore, form)
          )
        )
        this.loadedResource = true
      } catch (e) {
        this.orgForms = observable.array([])
        this.loadedResource = true
      }
    }
  }

  handleSocket = () => {
    if (this.rootStore.userStore._id) {
      handleSocketListener(
        'orgForms',
        OrganizationForm,
        this.orgForms,
        this.rootStore
      )
    }
  }
}
