import React, { ChangeEvent, FC, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react'

import ClientSmartTagModal from '../ModalDialog/ClientSmartTagModal'

import MultiSelect from './MultiSelect'

import ModelBase from '../../Model/ModelBase'

import { useRootStore } from '../../services/RootStoreContext'

const ClientTagPermissionFilter: FC<{
  helperText?: JSX.Element | string
  item: ModelBase
  disabled?: boolean
  onChange: (newValue: string[]) => void
}> = ({ helperText, item, disabled, onChange, ...rest }) => {
  const {
    tagStore: { tags, getAvailableTags },
  } = useRootStore()

  const handleSelectOnChange = useCallback(
    (_event: ChangeEvent, newValue: string[]) => {
      onChange(newValue.filter(Boolean).sort())
    },
    []
  )

  const availableTags = useMemo(() => getAvailableTags(), [tags.toJSON()])

  const options = availableTags.map(orgTag => ({
    key: orgTag.name,
    label: orgTag.name,
    value: orgTag.name.toLowerCase(),
  }))

  const actualHelperText = useMemo(
    () =>
      item.permissionTags.length > 0 ? (
        <ClientSmartTagModal item={item} />
      ) : (
        helperText || undefined
      ),
    [item.permissionTags]
  )

  if (options.length > 0) {
    return (
      <MultiSelect
        filterSelectedOptions
        helperText={actualHelperText}
        label='Client Tag(s) Permissions'
        limitTags={5}
        onChange={handleSelectOnChange}
        options={options}
        disabled={disabled}
        value={item.permissionTags}
        {...rest}
      />
    )
  }

  return null
}

ClientTagPermissionFilter.defaultProps = {
  helperText: undefined,
  disabled: false,
}

export default observer(ClientTagPermissionFilter)
