import React, { FC, useState } from 'react'
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import { observer } from 'mobx-react'
import { createViewModel, ViewModel } from 'mobx-utils'

import { GetWrappedIcon } from '../Icons'
import ConfirmNotificationOnSave from '../ConfirmNotificationOnSave/ConfirmNotificationOnSave'
import ShareItem from '../ShareItem/ShareItem'
import StarItem from '../StarItem/StarItem'

import TagAction from './TagAction'

import ModelBase from '../../Model/ModelBase'

import { useRootStore } from '../../services/RootStoreContext'

const Actions: FC<{
  actions?: JSX.Element | JSX.Element[]
  addShare?: boolean
  closeOnClick?: boolean
  item?: ModelBase | ViewModel<ModelBase>
}> = ({ actions, addShare, closeOnClick, item }): JSX.Element => {
  const { orgStore, userStore } = useRootStore()
  const { currentOrg } = orgStore
  const { isImpersonating } = currentOrg
  const [anchorEl, setAnchorEl] = useState(undefined)

  const open = Boolean(anchorEl)
  const closeMenu = () => {
    setAnchorEl(undefined)
  }
  const getShareRecord = () => {
    if (item instanceof ViewModel) {
      return item
    }
    return createViewModel(item)
  }

  return (
    <div>
      <IconButton
        onClick={({ currentTarget }) => {
          setAnchorEl(currentTarget)
        }}
      >
        <GetWrappedIcon name='actions' data-qa='actions-menu-opener' />
      </IconButton>
      <Menu
        onClose={closeMenu}
        role='presentation'
        open={open}
        anchorEl={anchorEl}
        onClick={closeOnClick ? closeMenu : undefined}
        keepMounted
      >
        {item && (
          <StarItem
            item={item}
            category={(item && item.modelCollection) || 'documents'}
            showText
          />
        )}
        {item && item.modelCollection && <TagAction forMenu item={item} />}
        {addShare &&
          orgStore &&
          item &&
          item.modelCollection !== 'discussions' && (
            <ShareItem item={getShareRecord()} type='action' />
          )}
        {item &&
          item.modelCollection &&
          item.modelCollection !== 'documents' &&
          !item.isInProgress && (
            <ConfirmNotificationOnSave
              currentUserId={userStore._id}
              currentOrg={currentOrg}
              isSendOnly
              onConfirm={() => {
                if (item.modelCollection === 'events') {
                  return item.save(false, true)
                }

                return item.save(true)
              }}
              item={item}
            >
              <MenuItem disabled={isImpersonating}>
                <ListItemIcon>
                  <GetWrappedIcon name='notify' />
                </ListItemIcon>
                <ListItemText>Notify</ListItemText>
              </MenuItem>
            </ConfirmNotificationOnSave>
          )}
        {actions}
      </Menu>
    </div>
  )
}

Actions.defaultProps = {
  actions: null,
  addShare: false,
  closeOnClick: true,
  item: undefined,
}

export default observer(Actions)
