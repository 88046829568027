import React from 'react'

import RootStore from '../stores/RootStore'

export const rootStore = new RootStore()

/* Store helpers */
export const RootStoreContext = React.createContext(rootStore)

/* Hook to use store in any functional component */
export const useRootStore = () => React.useContext(RootStoreContext)
