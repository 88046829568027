import React, { FC, Fragment, useState, useEffect } from 'react'
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  Paper,
  Divider,
} from '@mui/material'
import { observer } from 'mobx-react'

import { AddAnotherItem } from '../../../../../components/Buttons'
import { RemoveTrigger } from '../../../../../components/ListTable'
import { Select, useFormContext } from '../../../../../components/Form'
import { useRootStore } from '../../../../../services/RootStoreContext'

const RelationshipManagement: FC = (): JSX.Element => {
  const [selectedAssigneeId, setSelectedAssignee] = useState('')
  const { model: orgData } = useFormContext()

  const { orgStore } = useRootStore()
  const { currentOrg } = orgStore
  const { relationshipManagement } = currentOrg

  const orgAssigments =
    relationshipManagement &&
    relationshipManagement.assignments.find(
      assigned => assigned.org_id === orgData._id
    )

  useEffect(() => {
    orgData.relationshipManagementAssignments =
      JSON.stringify(orgAssigments?.users) ?? ''
  }, [JSON.stringify(orgAssigments)])

  return (
    <Container maxWidth='lg'>
      <Stack spacing={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Fragment>
                <Box>
                  {orgAssigments &&
                  orgAssigments.users &&
                  orgAssigments.users.length > 0
                    ? orgAssigments.users.map(advisor => {
                        const currentMember = currentOrg.getMemberDetailsById(
                          advisor._id
                        )

                        if (!currentMember) {
                          return null
                        }

                        return (
                          <Paper
                            key={advisor._id}
                            variant='outlined'
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                              pt: 1,
                              pb: 1,
                              mb: 1,
                              pl: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirecton: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography>{currentMember.name}</Typography>
                              <Box sx={{ pl: 1, pr: 1 }}>
                                <RemoveTrigger
                                  content='Remove Contact'
                                  onClick={() =>
                                    relationshipManagement.removeAssigneeFromList(
                                      orgData._id,
                                      advisor._id
                                    )
                                  }
                                />
                              </Box>
                            </Box>
                            <Divider sx={{ mb: 1, mt: 1 }} />
                            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                              {relationshipManagement.roleOptions.map(
                                roleOpt => (
                                  <Grid
                                    item
                                    xs={2}
                                    sm={4}
                                    md={4}
                                    key={roleOpt.key}
                                  >
                                    <FormControlLabel
                                      key={roleOpt.key}
                                      control={
                                        <Checkbox
                                          checked={advisor.roles.includes(
                                            roleOpt.value
                                          )}
                                          onChange={() => {
                                            if (
                                              advisor.roles.includes(
                                                roleOpt.value
                                              )
                                            ) {
                                              advisor.roles =
                                                advisor.roles.filter(
                                                  r => r !== roleOpt.value
                                                )
                                            } else {
                                              advisor.roles.push(roleOpt.value)
                                            }
                                          }}
                                        />
                                      }
                                      label={roleOpt.label}
                                    />
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </Paper>
                        )
                      })
                    : null}
                </Box>
                <Paper variant='outlined'>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      p: 2,
                    }}
                  >
                    <Select
                      label='Select Contact'
                      onChange={(e, { value }) => {
                        setSelectedAssignee(value)
                      }}
                      options={relationshipManagement.assigneeOptions(
                        orgData._id
                      )}
                      required
                      value={selectedAssigneeId || ''}
                    />
                  </Box>
                  <Grid sx={{ mb: 1, pl: 2, pr: 2 }}>
                    <AddAnotherItem
                      disabled={selectedAssigneeId === ''}
                      id='add-advisor-assignee-button'
                      onClick={() => {
                        relationshipManagement.addAssigneeToList(
                          orgData._id,
                          selectedAssigneeId
                        )
                        setSelectedAssignee('')
                      }}
                    >
                      Add Selected Contact
                    </AddAnotherItem>
                  </Grid>
                </Paper>
              </Fragment>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  )
}

export default observer(RelationshipManagement)
