import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'

import Select, { SelectProps } from './Select'

import { useRootStore } from '../../services/RootStoreContext'

import { AvailableOrg } from '../../types'

const ClientSelect: FC<
  Omit<SelectProps, 'options'> & {
    filterClient?: (clientToFilter: AvailableOrg) => boolean
  }
> = ({ filterClient, ...rest }): JSX.Element => {
  const {
    orgStore: { availableOrgs, currentOrg },
  } = useRootStore()

  const options = useMemo(
    () =>
      availableOrgs
        .filter(
          org => !org.isHost && org.host === currentOrg._id && filterClient(org)
        )
        .map(org => ({
          ...org,
          key: org.value,
        })),
    [availableOrgs.toJSON(), currentOrg]
  )

  if (!currentOrg.isHost || availableOrgs.length === 0) {
    return null
  }

  return <Select {...rest} options={options} />
}

ClientSelect.defaultProps = {
  filterClient: () => true,
}

export default observer(ClientSelect)
