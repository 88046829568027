import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Alert, Box, Button, IconButton, Stack, Tooltip } from '@mui/material'
import { observer } from 'mobx-react'

import { SaveButton } from '../Buttons'
import { ShareAction } from '../Actions'
import { FormDrawer, FormDrawerContent, FormDrawerHeader } from '../Form'
import { GetWrappedIcon } from '../Icons'

import { useRootStore } from '../../services/RootStoreContext'
import ModelBase from '../../Model/ModelBase'
import SharePublic from './components/SharePublic'

const ShareItem: FC<{
  item: ModelBase
  type: 'action' | 'activity'
}> = ({ item, type }): JSX.Element => {
  const { documentStore, orgStore } = useRootStore()
  const { documents } = documentStore
  const { currentOrg } = orgStore
  const { isOrgAdmin } = currentOrg

  const [errorMessage, setErrorMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [initialExpires] = useState(item.shareUrlExpiresAt)

  const getRecordTitle = useCallback(() => item.listTitle, [item])
  const checkUrl = useCallback(() => {
    if (item.isPublic && !item.shareUrl) {
      item.generatePublicUrl(false)
    }
  }, [item])
  const handleOpen = useCallback(() => {
    setIsOpen(true)

    if (orgStore) {
      checkUrl()
    }
  }, [orgStore])
  const handleClose = useCallback(() => {
    setIsOpen(false)
    setIsSaving(false)
    setErrorMessage('')
  }, [])

  const trigger = useMemo(() => {
    if (!isOrgAdmin || !item.isPublicShareAllowed) {
      return null
    }

    switch (type) {
      case 'action':
        return <ShareAction onClick={handleOpen} />
      case 'activity':
        return (
          <Tooltip
            placement='top'
            title={`${item.listTitle} is shared publicly`}
          >
            <IconButton onClick={handleOpen}>
              <GetWrappedIcon height='16px' width='16px' name='globe' />
            </IconButton>
          </Tooltip>
        )
      default:
        return null
    }
  }, [item, type])

  useEffect(() => {
    checkUrl()
  }, [item])

  const getModalHeader = () => {
    const title = getRecordTitle()
    const recordName = item ? item.displayName : ''
    if (!title || title.length === 0) return `Share ${recordName}`

    return `Share ${recordName} - ${title} Publicly`
  }

  const handleSubmit = useCallback(async () => {
    setIsSaving(true)

    try {
      const hasUrl = Boolean(item.shareUrl)

      if ('submit' in item) {
        item.submit()
      }

      if (item && item.modelCollection === 'documents') {
        await documents.save()
      } else if ('save' in item) {
        await item.save()
      }

      if (item.isPublic) {
        await item.generatePublicUrl(!hasUrl)
      }
    } catch (e) {
      if (e.message) {
        setErrorMessage(e.message)
      }
    }

    setIsSaving(false)
  }, [item])

  return (
    <Fragment>
      {trigger}
      <FormDrawer
        key={`share-${item.getNotifyId()}`}
        onClose={handleClose}
        isOpen={isOpen}
      >
        <FormDrawerHeader onClose={handleClose} title={getModalHeader()}>
          {item.shareUrlExpiresAt ||
          (initialExpires && !item.shareUrlExpiresAt) ? (
            <SaveButton
              isSaving={isSaving}
              onClick={() => {
                handleSubmit().then(() => {
                  if (!errorMessage) {
                    setIsOpen(false)
                  }
                })
              }}
            />
          ) : null}
        </FormDrawerHeader>
        <FormDrawerContent>
          <Box>
            <Stack spacing={2}>
              {Boolean(errorMessage) && (
                <Alert severity='error'>{errorMessage}</Alert>
              )}
              <SharePublic
                isSaving={isSaving}
                onGenerateUrl={() => {
                  item.isPublic = true
                  handleSubmit()
                }}
                record={item}
              />
              {item.isPublic ? (
                <Button
                  color='error'
                  disabled={isSaving}
                  onClick={() => {
                    item.isPublic = false
                    item.shareUrl = ''

                    handleSubmit().then(() => {
                      if (!errorMessage) {
                        setIsOpen(false)
                      }
                    })
                  }}
                  data-qa='share-item-remove-public'
                  variant='contained'
                >
                  Remove Public Access
                </Button>
              ) : null}
            </Stack>
          </Box>
        </FormDrawerContent>
      </FormDrawer>
    </Fragment>
  )
}

export default observer(ShareItem)
