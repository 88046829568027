import React, { memo } from 'react'
import { TextField } from '@mui/material'

const TextArea = props => <TextField multiline {...props} />

TextArea.defaultProps = {
  fullWidth: true,
  rows: 4,
}

export default memo(TextArea)
