import React, { useState, useEffect } from 'react'
import { ListItemIcon, MenuItem, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { ViewModel } from 'mobx-utils'
import PropTypes, { InferProps } from 'prop-types'

import { GetWrappedIcon } from '../Icons'

import { useRootStore } from '../../services/RootStoreContext'

import ModelBase from '../../Model/ModelBase'
import StarItem from '../../Model/StarItem'

import { generateDocumentSlug } from '../../lib/slug'

const StarItemView = ({
  item,
  disabled,
  showText,
}: InferProps<typeof StarItemView.propTypes>) => {
  const {
    allItems,
    orgStore: { currentOrg },
    starStore: { stars },
  } = useRootStore()
  const { isOrgAdmin } = currentOrg
  const [itemFound, setItemFound] = useState(false)
  const itemIUD = item.getStarUID && item.getStarUID()
  useEffect(() => {
    setItemFound(false)
    const findNested = (arr, uid) =>
      arr.forEach(i => {
        if (i.uid === uid) setItemFound(true)
        if (i.children && Array.isArray(i.children)) {
          findNested(i.children, uid)
        }
      })
    if (stars && stars.items) {
      findNested(stars.items, itemIUD)
    }
  }, [item, stars && stars.updated])

  const getIcon = () => {
    return (
      <ListItemIcon>
        <GetWrappedIcon name='stars' />
      </ListItemIcon>
    )
  }
  return stars ? (
    <MenuItem
      onClick={async () => {
        if (!isOrgAdmin || disabled) return
        if (itemFound) {
          await stars.removeItem(itemIUD)
          setItemFound(false)
        } else {
          const fromAllItems = allItems.find(
            aI => item._id === aI.normalized.uid
          )
          const url =
            item.modelCollection === 'documents'
              ? `/documents/${generateDocumentSlug(
                  currentOrg._id,
                  item.type === 'folder'
                    ? { path: itemIUD }
                    : { download: itemIUD }
                )}`
              : fromAllItems
              ? `${fromAllItems.normalized.category.link}/${fromAllItems.normalized.slug}`
              : location.hash
          stars.items.push(
            new StarItem({
              uid: itemIUD,
              parentKey: false,
              title: item.title || item.topic || item.name || item.question,
              url,
              type:
                item.modelCollection === 'documents'
                  ? 'document'
                  : item.modelCollection,
            })
          )
          setItemFound(true)
          await stars.save(stars.data)
        }
      }}
      role='presentation'
    >
      {getIcon()}
      <Typography>
        {showText ? (itemFound ? 'Unstar' : 'Star') : null}
      </Typography>
    </MenuItem>
  ) : null
}

StarItemView.defaultProps = {
  disabled: false,
  showText: false,
}

StarItemView.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.instanceOf(ViewModel),
    PropTypes.instanceOf(ModelBase),
  ]).isRequired,
  disabled: PropTypes.bool,
  showText: PropTypes.bool,
}

export default observer(StarItemView)
