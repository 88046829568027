import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx'
import * as yup from 'yup'

import AssetOverride from './AssetOverride'
import FeeScheduleAssignment from './FeeScheduleAssignment'
import ModelBase from '../ModelBase'
import Organization from '../Organization'

import RootStore from '../../stores/RootStore'

import {
  InvestmentBillingProfileProps,
  InvestmentFeeScheduleAssignmentProps,
  SelectOptionType,
} from '../../types'

import { useLog } from '../../lib/log'

const log = useLog()

export default class BillingProfile extends ModelBase {
  modelCollection = 'investment_billing_profiles'

  _id: string | undefined = undefined

  createdAt = Date.now()

  deletedAt = 0

  updatedAt = Date.now()

  assetOverrides: IObservableArray<AssetOverride> = observable.array([])

  creator: string = undefined

  org: Organization = undefined

  feeScheduleAssignments: IObservableArray<FeeScheduleAssignment> =
    observable.array([])

  org_id = ''

  updator = ''

  constructor(rootStore: RootStore, profile: InvestmentBillingProfileProps) {
    super(rootStore)
    makeObservable(this, {
      addAsssetOverride: action,
      addFeeScheduleAssignment: action,
      removeAssetOverride: action,
      removeFeeScheduleAssignment: action,
      setOrg: action,
      save: action,
      data: override,
      displayName: override,
      validationSchema: override,
      _id: observable,
      assetOverrides: observable,
      feeScheduleAssignments: observable,
      org_id: observable,
      accountIdSelectOptions: computed,
    })

    this.setProps(profile)
  }

  setProps = (data: InvestmentBillingProfileProps) => {
    this._id = data._id || undefined
    this.assetOverrides = observable.array(
      data?.assetOverrides?.map(
        assetOverride => new AssetOverride(this.rootStore, assetOverride)
      ) || []
    )
    this.createdAt = data.createdAt || Date.now()
    this.creator = data.creator || this.rootStore.userStore._id
    this.deletedAt = data.deletedAt || 0
    this.org_id = data.org_id || ''
    this.updatedAt = data.updatedAt || Date.now()
    this.updator = data.updator || this.rootStore.userStore._id
    this.feeScheduleAssignments = observable.array(
      data?.feeScheduleAssignments?.map(
        (schedule: InvestmentFeeScheduleAssignmentProps) =>
          new FeeScheduleAssignment(this.rootStore, schedule)
      ) || []
    )
  }

  addAsssetOverride = () => {
    this.assetOverrides.push(new AssetOverride(this.rootStore))

    return this
  }

  removeAssetOverride = (assetOverride: AssetOverride) => {
    this.assetOverrides.replace(
      this.assetOverrides.filter(
        existing =>
          assetOverride.securityId !== existing.securityId &&
          assetOverride.securityClass !== existing.securityClass
      )
    )

    return this
  }

  addFeeScheduleAssignment = () => {
    if (
      this.feeScheduleAssignments.length === 0 ||
      this.feeScheduleAssignments.every(
        assignment =>
          assignment.accountIds.length > 0 && assignment.feeScheduleId
      )
    ) {
      this.feeScheduleAssignments.push(
        new FeeScheduleAssignment(this.rootStore)
      )
    }

    return this
  }

  removeFeeScheduleAssignment = (
    feeScheduleAssignment: FeeScheduleAssignment
  ) => {
    this.feeScheduleAssignments.replace(
      this.feeScheduleAssignments.filter(
        existing =>
          feeScheduleAssignment.feeScheduleId !== existing.feeScheduleId
      )
    )

    return this
  }

  setOrg = async (orgId = this.org_id) => {
    if (orgId && this?.org?._id !== orgId) {
      try {
        this.org = await this.rootStore.orgStore.getOrgById(orgId)
        this.org_id = this.org._id
      } catch (e) {
        //
      }
    }
  }

  get orgName(): string {
    if (this.org) {
      return this.org.legal_business_name
    }

    if (this.org_id) {
      return (
        this.rootStore.orgStore.availableOrgs.find(
          org => org.value === this.org_id
        )?.label || ''
      )
    }

    return ''
  }

  get id(): string {
    return this._id
  }

  get overrideCount(): number {
    return this.assetOverrides.length
  }

  get feeScheduleAssignmentCount(): number {
    return this.feeScheduleAssignments.length
  }

  get accountIdSelectOptions(): SelectOptionType[] {
    if (this.org_id) {
      this.setOrg()
    }

    if (this.org) {
      return this.org.accounts.map((accountId: string) => ({
        key: accountId,
        value: accountId,
        label: accountId,
      }))
    }

    return []
  }

  get displayName(): string {
    return 'Investment Billing Profile'
  }

  get data(): InvestmentBillingProfileProps {
    return {
      _id: toJS(this._id),
      assetOverrides: this.assetOverrides
        .toJSON()
        .map(assetOverride => assetOverride.data),
      feeScheduleAssignments: this.feeScheduleAssignments
        .toJSON()
        .map(schedule => schedule.data),
      org_id: toJS(this.org_id),
      updator: toJS(this.updator),
    }
  }

  get validationSchema(): yup.Schema<object> {
    const accountIdValidation = yup
      .array()
      .of(yup.string().required('You must select at least one Account'))
      .min(1, 'You must select at least one Account')
    return yup.object().shape({
      assetOverrides: yup.array().of(
        yup.object().shape(
          {
            accountIds: accountIdValidation,
            amount: yup.number(),
            feeType: yup
              .string()
              .oneOf(['flat', 'percent'], 'You must select a Fee Type')
              .required('You must select a Fee Type'),
            securityClass: yup
              .string()
              .when('securityId', ([securityId], schema) => {
                return securityId
                  ? schema.min(0)
                  : schema.required('You must select an Asset Class')
              }),
            securityId: yup
              .string()
              .when('securityClass', ([securityClass], schema) => {
                return securityClass
                  ? schema.min(0)
                  : schema.required('You must select an Asset')
              }),
          },
          ['securityClass', 'securityId']
        )
      ),
      feeScheduleAssignments: yup
        .array()
        .of(
          yup.object({
            accountIds: accountIdValidation,
            feeScheduleId: yup
              .string()
              .required('You must select at least one Fee Schedule'),
          })
        )
        .required()
        .min(1, 'You must select at least one Fee Schedule'),
      org_id: yup.string().required('You must select a Client'),
    })
  }

  save = async () => {
    try {
      const formData = this.data
      formData.assetOverrides = formData.assetOverrides.filter(
        assetOverride =>
          (assetOverride.securityId || assetOverride.securityClass) &&
          assetOverride.accountIds.length > 0
      )
      formData.feeScheduleAssignments = formData.feeScheduleAssignments.filter(
        assignment =>
          assignment.feeScheduleId && assignment.accountIds.length > 0
      )

      const { data } =
        await this.rootStore.client.investmentBilling.saveBillingProfile(
          formData
        )
      this.setProps(data)

      if (
        this.rootStore.investmentBillingStore.billingProfiles.some(
          profile => profile._id === this._id
        )
      ) {
        this.rootStore.investmentBillingStore.billingProfiles.replace(
          this.rootStore.investmentBillingStore.billingProfiles.map(profile => {
            if (profile._id === this._id) {
              return this
            }

            return profile
          })
        )
      } else {
        this.rootStore.investmentBillingStore.billingProfiles.push(this)
      }
    } catch (e) {
      log.error({ errorMessage: e.message, errorStack: e.stack })
      throw e
    }
  }
}
