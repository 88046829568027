import React, { ForwardRefRenderFunction, forwardRef } from 'react'
import { IconButton, IconButtonProps } from '@mui/material'

import { GetWrappedIcon } from '../Icons'

const CreateButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  Omit<IconButtonProps, 'color' | 'children' | 'sx'>
> = (props, ref) => {
  const { sx } = props

  return (
    <IconButton
      {...props}
      children={<GetWrappedIcon name='create' />}
      sx={{
        ...sx,
        backgroundColor: 'primary.main',
        color: 'primary.contrastText',
        '&:hover': {
          backgroundColor: 'primary.dark',
        },
        mb: 1,
        mt: 1,
      }}
      ref={ref}
    />
  )
}

export default forwardRef(CreateButton)
