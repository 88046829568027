import React, { FC } from 'react'
import { Avatar, Chip } from '@mui/material'
import { observer } from 'mobx-react'
import { getNameInitials } from '../../lib/utils'
import { MemberType } from '../../Model/Organization'

const UserChip: FC<MemberType> = ({ user }): JSX.Element => {
  if (typeof user === 'string' || !user) {
    return null
  }

  const { name, fname, lname, photo, label } = user
  const fullName = `${fname ? fname.trim() : ''}${
    lname ? ` ${lname.trim()}` : ''
  }`

  function getAvatar() {
    if (photo) {
      return <Avatar alt={fullName} src={photo} />
    }

    return <Avatar>{getNameInitials(name || fullName || label)}</Avatar>
  }

  return (
    <Chip
      avatar={getAvatar()}
      label={name || fullName || label}
      variant='outlined'
      sx={{ mr: 0.5, mt: 0.5 }}
    />
  )
}

export default observer(UserChip)
