import {
  observable,
  action,
  computed,
  makeObservable,
  IObservableArray,
  reaction,
} from 'mobx'
import moment from 'moment'
import RootStore from './RootStore'

import Link from '../Model/Link'
import Organization from '../Model/Organization'
import Template from '../Model/Template'
import Todo from '../Model/Todo'

import { TodoProps } from '../types'

import { slugMatchesId } from '../lib/slug'
import { handleSocketListener } from '../lib/socket'
import StoreResourceBase from './StoreResourceBase'

export default class TodoStore extends StoreResourceBase {
  todos: IObservableArray<Todo> = observable.array([])

  childOrg: Organization | undefined = undefined

  listById = observable.map()

  updatedOn = moment().valueOf()

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this, {
      todos: observable,
      filterCompletedTodos: action,
      childOrg: observable,
      getTodos: action,
      getTodoCreateModel: action,
      updateTodos: action,
      getModel: action,
      copyTodoAsTemplate: action,
      activeTodos: computed,
      completedTodos: computed,
      archivedTodos: computed,
      todoTemplates: computed,
      updatedOn: observable,
    })

    reaction(() => this.rootStore.userStore._id, this.handleSocket)
    reaction(() => this.todos, this.handleSocket)
  }

  filterCompletedTodos() {
    if (this.rootStore.orgStore.currentOrg) {
      this.todos = observable.array(
        this.todos
          ? this.todos.filter(
              todo =>
                (this.rootStore.orgStore.currentOrg &&
                this.rootStore.orgStore.currentOrg.isHost
                  ? this.rootStore.orgStore.currentOrg._id === todo.org_id
                    ? todo.author === this.rootStore.userStore._id
                    : true
                  : false) || +todo.completedAt === 0
            )
          : []
      )
    }
  }

  updateTodos(todo: Todo) {
    if (
      this.todos.length === 0 ||
      !this.todos.find(td => td._id === todo._id)
    ) {
      this.todos.push(todo)
    } else {
      this.todos[this.todos.findIndex(t => t._id === todo._id)] = todo
    }
  }

  getTodoCreateModel() {
    return new Todo(this.rootStore)
  }

  saveTemplate(templateData: object) {
    const template = new Template(this.rootStore, templateData)
    template.save()
  }

  /**
   * if there is not match for the slug id
   * a new model is NOT returned
   */
  getModelBySlugId(slugId: string): Todo | undefined {
    if (slugId) {
      const model = this.todos.find(t => slugMatchesId(slugId, t._id))

      if (model) {
        return model
      }
    }
    return undefined
  }

  getModel(data: TodoProps): Todo {
    if (data._id) {
      const model = this.todos.find((todo: Todo) => todo._id === data._id)
      if (model) {
        return model
      }
    }
    return new Todo(this.rootStore, data)
  }

  getTodos = async (
    org: Organization = this.rootStore.orgStore.currentOrg
  ): Promise<Todo[]> => {
    try {
      if (org && org._id) {
        const { data } = await this.rootStore.client.todos.get(org._id)

        this.todos = observable.array(
          data.map((todo: TodoProps) => {
            return new Todo(this.rootStore, todo, org)
          })
        )
        this.loadedResource = true
        return this.todos
      }
      this.loadedResource = true
      return []
    } catch (e) {
      this.todos = observable.array([])
      this.loadedResource = true
      return []
    }
  }

  copyTodoAsTemplate = async (todoId: string) => {
    try {
      const model = this.todos.find((todo: Todo) => todo._id === todoId)
      const { data } = model
      /**
       * Sanitize any links that are specific
       * to the org that the To-do that is being
       * copied as a template from
       */
      data.items = data.items.map(
        (item: {
          completed: string[]
          groups: string[]
          users: string[]
          links: Link[]
        }) => {
          item.links = item.links.filter(
            link =>
              Boolean(link.user_id) ||
              (link.org_id &&
                link.org_id === this.rootStore.orgStore.currentOrg._id)
          )
          item.completed = []
          item.groups = []
          item.users = []
          return item
        }
      )
      const name = `${data.name} Template`
      const templateData = {
        author: this.rootStore.userStore._id,
        name,
        org_id: this.rootStore.orgStore.currentOrg._id,
        payload: {
          ...data,
          ...{
            _id: false,
            name,
            org_id: undefined,
          },
        },
        type: 'todo',
      }

      await this.saveTemplate(templateData)

      this.rootStore.addNotificationItem({
        message: `Successfully created ${name}`,
        success: true,
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.addNotificationItem({
        message: `Failed to copy ${name} template`,
        error: true,
      })
      return Promise.reject()
    }
  }

  handleSocket = () => {
    if (this.rootStore.userStore._id) {
      handleSocketListener('todos', Todo, this.todos, this.rootStore)
    }
  }

  get activeTodos() {
    return (
      this.todos.filter(
        (todo: Todo) => todo && !todo.archived && +todo.completedAt === 0
      ) || []
    )
  }

  get completedTodos() {
    return (
      this.todos.filter(
        (todo: Todo) => todo && !todo.archived && +todo.completedAt > 0
      ) || []
    )
  }

  get archivedTodos() {
    return this.todos.filter((todo: Todo) => todo && todo.archived) || []
  }

  get todoTemplates() {
    return (
      this.rootStore.templateStore.templates.filter(t => t.type === 'todo') ||
      []
    )
  }
}
