import { makeObservable, observable } from 'mobx'

import RootStore from '../stores/RootStore'

export default class StoreResourceBase {
  rootStore: RootStore = undefined

  loadedResource = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this, {
      loadedResource: observable,
    })
  }
}
