import React, { lazy, Suspense } from 'react'
import { Box } from '@mui/material'
import { observer } from 'mobx-react'
import { Outlet } from 'react-router-dom'

import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator'
import { AuthProvider } from '../services/Auth'

const AppRefreshModal = lazy(
  () => import('../components/AppRefreshModal/AppRefreshModal')
)
const MeetingModal = lazy(() => import('../pages/Meet/MeetingModal'))
const NewUserSignup = lazy(
  () => import('../components/NewUserSignup/NewUserSignup')
)

export const Authenticated = () => {
  return (
    <AuthProvider>
      <Suspense
        fallback={
          <Box height='100vh' width='100vw'>
            <LoadingIndicator />
          </Box>
        }
      >
        <Outlet />
        <MeetingModal />
        <AppRefreshModal />
        <NewUserSignup />
      </Suspense>
    </AuthProvider>
  )
}

export default observer(Authenticated)
